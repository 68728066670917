.header-wrap {
  width: 100%;
  height: 55px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  background-color: #fff;
  /* box-shadow: 3px 6px 2px rgba(216, 216, 216, 0.309); */
  /* border-bottom: 1px solid #357c42b5; */
  /* border: 2px solid #f3f4f6; 
  */
  border-bottom: 2px solid #f3f4f6;
}
.header-wrap.boxShadow {
}
.header-m-box {
  width: 100%;
  height: 55px;
}
.header-more-wrap {
  min-height: 0;
  transition: 0.1s;
  visibility: hidden;
  overflow: hidden;
  transition-property: min-height;
}

.header-more-wrap.view {
  min-height: 360px;
  position: absolute;
  top: 52px;
  z-index: 999;
  background-color: #fff;
  width: 110%;
  /* min-height: 360px; */
  left: 0;
  border-top: 1px solid #00c73c;
  visibility: visible;
  transition: 0.1s;
  transition-property: min-height;
  border-bottom: 2px solid #f3f4f6;
  /* box-shadow: 1px 4px 2px rgba(184, 184, 184, 0.4); */
}
.header-more-back {
  position: fixed;
  width: 100%;
  min-height: 0;
  background-color: #898989;
  top: 55px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 998;

  visibility: hidden;
}
.header-more-back.backview {
  min-height: 100%;
  background-color: #898989;
  top: 446.18px;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  z-index: 998;
  visibility: visible;
}
.header-more-wrap .header-more-box {
  max-width: 1280px;
  height: auto;
  margin: 0 auto;
  padding-top: 50px;
  padding-left: 180px;
  /* background-color: red; */
}
.header-more-box .header-more-content {
  /* display: flex; */
  display: flex;
  width: 100%;
  min-height: 340px;
  /* background-color: red; */
}
.header-more-content .header-more-ul {
  width: 200px;
  border-right: 2px solid #edeff2;
  padding-bottom: 10px;
}
.header-more-content .header-more-ul:last-child {
  border-right: 0;
}
.header-more-content .header-more-ul.ul-one {
  width: 220px;
  /* padding-right: 20px; */
  padding-left: 20px;
  display: flex;
  flex-direction: column;

  /* justify-content: space-between; */
}
/* .header-more-content .header-more-ul.ul-one li {
  margin-bottom: 11px;
} */
.header-more-content .header-more-ul li {
  padding-left: 15px;
  margin-bottom: 11px;
  font-size: 14px;
  height: 20px;
  /* background-color: beige; */
  line-height: 20px;
}
.header-more-content .header-more-ul li:last-child {
  margin-bottom: 0;
}
.header-more-content .header-more-ul .li-more-title {
  font-size: 12px;
  color: #898989;
}
.header-more-content .header-more-ul.ul-one li {
  height: 50px;
  background-color: #e9ecef;
  border-radius: 5px;
  padding: 10px 15px;

  font-size: 14px;
  /* margin-bottom: 0; */
}
.header-more-ul.ul-one .header-li-back {
  width: 35px;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 10px;
}
.header-more-ul.ul-one li .header-li-img {
  width: 100%;
  height: 100%;
}
.header-more-ul.ul-one li a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.header-more-ul.ul-one li:first-child .header-li-img {
  background: url(../../assets//images//hd_blog.png) no-repeat;
  background-position: center;
  background-size: 70%;
}
.header-more-ul.ul-one li:nth-child(2) .header-li-img {
  background: url(../../assets//images//hd_tv.png) no-repeat;
  background-position: center;
  background-size: 60%;
}
.header-more-ul.ul-one li:nth-child(3) .header-li-img {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-more-ul.ul-one li:nth-child(4) .header-li-img {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-more-ul.ul-one li:nth-child(5) .header-li-img {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-more-ul.ul-one li:nth-child(3) .header-li-img svg {
  width: 80%;
  height: 80%;
  /* font-size: 18px; */
  /* background: url(../../assets//images//hd_invest.png) no-repeat;
  background-position: center;
  background-size: 60%; */
  color: #ababab;
}
.header-more-ul.ul-one li:nth-child(4) .header-li-img svg {
  width: 80%;
  height: 80%;
  /* font-size: 18px; */
  /* background: url(../../assets//images//hd_invest.png) no-repeat;
  background-position: center;
  background-size: 60%; */
  color: #ababab;
}
.header-more-ul.ul-one li:nth-child(5) .header-li-img svg {
  width: 80%;
  height: 80%;
  /* font-size: 18px; */
  /* background: url(../../assets//images//hd_invest.png) no-repeat;
  background-position: center;
  background-size: 60%; */
  color: #ababab;
}

.header-box {
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  /* background: red; */
  /* background-color: red; */
  /* background-color: red; */
  /* padding: 0 40px; */
  /* background-color: red; */
  align-items: center;
  /* background-color: red; */
}
.header-box .header-left {
  width: 83px;
  height: 18px;
  /* padding: 0 20px 0 20px; */
}
.header-box .header-left-m {
  display: none;
}
.header-box .header-logo {
  /* 
  width: 108.59281437125px;
  height: 45px; */
}

.header-box .header-right {
  display: flex;
  height: 55px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* background-color: blue; */
  /* margin-left: 0px; */
}
.header-right .header-menu-wrap {
  padding: 0 20px;
  height: 40px;
}
.header-right .header-menu-wrap > ul {
  display: flex;
  height: 40px;
}
.header-right .header-menu-wrap .hd_menu {
  font-weight: 500;
  /* color: #0e9e5d; */
  font-size: 20px;
  padding: 0 20px;
  line-height: 40px;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}
.header-right .header-menu-wrap .hd_menu a {
  /* color: #0e9e5d; */
}
.header-right .header-search-wrap-mbox {
  display: none;
}
.header-right .header-search-wrap-m {
  display: none;
}
.header-right .header-title-m {
  display: none;
}
.header-right .header-search-wrap {
  width: 344px;
  height: 40px;
  border: 1px solid #00c73c;
  border-radius: 5px;
  /* margin-left: 40px; */
  overflow: hidden;
}
.header-search-wrap input {
  width: 290px;
  height: 100%;
  float: left;
  text-indent: 5px;
}
.header-search-wrap input::placeholder {
  font-size: 14px;
  color: #a4abb3;
  text-indent: 5px;
}
.header-search-wrap button {
  width: 50px;
  height: 100%;
  /* background-color: red; */
  text-align: center;
  line-height: 20px;
  float: right;
  font-size: 24px;
  color: #a4abb3;
}
.header-right .header-sub-menu {
  display: flex;
  height: 100%;
  align-items: center;
  /* background-color: beige; */
  width: 260px;
  justify-content: space-between;
  /* margin-left: 50px; */
  /* float: right; */
}
.header-right .header-sub-menu.userview {
  width: 260px;
}
.header-sub-menu .header-move-sub {
  width: 122px;
  height: 41px;
  background-color: #00c73c;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  border-radius: 3px;
  /* border-radius: 5px; */
  text-align: center;
}
.header-sub-menu .header-move-sub a {
  color: #fff;
}
.header-sub-menu .header-login {
  font-size: 14px;
  /* margin-left: 40px; */
  /* font-weight: 500; */
  display: flex;
  padding: 0 10px;
  /* background-color: red; */
  /* margin-right: 15px; */
}
.header-sub-menu .header-login span:first-child {
  margin-right: 10px;
}
.header-login-icon-wrap {
  /* background-color: red; */
  width: 45px;
  height: 45px;
}
.header-login-icon-wrap a {
  width: 100%;
  height: 100%;
}
.header-login-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.header-login-icon img {
  width: 100%;
  height: 100%;
}
.header-login-icon svg {
  width: 100%;
  height: 100%;
}
.header-right .header-mobile-icon {
  display: none;
}
.header-mobile-slider {
  display: none;
}
@media all and (max-width: 1300px) {
  .header-wrap {
    /* width: 100%;
    height: 55px;
    position: relative; */
    padding: 0 20px;
    /* border-bottom: 1px solid #357c42b5; */
  }
  .header-more-wrap {
    min-height: 0;
    transition: 0.1s;
    visibility: hidden;
    overflow: hidden;
    transition-property: min-height;
  }
  /* 
  .header-more-wrap.view {
    min-height: 360px;
    position: absolute;
    top: 55px;
    z-index: 999;
    background-color: #fff;
    width: 100%;

    left: 0;
    border-top: 1px solid #00c73c;
    visibility: visible;
    transition: 0.1s;
    transition-property: min-height;
  }
  .header-more-back {
    position: fixed;
    width: 100%;
    min-height: 0;
    background-color: #898989;
    top: 55px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 998;

    visibility: hidden;
  }
  .header-more-back.backview {
    min-height: 100%;
    background-color: #898989;
    top: 55px;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.3;
    z-index: 998;
    visibility: visible;
  } */
  .header-more-wrap .header-more-box {
    max-width: 1280px;
    height: auto;
    margin: 0 auto;
    padding-top: 50px;
    padding-left: 180px;

    /* background-color: red; */
  }
  .header-more-box .header-more-content {
    /* display: flex; */
    display: flex;
    width: 100%;
    /* min-height: 250px; */
    /* background-color: red; */
  }
  .header-more-content .header-more-ul {
    width: 200px;
    border-right: 2px solid #edeff2;
    padding-bottom: 10px;
  }
  .header-more-content .header-more-ul:last-child {
    border-right: 0;
  }
  .header-more-content .header-more-ul.ul-one {
    width: 220px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }
  .header-more-content .header-more-ul li {
    padding-left: 15px;
    margin-bottom: 11px;
    font-size: 14px;
    height: 20px;
    /* background-color: beige; */
    line-height: 20px;
  }
  .header-more-content .header-more-ul li:last-child {
    margin-bottom: 0;
  }
  .header-more-content .header-more-ul .li-more-title {
    font-size: 12px;
    color: #898989;
  }
  .header-more-content .header-more-ul.ul-one li {
    height: 50px;
    background-color: #e9ecef;
    border-radius: 5px;
    padding: 10px 15px;

    font-size: 14px;
    /* margin-bottom: 0; */
  }
  .header-more-ul.ul-one .header-li-back {
    width: 35px;
    height: 100%;
    border-radius: 5px;
    background-color: #fff;
    margin-right: 10px;
  }
  .header-more-ul.ul-one li .header-li-img {
    width: 100%;
    height: 100%;
  }
  .header-more-ul.ul-one li a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .header-more-ul.ul-one li:first-child .header-li-img {
    background: url(../../assets//images//hd_blog.png) no-repeat;
    background-position: center;
    background-size: 70%;
  }
  .header-more-ul.ul-one li:nth-child(2) .header-li-img {
    background: url(../../assets//images//hd_tv.png) no-repeat;
    background-position: center;
    background-size: 60%;
  }
  .header-more-ul.ul-one li:nth-child(3) .header-li-img {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-more-ul.ul-one li:nth-child(4) .header-li-img {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-more-ul.ul-one li:nth-child(5) .header-li-img {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-more-ul.ul-one li:nth-child(3) .header-li-img svg {
    width: 80%;
    height: 80%;
    /* font-size: 18px; */
    /* background: url(../../assets//images//hd_invest.png) no-repeat;
    background-position: center;
    background-size: 60%; */
    color: #ababab;
  }
  .header-more-ul.ul-one li:nth-child(4) .header-li-img svg {
    width: 80%;
    height: 80%;
    /* font-size: 18px; */
    /* background: url(../../assets//images//hd_invest.png) no-repeat;
    background-position: center;
    background-size: 60%; */
    color: #ababab;
  }
  .header-more-ul.ul-one li:nth-child(5) .header-li-img svg {
    width: 80%;
    height: 80%;
    /* font-size: 18px; */
    /* background: url(../../assets//images//hd_invest.png) no-repeat;
    background-position: center;
    background-size: 60%; */
    color: #ababab;
  }
  .header-box {
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    /* padding: 0 20px; */
    /* background: red; */
    /* background-color: red; */
    /* background-color: red; */
    /* padding: 0 40px; */
    /* background-color: red; */
    align-items: center;
    /* background-color: red; */
  }
  .header-box .header-left {
    width: 83px;
    height: 18px;
    /* padding: 0 20px 0 20px; */
  }
  .header-box .header-logo {
    width: 83px;
    height: 18px;
    /* 
    width: 108.59281437125px;
    height: 45px; */
  }

  .header-box .header-right {
    display: flex;
    height: 55px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 20px; */
    /* background-color: blue; */
    /* margin-left: 0px; */
  }
  .header-right .header-menu-wrap {
    padding: 0 20px;
    height: 40px;
  }
  .header-right .header-menu-wrap > ul {
    display: flex;
    height: 40px;
  }
  .header-right .header-menu-wrap .hd_menu {
    font-weight: 500;
    /* color: #0e9e5d; */
    font-size: 18px;
    padding: 0 10px;
    line-height: 40px;
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }
  .header-right .header-menu-wrap .hd_menu a {
    /* color: #0e9e5d; */
  }
  .header-right .header-search-wrap {
    width: 26.875%;
    height: 40px;
    border: 1px solid #00c73c;
    border-radius: 5px;
    /* margin-left: 40px; */
    overflow: hidden;
  }
}
@media all and (max-width: 1100px) {
  .header-more-ul.mobile_out li:last-child {
    display: flex;
  }
  .header-more-back {
    /* display: none; */
  }
  .header-more-back.backview {
    min-height: 100%;
    background-color: #898989;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.3;
    z-index: 99;
    visibility: visible;
  }
  .header-wrap {
    /* height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 996;
    background-color: transparent;
    background-color: #fff; */
  }

  .header-box {
    flex-wrap: nowrap;
  }
  .header-box .header-left {
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 30px; */
    /* display: none; */
    display: none;
  }
  .header-box .header-left-m {
    display: block;
    width: 64px;
    height: 20px;
    position: absolute;
    z-index: 1;
    /* padding: 0 20px 0 20px; */
  }
  .header-box .header-left-m .header-logo {
    width: 100%;
    height: 100%;
  }
  .header-box .header-right {
    justify-content: flex-start;
    position: relative;
    /* background-color: blue; */
    /* margin-left: 0px; */
  }
  .header-right .header-menu-wrap {
    display: none;
  }
  .header-right .header-search-wrap {
    display: none;
  }
  .header-right .header-search-wrap-mbox {
    display: block;

    height: 40px;

    /* margin-left: 40px; */
    overflow: hidden;
    flex: 1;
    padding: 0 64px 0 80px;
  }

  .header-right .header-search-wrap-m {
    display: block;
    /* width: 26.875%; */
    width: 100%;
    height: 40px;
    border: 1px solid #00c73c;
    border-radius: 5px;
    /* margin-left: 40px; */
    overflow: hidden;
    display: flex;
    /* flex: 1; */
  }

  .header-right .header-title-m {
    display: block;

    flex: 1;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #00c73c;
  }
  .header-search-wrap-mbox {
    width: 100%;
  }
  .header-search-wrap-m input {
    /* width: 70%; */

    flex: 1;
    height: 100%;
    /* float: left; */
    text-indent: 5px;
  }
  .header-search-wrap-m input::placeholder {
    font-size: 14px;
    color: #a4abb3;
    text-indent: 5px;
  }
  .header-search-wrap-m button {
    width: 50px;
    height: 100%;
    flex: none;
    /* background-color: red; */
    text-align: center;
    line-height: 20px;
    /* float: right; */
    font-size: 24px;
    color: #a4abb3;
  }

  .header-right .header-sub-menu {
    display: none;
  }
  .header-right .header-mobile-icon {
    display: block;
    /* width: 24px;
    height: 24px; */
    width: 30px;
    height: 30px;
    margin-left: 10px;
    font-size: 30px;
    color: #00c73c;
    position: absolute;
    z-index: 1;
    right: 0;
    /* right: 20px; */
    /* background-color: red; */
    /* background-color: red; */
  }
  .header-right .header-mobile-icon img {
    width: 100%;
    height: 100%;
  }
  .header-mobile-slider {
    display: block;
    width: 100%;
    height: auto;
    padding: 20px;
    position: relative;

    /* background-color: red; */
  }
  .header-more-wrap {
    position: fixed;
    right: 0;
    top: 0;
    /* display: none; */
  }
  .header-more-wrap.view {
    position: fixed;
    z-index: 1000;
    left: auto;
    right: 0;
    top: 0;
    max-width: 450px;
    height: 100%;
    border-top: 0;
  }
  .header-more-wrap .header-more-box {
    max-width: auto;
    height: auto;
    margin: 0 auto;
    padding-top: 0;
    padding-left: 0;
    /* background-color: red; */
  }
  .header-more-box .header-more-content {
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
  }
  .header-more-content .header-more-ul {
    width: 100%;
    border-right: 0;
    padding-bottom: 0;
    padding: 0 20px;
    margin-bottom: 20px;
  }

  .header-more-content .header-more-ul.ul-one {
    width: 100%;
    padding-right: 0;
    padding: 0 20px 20px;
    margin-bottom: 0;

    /* justify-content: space-between; */
  }

  .header-more-content .header-more-ul li {
    padding-left: 0;
    margin-bottom: 11px;
    font-size: 16px;
    width: 100%;
    height: 28px;
    text-indent: 10px;
    /* background-color: beige; */
    /* line-height: 20px; */
    /* border-bottom: 1px solid #898989; */
  }
  .header-more-content .header-more-ul.ul-one li {
    text-indent: 0;
    /* background-color: beige; */
    /* line-height: 20px; */
    /* border-bottom: 1px solid #898989; */
  }

  .header-more-content .header-more-ul li a {
    width: 100%;
    height: 100%;
    font-weight: 500;
  }
  .header-more-content .header-more-ul li.sub a {
    font-size: 12px;
    color: #898989;
  }
  .header-more-content .header-more-ul .li-more-title {
    /* display: none; */
  }
  .header-mobile-sliderul {
  }
  .header-mobile-sl {
  }
  .header-mobile-sl .slick-arrow {
    display: none !important;
  }
  .header-mobile-sl .header-mobile-sliderli {
    padding: 0 10px;
    /* padding: 0 20px; */
    /* width: 100%; */
  }
  .header-mobile-sl .header-mobile-sliderli a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .header-slider-img-box {
    width: 60px;
    height: 45px;
    background-color: #e9ebee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .header-slider-img {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .header-slider-img img {
  }
  .header-mobile-sl li span {
    font-size: 12px;
  }

  .header_custom_class_name {
    /* background-color: red; */
    position: absolute;
    bottom: -10px;

    width: 100%;
    display: flex !important;
    justify-content: center;
    /* margin-top: 30px; */
  }
  .header_custom_class_name > li {
    width: 7px;
    height: 7px;
    background-color: #e9ebee;
    border-radius: 50%;
    overflow: hidden;

    margin-right: 5px;
    /* opacity: 0.5; */
    cursor: pointer;
  }
  .header_custom_class_name > li.slick-active {
    /* opacity: 1; */
    background-color: #aeb4bc;
  }

  .header-slider-dot {
    display: block;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }
}

@media all and (max-width: 660px) {
  .header-more-wrap.view {
    position: fixed;
    z-index: 1000;
    left: auto;
    right: 0;
    top: 0;
    max-width: 250px;
    height: 100%;
    border-top: 0;
  }
  .header-more-box .header-more-content {
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
  }
  .header-more-content .header-more-ul {
    width: 100%;
    border-right: 0;
    padding-bottom: 0;
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .header-more-content .header-more-ul.ul-one {
    width: 100%;
    padding-right: 0;
    padding: 0 10px 10px;
    margin-bottom: 0;
    display: flex;

    /* justify-content: space-between; */
  }
  .header-more-content .header-more-ul li {
    padding-left: 0;
    margin-bottom: 8px;
    font-size: 14px;
    width: 100%;
    height: 24px;
    text-indent: 5px;
    /* background-color: beige; */
    /* line-height: 20px; */
    /* border-bottom: 1px solid #898989; */
  }
  .header-more-content .header-more-ul.ul-one li {
    padding: 5px 10px;
    height: 40px;
  }
  .header-more-ul.ul-one li:first-child .header-li-img {
    background: url(../../assets//images//hd_blog.png) no-repeat;
    background-position: center;
    background-size: 70%;
  }
  .header-more-ul.ul-one li:nth-child(2) .header-li-img {
    background: url(../../assets//images//hd_tv.png) no-repeat;
    background-position: center;
    background-size: 60%;
  }
  .header-more-ul.ul-one li:nth-child(3) .header-li-img {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-more-ul.ul-one li:nth-child(4) .header-li-img {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-more-ul.ul-one li:nth-child(5) .header-li-img {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-more-ul.ul-one li:nth-child(3) .header-li-img svg {
    width: 80%;
    height: 80%;
    /* font-size: 18px; */
    /* background: url(../../assets//images//hd_invest.png) no-repeat;
    background-position: center;
    background-size: 60%; */
    color: #ababab;
  }
  .header-more-ul.ul-one li:nth-child(4) .header-li-img svg {
    width: 80%;
    height: 80%;
    /* font-size: 18px; */
    /* background: url(../../assets//images//hd_invest.png) no-repeat;
    background-position: center;
    background-size: 60%; */
    color: #ababab;
  }
  .header-more-ul.ul-one li:nth-child(5) .header-li-img svg {
    width: 80%;
    height: 80%;
    /* font-size: 18px; */
    /* background: url(../../assets//images//hd_invest.png) no-repeat;
    background-position: center;
    background-size: 60%; */
    color: #ababab;
  }
  .header-search-wrap-m input {
  }
  .header-search-wrap-m input::placeholder {
    font-size: 12px;
  }
  .header-search-wrap-m button {
  }
}
