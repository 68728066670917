/* 배너 */
.main_banner_wrap {
  width: 100%;
  height: 400px;
}
.main_slick_div {
  width: 100%;
  height: 100%;
}
.main_slick_div > a {
  width: 100%;
  height: 100%;
}
.main_slick_div .main_slick_background {
  width: 100%;
  height: 100%;
  position: relative;
}
.main_slick_div .main_slick_background::after {
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
}
.main_slick_content {
  max-width: 1300px;
  height: 400px;
  margin: 0 auto;
  position: relative;
  padding-left: 130px;
  display: flex;
  align-items: center;
  color: #fff;
  z-index: 2;
}
.main_slick_content .main_slick_content_text p {
  font-size: 30px;
  line-height: 42px;
  font-weight: 400;
}
.main_slick_content .main_slick_content_text em {
  font-size: 40px;
  font-weight: 600;
}

.main_banner_button_wrap {
  position: relative;
  z-index: 2;
  bottom: 70px;

  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  /* left: 50%; */
}

.main_banner_button_wrap .main_banner_nav {
  width: 390px;
  height: 3px;

  border-radius: 3px;

  display: flex;
}
.main_banner_button_wrap .banner_nav {
  flex: 1;
  background-color: #fff;
  opacity: 0.5;
}
.main_banner_button_wrap .banner_nav.banner_active {
  background: #fff;
  opacity: 1;
}
.main_banner_button {
  display: flex;
  justify-content: flex-end;

  /* position: absolute; */
  /* right: 0; */
}
.main_banner_button > div {
  width: 45px;
  height: 45px;
  background-color: #000000d8;
  opacity: 0.7;
  /* font-size: 20px; */
  position: relative;
}
.main_banner_button > div > div {
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 30px;
  /* line-height: 30px; */
  text-align: center;
  cursor: pointer;
  opacity: 1;
}
.banner_style_inner .banner_style_dots {
  width: 240px;
  height: 6px;
  background-color: #ffffff9f;
  position: absolute;
  bottom: 50px;
}
.banner_style_dots > li {
  height: 6px;
  width: 80px;
  margin: 0;
}

.test_css {
  /* bottom: 0; */
  max-width: 1300px;
  height: 40px;
  margin: 0 auto;
  /* left: 0; */
  /* margin-left: -50%; */
  /* display: flex; */
  list-style: none;
  /* text-align: end; */
  position: relative;
  /* left: 50%; */
  /* right: 50%; */
  /* margin-right: -50%; */
  padding: 0;
  z-index: 100;
  /* background-color: red; */
  bottom: 50px;
  z-index: 2;
  /* position: relative; */
}
.test_css li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  /*margin: 1px;*/
  padding: 0;
  cursor: pointer;
  /* margin-bottom: 30px; */
}

.test_css li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.test_css li button:before {
  font-size: 2.7rem;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.75;
  color: #6d6968;
}

.test_css li.slick-active button:before {
  opacity: 0.75;
  color: #ffffff;
}

/* 메인영역 */
.main_wrap {
  margin-bottom: 100px;
}
/* 첫번쨰 영역 */
.main_section_inner {
  width: 100%;
  height: 632px;

  border-bottom: 2px solid #f3f4f6;
}

/* 두번쨰 영역 */
.main_section2_inner {
  width: 100%;
  height: auto;

  border-bottom: 2px solid #f3f4f6;
}

.main_section4_inner {
  width: 100%;
  height: auto;

  border-bottom: 2px solid #f3f4f6;
}

.main_section_wrap {
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
  display: flex;
}

.main_title_h3 {
  font-size: 24px;

  font-weight: bold;
}
.main_title_div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.main_title_sub {
  margin-bottom: 4px;
  /* text-indent: 5px; */
}

/* 상품 등록 x - 새로운 / 실시간 */
.main_not_data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}
.main_not_data > .main_not_span {
  color: #898989;
  /* background-color: red; */
  font-size: 15px;
  font-weight: 600;
}
/* 상품 등록 x - 스토어 */
.main_not_data_store {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
}
.main_not_data_store > .main_not_span_store {
  color: #898989;
  /* background-color: red; */
  font-size: 15px;
  font-weight: 600;
}

/* 첫번째 영역 - 새로운 투자 상품 / 실시간 인기상품 */
.main_section_wrap .main_section_left {
  width: 860px;

  border-right: 2px solid #f3f4f6;
  padding: 40px 0;
  padding-right: 60px;
  padding-left: 0;
}
.main_section_wrap .main_section_left .main_news_more {
  font-family: "Myriad-pro";
  font-weight: 600;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  text-align: right;
  /* background-color: red; */
}
.main_section_wrap .main_section_left .main_news_more {
  display: flex;
  align-items: center;
  padding-left: 70px;
}
.main_section_wrap .main_section_left .main_news_more .main_svg1_after {
  display: none;
  /* width: 16px;
  height: 16px; */
}
.main_section_wrap .main_section_left .main_news_more .main_news_open {
  position: absolute;
  width: 100%;

  background: #00c73c;
  padding: 4px 10px;
  right: 0;
  z-index: 1;
  bottom: -32px;
  text-align: center;

  display: none;
}
.main_section_wrap .main_section_left .main_news_more .main_news_open a {
  font-weight: 600;
  color: #fff;
  font-size: 12px;
}
.main_section_wrap .main_section_left .main_news_more:hover .main_svg1_before {
  display: none;
}
.main_section_wrap .main_section_left .main_news_more:hover .main_svg1_after {
  display: block;
  color: #00c73c;
}
.main_section_wrap .main_section_left .main_news_more:hover .main_news_open {
  display: block;
}
.main_section_wrap .main_section_left .main_news_more p {
  margin-left: 4px;
}

.main_section_wrap .main_section_left .main_news_button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.main_store_button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.main_store_button button {
  width: 100px;
  height: 40px;
  border: 1px solid #dfdfdf;
  font-size: 13px;
}

.main_section_wrap .main_section_left .main_news_button button {
  width: 100px;
  height: 40px;
  border: 1px solid #dfdfdf;
  font-size: 13px;
}
.main_section_wrap .main_section_left .main_news_button a {
  width: 100%;
  height: 100%;
  line-height: 40px;
}
.main_store_button button a {
  width: 100%;
  height: 100%;
  line-height: 40px;
}

.main_news_button button span {
  color: #00c73c;
}
.main_section_wrap .main_section_left .main_news_more em:first-child {
  color: #00c73c;
}
.main_section_wrap .main_section_left .main_news_more em:last-child {
  height: 16px;
}
.main_section_wrap .main_section_right {
  padding: 40px 0;
  padding-left: 58px;
}
.main_section_left .main_news_wrap {
  display: flex;
  flex-flow: row wrap;

  width: 800px;

  height: auto;
}

.main_news_wrap .main_news_item {
  float: left;
  margin: 0 32px 16px 0;
  width: calc(33.333% - 21.333px);
  cursor: pointer;
}
.main_news_wrap .main_news_item:nth-child(3n) {
  margin: 0 0 16px;
}

.main_news_wrap .main_news_item.news_skeleton {
  background-color: rgba(183, 183, 183, 0.56);
}
.main_news_wrap .main_news_item a {
  width: 100%;
  height: 100%;
}
.main_news_inner {
  /* border: 1px solid rgb(227, 227, 227); */
}
.main_news_inner .main_news_img {
  width: 100%;
  aspect-ratio: 16 / 9;
  /* height: 130px; */
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #ededed;
}

.main_news_wrap .main_news_item .main_news_img img {
  object-fit: cover;
  object-position: 50%;
  /* transition: all 0.5s; */
  width: 100%;
  height: 100%;
  /* transition: all 0.3s ease-in-out;
  box-sizing: border-box; */
}

.main_news_wrap .main_news_item:hover .main_news_img img {
  /* transform: scale(1.2);

  object-position: 90%; */
  /* transition: all 0.5s; */
}

.main_news_wrap .main_news_item .main_news_content {
  width: 100%;
  height: auto;
  margin-top: 10px;
  /* padding: 0 5px; */
  /* overflow: hidden; */

  /* background: red; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
}
.main_news_wrap .main_news_item .main_news_content .main_news_title {
  font-weight: 600;
  /* height: auto; */
  width: 100%;
  height: 30px;
  /* background-color: red; */
  padding: 3px 0;
  /* max-width: 100%; */
  /* overflow: hidden; */
}
.main_news_content .main_news_title span {
  width: 100%;
  height: 100%;
}

.main_news_wrap .main_news_item .main_news_content .main_news_date {
  width: 100%;
  font-size: 13px;
  color: #848383;
  /* text-align: right; */
}
.main_news_wrap .main_news_item .main_news_content .main_news_date em {
  color: #00c73c;
  font-weight: bold;
  font-size: 18px;
  margin-right: 3px;
}
.main_news_wrap .main_news_item .main_news_content .main_news_cont {
  /* height: 50px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
  font-size: 15px;
  width: 100%;
  height: auto;
  line-height: 20px;
  /* background-color: red; */
  /* white-space: pre; */
  /* height: 20px/; */
}
.main_news_inner .main_news_img.main_skeleton_img {
  background-color: #f2f2f2;
  position: relative;
}

.main_news_inner .main_news_img.main_skeleton_img::after {
  content: "";
  display: block;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd);
  animation: loading 2s infinite linear;
  z-index: 1;
}

.main_news_wrap
  .main_news_item
  .main_news_content
  .main_news_cont.main_skeleton_cont {
  background-color: #f2f2f2;
  position: relative;
  height: 30px;
  overflow: hidden;
}
.main_news_wrap
  .main_news_item
  .main_news_content
  .main_news_cont.main_skeleton_cont::after {
  content: "";
  display: block;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd);
  animation: loading 2s infinite linear;
  z-index: 1;
}
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  50%,
  100% {
    transform: translateX(460px);
  }
}
.main_section_right .main_rank_menu {
  display: flex;
  font-size: 15px;
  width: 100%;
  margin: 10px 0 3px;
}
.main_rank_menu li {
  height: 30px;
  cursor: pointer;

  /* width: 90px; */
}
.main_rank_menu li:first-child {
  margin-right: 3px;

  /* width: 90px; */
}
.main_rank_menu li .rank_menu {
  width: 100%;
  height: 100%;
  position: relative;
  font-weight: 500;
  color: rgb(155, 155, 155);
}
.main_rank_menu li .rank_menu.active {
  color: #333;
}
.main_rank_menu li .rank_menu.active::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #4d4d4d;
  bottom: 2px;
}

.main_rank_box {
  width: 100%;
  height: auto;
  padding-top: 0px;
  margin-top: 20px;
}
.main_rank_box .main_rank_inner {
  width: 100%;
}
.main_rank_box .main_rank_inner.main_rank_not {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.main_rank_box .main_rank_item {
  width: 100%;
  height: 66px;
  padding: 2px 0;
  margin-bottom: 24px;
}
.main_rank_item .main_rank_item_inner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}
.main_rank_item_inner .main_rank_num {
  /* flex-basis: 13px; */
  text-align: center;
  font-size: 23px;
  flex: none;
  width: 13px;
  font-family: "Myriad-pro";
  font-weight: 600;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}
.main_rank_item_inner .main_rank_content {
  flex: 1;
  padding-left: 10px;
  padding-right: 20px;
}
.main_rank_content .main_rank_title {
  font-size: 14px;
  line-height: 16px;
  /* white-space: pre-line; */
  /* width: 200px; */
  word-break: break-word;
}

.main_rank_content .main_rank_detail {
  font-size: 13px;
  color: #848383;
}
.main_rank_content .main_rank_detail em {
  font-size: 17px;
  color: #00c73c;
  margin-right: 3px;
  font-family: "Myriad-pro";
  font-weight: 600;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}

.main_rank_content .main_rank_cont {
}
.main_rank_item_inner .main_rank_img_mobile {
  display: none;
}
.main_rank_item_inner .main_rank_img {
  /* flex-basis: 93px; */
  flex: none;
  width: 93px;
  height: 66px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #ededed;
}
.main_rank_item_inner .main_rank_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 두번째 영역 - 스토어 */
.main_section2_wrap {
  max-width: 1280px;
  margin: 0 auto;
  height: auto;

  padding: 40px 0px;
}

.main_section4_wrap {
  max-width: 1280px;
  margin: 0 auto;
  height: auto;

  padding: 40px 0px;
  display: flex;
  align-items: center;
}
.main_section4_wrap h3 {
  width: 140px;
  /* float: left; */
}
.main_section4_wrap .main_new_news {
  overflow: hidden;
  width: 800px;
  /* margin-left: 80px; */
}

.main_section4_wrap .main_new_news_inner .main_new_news_in {
  /* margin-left: 80px; */
  display: flex;
  align-items: center;
  height: 20px;
  cursor: pointer;
}

/* 공지 스켈레톤 */
.main_new_news_in.main_new_news_in_skeleton {
  background-color: #f2f2f2;
  position: relative;
  width: 500px;
  overflow: hidden;
}
.main_new_news_in.main_new_news_in_skeleton::after {
  content: "";
  display: block;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd);
  animation: loading 2s infinite linear;
  z-index: 1;
}
.main_section4_wrap .main_new_news_inner .main_new_news_type {
  background-color: #f6f7f9;
  width: 55px;
  height: 15px;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  border-radius: 10px;
  margin-right: 10px;
}
.main_section4_wrap .main_new_news_inner .main_new_news_title {
  font-size: 15px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.main_section4_wrap .main_new_news_inner .main_new_news_title span {
  font-size: 12px;
  color: #90989f;
}
.main_section4_wrap .main_new_news_inner .main_new_news_title em {
  /* display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.main_section2_wrap .slick_item_div {
  /* background-color: red; */
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
.main_section2_wrap .main_title_top {
  display: flex;
  justify-content: space-between;
}
.main_section2_wrap .main_title_top .main_store_more {
  font-family: "Myriad-pro";
  font-weight: 600;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 70px;
}
.main_section2_wrap .main_title_top .main_store_more p {
  margin-left: 4px;
}
.main_section2_wrap .main_title_top .main_store_more .main_svg2_after {
  display: none;
  /* width: 16px;
  height: 16px; */
}
.main_section2_wrap .main_title_top .main_store_more .main_store_open {
  position: absolute;
  width: 100%;

  background: #00c73c;
  padding: 4px 10px;
  right: 0;
  z-index: 1;
  bottom: -34px;
  text-align: center;

  display: none;
}
.main_section2_wrap .main_title_top .main_store_more .main_store_open a {
  font-weight: 600;
  color: #fff;
  font-size: 12px;
}
.main_section2_wrap .main_title_top .main_store_more:hover .main_svg2_before {
  display: none;
}
.main_section2_wrap .main_title_top .main_store_more:hover .main_svg2_after {
  display: block;
  color: #00c73c;
}
.main_section2_wrap .main_title_top .main_store_more:hover .main_store_open {
  display: block;
}

.main_section2_wrap .main_title_top .main_store_more em:nth-child(2) {
  color: #00c73c;
}

.main_section2_wrap .main_store_box {
  /* background-color: red; */
  position: relative;
  /* overflow: hidden; */
}

/* 스토어 스켈레톤 */
.main_stroe_box_skeleton {
  width: 100%;
  display: flex;
  height: 232px;
}
.main_stroe_box_skeleton > div {
  flex: 1;
  height: 100%;
  padding: 0 10px;
}
.main_stroe_box_skeleton .store_item_inner_skeleton {
  background-color: #f2f2f2;
  position: relative;
  height: 200px;
  overflow: hidden;
}
.main_stroe_box_skeleton .store_item_inner_skeleton::after {
  content: "";
  display: block;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd);
  animation: loading 2s infinite linear;
  z-index: 1;
}
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  50%,
  100% {
    transform: translateX(460px);
  }
}

.main_section2_wrap .store_item {
  /* max-width: 200px; */
  margin-right: 7px;
  /* width: calc(16.6%-10px); */
}
.main_section2_wrap .store_item .store_item_content {
  font-size: 14px;
  margin-top: 5px;
  line-height: 20px;
}
.main_section2_wrap .store_item .store_item_img {
  border-radius: 4px;
  overflow: hidden;
}

.main_section2_wrap .store_item .store_item_detail p:first-child {
  font-size: 17px;
  margin-top: 4px;
  color: #00c73c;
}

.main_section2_wrap .store_item .store_item_detail p:last-child {
  font-size: 17px;
  margin-top: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  /* color: #00c73c; */
}
.main_section2_wrap .store_item .store_item_detail p:last-child svg {
  color: #fdbf1e;
  margin-right: 4px;
}
.main_section2_wrap .store_item .store_item_type {
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 4px;
}
.main_section2_wrap .store_item .store_item_type span {
  width: 40px;
  height: 15px;
  font-size: 10px;
  background: #f3f4f6;
  line-height: 15px;
  text-align: center;
  margin-right: 3px;
}

.main_section2_wrap .slick_item_div.slick_item_search {
  width: 272px;
  height: 192px;
  margin-right: 10px;
}
.slick_item_div2 {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(227, 227, 227);
  position: relative;
  cursor: pointer;
}
.slick_item_div2 .slick_item_heart {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  background-color: #ffffffb8;
  /* opacity: 0.4; */
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;

  /* color: red; */
  /* color: transparent; */
}

.heart_color_no {
  color: black;
}
.heart_color {
  color: red;
}
.slick_item_div2 .slick_item_heart svg {
  cursor: pointer;
  /* border: 1px solid #fff; */
  /* background-color: red; */
}
.slick_item_div2 .slick_item_link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.slick_item_div .slick_item_imgbox {
  width: 100%;
  padding-top: 60%;
  /* background-position: center center; */
  /* flex: 1; */

  /* height: 100%; */
  /* height: 180px; */
  /* background-color: red; */
  /* object-fit: cover; */
  /* overflow: hidden; */
}
.slick_item_div .slick_item_imgbox img {
  /* max-height: 180px; */
  /* object-fit: cover; */
  /* object-fit: contain; */
}

.slick_item_div .slick_item_contbox {
  padding: 5px 0;
  /* flex: 1; */
}
.slick_item_div2.slick_not {
  position: relative;
}
.slick_item_div2.slick_not::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: #81818166;
  content: "";
  display: block;
  opacity: 0.3;
}
.slick_item_contbox .slick_item_title {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
.main_section3_wrap {
  width: 100%;
  height: 280px;

  /* padding: 0 20px; */
  margin: 50px 0;
}
.main_section3_wrap .main_section3_imgbox {
  width: 100%;
  height: 100%;
  /* background: url("../assets/images/Layer\ 529.png") no-repeat;
  background-position: center; */
  /* background-attachment: fixed; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* margin-bottom: 100px; */
}
/* .main_section3_wrap .main_section3_imgbox::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #333;
  opacity: 0.4;
} */
.main_section3_imgbox .main_section3_content {
  /* width: 40%; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  /* position: absolute;
  z-index: 2; */
}
.main_section3_content h3 {
  /* font-weight: 700; */
  font-size: 36px;
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
  /* line-height: 240px; */
}
.main_section3_content p {
  font-size: 17px;
  color: #fff;
  margin-bottom: 25px;
}

.main_section3_content button {
  background-color: #00c73c;
  width: 150px;
  height: 48px;
  color: #fff;
  border-radius: 3px;
}
.main_section3_wrap .main_section3_imgbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 검색 */
.search_container {
  width: 1024px;
  margin: 0 auto;
  /* background-color: #f5f5f5; */
  padding: 40px 0;
}

.search_container .search_top {
  width: 100%;
  margin-bottom: 40px;
}
.search_top .search_title {
  text-align: center;
  font-size: 40px;
  min-height: 60px;
  line-height: 60px;
  margin-bottom: 30px;
  font-weight: bold;
}
.search_cont > section {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 30px;
}

.search_top .search_input_box {
  width: 100%;
  height: 50px;
  display: flex;
  padding: 0 160px;
}

.search_input_box .search_input_cont {
  flex: 1;
  margin-right: 15px;
  display: flex;
  position: relative;
}
.search_input_cont input {
  width: 100%;
  height: 100%;
  text-indent: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  padding-right: 50px;
}
.search_input_cont .search_xmark {
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  text-align: center;

  z-index: 1;
}
.search_input_box button {
  width: 100px;
  height: 100%;
  background-color: #00c73c;
  border-radius: 8px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
}

.search_bottom .search_menu_box {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 10px;
}
.search_menu_box ul {
  display: flex;
  height: 40px;
  font-size: 15px;
  font-weight: 700;
}
.search_menu_box ul .search_menu_li {
  height: 100%;
  min-width: 70px;
  text-align: center;
  margin-right: 15px;
  line-height: 40px;
  position: relative;
  cursor: pointer;
}
.search_menu_box ul .search_menu_li::before {
  display: block;
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 5px;
  border-radius: 5px;
  background-color: #3498db;
  transition: all 0.4s ease-in-out;
}
.search_menu_box ul .search_menu_li:hover:before {
  width: 100%;
}
.search_menu_box ul .search_menu_li.active:before {
  width: 100%;
}
.search_menu_box li .search_detail-cnt {
  padding-left: 3px;
  font-weight: 500;
  font-size: 14px;
  color: #676767;
}

.search_order_box {
  text-align: right;
  height: 40px;
}
.search_order_box select {
  width: 100px;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  cursor: pointer;
}
.search_cont {
  /* display: flex; */
}
.search_cont .search_item_inner {
  padding-bottom: 0;
}
.search_cont .search_item_top span.star {
  padding-left: -27px;
}
.search_cont .search_item_top span.star:before {
  top: -4px;
  width: 25px;
  height: 25px;
}

.search_cont .search_item_list {
  width: calc(100% + 20px);
  height: auto;
  margin: -10px;
}
.search_cont .search_item {
  width: calc(25% - 20px);
  height: 500px;
  margin: 0 18px;
  margin: 10px;
  display: inline-block;
  font-size: 0;
  line-height: 0;
  text-align: left;
  vertical-align: top;
  position: relative;
  background-color: #fff;
}
.search_cont .search_item_link {
  padding: 30px 20px;
}
.search_cont .search_item_top {
  font-size: 16px;
  line-height: 18px;
}
.search_item_img {
  margin: 22px 0;
}
.search_cont .search_item_sub {
  font-size: 14px;
  line-height: 16px;
}
.search_cont .search_item_title {
  margin-top: 7px;
  font-size: 14px;
  line-height: 16px;
}
.search_cont .search_item_text {
  font-size: 14px;
  line-height: 25px;
}
.search_cont .search_item_hash {
  margin-top: 12px;
  font-size: 12px;
  line-height: 19px;
}
.search_cont .search_item_hash li {
  margin-right: 10px;
  letter-spacing: -0.08em;
}

.funding_inner_item {
  /* width: 32%; */
  /* display: inline-block; */
  /* float: left; */
  /* width: calc(33.3% - 10px); */
  /* height: auto; */
  /* padding: 0 5px; */
  /* background-color: red; */
  cursor: pointer;
}

.funding_inner_item .funding_inner_imgbox {
  width: 100%;
  height: auto;
  padding-top: calc(185 / 325 * 100%);
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  /* margin: 22px 0; */
}
.funding_inner_item .funding_inner_imgbox img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  /* width: 100%; */
  object-fit: cover;
}
.funding_inner_imgbox .funding_detail_one {
  font-size: 10px;
  background-color: #00c73c;
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
  color: #fff;
  padding: 0;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
  height: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.funding_inner_imgbox .funding_detail_one > span {
  line-height: 20px !important;
  /* width: 100%;
  height: 100%; */
}
.funding_inner_imgbox .funding_heart {
  /* background-color: red; */
  z-index: 5;
  width: 25px;
  height: 25px;
  right: 15px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.funding_inner_imgbox .funding_heart_color {
  /* background-color: red; */
  z-index: 5;
  width: 25px;
  height: 25px;
  right: 15px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.funding_inner_imgbox .funding_heart svg {
  width: 24px;
  height: 24px;
  color: rgb(0, 0, 0, 0.4);
  transition: 0.5s;
}
.funding_inner_imgbox .funding_heart path {
  stroke: #fff;
  stroke-width: 60px;
}

.funding_inner_imgbox .funding_heart svg:hover {
  transition: 0.5s;
  /* color: red; */
  color: #fff;
}

.funding_inner_imgbox .funding_heart_color svg {
  width: 24px;
  height: 24px;
  /* color: red; */
  color: #fff;
  transition: 0.5s;
}
.funding_inner_imgbox .funding_heart_color path {
  stroke: #fff;
  stroke-width: 60px;
}

.funding_inner_imgbox .funding_heart_color svg:hover {
  transition: 0.5s;

  color: rgb(0, 0, 0, 0.4);
}

.funding_inner_imgbox .funding_detail_two {
  /* background-color: red; */
  height: 20px;

  /* background-color: #00c73c; */
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 15px;
  color: #fff;
  font-weight: 400;
  width: 65px;
}
.funding_inner_imgbox .funding_detail_four {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: #00c73c;
  font-size: 12px;
  padding: 0 10px;
  color: #fff;
  border-radius: 3px;
  font-weight: 500;
}
.funding_inner_imgbox .funding_detail_back {
  width: 100%;
  height: 100%;
  position: relative;

  line-height: 18px;
  text-align: center;
}
.funding_inner_imgbox .funding_detail_back::before {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  border: 1px solid #fff;
  content: "";
  display: block;
  border-radius: 14px;
  opacity: 0.5;
}
.funding_inner_imgbox .funding_detail_back .funding_detail_back_1 {
  border-radius: 14px;
  border: 1px solid #fff;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
.funding_inner_imgbox .funding_detail_two span {
  /* padding-bottom: 2px; */
  line-height: 0;
  font-size: 18px;
  background-color: red;
  /* line-height: 1; */
  /* position: absolute;
  z-index: 1;
  top: 0;
  left: 0; */
  /* width: 65px;
  height: 20px; */
  /* margin-left: -32.5px; */
  /* line-height: 12p; */
  /* height: 20p; */
}

.funding_inner_imgbox .funding_detail_three {
  font-size: 16px;
  /* background-color: red; */

  /* background-color: #00c73c; */
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 15px;
  color: #fff;
  font-weight: 400;
  /* padding: 0 10px; */
  background-color: #00c73c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
}
.funding_inner_imgbox .funding_detail_three span {
  line-height: 18px;
}
.funding_inner_cont {
  height: auto;
  padding: 10px 0;
}

.funding_inner_cont .funding_inner_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.funding_inner_left {
  /* font-weight: bold; */
}

.funding_span_green {
  font-size: 19px;
  color: #00c73c;
  margin-right: 3px;
}
.funding_inner_left em {
  font-weight: bold;
}
.funding_span_default {
  color: #b1b1b1;
  font-size: 13px;
}

.funding_inner_right {
  color: #929191;
  background: #f2f4f6;
  font-size: 13px;
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
}
.funding_inner_right span {
  line-height: 0;
  font-size: 10px;
}
.funding_inner_middle {
  font-size: 16px;
  margin-top: 12px;
  font-weight: normal;
}
.funding_inner_middle span {
  line-height: 30px;
}

.funding_inner_bottom {
  font-size: 14px;
  color: #929191;
}
@media all and (max-width: 1100px) {
  .search_container {
    width: 100%;
    margin: 0 auto;
    /* background-color: #f5f5f5; */
    padding: 30px 20px;
  }

  .search_container .search_top {
    width: 100%;
    margin-bottom: 40px;
  }
  .search_top .search_title {
    text-align: center;
    font-size: 30px;
    min-height: 40px;
    line-height: 40px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  .search_cont > section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 30px;
  }

  .search_top .search_input_box {
    width: 100%;
    height: 50px;
    display: flex;
    padding: 0 160px;
  }

  .search_input_box .search_input_cont {
    flex: 1;
    margin-right: 15px;
    display: flex;
    position: relative;
  }
  .search_input_cont input {
    width: 100%;
    height: 100%;
    text-indent: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    padding-right: 50px;
  }
  .search_input_cont .search_xmark {
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    text-align: center;

    z-index: 1;
  }
  .search_input_box button {
    width: 100px;
    height: 100%;
    background-color: #00c73c;
    border-radius: 8px;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
  }

  .search_bottom .search_menu_box {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 10px;
  }
  .search_menu_box ul {
    display: flex;
    height: 40px;
    font-size: 15px;
    font-weight: 700;
  }
  .search_menu_box ul .search_menu_li {
    height: 100%;
    min-width: 70px;
    text-align: center;
    margin-right: 15px;
    line-height: 40px;
    position: relative;
    cursor: pointer;
  }
  .search_menu_box ul .search_menu_li::before {
    display: block;
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 5px;
    border-radius: 5px;
    background-color: #3498db;
    transition: all 0.4s ease-in-out;
  }
  .search_menu_box ul .search_menu_li:hover:before {
    width: 100%;
  }
  .search_menu_box ul .search_menu_li.active:before {
    width: 100%;
  }
  .search_menu_box li .search_detail-cnt {
    padding-left: 3px;
    font-weight: 500;
    font-size: 14px;
    color: #676767;
  }

  .search_order_box {
    text-align: right;
    height: 40px;
  }
  .search_order_box select {
    width: 100px;
    height: 100%;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    cursor: pointer;
  }
  .search_cont {
    /* display: flex; */
  }
  .search_cont .search_item_inner {
    padding-bottom: 0;
  }
  .search_cont .search_item_top span.star {
    padding-left: -27px;
  }
  .search_cont .search_item_top span.star:before {
    top: -4px;
    width: 25px;
    height: 25px;
  }

  .search_cont .search_item_list {
    width: calc(100% + 20px);
    height: auto;
    margin: -10px;
  }
  .search_cont .search_item {
    width: calc(25% - 20px);
    height: 500px;
    margin: 0 18px;
    margin: 10px;
    display: inline-block;
    font-size: 0;
    line-height: 0;
    text-align: left;
    vertical-align: top;
    position: relative;
    background-color: #fff;
  }
  .search_cont .search_item_link {
    padding: 30px 20px;
  }
  .search_cont .search_item_top {
    font-size: 16px;
    line-height: 18px;
  }
  .search_item_img {
    margin: 22px 0;
  }
  .search_cont .search_item_sub {
    font-size: 14px;
    line-height: 16px;
  }
  .search_cont .search_item_title {
    margin-top: 7px;
    font-size: 14px;
    line-height: 16px;
  }
  .search_cont .search_item_text {
    font-size: 14px;
    line-height: 25px;
  }
  .search_cont .search_item_hash {
    margin-top: 12px;
    font-size: 12px;
    line-height: 19px;
  }
  .search_cont .search_item_hash li {
    margin-right: 10px;
    letter-spacing: -0.08em;
  }
  .funding_inner_item {
    /* width: 32%; */
    /* display: inline-block; */
    /* float: left; */
    /* width: calc(33.3% - 10px); */
    /* height: auto; */
    /* padding: 0 5px; */
    /* background-color: red; */
    cursor: pointer;
  }

  .funding_inner_item .funding_inner_imgbox {
    width: 100%;
    height: auto;
    padding-top: calc(185 / 325 * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    /* margin: 22px 0; */
  }
  .funding_inner_item .funding_inner_imgbox img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* object-fit: contain; */
  }
  .funding_inner_imgbox .funding_detail_one {
    font-size: 10px;
    background-color: #00c73c;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    color: #fff;
    padding: 0;
    line-height: 20px;
    padding: 0 5px;
    border-radius: 3px;
    height: 20px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .funding_inner_imgbox .funding_detail_one > span {
    line-height: 20px !important;
    /* width: 100%;
    height: 100%; */
  }
  .funding_inner_imgbox .funding_heart {
    width: 22px;
    height: 22px;
    right: 13px;
    top: 13px;
  }
  .funding_inner_imgbox .funding_heart_color {
    width: 22px;
    height: 22px;
    right: 13px;
    top: 13px;
  }
  .funding_inner_imgbox .funding_heart svg {
    width: 21px;
    height: 21px;
    color: rgb(0, 0, 0, 0.4);
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart svg:hover {
    transition: 0.5s;
    color: #fff;
  }

  .funding_inner_imgbox .funding_heart_color svg {
    width: 24px;
    height: 24px;
    color: #fff;
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart_color path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart_color svg:hover {
    transition: 0.5s;

    color: rgb(0, 0, 0, 0.4);
  }
  .funding_inner_imgbox .funding_detail_two {
    /* background-color: red; */
    height: 20px;

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    width: 65px;
  }

  .funding_inner_imgbox .funding_detail_back {
    width: 100%;
    height: 100%;
    position: relative;

    line-height: 18px;
    text-align: center;
  }
  .funding_inner_imgbox .funding_detail_back::before {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border: 1px solid #fff;
    content: "";
    display: block;
    border-radius: 14px;
    opacity: 0.5;
  }
  .funding_inner_imgbox .funding_detail_back .funding_detail_back_1 {
    border-radius: 14px;
    border: 1px solid #fff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  .funding_inner_imgbox .funding_detail_two span {
    /* padding-bottom: 2px; */
    line-height: 0;
    font-size: 18px;
    background-color: red;
    /* line-height: 1; */
    /* position: absolute;
    z-index: 1;
    top: 0;
    left: 0; */
    /* width: 65px;
    height: 20px; */
    /* margin-left: -32.5px; */
    /* line-height: 12p; */
    /* height: 20p; */
  }

  .funding_inner_imgbox .funding_detail_three {
    font-size: 16px;
    /* background-color: red; */

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    /* padding: 0 10px; */
    background-color: #00c73c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
  }
  .funding_inner_imgbox .funding_detail_three span {
    line-height: 18px;
  }
  .funding_inner_cont {
    padding: 6px 0;
  }

  .funding_span_green {
    font-size: 17px;
  }

  .funding_span_default {
    color: #b1b1b1;
    font-size: 13px;
  }

  .funding_inner_right {
    color: #929191;
    background: #f2f4f6;
    font-size: 13px;
    padding: 0 10px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
  }
  .funding_inner_right span {
    line-height: 0;
    font-size: 10px;
  }
  .funding_inner_middle {
    font-size: 16px;
    margin-top: 7px;
    font-weight: normal;
  }
  .funding_inner_middle span {
    line-height: 24px;
  }

  .funding_inner_bottom {
    font-size: 14px;
    color: #929191;
  }

  .funding_inner_item .funding_inner_imgbox.fund_skeleton_img {
    background-color: #f2f2f2;
    position: relative;
  }

  .funding_inner_item .funding_inner_imgbox.fund_skeleton_img::after {
    content: "";
    display: block;
    width: 20%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #f2f2f2, #ddd);
    animation: loading 2s infinite linear;
    z-index: 1;
  }

  .funding_inner_cont .funding_inner_top.fund_skeleton_div {
    background-color: #f2f2f2;
    position: relative;
    height: 30px;
    overflow: hidden;
  }
}

@media all and (max-width: 900px) {
  .search_container {
    width: 100%;
    margin: 0 auto;
    /* background-color: #f5f5f5; */
    padding: 20px;
  }
  .search_container .search_top {
    /* width: 100%; */
    margin-bottom: 30px;
  }
  .search_top .search_title {
    text-align: center;
    font-size: 24px;
    min-height: 30px;
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .search_cont > section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 24px;
  }
  .search_top .search_input_box {
    width: 100%;
    height: 50px;
    display: flex;
    padding: 0;
  }

  .funding_inner_item {
    cursor: pointer;
  }

  .funding_inner_item .funding_inner_imgbox {
    width: 100%;
    height: auto;
    padding-top: calc(185 / 325 * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    /* margin: 22px 0; */
  }
  .funding_inner_item .funding_inner_imgbox img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* object-fit: contain; */
  }
  .funding_inner_imgbox .funding_detail_one {
    font-size: 10px;
    background-color: #00c73c;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    color: #fff;
    padding: 0;
    line-height: 20px;
    padding: 0 5px;
    border-radius: 3px;
    height: 20px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .funding_inner_imgbox .funding_detail_one > span {
    line-height: 20px !important;
    /* width: 100%;
    height: 100%; */
  }
  .funding_inner_imgbox .funding_heart {
    /* background-color: red; */
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
  .funding_inner_imgbox .funding_heart_color {
    /* background-color: red; */
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
  .funding_inner_imgbox .funding_heart svg {
    width: 19px;
    height: 19px;
    color: rgb(0, 0, 0, 0.4);
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart svg:hover {
    transition: 0.5s;
    color: #fff;
  }

  .funding_inner_imgbox .funding_heart_color svg {
    width: 24px;
    height: 24px;
    color: #fff;
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart_color path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart_color svg:hover {
    transition: 0.5s;

    color: rgb(0, 0, 0, 0.4);
  }
  .funding_inner_imgbox .funding_detail_two {
    /* background-color: red; */
    height: 20px;

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    width: 65px;
  }

  .funding_inner_imgbox .funding_detail_back {
    width: 100%;
    height: 100%;
    position: relative;

    line-height: 18px;
    text-align: center;
  }
  .funding_inner_imgbox .funding_detail_back::before {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border: 1px solid #fff;
    content: "";
    display: block;
    border-radius: 14px;
    opacity: 0.5;
  }
  .funding_inner_imgbox .funding_detail_back .funding_detail_back_1 {
    border-radius: 14px;
    border: 1px solid #fff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  .funding_inner_imgbox .funding_detail_two span {
    /* padding-bottom: 2px; */
    line-height: 0;
    font-size: 18px;
    background-color: red;
    /* line-height: 1; */
    /* position: absolute;
    z-index: 1;
    top: 0;
    left: 0; */
    /* width: 65px;
    height: 20px; */
    /* margin-left: -32.5px; */
    /* line-height: 12p; */
    /* height: 20p; */
  }

  .funding_inner_imgbox .funding_detail_three {
    font-size: 16px;
    /* background-color: red; */

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    /* padding: 0 10px; */
    background-color: #00c73c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
  }
  .funding_inner_imgbox .funding_detail_three span {
    line-height: 18px;
  }
  .funding_inner_cont {
    padding: 6px 0;
  }

  .funding_span_green {
    font-size: 16px;
  }

  .funding_span_default {
    color: #b1b1b1;
    font-size: 11px;
  }

  .funding_inner_right {
    font-size: 12px;
    padding: 0 8px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
  }
  .funding_inner_right span {
    line-height: 0;
    font-size: 10px;
  }
  .funding_inner_middle {
    font-size: 14px;
    margin-top: 7px;
    font-weight: normal;
  }
  .funding_inner_middle span {
    line-height: 20px;
  }

  .funding_inner_bottom {
    font-size: 12px;
    /* color: #929191; */
  }

  .funding_inner_bottom span {
    height: 22px;
    line-height: 22px;
    /* background-color: red; */
  }
}
@media all and (max-width: 760px) {
  .search_cont > section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px 24px;
  }
  .funding_inner_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px 24px;
  }
  .funding_inner_list.content_not {
    width: 100%;
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;
  }
  /* 
  .funding_inner_list::after {
    content: "";
    display: block;
    clear: both;
  } */

  .funding_inner_item {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .funding_inner_item .funding_inner_imgbox {
    max-width: 160px;
    height: 100px;
    padding-top: 0;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    /* margin: 22px 0; */
  }
  .funding_inner_item .funding_inner_imgbox img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* object-fit: contain; */
  }
  .funding_inner_imgbox .funding_detail_one {
    font-size: 10px;
    background-color: #00c73c;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    color: #fff;
    padding: 0;
    line-height: 20px;
    padding: 0 5px;
    border-radius: 3px;
    height: 20px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .funding_inner_imgbox .funding_detail_one > span {
    line-height: 20px !important;
    /* width: 100%;
    height: 100%; */
  }
  .funding_inner_imgbox .funding_heart {
    /* background-color: red; */
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
  .funding_inner_imgbox .funding_heart_color {
    /* background-color: red; */
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
  .funding_inner_imgbox .funding_heart svg {
    width: 19px;
    height: 19px;
    color: rgb(0, 0, 0, 0.4);
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart svg:hover {
    transition: 0.5s;
    color: #fff;
  }

  .funding_inner_imgbox .funding_heart_color svg {
    width: 24px;
    height: 24px;
    color: #fff;
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart_color path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart_color svg:hover {
    transition: 0.5s;

    color: rgb(0, 0, 0, 0.4);
  }
  .funding_inner_imgbox .funding_detail_two {
    /* background-color: red; */
    height: 20px;

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    width: 65px;
  }

  .funding_inner_imgbox .funding_detail_back {
    width: 100%;
    height: 100%;
    position: relative;

    line-height: 18px;
    text-align: center;
  }
  .funding_inner_imgbox .funding_detail_back::before {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border: 1px solid #fff;
    content: "";
    display: block;
    border-radius: 14px;
    opacity: 0.5;
  }
  .funding_inner_imgbox .funding_detail_back .funding_detail_back_1 {
    border-radius: 14px;
    border: 1px solid #fff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  .funding_inner_imgbox .funding_detail_two span {
    /* padding-bottom: 2px; */
    line-height: 0;
    font-size: 18px;
    background-color: red;
    /* line-height: 1; */
    /* position: absolute;
    z-index: 1;
    top: 0;
    left: 0; */
    /* width: 65px;
    height: 20px; */
    /* margin-left: -32.5px; */
    /* line-height: 12p; */
    /* height: 20p; */
  }

  .funding_inner_imgbox .funding_detail_three {
    font-size: 16px;
    /* background-color: red; */

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    /* padding: 0 10px; */
    background-color: #00c73c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
  }
  .funding_inner_imgbox .funding_detail_three span {
    line-height: 18px;
  }

  .funding_inner_cont {
    padding: 6px 0;
    padding-left: 14px;
  }

  .funding_span_green {
    font-size: 14px;
  }

  .funding_span_default {
    color: #b1b1b1;
    font-size: 11px;
  }

  .funding_inner_right {
    font-size: 12px;
    padding: 0 8px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
  }
  .funding_inner_right span {
    line-height: 0;
    font-size: 10px;
  }
  .funding_inner_middle {
    font-size: 14px;
    margin-top: 7px;
    font-weight: normal;
  }
  .funding_inner_middle span {
    line-height: 18px;
    font-size: 14px;
  }

  .funding_inner_bottom {
    font-size: 12px;
    /* color: #929191; */
  }

  .funding_inner_bottom span {
    height: 22px;
    line-height: 22px;
    /* background-color: red; */
  }
  .funding_inner_left span {
    line-height: 18px;
    /* font-weight: bold; */
  }
}
@media all and (max-width: 1300px) {
  .main_section_wrap {
    max-width: 100%;
    margin: 0 auto;
    /* padding: 50px 20px 14px; */
    display: flex;
  }

  .main_section_wrap .main_section_left {
    /* flex: 2.2; */
    width: 67.1875%;

    padding: 0 20px;
    padding-top: 40px;
    /* padding-right: 40px; */
    /* width: 100%; */
    /* background-color: red; */
  }
  .main_section_left .main_news_wrap {
    width: 100%;
    /* padding-right: 60px; */
    /* width: 790px !important; */
    /* grid-template-columns: repeat(3, 1fr); */
    /* gap: 10px 36px; */

    /* overflow: hidden; */
    /* box-sizing: border-box; */
    /* background-color: beige; */
    /* justify-content: space-between; */
  }
  .main_news_inner .main_news_img {
    width: 100%;
    /* height: 120px; */
    overflow: hidden;
    border-radius: 4px;
  }

  .main_section2_wrap {
    max-width: 100%;
    margin: 0 auto;
    height: auto;

    padding: 40px 20px;
  }
  .main_section2_wrap .main_title_top {
    /* padding: 0 20px; */
  }
  .main_title_sub {
    /* padding: 0 20px; */
  }

  .main_section4_inner {
    padding: 0 20px;
  }
  .main_section3_wrap {
    padding: 0 20px;
  }
}

@media all and (max-width: 1100px) {
  /* 배너 */
  .main_slick_content {
    max-width: 100%;
    height: 380px;
    padding-left: 80px;
  }
  .main_slick_content .main_slick_content_text p {
    font-size: 28px;
    line-height: 40px;
    /* font-weight: 400; */
  }
  .main_slick_content .main_slick_content_text em {
    font-size: 38px;
  }
  .main_banner_button_wrap {
    max-width: 100%;
    bottom: 90px;
    padding: 0 20px;
  }
  /* 
  .main_wrap {
    background-color: #f2f3f5;
    padding: 20px 0 0;
    margin-bottom: 0;
  }

  .main_section_wrap {
    flex-direction: column;
  }
  .main_section_inner {
    width: 560px;
    height: auto;
    margin: 0 auto;

    border-bottom: none;
  }
  .main_section_wrap .main_section_left {
    border-right: 0;
    width: 100%;
    padding: 24px 20px;
    background-color: #fff;
  }
  .main_section_wrap .main_section_left .main_news_more {
    font-size: 14px;
  }

  .main_title_h3 {
    font-size: 20px;
  }
  .main_title_sub {
    font-size: 14px;
  }
  .main_section_left .main_news_wrap {
    height: auto;
  }
  .main_news_wrap .main_news_item {
    float: none;

    width: 100%;

    margin: 24px 0;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, 0.1),
      0 0 5px rgba(10, 22, 70, 0.06);
    border-radius: 4px;
  }
  .main_news_wrap .main_news_item:nth-child(3n) {
    margin: 24px 0;
  }
  .main_news_inner .main_news_img {
    height: 280px;
  }
  .main_news_wrap .main_news_item .main_news_content {
    height: 130px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main_news_wrap .main_news_item .main_news_content .main_news_cont {
    font-size: 20px;
  }
  .main_news_wrap .main_news_item .main_news_content .main_news_date {
    font-size: 15px;
  }
  .main_news_wrap .main_news_item .main_news_content .main_news_date em {
    font-size: 20px;
  }
  .main_section_wrap .main_section_left .main_news_button {
  }
  .main_section_wrap .main_section_left .main_news_button button {
    width: 100%;
    height: 40px;
  }
  .main_section_wrap .main_section_right {
    width: 100%;
    background-color: #fff;
    padding: 24px 20px;
    margin-top: 12px;
  }

  .main_section_right .main_rank_menu {
    display: flex;
    font-size: 15px;
    width: 100%;
    margin: 8px 0;
  }
  .main_rank_menu li {
    height: 30px;
    width: 90px;
  }
  .main_rank_menu li .rank_menu {
    width: 100%;
    height: 100%;
    position: relative;
    font-weight: 500;
    color: rgb(155, 155, 155);
  }
  .main_rank_menu li .rank_menu.active {
    color: #333;
  }
  .main_rank_menu li .rank_menu.active::after {
    position: absolute;
    content: "";
    display: none;
    width: 100%;
    height: 3px;
    background-color: #4d4d4d;
  }

  .main_rank_box {
    width: 100%;
    height: auto;
  }
  .main_rank_box .main_rank_inner {
    width: 100%;
  }
  .main_rank_box .main_rank_item {
    width: 100%;
    height: 80px;
    padding: 0;
    margin-bottom: 10px;
  }
  .main_rank_item .main_rank_item_inner {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
  }
  .main_rank_item_inner .main_rank_num {
    flex-basis: 13px;
    text-align: center;
    font-size: 23px;

    font-family: "Myriad-pro";
    font-weight: 600;
    margin-right: 8px;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
    line-height: 20px;
  }
  .main_rank_item_inner .main_rank_content {
    flex: 1;
    padding-left: 10px;
    padding-right: 20px;
  }
  .main_rank_content .main_rank_title {
    font-size: 14px;
    line-height: 16px;
    height: 40px;
  }

  .main_rank_content .main_rank_detail {
    font-size: 13px;
    color: #848383;
  }
  .main_rank_content .main_rank_detail em {
    font-size: 17px;
    color: #00c73c;
    margin-right: 3px;
    font-family: "Myriad-pro";
    font-weight: 600;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
  }

  .main_rank_content .main_rank_cont {
  }
  .main_rank_item_inner .main_rank_img_mobile {
    display: block;
    flex-shrink: 0;
    flex-basis: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
  }
  .main_rank_item_inner .main_rank_img_mobile img {
    width: 100%;
    height: 100%;
  }
  .main_rank_item_inner .main_rank_img {
    display: none;
  }
  .main_rank_item_inner .main_rank_img img {
    width: 100%;
    height: 100%;
  } */

  /* 메인영역 */
  .main_wrap {
    margin-bottom: 100px;
  }
  .main_section_wrap {
    max-width: 100%;
    height: auto;
  }
  /* 첫번째 영역 - 새로운 투자 상품 / 실시간 인기상품 */
  .main_section_inner {
    width: 100%;
    height: 570px;

    border-bottom: 2px solid #f3f4f6;
  }

  .main_section_wrap .main_section_left {
    width: 680px;

    border-right: 2px solid #f3f4f6;
    padding: 36px 0;
    padding-right: 20px;
    padding-left: 0;
  }
  .main_section_left .main_news_wrap {
    display: flex;
    flex-flow: row wrap;

    width: 100%;

    height: auto;
  }

  .main_news_wrap .main_news_item {
    float: left;
    margin: 0 10px 16px 0;
    width: calc(33.333% - 10.333px);
    cursor: pointer;
  }
  .main_section_wrap .main_section_right {
    padding: 36px 0;
    padding-left: 10px;
  }

  .main_section5_inner {
    width: 560px;
    height: auto;
    margin: 0 auto;
    margin-top: 8px;
  }

  .main_section5_wrap {
    max-width: 100%;
    margin: 0 auto;
    height: auto;

    padding: 24px 20px;
    background: #fff;
  }
  .main_section5_inner .main_title_top {
    display: flex;
    justify-content: space-between;
  }
  .main_section5_inner .main_title_div .main_store_more {
    font-family: "Myriad-pro";
    font-weight: 600;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
  }

  .main_section5_wrap .main_title_div .main_store_more svg {
    margin-left: 4px;
  }

  .main_section5_wrap .main_title_div .main_store_more em:nth-child(2) {
    color: #00c73c;
  }

  .main_section5_wrap .store_item_mobile {
    /* max-width: 200px; */
    width: 100%;

    margin: 24px 0;
    box-shadow: 0 16px 16px -1px rgba(10, 22, 70, 0.1),
      0 0 5px rgba(10, 22, 70, 0.06);
    border-radius: 4px;
    /* width: calc(16.6%-10px); */
  }
  .main_section5_wrap .store_item_mobile a {
    width: 100%;
    height: 100%;
  }
  .store_item_cont_mobile {
    padding: 10px 20px;
  }
  .main_section5_wrap .store_item_mobile .store_item_content_mobile {
    font-size: 18px;
    margin-top: 0;
    line-height: 20px;
  }
  .main_section5_wrap .store_item_mobile .store_item_img_mobile {
    border-radius: 4px;
    height: 280px;
    overflow: hidden;
    width: 100%;
  }
  .main_section5_wrap .store_item_mobile .store_item_img_mobile img {
    object-fit: cover;
    object-position: top;
    /* transition: all 0.5s; */
    width: 100%;
    height: 100%;
    /* width: 100%; */
  }

  .main_section5_wrap
    .store_item_mobile
    .store_item_detail_mobile
    p:first-child {
    font-size: 17px;
    margin-top: 8px;
    color: #00c73c;
  }

  .main_section5_wrap
    .store_item_mobile
    .store_item_detail_mobile
    p:last-child {
    font-size: 17px;
    margin-top: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;
    /* color: #00c73c; */
  }
  .main_section5_wrap
    .store_item_mobile
    .store_item_detail_mobile
    p:last-child
    svg {
    color: #fdbf1e;
    margin-right: 4px;
  }
  .main_section5_wrap .store_item_mobile .store_item_type_mobile {
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 8px;
  }
  .main_section5_wrap .store_item_mobile .store_item_type_mobile span {
    width: 40px;
    height: 15px;
    font-size: 10px;
    background: #f3f4f6;
    line-height: 15px;
    text-align: center;
    margin-right: 3px;
  }

  .main_section5_wrap .main_store_button {
    width: 100%;
    text-align: right;
  }
  .main_section5_wrap .main_store_button button {
    width: 100%;
    height: 40px;
    border: 1px solid #dfdfdf;
    font-size: 13px;
  }

  .main_store_button button span {
    color: #00c73c;
  }

  /* 새로운 소식 */
  .main_section4_inner {
    /* border-bottom: none; */
    margin: 12px 0;
    /* background-color: red; */
  }
  .main_section4_wrap {
    max-width: 100%;
    margin: 0 auto;
    padding: 18px 0px;
    background-color: #fff;
  }
  .main_section4_wrap > h3 {
    width: 140px;
    height: 70px;
    /* background: red; */
    /* height: 100%; */
    line-height: 70px;
    /* padding: 0 20px; */
    text-indent: 0;
  }
  .main_section4_wrap .main_new_news {
    margin-left: 30px;
    width: 100%;
  }

  .main_section4_wrap .main_new_news .main_new_news_inner {
    /* margin-left: 80px; */
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    /* justify-content: start; */
  }
  .main_section4_wrap .main_new_news_inner {
    /* margin-left: 80px; */
    display: flex;
    align-items: start;
  }

  .main_section4_wrap .main_new_news_inner .main_new_news_type {
    background-color: #f6f7f9;
    width: 55px;
    height: 15px;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    border-radius: 10px;
    margin-right: 6px;
  }
  .main_section4_wrap .main_new_news_inner .main_new_news_title {
    font-size: 15px;
  }

  .main_section4_wrap .main_new_news_inner .main_new_news_title span {
    font-size: 12px;
    color: #90989f;
  }

  /*맨 밑 광고 배너 */
  .main_section3_wrap {
    width: 100%;
    margin: 12px auto 0;
    background-color: #fff;
    padding: 20px 20px;
    height: 320px;
  }
  .main_section3_wrap .main_section3_imgbox {
    /* margin-bottom: 100px; */
  }
  /* .main_section3_wrap .main_section3_imgbox::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #333;
  opacity: 0.4;
} */
  .main_section3_imgbox .main_section3_content {
  }
  .main_section3_content h3 {
    /* font-weight: 700; */
    font-size: 20px;
    color: #fff;
    margin-bottom: 15px;
    /* text-align: center; */
    /* line-height: 240px; */
  }
  .main_section3_content p {
    font-size: 17px;
    color: #fff;
    margin-bottom: 25px;
  }

  .main_section3_content button {
    background-color: #00c73c;
    width: 150px;
    height: 48px;
    color: #fff;
    border-radius: 3px;
  }
  .main_section3_wrap .main_section3_imgbox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media all and (max-width: 800px) {
  .main_slick_content {
    max-width: 100%;
    height: 380px;
    padding-left: 55px;
  }
  .main_slick_content .main_slick_content_text p {
    font-size: 26px;
    line-height: 40px;
    /* font-weight: 400; */
  }
  .main_slick_content .main_slick_content_text em {
    font-size: 36px;
  }
  .main_banner_button_wrap {
    max-width: 100%;
    bottom: 90px;
    padding: 0 20px;
  }

  .main_section_wrap {
    max-width: 100%;
    flex-direction: column;
    padding: 0;
  }
  /* 첫번째 영역 - 새로운 투자 상품 / 실시간 인기상품 */
  .main_section_inner {
    width: 100%;
    height: auto;
  }

  .main_section_wrap .main_section_left {
    width: 100%;

    border-right: 0;
    /* padding: 36px 0px; */
    padding: 30px 20px;
    border-bottom: 2px solid #f3f4f6;
  }
  .main_section_left .main_news_wrap {
    display: flex;
    flex-flow: row wrap;

    width: 100%;

    height: auto;
  }

  .main_news_wrap .main_news_item {
    float: left;
    margin: 0 10px 16px 0;
    width: calc(33.333% - 7.333px);
    cursor: pointer;
  }
  .main_section_wrap .main_section_right {
    padding: 30px 20px;
    border-bottom: 2px solid #f3f4f6;
  }

  .main_rank_box {
    width: 100%;
    height: auto;
    padding-top: 20px;
  }
  .main_rank_box .main_rank_inner {
    width: 100%;
  }
  .main_rank_box .main_rank_inner.main_rank_not {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .main_rank_box .main_rank_item {
    width: 100%;
    height: auto;
    padding: 2px 0;
    margin-bottom: 14px;
  }
  .main_rank_item .main_rank_item_inner {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
  }
  .main_rank_item_inner .main_rank_num {
    /* flex-basis: 20px; */
    width: 20px;
    font-size: 34px;
  }
  .main_rank_item_inner .main_rank_content {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    /* background-color: red; */
  }
  .main_rank_content .main_rank_title {
    font-size: 18px;
    line-height: 20px;
  }

  .main_rank_content .main_rank_detail {
    font-size: 15px;
    color: #848383;
  }
  .main_rank_content .main_rank_detail em {
    font-size: 18px;
    color: #00c73c;
    margin-right: 3px;
    font-family: "Myriad-pro";
    font-weight: 600;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
  }

  .main_rank_content .main_rank_cont {
  }
  .main_rank_item_inner .main_rank_img_mobile {
    display: none;
  }
  .main_rank_item_inner .main_rank_img {
    /* flex-basis: 140px; */
    width: 140px;
    height: 100px;
    border-radius: 3px;
    overflow: hidden;
  }
  .main_rank_item_inner .main_rank_img img {
    width: 100%;
    height: 100%;
  }
  .main_section4_wrap > h3 {
    width: 200px;
    height: 70px;
    /* background: red; */
    /* height: 100%; */
    line-height: 70px;
    /* padding: 0 20px; */
    text-indent: 0;
  }
}

@media all and (max-width: 660px) {
  .main_banner_wrap {
    height: 528px;
  }
  .main_slick_div .main_slick_background::after {
    display: none;
  }
  .main_slick_content {
    max-width: 100%;
    height: 528px;
    padding-left: 48px;
    padding-top: 109px;
    align-items: flex-start;
  }

  .main_slick_content .main_slick_content_text p {
    font-size: 36px;
    line-height: 50px;
    /* font-weight: 400; */
  }
  .main_slick_content .main_slick_content_text em {
    font-size: 60px;
  }

  .main_banner_button_wrap {
    bottom: 70px;

    max-width: 100%;

    /* left: 50%; */
  }

  .main_banner_button_wrap .main_banner_nav {
    width: 340px;
    height: 3px;

    border-radius: 3px;

    display: flex;
  }
  .main_banner_button_wrap .banner_nav {
  }
  .main_banner_button_wrap .banner_nav.banner_active {
  }
  .main_banner_button {
  }
  .main_banner_button > div {
    width: 40px;
    height: 40px;
  }
  .main_banner_button > div > div {
    font-size: 28px;
  }

  .main_section_inner {
    width: 100%;
  }
  .main_news_inner .main_news_img {
    height: auto;
  }

  .main_section_inner {
    /* width: 560px; */
    height: auto;
    margin: 0 auto;

    border-bottom: none;
  }
  .main_section_wrap .main_section_left {
    border-right: 0;
    width: 100%;
    padding: 24px 20px;
    background-color: #fff;
  }

  .main_title_h3 {
    font-size: 20px;
  }
  .main_title_sub {
    font-size: 14px;
  }
  .main_section_wrap .main_section_left .main_news_more {
    font-size: 14px;
  }

  .main_rank_item_inner .main_rank_num {
    /* flex-basis: 13px; */
    width: 13px;
    text-align: center;
    font-size: 23px;

    font-family: "Myriad-pro";
    font-weight: 600;
    margin-right: 8px;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
    line-height: 20px;
  }
  .main_rank_item_inner .main_rank_content {
    flex: 1;
    padding-left: 10px;
    padding-right: 20px;
  }
  .main_rank_content .main_rank_title {
    font-size: 14px;
    /* line-height: 16px; */
    min-height: 40px;
  }

  .main_rank_content .main_rank_detail {
    font-size: 13px;
    color: #848383;
  }
  .main_rank_content .main_rank_detail em {
    font-size: 17px;
    color: #00c73c;
    margin-right: 3px;
    font-family: "Myriad-pro";
    font-weight: 600;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
  }

  .main_rank_content .main_rank_cont {
  }
  .main_rank_item_inner .main_rank_img_mobile {
    display: block;
    flex-shrink: 0;
    /* flex-basis: 113px; */
    flex: none;
    width: 113px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #ededed;
  }
  .main_rank_item_inner .main_rank_img_mobile img {
    width: 100%;
    height: 100%;
  }
  .main_rank_item_inner .main_rank_img {
    display: none;
  }
  .main_rank_item_inner .main_rank_img img {
    width: 100%;
    height: 100%;
  }

  .main_section5_inner {
    width: 100%;
  }
  .main_section4_inner {
    padding: 0px 20px;
  }
  .main_section4_wrap {
    max-width: 100%;
    align-items: center;
    /* flex-direction: column; */
  }
  .main_section3_wrap {
    width: 100%;
  }

  .main_section3_imgbox .main_section3_content {
  }
  .main_section3_content h3 {
    /* font-weight: 700; */
    width: 260px;
    font-size: 20px;
    color: #fff;
    margin-bottom: 15px;
    /* text-align: center; */
    /* line-height: 240px; */
  }
  .main_section3_content p {
    font-size: 17px;
    color: #fff;
    margin-bottom: 25px;
  }

  .main_section3_content button {
    background-color: #00c73c;
    width: 150px;
    height: 48px;
    color: #fff;
    border-radius: 3px;
  }
  .main_section3_wrap .main_section3_imgbox img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .main_section4_wrap .main_new_news {
    margin-left: 30px;
    margin-left: 0;
  }
  .main_section4_wrap > h3 {
    height: 30px;
    line-height: 30px;
  }
}

@media all and (max-width: 560px) {
  .main_banner_wrap {
    height: 448px;
  }
  .main_slick_div .main_slick_background::after {
    display: none;
  }
  .main_slick_content {
    max-width: 100%;
    height: 448px;
    padding-left: 28px;
    padding-top: 93.33333333332px;
  }

  .main_banner_button_wrap .main_banner_nav {
    width: 280px;
    height: 3px;

    border-radius: 3px;

    display: flex;
  }
  .main_banner_button_wrap .banner_nav {
  }
  .main_banner_button_wrap .banner_nav.banner_active {
  }
  .main_banner_button {
  }
  .main_banner_button > div {
    width: 38px;
    height: 38px;
  }
  .main_banner_button > div > div {
    font-size: 28px;
  }
  .main_slick_content .main_slick_content_text p {
    font-size: 28px;
    line-height: 36px;
    /* font-weight: 400; */
  }
  .main_slick_content .main_slick_content_text em {
    font-size: 50px;
  }

  .search_menu_box ul .search_menu_li {
    min-width: 0;
  }
  .search_menu_box ul .search_menu_li:last-child {
    margin-right: 0;
  }
}

@media all and (max-width: 540px) {
  /* .main_section_wrap .main_section_left {
    width: 100%;

    border-right: 0;
    padding: 30px 20px;
    border-bottom: 2px solid #f3f4f6;
  }
  .main_section_left .main_news_wrap {
    display: flex;
    flex-flow: row wrap;

    width: 100%;

    height: auto;
  }

  .main_news_wrap .main_news_item {
    float: left;
    margin: 0 10px 16px 0;
    width: calc(50% - 10.333px);
    cursor: pointer;
  }
  .main_news_wrap .main_news_item:nth-child(2n) {
    margin: 0 0 16px;
  }
  .main_news_wrap .main_news_item:nth-child(3n) {
    margin: 0 10px 16px 0;
  }
  .main_section_wrap .main_section_right {
    padding: 30px 20px;
  } */
}

@media all and (max-width: 520px) {
  .search_menu_box ul .search_menu_li {
    font-size: 13px;
  }
  .search_menu_box ul .search_menu_li span {
    font-size: 13px;
  }
}
@media all and (max-width: 500px) {
  .search_top .search_input_box {
    font-size: 14px;
    height: 40px;
  }
  .search_top .search_input_box p {
    width: 40px;
    height: 40px;
  }
  .search_top .search_input_box p .svg-inline--fa {
    vertical-align: 0.1em;
  }
  .search_top .search_input_box button {
    font-size: 14px;
    width: 80px;
    height: 40px;
  }
}
@media all and (max-width: 460px) {
  .main_banner_wrap {
    height: 368px;
  }
  .main_slick_div .main_slick_background::after {
    display: none;
  }
  .main_slick_content {
    max-width: 100%;
    height: 368px;
    padding-left: 20px;
    padding-top: 76.66666666665px;
  }
  .main_banner_button_wrap .main_banner_nav {
    width: 50%;
    height: 3px;

    border-radius: 3px;

    display: flex;
  }
  .main_banner_button_wrap .banner_nav {
  }
  .main_banner_button_wrap .banner_nav.banner_active {
  }
  .main_banner_button {
  }
  .main_banner_button > div {
    width: 36px;
    height: 36px;
  }
  .main_banner_button > div > div {
    font-size: 26px;
  }
  .main_slick_content .main_slick_content_text p {
    font-size: 24px;
    line-height: 30px;
    /* font-weight: 400; */
  }
  .main_slick_content .main_slick_content_text em {
    font-size: 36px;
  }

  .search_menu_box ul .search_menu_li {
    font-size: 12px;
  }
  .search_menu_box ul .search_menu_li span {
    font-size: 12px;
  }
}

@media all and (max-width: 430px) {
  .search_top .search_title {
    font-size: 30px;
    line-height: 45px;
  }
  .search_menu_box ul .search_menu_li {
    margin-right: 15px;
  }
  .search_menu_box ul .search_menu_li:last-child {
    margin-right: 0;
  }
}
@media all and (max-width: 380px) {
  .search_bottom .search_menu_box {
    height: 65px;
  }
  .search_menu_box ul .search_menu_li {
    height: 65px;
  }
  .search_menu_box ul .search_menu_li span {
    display: block;
    line-height: 12px;
    padding-left: 0;
  }
  .search_menu_box ul .search_menu_li::before {
    bottom: -1px;
  }
}

/* 공통 CSS */

.ellipsis {
  /* display: block; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 말줄임 적용 */
}

.two_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
