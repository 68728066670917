.login_header_wrap {
  width: 100%;

  height: 60px;
  border-bottom: 1px solid #b6b6b6b5;
}
.login_header_wrap .login_header {
  width: 440px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.login_header_wrap .login_header img {
  width: 83px;
  /* height: 18px; */
}

.login_content_wrap {
  width: 100%;
  height: auto;
}
.login_content_wrap .login_content {
  width: 440px;
  height: auto;
  margin: 0 auto;
  padding: 50px 30px;
}

.login_h3 {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: #00c73c;
  margin-bottom: 20px;
  padding: 0 10px;
}
.login_h3.login_find_h3 {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: #00c73c;
  margin-bottom: 4px;
  padding: 0 10px;
}
.login_ex {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #00c73c;
  margin-bottom: 0px;
  padding: 0 10px;
}
.login_box {
  width: 100%;
  padding: 0 10px;
}
.login_box .login_section {
  display: flex;
  flex-direction: column;
}
.login_box .login_section > input {
  border: 1px solid #a5a5a5d8;
  border-radius: 3px;
  height: 44px;
  margin-bottom: 15px;
  font-size: 12px;
  text-indent: 5px;
  box-shadow: inset 0 0 2px rgb(127, 127, 127, 0.5);
  color: #818181;
}

.login_box .login_btn_box {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.login_btn_box .login_btn {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
}
.login_btn_box .login_btn.login_nomal {
  background-color: #00c73c;
  color: #fff;
  margin-bottom: 10px;
}
.login_btn_box.sns_login {
  padding-top: 20px;
  border-top: 1px solid #b9b9b9;
  position: relative;
}
.login_btn_box.sns_login .sns_title {
  position: absolute;
  top: -15px;
  left: 50%;
  margin-left: -50px;

  font-size: 12px;
  width: 100px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #fff;
  color: #818181;
}
.etc_ul {
  margin-bottom: 30px;
  font-size: 12px;
  text-align: center;
}
.etc_ul li {
  height: 30px;
}
.etc_ul li span a {
  margin-left: 3px;
  text-decoration: underline;
  color: #00c73c;
  cursor: pointer;
}

.login_btn_box.sns_login {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login_btn_box .login_btn.login_kakao {
  background: #fee500;

  color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.login_btn_box .login_btn.login_naver {
  display: flex;

  justify-content: center;
  align-items: center;
  background-color: #03c75a;
  color: #fff;
}

.login_btn_box .login_btn > img {
  width: 20px;
  margin-right: 4px;
}
@media all and (max-width: 1100px) {
  .login_header_wrap {
    height: 55px;
  }
  .login_header_wrap .login_header {
    width: 100%;

    height: 55px;
  }
  .login_header_wrap .login_header img {
    width: 83px;
    /* height: 18px; */
  }

  .login_content_wrap {
    width: 100%;
    height: auto;
  }
  .login_content_wrap .login_content {
    width: 420px;
    height: auto;
    margin: 0 auto;
    padding: 40px 30px;
  }

  .login_h3 {
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    color: #00c73c;
    margin-bottom: 20px;
    padding: 0 10px;
  }
  .login_box {
    width: 100%;
    padding: 0 10px;
  }

  .login_box .login_section > input {
    border: 1px solid #a5a5a5d8;
    border-radius: 3px;
    height: 44px;
    margin-bottom: 15px;
    font-size: 12px;
    text-indent: 5px;
    box-shadow: inset 0 0 2px rgb(127, 127, 127, 0.5);
    color: #818181;
  }

  .login_box .login_btn_box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .login_btn_box .login_btn {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .login_btn_box .login_btn.login_nomal {
    background-color: #00c73c;
    color: #fff;
    margin-bottom: 10px;
  }
  .login_btn_box.sns_login {
    padding-top: 20px;
    border-top: 1px solid #b9b9b9;
    position: relative;
  }
  .login_btn_box.sns_login .sns_title {
    position: absolute;
    top: -15px;
    left: 50%;
    margin-left: -50px;

    font-size: 12px;
    width: 100px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background-color: #fff;
    color: #818181;
  }
  .etc_ul {
    margin-bottom: 30px;
    font-size: 12px;
    text-align: center;
  }
  .etc_ul li {
    height: 30px;
  }
  .etc_ul li span a {
    margin-left: 3px;
    text-decoration: underline;
    color: #00c73c;
    cursor: pointer;
  }

  .login_btn_box.sns_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .login_btn_box .login_btn.login_kakao {
    background: #fee500;

    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .login_btn_box .login_btn.login_naver {
    display: flex;

    justify-content: center;
    align-items: center;
    background-color: #03c75a;
    color: #fff;
  }

  .login_btn_box .login_btn > img {
    width: 20px;
    margin-right: 4px;
  }
}
@media all and (max-width: 450px) {
  .login_content_wrap .login_content {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 24px 40px;
  }

  .login_h3 {
    font-size: 20px;

    margin-bottom: 16px;
  }
  .login_box {
    width: 100%;
    padding: 0 10px;
  }

  .login_box .login_section > input {
    border: 1px solid #a5a5a5d8;
    border-radius: 3px;
    height: 44px;
    margin-bottom: 15px;
    font-size: 12px;
    text-indent: 5px;
    box-shadow: inset 0 0 2px rgb(127, 127, 127, 0.5);
    color: #818181;
  }

  .login_box .login_btn_box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .login_btn_box .login_btn {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
  }
  .login_btn_box .login_btn.login_nomal {
    background-color: #00c73c;
    color: #fff;
    margin-bottom: 6px;
  }
  .login_btn_box.sns_login {
    padding-top: 20px;
    border-top: 1px solid #b9b9b9;
    position: relative;
  }
  .login_btn_box.sns_login .sns_title {
    position: absolute;
    top: -15px;
    left: 50%;
    margin-left: -50px;

    font-size: 12px;
    width: 100px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background-color: #fff;
    color: #818181;
  }
  .etc_ul {
    margin-bottom: 18px;
    font-size: 12px;
    text-align: center;
  }
  .etc_ul li {
    height: 24px;
  }
  .etc_ul li span a {
    margin-left: 3px;
    text-decoration: underline;
    color: #00c73c;
    cursor: pointer;
  }

  .login_btn_box.sns_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .login_btn_box .login_btn.login_kakao {
    background: #fee500;

    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .login_btn_box .login_btn.login_naver {
    display: flex;

    justify-content: center;
    align-items: center;
    background-color: #03c75a;
    color: #fff;
  }

  .login_btn_box .login_btn > img {
    width: 20px;
    margin-right: 4px;
  }
}
/* 회원가입 */
.signup_content_wrap {
  width: 100%;
  height: auto;
}

.signup_content_wrap .signup_content {
  width: 400px;
  height: auto;
  margin: 0 auto;
  padding: 30px 30px;
}

.signup_h3 {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #00c73c;
  margin-bottom: 20px;
  padding: 0 10px;
}
.signup_section {
  padding: 0 10px;
  margin-bottom: 5px;
}
.signup_section label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
}

.signup_name_inp {
  width: 100%;
  display: flex;
  height: 44px;
  box-shadow: inset 0 0 2px rgb(127, 127, 127, 0.5);
  border-radius: 3px;
}
.signup_name_inp > input {
  height: 44px;

  font-size: 14px;
  text-indent: 5px;

  color: #818181;
  background: transparent;
  width: 100%;
}
.signup_name_inp .p_nameByte {
  width: 50px;
  text-align: center;
  font-size: 14px;
  color: #818181;
  height: 44px;
  line-height: 44px;
}

.signup_email_box {
  display: flex;
  justify-content: space-between;
}
.signup_email_box.box2 {
  margin-top: 6px;
}
.signup_email_box .signup_email_inp {
  width: 70%;
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgb(127, 127, 127, 0.5);
  overflow: hidden;
}
.signup_email_box .signup_email_inp > input {
  border-radius: 3px;
  height: 44px;

  font-size: 14px;
  text-indent: 5px;

  color: #818181;
  width: 100%;
}
.signup_email_box > button {
  width: 80px;
  height: 44px;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
}

.signup_email_inp2 {
  display: flex;
  width: 70%;
  height: 44px;
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgb(127, 127, 127, 0.5);
  overflow: hidden;
}
.signup_email_inp2 > input {
  height: 44px;
  flex: 1;
  background: transparent;
  text-indent: 5px;
  color: #818181;
}
.signup_email_inp2 > span {
  flex-basis: 40px;
  line-height: 44px;
  text-align: center;
  font-size: 14px;
  color: #818181;
}
.signup_pass_inp {
  border-radius: 5px;
  box-shadow: inset 0 0 2px rgb(127, 127, 127, 0.5);
  overflow: hidden;
  height: 44px;
}
.signup_pass_inp > input {
  background: transparent;
  text-indent: 5px;
  color: #818181;
  height: 44px;
  width: 100%;
}
.btn_signup_box {
  padding: 0 10px;
  margin-top: 10px;
}
.btn_signup {
  width: 100%;
  height: 44px;
  text-align: center;
  background-color: #00c73c;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.no_btn_signup {
  width: 100%;
  height: 44px;
  text-align: center;
  background-color: #00c73c;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  cursor: not-allowed !important;
}

.sns_signup {
  padding-top: 20px;
  border-top: 1px solid #b9b9b9;
  position: relative;
  margin-top: 50px;
}
.sns_title_signup {
  position: absolute;
  top: -15px;
  left: 50%;
  margin-left: -70px;

  font-size: 12px;
  width: 140px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #fff;
  color: #818181;
}

.sns_signup {
  display: flex;
  flex-direction: column;
}
.sns_signup .login_btn.login_kakao {
  background: #fee500;
  width: 100%;
  height: 44px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.sns_signup .login_btn {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 5px;
}

.sns_signup .login_btn.login_naver {
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: center;
  align-items: center;
  background-color: #03c75a;
  color: #fff;
}

.sns_signup img {
  width: 20px;
}

.error_em {
  font-size: 10px;
  color: #e2885e;
  height: 20px;
  line-height: 20px;
}
.noerror_em {
  font-size: 10px;
  color: #00c73c;
  height: 20px;
  line-height: 20px;
}
