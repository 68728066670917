a {
  display: inline-block;
}
span {
  line-height: 30px;
}
.wrap1 {
  display: flex;
  /* justify-content: space-between; */
}
.wrap1 .wrap_dashboard_box {
  background-color: #3c3c3c;
  /* background-color: #00c73c; */
  color: #fff;
  min-height: 1000px;
  min-width: 240px;
}
.wrap1 .wrap_dashboard_box .dashboard_content_list.logo_box {
  height: 80px;

  text-align: center;
}
.wrap1 .wrap_dashboard_box .dashboard_content_list .logo_img {
  width: 80px;
  /* height: 60px; */
  /* padding: 15px; */
}

.wrap1 .wrap_dashboard_box .horizen {
  border: 1px solid #fff;
}

.wrap1 .wrap_dashboard_box .dashboard_content_list {
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  height: 50px;
  line-height: 30px;
  padding: 5px;
}

.wrap1 .wrap_dashboard_box .dashboard_content_list .icons {
  display: inline-block;
  color: #fff;
  font-size: 15px;
  height: 40px;
  text-align: center;
  width: 40px;
}
.wrap1 .wrap_dashboard_box .dashboard_content_list .icons_count {
  /* display: inline-block; */
  color: #fff;
  font-size: 10px;
  /* padding: 2px 5px; */
  height: auto;
  text-align: left;
  width: 25px;
  height: 25px;
  background-color: rgb(0, 199, 60);
  border-radius: 50%;

  /* width: 50px; */
}
.wrap1 .wrap_dashboard_box .dashboard_content_list .icons_count span {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  /* height: auto; */
}
.wrap1 .wrap_dashboard_box .dashboard_content_list .text {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  height: 50px;
  padding: 5px;
}

.wrap1 .wrap_dashboard_box .dashboard_content_list.middle_text {
  color: #9e9e9e;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  margin-left: 13px;
  padding: 5px;
}

.wrap1 .wrap_dashboard_box .dashboard_subject {
  height: 50px;
  overflow: hidden;
}

.wrap1 .wrap_dashboard_box .dashboard_subject.viewActive {
  height: auto;
}
.wrap1 .wrap_dashboard_box .dashboard_list .text {
  display: inline-block;
  /* color: #fff; */
  font-size: 13px;
  margin: 10px 10px 10px 50px;
}
.wrap1 .wrap_dashboard_box .dashboard_list > a {
  height: 50px;
}

.wrap_content_box {
  min-width: 1000px;
  position: relative;
}
.admin_ing_all_div {
  width: 100%;
  padding: 20px 240px;
  background-color: #b0b0b0;

  /* display: flex; */
  /* justify-content: center; */
}
.admin_ing_all_div > p {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
}
.wrap_content_box .wrap_content_box_header {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: space-between;
  line-height: 50px;
  padding: 0 20px;
  /* padding: 10px 10px 10px 20px; */
  font-weight: bold;

  height: 90px;
}
/* .wrap_content_box .wrap_content_box_header::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
} */
.wrap_content_box .wrap_content_box_header .header_button {
  width: 140px;
  height: 50px;
  background-color: #00c73c;
  cursor: pointer;
}
.wrap_content_box_header .header_button > button {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  color: #fff;
}
.wrap_content_box .wrap_chat_box_body {
  padding: 10px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
}

.wrap_content_box .wrap_chat_box_body > .room_list {
  background-color: white;
  width: 200px;
  height: 200px;
  padding: 10px;
  font-size: 13px;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid black;
  border-radius: 10px;
}

.wrap_content_box .wrap_chat_box_body > .room_list > img {
  width: 100px;
  height: 100px;
}

.wrap_content_box .wrap_content_box_body {
  background-color: #f5f5f5;
  padding: 20px;
  min-height: 900px;
}

.wrap_content_box .wrap_content_box_body .user_search {
  height: auto;
  background-color: white;
  margin-bottom: 20px;
}
.wrap_content_box .wrap_content_box_body .user_search > .search_icon {
  /* line-height: 50px; */
  padding: 15px;
}

.wrap_content_box .wrap_content_box_body .user_search > input {
  border: 0;
  /* line-height: 50px; */
  font-size: 15px;
  width: 90%;
}

.wrap_content_box .wrap_content_box_body .user_search > input:focus {
  outline: none;
}

.wrap_content_box .wrap_content_box_body > .user_header {
  display: flex;
  font-size: 13px;
  height: 50px;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}
.wrap_content_box .wrap_content_box_body > .user_header.user_header2 {
  margin-bottom: 20px;
  width: 100%;
}
.wrap_content_box
  .wrap_content_box_body
  > .user_header.user_header2
  > .user_option {
  width: 100%;
}
.wrap_content_box
  .wrap_content_box_body
  > .user_header.user_header2
  > .user_option
  > div {
  width: auto;
  background: transparent;
}

.wrap_content_box
  .wrap_content_box_body
  > .user_header.user_header2
  > .user_option
  > div.clicked {
  background: transparent;
  /* background: #a5cdbb; */

  /* background: #3c3c3c; */
  /* color: #0e9e5d; */
  color: black;
}
.wrap_content_box
  .wrap_content_box_body
  > .user_header.user_header2
  > .user_option
  > div.clicked
  span {
  text-decoration: underline;
}

.wrap_content_box .wrap_content_box_body > .user_header > .user_option {
  text-align: center;
  width: 650px;
  height: 50px;
}
.wrap_content_box
  .wrap_content_box_body
  > .user_header
  > .user_option.banner_header {
  /* text-align: center; */
  width: 900px;
  /* height: 50px; */
}
.wrap_content_box .wrap_content_box_body > .user_header > .user_option > div {
  background-color: white;
  cursor: pointer;
  float: left;
  padding: 10px;
  height: 30px;
  line-height: 30px;
  width: 20%;
  font-weight: bold;
  height: 50px;
}
.wrap_content_box
  .wrap_content_box_body
  > .user_header
  > .user_option.banner_header
  > div {
  width: 15%;
  /* text-align: center; */
  /* width: 780px; */
  /* height: 50px; */
}
.wrap_content_box
  .wrap_content_box_body
  > .user_header
  > .user_option
  > div.clicked {
  /* background: #a5cdbb; */

  background: #3c3c3c;
  /* color: #0e9e5d; */
  color: #fff;
}
.wrap_content_box .wrap_content_box_body > .user_header > .user_button {
  width: 210px;
  height: 50px;
  cursor: pointer;
}
.wrap_content_box .wrap_content_box_body > .user_header > .user_button button {
  width: 100px;
  height: 100%;
  font-size: 13px;
  font-weight: 500;
  background: #00c73c;
  color: #fff;
  /* background-color: #eeeeee; */
  cursor: pointer;
  border-radius: 5px;
}
.wrap_content_box
  .wrap_content_box_body
  > .user_header
  > .user_button
  button:first-child {
  margin-right: 10px;
}
.wrap_content_box .wrap_content_box_body .user_header .header_btns {
  height: 50px;
}

.wrap_content_box .wrap_content_box_body .user_header .header_btns button {
  background-color: #0e9e5d;
  color: white;
  font-size: 13px;
  font-weight: 700;
  height: 50px;
  margin-bottom: 5px;
  margin-right: 3px;
  width: 120px;
  border-radius: 10px;
}

.wrap_content_box .wrap_content_box_body > table {
  background-color: rgb(255, 255, 255);
  font-size: 15px;
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
}
.wrap_content_box .wrap_content_box_body > table tr {
  height: 60px;
  text-align: center;
  border-bottom: 1px solid #bebebe;
}

.wrap_content_box .wrap_content_box_body > .table_pagination {
  background-color: white;
  font-size: 13px;
  width: 100%;
  text-align: center;
  height: 60px;
  /* line-height: 60px; */
}
.wrap_content_box .wrap_content_box_body > .table_pagination > button {
  border: 0;
  margin: 5px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
}
.admin_user_box {
  width: 54%;
  height: auto;
  display: flex;

  flex-direction: column;
  /* justify-content: space-around; */
}
.admin_user_box.user_box {
  width: 100%;
}
.admin_user_box.user_box > div {
  width: 54%;
}
.admin_user_box.user_box > .user_box_inner {
  width: 100%;
  display: flex;
  background-color: transparent;
  justify-content: space-between;
}

.admin_user_box > div {
  width: 100%;
  background: #fff;
  min-height: 300px;
}
.admin_user_left {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.admin_user_box.user_box > .user_box_inner > .admin_user_left {
  width: 54%;
  background-color: #fff;
  /* display: flex; */
}
.admin_user_box.user_box > .user_box_inner > .admin_user_right {
  width: 44%;
  /* background-color: #fff; */
  /* display: flex; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.admin_user_box.user_box > .user_box_inner > .admin_user_right > div {
  width: 100%;
  background-color: #fff;

  min-height: 200px;
}
.admin_user_rigth {
  padding: 20px 24px;
  margin-top: 10px;
  background: #fff;
  /* display: flex; */
  /* flex-direction: column; */
  width: 100%;

  /* align-items: center; */
}
.admin_user_rigth .admin_memo_title {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
}
.admin_user_rigth .admin_memo_title button {
  width: 64px;
  height: 24px;
  background-color: #00c73c;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
}

.admin_user_rigth .admin_memo_div {
  width: 100%;
  height: 260px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
}
.admin_user_rigth .admin_memo_div textarea {
  width: 100%;
  height: 100%;
  padding: 4px;
  font-size: 14px;
  resize: none;
  outline: none;
}

.admin_user_rigth span {
  width: 100%;
  text-align: right;
  font-size: 12px;
}
.admin_user_step {
  width: 100%;
  height: auto;
}
.admin_user_step_1 {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: auto;
  margin-bottom: 10px;
}
.admin_user_step_1 .admin_user_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.admin_user_step_1 .admin_user_img img {
  width: 100%;
  height: 100%;
}
.admin_user_step_1 .admin_user_name span {
  font-weight: 700;
}
.admin_user_step_1 .admin_user_kind {
  height: 24px;
}
.admin_user_step_1 .admin_user_kind span {
  color: #0e9e5d;
  font-weight: 600;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
}
.admin_user_step {
  width: 100%;
}
.admin_user_step .admin_user_title {
  font-weight: 500;
  font-size: 14px;
  /* background-color: red; */
  width: 160px;
  text-align: right;
  flex: none;
}
.admin_user_right .admin_user_address {
  width: 100%;
  padding: 10px;
}
.admin_user_right .admin_user_address .admin_address_title {
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-weight: 500;
}
.admin_user_right .admin_user_address ul {
  width: 100%;
  margin-top: 20px;
  padding-left: 10px;
}
.admin_user_right .admin_user_address ul li {
  display: flex;
  margin-bottom: 4px;
}

.admin_user_right .admin_user_address ul li label {
  width: 80px;
  flex: none;
  text-align: right;
  padding-right: 4px;
  font-weight: 500;
  margin-right: 5px;
}
.admin_user_step .admin_user_cont {
  width: 200px;
  padding-left: 5px;
  /* background-color: red; */
}
.admin_user_step .admin_user_cont.admin_user_cont_btn button {
  background-color: #676767;
  width: 140px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
}
.admin_user_step_2 {
  display: flex;
  justify-content: center;
}

/* 임시비밀번호 생성 */

.admin_user_box_pass {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: space-around;
}
.admin_user_box_pass > div {
  width: 100%;
  background: #fff;
  min-height: 300px;
}
.admin_user_left_pass {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.admin_user_step_pass {
  width: 100%;
  height: auto;
}
.admin_user_step_pass_1 {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: auto;
  margin-bottom: 10px;
}
/* .admin_user_step_pass_1 .admin_user_img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.admin_user_step_1 .admin_user_img img {
  width: 100%;
  height: 100%;
}
.admin_user_step_1 .admin_user_name span {
  font-weight: 700;
}
.admin_user_step_1 .admin_user_kind {
  height: 24px;
}
.admin_user_step_1 .admin_user_kind span {
  color: #0e9e5d;
  font-weight: 600;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
}
.admin_user_step {
  width: 240px;
}
.admin_user_step .admin_user_title {
  font-weight: 500;
  font-size: 14px;
  width: 100px;
  text-align: right;
}
.admin_user_step .admin_user_cont {
  width: 120px;
}
.admin_user_step_2 {
  display: flex;
  justify-content: space-between;
} */

/* 공지사항 */
.notice_write_wrap {
  width: 100%;
  height: auto;
  padding: 20px 10px;
}
.notice_write_wrap .notice_write_inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.notice_write_wrap .notice_write_inner .notice_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.notice_write_inner .notice_write_title h4 {
  font-size: 18px;
}
.notice_write_inner .notice_write_title .notice_write_button {
  width: 90px;
  height: 40px;
  background-color: #00c73c;
  border-radius: 5px;
}
.notice_write_inner .notice_write_title button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.notice_write_inner .notice_write_content {
  display: flex;
  width: 100%;
}
.notice_write_content .notice_write_left {
  width: 680px;
  /* flex: 0.5; */
  padding: 20px;
  border-top: 2px solid #c7c7c7;
}
.notice_write_content .notice_write_right {
  /* flex: 2; */
  /* background-color: rgb(159, 210, 165);
   */
  /* background: #a5cdbb; */
  background: #b7b7b7;
  /* background-color: #39dd6a; */
  padding: 20px;
  width: 260px;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* width: 100%; */
  /* flex: 2; */
}
.notice_write_content .notice_write_left .notice_write_inp {
  width: 100%;
  height: 50px;
  border: 1px solid #dadde6;
  text-indent: 10px;
  margin-bottom: 10px;
}
.notice_write_content .notice_write_left .notice_write_prview_div {
  text-indent: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}
.notice_write_content .notice_write_left .notice_write_prview {
  width: 100%;
  height: 50px;
  border: 1px solid #dadde6;

  resize: none;
}
.notice_write_content .notice_write_right .notice_file_box {
  text-align: center;
}
.notice_write_content .notice_write_right h4 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.notice_write_content .notice_write_right .notice_file_box .notice_img_box {
  width: 134px;
  height: 100px;
  background-color: #ffffff;
  margin: 0 auto;
}
.notice_img_box > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 110px; */
  /* font-size: 110px; */
  /* background-color: red; */
}

.notice_img_box > div .image_span {
  font-size: 24px;
  font-family: "Myriad-pro";
  color: #b8b8b8;
  font-weight: 700;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}

.notice_file_box .notice_img_box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.notice_file_box .notice_file {
  width: 134px;
  height: 34px;
  /* position: relative; */
  font-weight: 400;
  font-size: 16px;
  background-color: #3c3c3c;
  color: #fff;
  line-height: 34px;
  /* margin-top: 10px; */
  cursor: pointer;
}
.notice_file_box .notice_file .notice_file_btn {
  /* display: block; */
  width: 100%;
  height: 100%;
}
.notice_file_box .notice_file .notice_file_inp {
  width: 100%;
  height: 100%;
  display: none;
}
.notice_open_box {
  margin-top: 20px;
  text-align: center;
}
.notice_open_box label {
  padding: 0 10px;
}
.notice_open_box label {
  cursor: pointer;
}
.ellipsis {
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임 적용 */
}

.notice_view_content .notice_view {
  width: 100%;
  border-bottom: 1px solid #00c73c;
  /* padding: 20px; */
  /* border-top: 2px solid #c7c7c7; */
}

.notice_view_tite {
  /* border-bottom: 1px solid #00c73c;
  display: flex;
  height: 50px;
  align-items: center;
  padding: 0 10px;
  background-color: #00c73c;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-bottom: 1px solid #00c73c;
  display: flex;
  height: auto;
  align-items: center;
  padding: 10px;
  /* background-color: #00c73c; */
  color: black;
}
/* .notice_view_tite > div {
  flex-shrink: 0;
  width: 70px;
  position: relative;
} */
/* .notice_view_tite > div::before {
  content: "";
  display: block;
  width: 2px;
  height: 50px;
  background: #fff;
  right: 0;
  bottom: -5px;

  position: absolute;
}
.notice_view_tite > div > span {
  width: 60px;
  height: 40px;
  margin: 0 auto;
  display: block;
}
.notice_view_tite > div > span > img {
  object-fit: contain;
} */
.notice_view_tite p {
  /* text-indent: 20px;
  font-weight: 500;
  font-size: 24px;
  flex-grow: 1; */
  padding-left: 10px;

  font-weight: 500;
  line-height: 30px;
  font-size: 24px;
  flex-grow: 1;
}
.notice_view_tite > span {
  flex-shrink: 0;
  width: 90px;
  /* background: beige; */
  text-align: right;
  height: 40px;
  line-height: 40px;
  /* line-height: 80px; */
}
.notice_view_cont {
  padding: 10px 5px;
  min-height: 460px;
  /* text-align: center; */
}
.notice_view_bottom {
  padding: 10px 20px;
  min-height: 460px;
}

/* 스토리 */
.story_write_wrap {
  width: 80%;
  height: auto;
  padding: 20px 10px;
}
.story_write_wrap .story_write_inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.story_write_wrap .story_write_inner .story_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.story_write_inner .story_write_title h4 {
  font-size: 18px;
}
.story_write_inner .story_write_title .story_write_button {
  width: 90px;
  height: 40px;
  background-color: #00c73c;
  border-radius: 5px;
}
.story_write_inner .story_write_title button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.story_write_inner .story_write_content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.story_write_content .story_write_top {
  width: 100%;
  /* flex: 0.5; */
  padding: 20px;
  border-top: 2px solid #c7c7c7;
}
.story_write_content .story_write_bottom {
  padding: 20px 80px;
  width: 100%;
}
.story_write_content .story_write_bottom > div {
  margin-bottom: 10px;
}

.story_write_content .story_write_inpbox {
  width: 100%;
  border: 1px solid #dadde6;
  height: 40px;
  display: flex;
  overflow: hidden;
}

.story_write_content .story_write_inpbox span {
  text-align: center;
  /* background-color: red; */
  padding: 0 10px;
  font-size: 12px;
  color: #7f7f7f;
}

.story_write_content .story_write_bottom .story_write_inp {
  width: 100%;
  height: 40px;
  /* border: 1px solid #dadde6; */
  text-indent: 10px;
  margin-bottom: 10px;
}
.story_write_content .story_write_bottom .story_inp_last {
  display: flex;
  justify-content: space-between;
}
.story_write_content .story_write_bottom .story_inp_last > div {
  /* margin-right: 10px; */
  flex: 1;
}
.story_write_content .story_write_bottom .story_inp_last > div:first-child {
  margin-right: 10px;
  /* flex: 1; */
}
.story_write_content .story_write_bottom label {
  font-weight: 400;
  text-indent: 7px;
  margin-bottom: 5px;
}
.story_write_content .story_write_top .story_file_box {
  text-align: center;
}
.story_write_content .story_write_top h4 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.story_write_content .story_write_top .story_file_box .story_img_box {
  width: 295px;
  height: 160px;
  background-color: #ffffff;
  margin: 0 auto;
  border: 1px solid #9e9e9e;
}

.story_img_box > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* line-height: 110px;
  font-size: 110px; */

  /* background-color: red; */
}
.story_img_box > div .image_span {
  font-size: 36px;
  font-family: "Myriad-pro";
  color: #b8b8b8;
  font-weight: 700;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}

.story_file_box .story_img_box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.story_file_box .story_file {
  width: 295px;
  height: 34px;
  /* position: relative; */
  font-weight: 400;
  font-size: 16px;
  background-color: #3c3c3c;
  color: #fff;
  line-height: 34px;
  /* margin-top: 10px; */
  cursor: pointer;
}
.story_warning {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #00c73c;
}
.story_file_box .story_file .story_file_btn {
  /* display: block; */
  width: 100%;
  height: 100%;
}
.story_file_box .story_file .story_file_inp {
  width: 100%;
  height: 100%;
  display: none;
  /* position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
}
.story_open_box {
  margin-top: 20px;
  text-align: center;
}
.story_open_box label {
  padding: 0 10px;
}
.story_open_box label {
  cursor: pointer;
}

/* 스토어 */
.store_write_wrap {
  width: 80%;
  height: auto;
  padding: 20px 10px;
}
.store_write_wrap .store_write_inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.store_write_wrap .store_write_inner .store_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.store_write_inner .store_write_title h4 {
  font-size: 18px;
}
.store_write_inner .store_write_title .store_write_button {
  width: 90px;
  height: 40px;
  background-color: #00c73c;
  border-radius: 5px;
}
.store_write_inner .store_write_title button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.store_write_inner .store_write_content {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.store_write_content .store_write_top {
  width: 100%;
  /* flex: 0.5; */
  padding: 20px;
  border-top: 2px solid #c7c7c7;
}
.store_write_content .store_write_bottom {
  padding: 20px 80px;
  width: 100%;
}

.store_write_content .store_write_inpbox {
  width: 100%;
  border: 1px solid #dadde6;
  height: 40px;
  /* background-color: red; */
  display: flex;
}

.store_write_content .store_write_inpbox span {
  text-align: center;
  /* background-color: red; */
  padding: 0 10px;
  font-size: 12px;
  color: #7f7f7f;
  line-height: 40px;
}
.store_write_content .store_write_bottom > div {
  margin-bottom: 10px;
}
.store_write_content .store_write_bottom .store_write_inp {
  width: 100%;
  height: 100%;
  /* height: 50px; */
  /* border: 1px solid #dadde6; */
  text-indent: 10px;
  /* margin-bottom: 10px; */
}
.store_write_content .store_write_bottom .store_inp_last {
  display: flex;
  justify-content: space-between;
}
.store_write_content .store_write_bottom .store_inp_last > div:first-child {
  margin-right: 10px;
}
.store_write_content
  .store_write_bottom
  .store_inp_last
  > div:first-child
  select {
  width: 100%;
}
.store_write_content .store_write_bottom .store_inp_last > div {
  flex: 1;
}
.store_write_content .store_write_bottom label {
  font-weight: 400;
  text-indent: 7px;
  margin-bottom: 5px;
}
.store_write_content .store_write_top .store_file_box {
  text-align: center;
}
.store_write_content .store_write_top h4 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.store_write_content .store_write_top .store_file_box .store_img_box {
  width: 235px;
  height: 235px;
  background-color: #ffffff;
  margin: 0 auto;
  border: 1px solid #9e9e9e;
}

.store_img_box > div {
  width: 100%;
  height: 100%;
  /* line-height: 1/px; */

  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}
.store_img_box > div .image_span {
  font-size: 30px;
  font-family: "Myriad-pro";
  color: #b8b8b8;
  font-weight: 700;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}
.store_file_box .store_img_box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.store_file_box .store_file {
  width: 235px;
  height: 34px;
  /* position: relative; */
  font-weight: 400;
  font-size: 16px;
  background-color: #3c3c3c;
  color: #fff;
  line-height: 34px;
  /* margin-top: 10px; */
  cursor: pointer;
}
.store_file_box .store_file .store_file_btn {
  /* display: block; */
  width: 100%;
  height: 100%;
}
.store_file_box .store_file .store_file_inp {
  width: 100%;
  height: 100%;
  display: none;
  /* position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
}
.store_open_box {
  margin-top: 20px;
  text-align: center;
}
.store_open_box label {
  padding: 0 10px;
}
.story_open_box label {
  cursor: pointer;
}

.store_warning {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #00c73c;
}
.notice_warning {
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  /* color: #00c73c; */
}

/* FNQ */

.fnq_write_wrap {
  width: 80%;
  height: auto;
  padding: 20px 10px;
}
.fnq_write_wrap .fnq_write_inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.fnq_write_wrap .fnq_write_inner .fnq_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.fnq_write_inner .fnq_write_title h4 {
  font-size: 18px;
}
.fnq_write_inner .fnq_write_title .fnq_write_button {
  width: 90px;
  height: 40px;
  background-color: #00c73c;
  border-radius: 5px;
}
.fnq_write_inner .fnq_write_title button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.fnq_write_inner .fnq_write_content {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.fnq_write_content .fnq_write_bottom {
  /* flex: 2; */
  /* background-color: rgb(159, 210, 165); */
  padding: 20px 80px;
  width: 100%;
  border-top: 2px solid #c7c7c7;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* width: 100%; */
  /* flex: 2; */
}
.fnq_write_content .fnq_write_bottom .fnq_write_inp {
  width: 100%;
  height: 50px;
  border: 1px solid #dadde6;
  text-indent: 10px;
  margin-bottom: 10px;
}
.fnq_write_content .fnq_write_bottom .fnq_write_inp_area {
  width: 100%;
  height: 120px;
  border: 1px solid #dadde6;
  text-indent: 10px;
  margin-bottom: 10px;
  resize: none;
}
.fnq_write_content .fnq_write_bottom .fnq_inp_last {
  display: flex;
  justify-content: space-between;
}
.fnq_write_content .fnq_write_bottom .fnq_inp_last > div:first-child {
  margin-right: 10px;
}
.fnq_write_content .fnq_write_bottom label {
  font-weight: 400;
  text-indent: 7px;
  margin-bottom: 5px;
}

.fnq_img_box > div {
  width: 100%;
  height: 100%;
  line-height: 110px;
  font-size: 110px;

  /* background-color: red; */
}
.fnq_open_box {
  margin-top: 20px;
  text-align: center;
}
.fnq_open_box label {
  padding: 0 10px;
}
.fnq_open_box label {
  cursor: pointer;
}

/* 리뷰 */

.review_write_wrap {
  width: 80%;
  height: auto;
  padding: 20px 10px;
}
.review_write_wrap .review_write_inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.review_write_wrap .review_write_inner .review_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.review_write_inner .review_write_title h4 {
  font-size: 18px;
}
.review_write_inner .review_write_title .review_write_button {
  width: 90px;
  height: 40px;
  background-color: #00c73c;
  border-radius: 5px;
}
.review_write_inner .review_write_title button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.review_write_inner .review_write_content {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.review_write_content .review_write_bottom {
  /* flex: 2; */
  /* background-color: rgb(159, 210, 165); */
  padding: 20px 40px;
  width: 100%;
  border-top: 2px solid #c7c7c7;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* width: 100%; */
  /* flex: 2; */
}
.review_write_content .review_write_bottom .review_write_inp,
.review_write_content .review_write_bottom .review_inp_first select {
  width: 100%;
  height: 50px;
  border: 1px solid #dadde6;
  text-indent: 10px;
  margin-bottom: 10px;
}
.review_write_content .review_write_bottom .review_write_inp_area {
  width: 100%;
  height: 120px;
  border: 1px solid #dadde6;
  text-indent: 10px;
  margin-bottom: 10px;
  resize: none;
}
.review_write_content .review_write_bottom .review_inp_first {
  display: flex;
  /* justify-content: space-between; */
}
.review_write_content .review_write_bottom .review_inp_last span {
  text-align: right;
  width: 100%;
  font-size: 12px;
  /* justify-content: space-between; */
}
.review_write_content .review_write_bottom .review_inp_first > div {
  margin-right: 10px;
}
.review_write_content .review_write_bottom .review_inp_first > div:last-child {
  margin-right: 0;
  /* background-color: red; */
}
.review_write_content .review_write_bottom label {
  font-weight: 400;
  text-indent: 7px;
  margin-bottom: 5px;
}

.review_open_box {
  margin-top: 20px;
  text-align: center;
}
.review_open_box label {
  padding: 0 10px;
}
.review_open_box label {
  cursor: pointer;
}

.wrap_content_box .wrap_cont_box_area {
  background-color: #f5f5f5;
  min-height: 1000px;
}
.wrap_content_box .wrap_content_flexbox_body {
  display: flex;
  min-height: 600px;
  justify-content: space-between;
}
.admin_cont_wrap {
  width: 100%;
  height: auto;
  background-color: #fff;
}
.admin_cont_wrap .admin_cont_box .admin_cont_title {
  border-bottom: 2px solid #bebebe;
  display: flex;
  /* height: 50px; */
  justify-content: space-between;
}

.admin_cont_wrap .admin_cont_title .title_name {
  padding: 10px 20px;
  font-weight: 700;
  font-size: 16px;
}

.wrap_content_box .wrap_content_history_box > .table_pagination {
  background-color: white;
  font-size: 13px;
  width: 100%;
  text-align: center;
}
.wrap_content_box .wrap_content_history_box > .table_pagination > button {
  border: 0;
  margin: 5px;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
}

.wrap_content_box .wrap_content_flexbox_body > .product_info_box {
  background-color: white;
  height: 100%;
  width: 100%;
}

/* .wrap_content_box .wrap_content_flexbox_body > .product_present_box {
  background-color: white;
  height: 100%;
  width: 450px;
} */

.wrap_content_box .title_box {
  border-bottom: 2px solid #bebebe;
  display: flex;
  height: 50px;
  justify-content: space-between;
}
.wrap_content_box .title_box > .title_name {
  padding: 10px 20px;
  font-weight: 700;
  font-size: 16px;
}

.wrap_content_box .wrap_content_flexbox_body .contents_box {
  padding: 50px 20px;
}
.wrap_content_box .wrap_content_flexbox_body .contents_box .product_info_inner {
  display: flex;
  justify-content: center;
}
.wrap_content_box .wrap_content_flexbox_body .contents_box .box_list {
  width: 420px;
  height: auto;
  /* line-height: 50px; */
}
.wrap_content_box .wrap_content_flexbox_body .contents_box .box_list > div {
  height: 50px;
  width: 100%;
}
.contents_box > .box_list {
  text-align: center;

  width: 100% !important;
}
.contents_box > .box_list > div {
  /* width: 90%;
  margin: 0 auto; */
  width: 100% !important;
  justify-content: center !important;
}

.contents_box > .box_list > div .box_list_inputbox {
  border: none !important;
  width: 100% !important;
}
.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_title {
  color: #7f7f7f;
  margin-right: 30px;
  text-align: right;
  width: 100px;
}
.wrap_content_box .wrap_content_flexbox_body .contents_box .box_img {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.wrap_content_flexbox_body .box_img .img_div {
  width: 280px;
  height: 160px;
}
.wrap_content_flexbox_body .box_img .img_div img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.wrap_content_flexbox_body .box_img .img_button {
  width: 280px;
  height: 70px;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}
.img_button > label {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  height: 34px;
  border: 1px solid #e0e0e0;
  position: relative;
  cursor: pointer;
  background: #3c3c3c;
  color: #fff;
}
.img_button > label > input {
  display: none;
  position: absolute;
  z-index: -1;
}
.img_button > label > span {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
.img_button button {
  width: 100% !important;
  height: 30px;
  display: block;
  border: 1px solid #e0e0e0;
  font-size: 14px;
}
.wrap_content_box .wrap_content_flexbox_body .contents_box .box_list > div {
  display: flex;
  align-items: center;
}
.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_inputbox {
  /* display: inline-block;
  position: relative; */
  height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 220px;
  margin-right: 5px;
}

.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_inputbox.box_list_etc {
  border: none;
}
.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_inputbox.box_list_etc
  > label {
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
}
.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_inputbox
  span {
  text-align: center;
  /* background-color: red; */
  width: 50px;
  font-size: 12px;
}
.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_inputbox
  .box_recommend {
  width: 190px;
}
.box_recommend label {
  margin-right: 5px;
  cursor: pointer;
}
.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_inputbox
  > input {
  font-size: 12px;
  height: 30px;
  margin-right: 2px;
  padding: 3px;
  width: 160px;
  padding-left: 10px;
  background-color: transparent;
}

.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_inputbox
  > select {
  font-size: 12px;
  height: 30px;
  margin-right: 2px;
  padding: 3px;
  width: 100%;
  /* padding-left: 10px; */
}

.wrap_content_box
  .wrap_content_flexbox_body
  .contents_box
  .box_list
  .box_list_inputbox
  > select {
  text-align: center;
}

.wrap_content_box .wrap_content_flexbox_body .contents_box .box_list button {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  width: 60px;
}

.wrap_content_box .wrap_content_history_box {
  background-color: white;
  margin-top: 20px;
  min-height: 270px;
  width: 100%;
}
.search_history_box {
  background-color: white;
  margin: 20px 0;
  width: 100%;

  /* position: relative; */
}

.search_history_box > .search_search_title {
  display: flex;
  height: 50px;
  justify-content: space-between;
}

.search_history_box > .search_search_title > .search_history {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.search_history_box
  > .search_search_title
  > .search_history
  > .search_title_box {
  width: 60px;
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  position: relative;
}

.search_history_box > .search_search_title > .search_history > .search_data {
  display: flex;
  font-size: 14px;
  padding-top: 0;
  margin-left: 10px;
}

.search_history_box
  > .search_search_title
  > .search_history
  > .search_data
  input {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 12px;
  height: 30px;
  margin-bottom: 5px;
  padding: 10px;
  width: 100px;
}
.product_filter_btn {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.product_filter_btn button {
  background-color: #00c73c;
  border-radius: 2px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  font-size: 14px;
  height: 35px;
  line-height: 33px;

  width: 80px !important;
}

.search_history_box .search_search_title .price_box {
  margin-left: 10px;
}
.search_history_box .search_search_title .price_box .datepicker {
  border: 1px solid #dadde6;
  margin: 0 10px;
}
.search_history_box .search_search_title .price_box input {
  /* text-align: right; */
  width: 120px;
  text-align: center;
}
.search_history_box .search_search_title .price_box .datepicker_ico {
  padding: 0 3px;
  font-size: 12px;
  margin: 0 5px;
  /* background-color: red; */
}
.search_history_box .search_search_title .filter_box .filter_text {
  margin: 0 5px;
  font-size: 14px;
  width: 70px;
  border-radius: 4px;
}
.search_history_box
  .search_search_title
  .filter_box
  .filter_text.filter_active {
  background: #00c73c;
  color: #fffdff;
  font-weight: 600;
}
.search_history_box .search_search_title .filter_box .filter_text.active {
  background: #00c73c;
  color: #fffdff;
  font-weight: 600;
}
.filter_text_active {
  background-color: #5e5e5e;
  color: #fff;
  font-weight: 700;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  line-height: 2.4em;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
}

.filter_text {
  border: 1px solid #e0e0e0;
  font-size: 12px;
  line-height: 2.4em;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
}

/* 상품 작성 */

.product_write_wrap {
  width: 80%;
  height: auto;
  padding: 20px 10px;
}
.product_write_wrap .product_write_inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.product_write_wrap .product_write_inner .product_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.product_write_inner .product_write_title h4 {
  font-size: 18px;
}
.product_write_inner .product_write_title .product_write_button {
  width: 90px;
  height: 40px;
  background-color: #00c73c;
  border-radius: 5px;
}
.product_write_inner .product_write_title button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.product_write_inner .product_write_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  /* min-height: 300px; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
}

.product_write_inner .product_write_content > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.product_write_inner .product_write_content .proudct_write_left {
  /* background-color: red; */
  /* border-right: 1px solid #7f7f7f; */
  /* background-color: rgb(159, 210, 165); */
}
.product_write_inner .product_write_content .proudct_write_right {
  /* border-top: 2px solid #c7c7c7; */
  /* background-color: red; */
  padding: 20px 0;
  width: 100%;
  /* background-color: red; */
}
.product_check {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  /* background-color: red; */
}
.product_open_box,
.product_recruitment_box {
  margin-top: 20px;
  text-align: center;
  padding: 0 4px;
}
.product_recruitment_box {
  /* border-left: 1px solid #9e9e9e; */
}
.product_open_box label,
.product_recruitment_box label {
  padding: 0 10px;
  cursor: pointer;
}
.product_open_box label {
}

.product_write_img_div {
  width: 280px;
  height: 166px;
  background-color: #ffffff;
}

.product_write_img_div > div {
  width: 100%;
  height: 100%;
  line-height: 110px;
  font-size: 110px;
  text-align: center;
  border: 1px solid #9e9e9e;
  /* background-color: red; */
}

.product_write_img_div img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.product_write_img_div .product_write_img_button {
  width: 280px;
  height: 70px;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}
.product_write_img_button > label {
  display: inline-block;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  height: 34px;
  background-color: #3c3c3c;
  color: #fff;
  position: relative;
  cursor: pointer;
}
.product_write_img_button > label > input {
  display: none;
  position: absolute;
  z-index: -1;
}
.product_write_img_button > label > span {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
.product_write_step_img {
  width: 280px;
  margin: 0 auto;
}
.product_write_step_img > .product_write_sub {
  width: 100%;

  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.product_write_content .product_write_sub {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.product_write_content .product_write_step {
  /* display: flex; */
  width: 55%;
  margin: 0 auto;
  /* height: 34px; */
  margin-bottom: 10px;
  /* background-color: black; */
}
.product_write_content .product_write_etc {
  width: 55%;
  margin: 0 auto;
  display: flex;
}
.product_write_content .product_write_etc_step {
  width: 55%;
  margin: 0 auto;
  display: flex;
}
.product_write_content .product_write_step .product_write_sub {
  height: 100%;
  display: block;
  /* flex: 0.8; */
  /* ; */
  /* text-align: right; */
  /* padding-right: 40px; */
}
.product_write_content .product_write_etc .product_write_step {
  padding-right: 6px;
}

.product_write_content .product_write_etc .product_write_sub {
  height: 34px;
  line-height: 34px;
}
.product_write_content .product_write_step .product_write_cont {
  height: 32px;
}

.product_write_content .product_write_etc_step .product_write_step {
  padding-right: 6px;
}

.product_write_content .product_write_etc_step .product_write_sub {
  height: 34px;
  line-height: 34px;
}
.product_write_content .product_write_etc_step .product_write_cont {
  height: 32px;
}
.product_write_content .product_write_etc_step .product_write_cont > label {
  margin-right: 20px;
}
.product_write_etc_step > div:last-child {
  margin-left: 20px;
}
.product_write_content
  .product_write_step
  .product_write_cont
  .product_write_inpbox {
  width: 100%;
  border: 1px solid #dadde6;
  height: 100%;
  display: flex;
}

.product_write_content
  .product_write_step
  .product_write_cont
  .product_write_inpbox
  span {
  text-align: center;
  /* background-color: red; */
  padding: 0 10px;
  font-size: 12px;
  color: #7f7f7f;
}
/* .product_write_content
  .product_write_step
  .product_write_cont
  .product_write_inpbox.product_write_inpetc {
  border: none;
} */
.product_write_cont .product_write_inpbox input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  text-indent: 5px;
}
.product_write_inpbox.product_write_etcbox {
  display: flex;
}
.product_write_inpbox.product_write_etcbox span {
  padding: 0 2px;
  font-size: 14px;
}
/* 농장 정보 */
.admin_farm .admin_cont_inner {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.admin_farm .admin_cont_inner .admin_cont_img_div {
  width: 40%;
}
.admin_farm .admin_cont_inner .admin_cont_img_div .img_div {
  width: 100%;
  height: 150px;
  border: 1px solid #e0e0e0;
}

.admin_farm .img_div > div {
  width: 100%;
  height: 100%;
  line-height: 110px;
  background-color: #d7d7d7;
  font-size: 110px;
  text-align: center;
  /* background-color: red; */
}

.admin_farm .admin_cont_inner .img_div img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.admin_farm .admin_cont_inner .img_button {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}
.admin_farm .admin_cont_inner .img_button > label {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  height: 30px;
  border: 1px solid #e0e0e0;
  position: relative;
  cursor: pointer;
}
.admin_farm .admin_cont_inner .img_button > label > input {
  display: none;
  position: absolute;
  z-index: -1;
}
.admin_farm .admin_cont_inner .img_button > label > span {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}
.admin_farm .admin_cont_inner .img_button button {
  width: 100% !important;
  height: 30px;
  display: block;
  border: 1px solid #e0e0e0;
  font-size: 12px;
}

.admin_farm .admin_cont_inner .admin_cont_content {
  display: flex;
  width: 82%;
}
.admin_farm .admin_cont_inner .admin_cont_content > div {
  flex: 1;
}
.admin_farm .admin_cont_inner .admin_cont_content > .admin_cont_content_1 {
  position: relative;
}
.admin_farm
  .admin_cont_inner
  .admin_cont_content
  > .admin_cont_content_1::after {
  position: absolute;
  right: -8px;
  top: 5%;
  display: block;
  width: 2px;
  height: 90%;
  background-color: rgb(231, 231, 231);
}
.admin_farm .admin_cont_inner .admin_cont_content .admin_cont_box {
  display: flex;
  height: auto;
  padding: 5px 0;
  align-items: center;
  width: 100%;
}

.admin_farm .admin_cont_span {
  /* flex: 1; */
  flex-shrink: 0;
  width: 90px;
  /* background-color: red; */
  text-align: right;
  padding-right: 8px;
  font-weight: 500;
  height: 34px;
}

.admin_farm .admin_cont_div {
  flex-grow: 1;
  display: flex;
  height: 34px;
  align-items: center;
}
.admin_farm .admin_cont_div input {
  border: 1px solid #e0e0e0;
  height: 30px;
  flex-grow: 1;
}

.admin_farm .admin_cont_div select {
  border: 1px solid #e0e0e0;
  height: 30px;
  /* flex-grow: 1; */
  width: 14.3em;
  /* width: 150px; */
}
.admin_farm .admin_cont_div select option {
  width: 100%;
}

.admin_farm .admin_cont_div button {
  width: 64px;
  text-align: center;
  flex-shrink: 0;
  height: 30px;
  border: 1px solid #e0e0e0;
  margin-left: 6px;
  border-radius: 4px;
  font-size: 14px;
}
/* 상품 수정 (필드)/ 농장 수정 (필드) */
.farm_write_wrap.field_wrap {
  width: 1000px;
}
.farm_write_inner .field_write_content {
  width: 80%;
  margin: 0 auto;
}
.farm_write_inner .field_write_content > div {
  display: flex;
}
.farm_write_inner .field_write_content > div > div {
  flex: 1;
}
.farm_write_inner .field_write_title {
  width: 100%;
  text-align: center;
}
.farm_item {
  /* width: 300px; */
  padding: 0 6px;
  /* margin-right: 10px; */
}
.farm_item_box {
  border-radius: 4px;
  padding: 16px;
  background-color: #fff;
}
.farm_top {
  position: relative;
  padding-bottom: 12px;
  border-bottom: 1px solid #d1d5db;
}
.farm_top .title {
  padding-right: 50px;
  font-size: 18px;
  line-height: 36px;
  color: #111827;
  font-weight: 700;
}
.farm_top .time_box {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}
.farm_top .time_box span {
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 300;
}
.farm_top .time_box .date {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 600;
}
.farm_top .time_box .time {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #4b5563;
  font-weight: 500;
}
.farm_text {
  padding: 12px 0 20px;
  border-bottom: 1px solid #d1d5db;
}
.farm_text li {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #4b5563;
  font-weight: 500;
}
.farm_text li .title {
  display: inline-block;
  vertical-align: top;
}
.farm_text li .text {
  display: inline-block;
  float: right;
  vertical-align: top;
  text-align: right;
  color: #111827;
  font-size: 15px;
}

.farm_text li .text .em_green {
  color: #00c73c;
  font-size: 16px;
  font-weight: 600;
}
.farm_now {
  padding-top: 14px;
  border-bottom: 1px solid #d1d5db;
}
.farm_now .now_title {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 700;
}
.farm_now .now_list {
  font-size: 0;
  line-height: 0;
}
.farm_now .now_list li {
  display: inline-block;
  position: relative;
  width: 25%;
  padding: 12px 4px;
  text-align: center;
}
.farm_now .now_list li span {
  width: 100%;
  font-size: 14px;
}
.farm_now .now_list li:before {
  display: block;
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
  width: 6px;
  height: 12px;
  background: url(../assets/images/farm_arrow.png) center center no-repeat;
}
.farm_now .now_list li:last-child:before {
  display: none;
}
.now_icon {
  /* font-size: 30px;
  color: rgb(0, 163, 112); */
  /* position: relative; */
}

.now_icon img {
  margin: 0 auto;
}
.now_icon.cc {
  /* animation: blink-effect 2s step-end infinite; */
}
.now_icon.notcc {
  /* color: #8e8e8e !important; */
}
/* 농장 등록 */

.farm_write_wrap {
  width: 80%;
  height: auto;
  padding: 20px 10px;
}
.farm_write_wrap .farm_write_inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.farm_write_wrap .farm_write_inner .farm_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.farm_write_inner .farm_write_title h4 {
  font-size: 18px;
}
.farm_write_inner .farm_write_title .farm_write_button {
  width: 90px;
  height: 40px;
  background-color: #00c73c;
  border-radius: 5px;
}
.farm_write_inner .farm_write_title button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.farm_write_inner .farm_write_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  /* min-height: 300px; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
}

.farm_write_inner .farm_write_content > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.farm_write_inner .farm_write_content .proudct_write_left {
  /* background-color: red; */
  /* border-right: 1px solid #7f7f7f; */
  /* background-color: rgb(159, 210, 165); */
}
.farm_write_inner .farm_write_content .farm_write_right {
  /* border-top: 2px solid #c7c7c7; */
  /* background-color: red; */
  padding: 20px 0;
  width: 100%;
  /* background-color: red; */
}
.farm_check {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  /* background-color: red; */
}
.farm_open_box,
.farm_recruitment_box {
  /* margin-top: 20px; */
  /* text-align: center; */
  /* padding: 0 4px; */
  width: 55%;
}
.farm_recruitment_box {
  /* border-left: 1px solid #9e9e9e; */
}
.farm_open_box label,
.farm_recruitment_box label {
  /* padding: 0 10px; */
  margin-right: 5px;
  cursor: pointer;
}

.farm_write_content .farm_write_sub {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.farm_write_content .farm_write_step {
  /* display: flex; */
  width: 55%;
  margin: 0 auto;
  /* height: 34px; */
  margin-bottom: 10px;
  /* background-color: black; */
}
.farm_write_content .farm_write_etc {
  width: 55%;
  margin: 0 auto;
  display: flex;
}
.farm_write_content .farm_write_etc.farm_write_etc_warp > div:first-child {
  width: 120px;
  margin: 0;
}
.farm_write_content
  .farm_write_etc.farm_write_etc_warp
  > div:first-child
  select {
  border: 1px solid #dadde6;
  width: 100%;
  text-align: center;
}

.farm_write_content .farm_write_etc.farm_write_etc_warp > div:last-child label {
  margin-right: 6px;
}

.farm_write_content .farm_write_etc_step {
  width: 55%;
  margin: 0 auto;
  display: flex;
}
.farm_write_content .farm_write_step .farm_write_sub {
  height: 100%;
  display: block;
  /* flex: 0.8; */
  /* ; */
  /* text-align: right; */
  /* padding-right: 40px; */
}
.farm_write_content .farm_write_etc .farm_write_step {
  padding-right: 6px;
}

.farm_write_content .farm_write_etc .farm_write_sub {
  height: 34px;
  line-height: 34px;
}
.farm_write_content .farm_write_step .farm_write_cont {
  height: 32px;
}

.farm_write_content .farm_write_etc_step .farm_write_step {
  padding-right: 6px;
}

.farm_write_content .farm_write_etc_step .farm_write_sub {
  height: 34px;
  line-height: 34px;
}
.farm_write_content .farm_write_etc_step .farm_write_cont {
  height: 32px;
}
.farm_write_content .farm_write_etc_step .farm_write_cont > label {
  margin-right: 20px;
}
.farm_write_content > div:last-child {
  /* margin-left: 20px; */
}
.farm_write_content .farm_write_step .farm_write_cont .farm_write_inpbox {
  width: 100%;
  border: 1px solid #dadde6;
  height: 100%;
  display: flex;
}

.farm_write_content .farm_write_step .farm_write_cont .farm_write_inpbox span {
  text-align: center;
  /* background-color: red; */
  padding: 0 10px;
  font-size: 12px;
  color: #7f7f7f;
}
/* .farm_write_content
  .farm_write_step
  .farm_write_cont
  .farm_write_inpbox.farm_write_inpetc {
  border: none;
} */
.farm_write_cont .farm_write_inpbox input {
  width: 100%;
  height: 100%;
  font-size: 14px;
  text-indent: 5px;
}
.farm_write_inpbox.farm_write_etcbox {
  display: flex;
}
.farm_write_inpbox.farm_write_etcbox span {
  padding: 0 2px;
  font-size: 14px;
}
.field_write_bottom {
  display: flex;
  width: 100%;
  /* width: 80%; */
  /* margin: 0 auto; */
  height: 480px;
  margin-top: 30px;
  /* background-color: red; */
}
.field_write_bottom .field_write_bottom_left {
  flex: none;
  width: 320px;
  padding: 10px 45px;
}
.field_write_bottom > .field_write_bottom_left {
  margin-right: 10px;
  background-color: #fff;
}

.field_write_bottom > .field_write_bottom_left ul {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.field_write_bottom > .field_write_bottom_left button {
  width: 140px;
  height: 34px;
  /* background-color: red; */
  border: 1px solid #c5c5c5;
  border-radius: 4px;

  font-size: 14px;

  /* margin-top: 20px; */
}
.field_write_bottom > .field_write_bottom_right button {
  width: 140px;
  height: 34px;
  /* background-color: red; */
  border: 1px solid #c5c5c5;
  border-radius: 4px;

  font-size: 14px;
  margin: 0 auto;

  /* margin-top: 20px; */
}
.field_write_bottom > .field_write_bottom_right .field_right_img label {
  width: 100px;
  height: 28px;
  /* background-color: red; */
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  line-height: 28px;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 2px;
}
.field_write_bottom
  > .field_write_bottom_right
  .field_write_bottom_inner
  > div {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.field_write_bottom > .field_write_bottom_left ul li {
  margin-bottom: 3px;
}
.field_write_bottom
  > .field_write_bottom_left
  ul
  li
  .field_write_bottom_inner_div {
  display: flex;
  align-items: center;
  border: 1px solid #cacaca;
  border-radius: 3px;
  padding: 0 4px;
  margin-top: 5px;
}
.field_write_bottom
  > .field_write_bottom_left
  ul
  li
  .field_write_bottom_inner_flex {
  display: flex;
  width: 100%;
}
.field_write_bottom_inner_flex > div {
  flex: 1;
}
.field_write_bottom_inner_flex input {
  width: 20px;
}

.field_write_bottom > .field_write_bottom_left > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.field_write_bottom > .field_write_bottom_left > div .field_write_bottom_save {
  height: 45px;
  width: 100%;
  flex: none;
  text-align: center;
}
.field_write_bottom .field_write_bottom_left ul label {
  display: block;
  width: 100%;
}
.field_write_bottom .field_write_bottom_left ul input {
  display: block;
  flex: 1;
  color: #374151;
  font-size: 14px;
  /* width: 100%; */
}
.field_write_bottom .field_write_bottom_left ul input span {
  width: 54px;
  font-size: 14px;
  flex: none;
}

.field_write_bottom_right {
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px 5px;
}
.field_write_bottom_right h4 {
  font-size: 16px;
  width: 100%;
  text-align: center;
}
.field_write_bottom_right h5 {
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}
.field_write_bottom_right .field_write_bottom_inner {
  background-color: #fff;
  flex: 1;
  width: 100%;
  /* padding: 10px 24px; */
}
.field_write_bottom_right .field_write_bottom_inner:first-child {
  margin-bottom: 10px;
}
.field_write_bottom_right ul {
  width: 100%;
  display: flex;
}
.field_write_bottom_right ul li {
  padding: 5px 8px;
}
.field_write_bottom_right ul li > .field_right_inp {
  width: 140px;

  display: flex;
  border: 1px solid #cacaca;
  border-radius: 3px;
  margin-top: 5px;
}
.field_write_bottom_right ul li > .field_right_inp > input {
  width: 0;
  flex: 1;
  font-size: 14px;
}
.field_write_bottom_right ul li label {
  display: block;
  width: 100%;
  text-align: center;
}
.field_write_bottom_right ul li > .field_right_inp > span {
  flex: none;
  width: 34px;
  font-size: 14px;
  text-align: center;
}
/* 투자 입금 현황 */
.invest_wrap {
  margin-bottom: 40px;
}
.invest_in {
  padding: 20px 40px 40px;
  background-color: #fff;
}
.invest_top {
  display: block;
  padding: 20px 24px 0;
}
.invest_product_item h2 {
  font-size: 18px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-bottom: 5px;
  /* margin-bottom: 20px; */
}
.invest_title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #00c73c;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.invest_top .num {
  vertical-align: top;
}
.invest_top .invest_text {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #444442;
}
.invest_top .invest_text em {
  display: block;
  margin-top: 8px;
  font-size: 25px;
  line-height: 32px;
  color: #111827;
  font-weight: 700;
}
.invest_top .invest_icon {
  position: absolute;
  top: 0;
  right: 0;
}
.invest_make_visual {
  padding: 30px 24px 0px;
}

.invest_make_visual > ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.invest_make_visual > ul > li > span {
  display: block;
  font-size: 14px;
}
.invest_make_visual > ul > li em {
  font-size: 24px;
  font-weight: bold;
}
.invest_make_visual > ul > li svg {
  font-size: 20px;
  color: #00c73c;
}
.invest_make_visual > ul > li:first-child {
  text-align: left;
}
.invest_make_visual > ul > li .num {
  display: flex;
  align-items: center;
}
.invest_make_visual > ul > li:nth-child(2) {
  text-align: left;
}
.invest_make_visual > ul > li:last-child {
  text-align: right;
}
.invest_make_visual > .line_wrap {
  width: 100%;
  height: 6px;
  position: relative;
}
.invest_make_visual > .line_wrap > .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* background-color: #00c73c; */
  height: 6px;
}

.invest_make_visual > .line_wrap > .progress::-webkit-progress-bar {
  background: #f3f4f6;
  border-radius: 10px;
  /* box-shadow: inset 3px 3px 10px #ccc; */
}
.invest_make_visual > .line_wrap > progress::-webkit-progress-value {
  border-radius: 10px;
  background: #00c73c;
  /* background: -webkit-linear-gradient(to right, #93f9b9, #1d976c);
  background: linear-gradient(to right, #93f9b9, #1d976c); */
}
.invest_make_visual > .line_text_box {
  display: flex;
  justify-content: space-between;
}
.invest_make_visual > .line_text_box > span:first-child {
  color: #00c73c;
  font-size: 13px;
}
.invest_make_visual > .line_text_box > span:last-child {
  /* color: #00c73c; */
  font-size: 12px;
}

.invest_deposit_title {
  display: flex;
  width: 100%;
  /* background-color: red; */
  height: 40px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.invest_deposit_item h2 {
  font-size: 18px;
}
.invest_deposit_item button:first-child {
  width: 120px;
  height: 35px;
  background-color: #00c73c;
  margin-right: 10px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
}
.invest_deposit_item button:last-child {
  width: 120px;
  height: 35px;
  background-color: #7f7f7f;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
}
.invest_deposit_info_box {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 40px 64px;
}
.invest_deposit_top {
  padding-bottom: 20px;
  border-bottom: 1px solid #00c73c;
}
.invest_deposit_top .invest_deposit_top_1,
.invest_deposit_top .invest_deposit_top_2 {
  display: flex;
}
.invest_deposit_top .invest_deposit_top_1 > div,
.invest_deposit_top .invest_deposit_top_2 > div {
  flex: 1;
}
.invest_deposit_top .deposit_top {
  display: flex;
  flex-direction: column;
}
.invest_deposit_top .deposit_top > label {
  font-weight: 600;
}
.invest_deposit_top .invest_deposit_top_1 {
  margin-bottom: 20px;
}

.invest_deposit_bottom {
  padding-top: 20px;
  /* border-bottom: 1px solid #00c73c; */
}
.invest_deposit_bottom .invest_deposit_bottom_1,
.invest_deposit_bottom .invest_deposit_bottom_2 {
  display: flex;
}
.invest_deposit_bottom .invest_deposit_bottom_1 > div,
.invest_deposit_bottom .invest_deposit_bottom_2 > div {
  flex: 1;
}
.invest_deposit_bottom .deposit_bottom {
  display: flex;
  flex-direction: column;
}
.invest_deposit_bottom .deposit_bottom > label {
  font-weight: 600;
}
.invest_deposit_bottom .invest_deposit_bottom_1 {
  margin-bottom: 20px;
}
.deposit_warring {
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  background: #fff;
}
.deposit_warring h4 {
  color: #898989;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}
.deposit_warring p {
  color: #898989;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
.deposit_warring p svg {
  font-size: 20px;
}
.deposit_warring span {
  width: 100%;
  color: #00c73c;
  font-weight: 400;
  display: block;
  font-size: 14px;
  text-align: center;
}
.deposit_warring .deposit_warring_table {
  justify-content: center;
  width: 400px;
  border: 2px solid #898989;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 500;
  color: #898989;
  margin-top: 10px;
}
.deposit_warring_table > ul {
  display: flex;
}
.deposit_warring_table > ul:first-child {
  background: #e7e7e7;
  color: black;
}
.deposit_warring_table > ul > li:first-child {
  width: 60px;
  text-align: center;
  border-right: 2px solid #898989;
}
.deposit_warring_table > ul > li:nth-child(2) {
  width: 140px;
  text-align: center;
  border-right: 2px solid #898989;
}
.deposit_warring_table > ul > li:nth-child(3) {
  width: 200px;
  text-align: center;
}
.invest_deposit_modal_back {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #d4d4d452;
}

.invest_deposit_modal_wrap {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invest_deposit_modal_wrap .invest_deposit_modal_box {
}

.invest_deposit_modal_box .invest_deposit_modal_1 {
  width: 420px;
  background-color: #fff;
  padding-bottom: 15px;
}
.invest_deposit_modal_box .invest_deposit_modal_2 {
  width: 420px;
  background-color: #fff;
  padding-bottom: 15px;
}
.invest_deposit_modal_1 span,
.invest_deposit_modal_2 span {
  font-size: 10px;
  color: #00c73c;
  text-align: center;
  padding-left: 40px;
  /* background-color: red; */
  width: 100%;
}
.invest_deposit_modal_box .invest_deposit_modal_1 .invest_deposit_modal1_tit {
  text-align: center;
  padding: 4px 0;
  background-color: #00c73c;
  font-size: 18px;
  font-weight: 500;
}
.invest_deposit_modal_box .invest_deposit_modal_2 .invest_deposit_modal1_tit {
  text-align: center;
  padding: 4px 0;
  background-color: #00c73c;
  font-size: 18px;
  font-weight: 500;
}

.invest_deposit_modal_box .invest_deposit_modal_1 p {
  text-align: center;
  padding: 4px 0;
  font-size: 14px;
  font-weight: 400;
}

.invest_deposit_modal_box .invest_deposit_modal_2 p {
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}

.invest_deposit_modal_box .invest_deposit_modal_1 .invest_deposit_modal1_div {
  padding: 5px 10px;
  /* display: flex;
  justify-content: center; */
}
.invest_deposit_modal1_div .invest_deposit_modal1_span {
  margin-bottom: 10px;
}
.invest_deposit_modal1_div .invest_deposit_modal1_inpbox {
  /* flex: 1; */
  text-align: center;
  /* margin-bottom: 10px; */
  font-size: 14px;
}
.invest_deposit_modal1_div .invest_deposit_modal1_inpbox > input {
  border: 1px solid #b1b1b1;
  margin-left: 10px;
  text-indent: 4px;
}
.invest_deposit_modal1_div .invest_deposit_modal1_inpbox > input::placeholder {
  font-size: 12px;
}
.invest_deposit_modal1_button {
  display: flex;
  width: 100%;
  height: 25px;
  justify-content: center;
}
.invest_deposit_modal1_button button:first-child {
  background-color: #00c73c;
  width: 70px;
  margin-right: 4px;
}
.invest_deposit_modal1_button button:last-child {
  background-color: #7f7f7f;
  width: 70px;
}
/* 출금 */
.invest_withdraw_section .invest_product_box {
  width: 100%;
}
.invest_my_data_box {
  width: 407px;
  background: #00c73c;
  height: auto;
  border-radius: 4px;
  padding: 30px 20px;
  color: #fff;
  margin: 0 auto;
  margin-top: 30px;
}

.invest_my_data_box .invest_my_data_box_1 {
  margin-bottom: 40px;
  margin-top: 10px;
}
.invest_my_data_box_2 {
  margin-bottom: 30px;
}
.invest_my_data_box span {
  font-size: 15px;
}
.invest_my_data_box p {
  font-size: 21px;
}
.invest_my_data_box em {
  font-size: 31px;
  font-weight: 600;
}
.invest_my_data_box em span {
  font-size: 31px;
  font-weight: 600;
}
.invest_my_data_top {
  width: 100%;
  border-bottom: 2px solid #fff;
  padding-bottom: 9px;
}
.invest_my_data_top_div {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.invest_my_data_top_div > div {
  line-height: 9px;
  flex: 2;
}
.invest_my_data_top_div > div > span {
  font-size: 12px;
  line-height: 18px;
  display: block;
}
.invest_my_data_top_div > span {
  font-size: 12px;
}
.invest_my_data_box .invest_my_data_btn button {
  width: 100%;
  height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  color: #000;
  margin-bottom: 3px;
}

.invest_my_bottom {
  width: 100%;
  margin-top: 30px;
  /* padding-top: 5px; */
}
.invest_my_bottom h4 {
  width: 407px;
  margin: 0 auto;
  margin-bottom: 15px;
  /* text-align: center; */
}

.invest_my_wrap .invest_myList_item {
  border: 2px solid #00c73c;
  border-radius: 5px;
  margin-right: 4px;
  padding: 40px 20px;
}
.invest_my_wrap .invest_myList_item .invest_myList_title {
  font-size: 19px;
}

.invest_my_wrap .invest_myList_item .invest_myList_join {
  font-size: 14px;
}
.invest_my_wrap .invest_myList_item ul {
  margin-top: 25px;
  font-size: 15px;
}
.invest_my_wrap .invest_myList_item ul li {
  display: flex;
  justify-content: space-between;
}
.invest_my_wrap .invest_myList_item .myList_value {
  font-size: 18px;
  font-weight: 500;
}
.myList_account_box button {
  width: 100%;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00a370;
  margin-top: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #00a370;
}
/* 회원 분석 페이지 */
.analyzeUser_search_wrap {
  width: 100%;
  height: auto;
  padding: 20px;
}
.analyzeUser_search_wrap .analyzeUser_search_inner {
  background-color: #fff;
  /* padding: 30px; */
}
.analyze_search {
  border-bottom: 1px solid #dadde6;
}
.analyzeUser_search_box .analyze_search_top {
  display: flex;
  padding: 20px;
}
.analyzeUser_search_box .analyze_search_top .price_box {
  margin-left: 10px;
}
.analyzeUser_search_box .analyze_search_top .price_box .datepicker {
  border: 1px solid #dadde6;
  margin: 0 10px;
}
.analyzeUser_search_box .analyze_search_top .price_box input {
  /* text-align: right; */
  width: 120px;
  text-align: center;
}
.analyzeUser_search_box .analyze_search_top .price_box .datepicker_ico {
  padding: 0 3px;
  font-size: 12px;
  margin: 0 5px;
  /* background-color: red; */
}
.analyzeUser_search_box .analyze_search_top .filter_box .filter_text {
  margin: 0 5px;
  font-size: 14px;
  width: 70px;
  border-radius: 4px;
}
.analyzeUser_search_box
  .analyze_search_top
  .filter_box
  .filter_text.filter_active {
  background: #00c73c;
  color: #fffdff;
  font-weight: 600;
}
.analyzeUser_search_box .analyze_search_middle {
  display: flex;
  padding: 20px;
}
.analyzeUser_search_box .analyze_search_middle .analyze_search_select {
  margin: 0 10px;
  width: 80px;
  border: 1px solid #dadde6;
}
.analyzeUser_search_box .analyze_search_middle .analyze_search_select select {
  width: 100%;
}
.analyzeUser_search_box .analyze_search_bottom {
  display: flex;
  padding: 20px;
}
.analyze_search .analyze_search_text {
  font-size: 16px;
  font-weight: 700;
}

.analyze_search_middle .analyze_search_field {
  display: flex;
}

.analyze_search_bottom .analyze_search_field {
  display: flex;
}
.analyze_search_bottom .analyze_searh_inp {
  display: flex;
}
.analyze_search_bottom .analyze_searh_inp input {
  margin: 0 10px;
  width: 240px;
  border: 1px solid #dadde6;
  font-size: 14px;
}
.analyze_search_bottom .analyze_search_label {
  font-size: 12px;
  margin: 0 10px;
}
.analyze_search_down {
  width: 100%;
  padding: 10px 0;
  text-align: center;
}
.analyze_search_down button {
  width: 140px;
  height: 34px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 4px;
  color: #fff;
  background: #00c73c;
}
.analyzeUser_list_wrap {
  /* margin-top: 20px; */

  padding: 20px;
}
.analyzeUser_list_inner {
  background-color: #fff;
  /* padding: 10px 0; */
}
.analyzeUser_list_inner .table_style_analyze {
  width: 100%;
}

.analyzeUser_list_inner > .table_style_analyze .table_style_tr {
  display: flex;
  align-items: center;
}
.analyzeUser_list_inner
  > .table_style_analyze
  .table_style_theader
  .table_style_tr {
  /* background-color: red; */
  height: 44px;
  border-bottom: 1px solid #c5c5c5;
  background-color: #e1e1e1;
  font-size: 16px;
  font-weight: 600;
}

.analyzeUser_list_inner
  > .table_style_analyze
  .table_style_tbody
  .table_style_tr {
  /* background-color: red; */
  height: 44px;
  border-bottom: 1px solid #c5c5c5;

  font-size: 14px;
}

.analyzeUser_list_inner
  > .table_style_analyze
  .table_style_theader
  .table_style_tr
  > div {
  text-align: center;
}

.analyzeUser_list_inner
  > .table_style_analyze
  .table_style_tbody
  .table_style_tr
  > div {
  text-align: center;
}

.table_style_analyze .table_style_theader .table_style_tr > div:first-child,
.table_style_analyze .table_style_tbody .table_style_tr > div:first-child {
  width: 34px;
}
.table_style_analyze .table_style_theader .table_style_tr > div:nth-child(2),
.table_style_analyze .table_style_tbody .table_style_tr > div:nth-child(2) {
  width: 22%;
  /* background-color: beige; */
}
.table_style_analyze .table_style_theader .table_style_tr > div:nth-child(3),
.table_style_analyze .table_style_tbody .table_style_tr > div:nth-child(3) {
  width: 12%;
}
.table_style_analyze .table_style_theader .table_style_tr > div:nth-child(4),
.table_style_analyze .table_style_tbody .table_style_tr > div:nth-child(4) {
  width: 10%;
}
.table_style_analyze .table_style_theader .table_style_tr > div:nth-child(5),
.table_style_analyze .table_style_tbody .table_style_tr > div:nth-child(5) {
  width: 10%;
  /* background-color: beige; */
}
.table_style_analyze .table_style_theader .table_style_tr > div:nth-child(6),
.table_style_analyze .table_style_tbody .table_style_tr > div:nth-child(6) {
  width: 10%;
  /* background-color: beige; */
}
.table_style_analyze .table_style_theader .table_style_tr > div:nth-child(7),
.table_style_analyze .table_style_tbody .table_style_tr > div:nth-child(7) {
  width: 15%;
  /* background-color: beige; */
}
.table_style_analyze .table_style_theader .table_style_tr > div:nth-child(8),
.table_style_analyze .table_style_tbody .table_style_tr > div:nth-child(8) {
  width: 15%;
  /* background-color: beige; */
}
.table_pagination_analyze {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  position: relative;
  /* background-color: red; */
}
.table_pagination_analyze_box {
  margin: 0 auto;
  /* background-color: rebeccapurple; */
  text-align: center;
}
.table_pagination_analyze_btn {
  position: absolute;
  z-index: 1;
  right: 0;
  width: 140px;
  height: 34px;
  background-color: #00c73c;
  font-weight: 500;
  color: #fff;
  top: 3px;
  border-radius: 7px;
}

/* 투자 분석 */
.analyze_search_field .analyze_btn {
  margin: 0 5px;
  font-size: 14px;
  width: 90px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
}
.analyze_search_field .analyze_btn.analyze_btn_active {
  background: #00c73c;
  color: #fffdff;
  font-weight: 600;
}

.analyzeUser_list_inner .table_style_analyze1 {
  width: 100%;
}

.analyzeUser_list_inner > .table_style_analyze1 .table_style_tr {
  display: flex;
  align-items: center;
}
.analyzeUser_list_inner
  > .table_style_analyze1
  .table_style_theader
  .table_style_tr {
  /* background-color: red; */
  height: 44px;
  border-bottom: 1px solid #c5c5c5;
  background-color: #e1e1e1;
  font-size: 16px;
  font-weight: 600;
  padding-left: 15px;
}

.analyzeUser_list_inner
  > .table_style_analyze1
  .table_style_tbody
  .table_style_tr {
  /* background-color: red; */
  height: 44px;
  border-bottom: 1px solid #c5c5c5;

  font-size: 14px;
  padding-left: 15px;
}

.analyzeUser_list_inner
  > .table_style_analyze1
  .table_style_theader
  .table_style_tr
  > div {
  text-align: center;
}

.analyzeUser_list_inner
  > .table_style_analyze1
  .table_style_tbody
  .table_style_tr
  > div {
  text-align: center;
}
.analyzeUser_list_inner
  > .table_style_analyze1
  .table_style_tbody
  .table_style_tr
  > div:first-child {
  text-align: left;
  /* text-indent: 30px; */
}

.table_style_analyze1 .table_style_theader .table_style_tr > div:first-child,
.table_style_analyze1 .table_style_tbody .table_style_tr > div:first-child {
  width: 30%;
}
.table_style_analyze1 .table_style_theader .table_style_tr > div:nth-child(2),
.table_style_analyze1 .table_style_tbody .table_style_tr > div:nth-child(2) {
  width: 10%;
  /* background-color: beige; */
}
.table_style_analyze1 .table_style_theader .table_style_tr > div:nth-child(3),
.table_style_analyze1 .table_style_tbody .table_style_tr > div:nth-child(3) {
  width: 10%;
}
.table_style_analyze1 .table_style_theader .table_style_tr > div:nth-child(4),
.table_style_analyze1 .table_style_tbody .table_style_tr > div:nth-child(4) {
  width: 10%;
}
.table_style_analyze1 .table_style_theader .table_style_tr > div:nth-child(5),
.table_style_analyze1 .table_style_tbody .table_style_tr > div:nth-child(5) {
  width: 10%;
  /* background-color: beige; */
}
.table_style_analyze1 .table_style_theader .table_style_tr > div:nth-child(6),
.table_style_analyze1 .table_style_tbody .table_style_tr > div:nth-child(6) {
  width: 10%;
  /* background-color: beige; */
}
.table_style_analyze1 .table_style_theader .table_style_tr > div:nth-child(7),
.table_style_analyze1 .table_style_tbody .table_style_tr > div:nth-child(7) {
  width: 10%;
  /* background-color: beige; */
}
.table_style_analyze1 .table_style_theader .table_style_tr > div:nth-child(8),
.table_style_analyze1 .table_style_tbody .table_style_tr > div:nth-child(8) {
  width: 10%;
  /* background-color: beige; */
}
.analyze_bottom_allbox {
  width: 100%;
  height: 44px;
  background-color: #e1e1e1;
}
.analyze_bottom_allbox ul {
  display: flex;
  line-height: 44px;
  /* align-items: center; */
}
.analyze_bottom_allbox ul li:first-child {
  width: 276px;
}
.analyze_bottom_allbox ul li {
  width: 92px;
  text-align: center;
  font-weight: 700;
}
/* 페이징 */

.table_pagination_delete {
  width: 100%;
  height: 40px;
  margin-top: 20px !important;
  position: relative;
  /* background-color: red; */
}
.table_pagination_delete {
  margin: 0 auto;
  /* background-color: rebeccapurple; */
  text-align: center;
}
.table_pagination_update_btn {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 140px;
  height: 34px;
  background-color: #00c73c;
  font-weight: 500;
  color: #fff;
  top: 3px;
  border-radius: 7px;
}
.table_pagination_delete_btn {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 140px;
  height: 34px;
  background-color: #00c73c;
  font-weight: 500;
  color: #fff;
  top: 3px;
  border-radius: 7px;
}

/* 투자 정보 */

.info_write_wrap {
  width: 424px;
  height: auto;
  padding: 0 10px;
  background-color: #fff;
  border: 2px solid #00c73c;
  border-radius: 5px;
}
.info_write_wrap.policy_wrap {
  width: 500px;
}
.info_write_wrap.policy_wrap .info_write_content > div {
  display: flex;
  margin-bottom: 5px;
}
.info_write_wrap.policy_wrap .info_write_content > div > label {
  width: 120px;
  text-align: right;
  font-weight: 500;
  margin-right: 20px;
  flex: none;
  line-height: 34px;
}
.info_write_wrap.policy_wrap .info_policy_div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
}
.info_write_wrap.policy_wrap .info_policy_div .info_policy_inp {
  width: 140px;
  height: 100%;
  box-shadow: none !important;
  font-size: 14px;
  color: #676767;
  /* border-bottom: 1px solid #d2d2d2; */
}
.info_write_wrap.policy_wrap .info_policy_div label {
  width: 50px;
  font-size: 14px;
  /* border: 1px solid #c6c6c6; */
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  font-weight: 400;
  margin-right: 0;
  line-height: 34px;
  flex: none;
  cursor: pointer;
  height: 100%;
  /* border-bottom: 1px solid #d2d2d2; */

  /* border-bottom: 1px solid #d2d2d2; */
}
.info_write_wrap.policy_wrap .info_write_content > div input:first-child {
  display: none;
}
.info_write_wrap .info_write_inner {
  width: 100%;
  height: 100%;
}

.info_write_wrap .info_write_inner .info_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.info_write_inner .info_write_title h4 {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}
.info_write_content {
  padding: 5px 20px;
}
.info_write_content label {
  width: 80px;
  text-align: right;
  font-weight: 500;
  margin-right: 20px;
  flex: none;
}
.info_write_content > div {
  height: 34px;
}
.info_write_content input {
  box-shadow: 0px 2px 0px #dfdfdf;
}

.info_write_wrap .info_write_inner.info_refund label {
  width: 140px;
}

.info_write_wrap .info_write_inner.info_refund input {
  width: 90px;
  text-align: right;
  padding-right: 3px;
}
.info_write_content button {
  width: 50px;
  margin-left: 20px;
  height: 30px;
  border: 1px solid #c6c6c6;
  font-size: 14px;
  border-radius: 4px;
  color: #444442;
}

.category_inp_wrap {
  width: 400px;
  height: auto;
  background-color: #fff;
  border: 2px solid #00c73c;
  border-radius: 5px;
  margin-bottom: 20px;
}
.category_inp_wrap .category_inp_title {
  display: flex;

  justify-content: space-between;
  padding: 4px 10px;
}
.category_inp_wrap .category_inp_title h4 {
  font-size: 18px;
  text-align: left;
  font-weight: 600;
}
.category_inp_wrap .category_inp_title button {
  background: #00c73c;
  color: #fff;
  font-weight: 600;
  width: 60px;
  height: 100%;
  border-radius: 5px;
  margin-left: 4px;
}
.category_inp_inner {
  display: flex;
  flex-direction: column;
  padding: 4px 10px;
  align-items: center;
}
.category_inp_inner > div {
  height: auto;
  margin-right: 4px;
}

.category_inp_inner .category_inpbox_div {
  display: flex;
  margin-top: 4px;
}
.category_inp_inner .category_inpbox_div .category_write_inpbox {
  display: flex;

  box-shadow: 0px 2px 0px #dfdfdf;
  border: 0;
}

.category_inp_inner input {
  box-shadow: 0px 2px 0px #dfdfdf;
  font-size: 12px;
}
.category_inp_inner .category_inpbox_div .category_write_inpbox input {
  box-shadow: 0;
}
.category_inp_inner > div > label {
  width: 80px;
  text-align: right;
  font-weight: 500;
  margin-right: 20px;
}
.category_inp_inner .category_img {
  width: 70px;
  height: 40px;
  margin: 0 auto;
  border: 1px solid #dadde6;
}
.category_img > div {
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.category_img > div .image_span {
  font-size: 18px;
  font-family: "Myriad-pro";
  color: #b8b8b8;
  font-weight: 700;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}

.category_img > div > svg {
  text-align: center;
  /* font-size: 20px; */
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.category_inp_inner .category_file {
  width: 70px;
  height: 34px;
  /* position: relative; */
  font-weight: 400;
  font-size: 8px;
  background-color: #3c3c3c;
  color: #fff;
  line-height: 34px;
  /* margin-top: 10px; */
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-align: center;
}
.category_inp_inner .category_file .notice_file_btn {
  /* display: block; */
  width: 100%;
  height: 100%;
}
.category_inp_inner .category_file .category_file_inp {
  width: 100%;
  height: 100%;
  display: none;
}

.category_inp_inner img {
  width: 100%;
  height: 100%;
}
.category_inp_wrap span {
  font-size: 12px;
  width: 100%;
  color: #00c73c;
  text-align: center;
}

.admin_category_orderbox {
  width: 960px;
  margin: 30px 0;
  /* background-color: #fff; */
  height: auto;
}
.admin_category_orderbox button {
  width: 140px;
  height: 34px;
  background-color: #00c73c;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
  border-radius: 7px;
}
.admin_category_orderbox > div {
  width: 100%;
}
.admin_category_orderbox h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.admin_category_orderbox .admin_category_orderul {
  display: flex;
  flex-direction: row;
  /* overflow-x: scroll; */
  overflow-x: auto;
  padding: 0 5px;
  background-color: #fff;
}
.admin_category_orderbox .admin_category_orderul li {
  width: 90px;
  flex: none;
  padding: 4px 5px;
  cursor: pointer;

  /* background-color: red; */
}
.admin_category_orderbox .admin_category_orderul li label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
  cursor: pointer;
  /* justify-content: center; */
}
.admin_category_orderbox .admin_category_orderul li:hover {
  background-color: #d2d2d2;
}
/* 파트너사 */

.partner_inp_wrap {
  width: 400px;
  height: auto;
  background-color: #fff;
  border: 2px solid #00c73c;
  border-radius: 5px;
  margin-bottom: 20px;
}
.partner_inp_wrap .partner_inp_title {
  display: flex;

  justify-content: space-between;
  padding: 4px 10px;
}
.partner_inp_wrap .partner_inp_title h4 {
  font-size: 18px;
  text-align: left;
  font-weight: 600;
}
.partner_inp_wrap .partner_inp_title button {
  background: #00c73c;
  color: #fff;
  font-weight: 600;
  width: 60px;
  height: 100%;
  border-radius: 5px;
  margin-left: 4px;
}
.partner_inp_inner {
  display: flex;
  flex-direction: column;
  padding: 4px 10px;
  align-items: center;
}
.partner_inp_inner > div {
  height: auto;
  /* margin-right: 4px; */
  /* background-color: red; */
}

.partner_inp_inner .partner_inpbox_div {
  display: flex;
  margin-top: 10px;
  width: 240px;

  /* background-color: red; */
}
.partner_inp_inner .partner_inpbox_div .partner_write_inpbox {
  display: flex;

  box-shadow: 0px 2px 0px #dfdfdf;
  border: 0;
  flex: 1;
}

.partner_inp_inner input {
  box-shadow: 0px 2px 0px #dfdfdf;
  font-size: 12px;
}
.partner_inp_inner .partner_inpbox_div .partner_write_inpbox input {
  box-shadow: 0;
}
.partner_inp_inner > div > label {
  width: 80px;
  text-align: right;
  font-weight: 500;
  margin-right: 20px;
  flex: none;
}
.partner_inp_inner .partner_img {
  width: 162px;
  height: 79px;
  margin: 0 auto;
  margin-top: 20px;

  border: 1px solid #dadde6;
}
.partner_img > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.partner_img > div .image_span {
  font-size: 18px;
  font-family: "Myriad-pro";
  color: #b8b8b8;
  font-weight: 700;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}

.partner_img > div > svg {
  text-align: center;
  /* font-size: 20px; */
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.partner_inp_inner .partner_file {
  width: 162px;
  height: 34px;
  /* position: relative; */
  font-weight: 400;
  font-size: 8px;
  background-color: #3c3c3c;
  color: #fff;
  line-height: 34px;
  /* margin-top: 10px; */
  cursor: pointer;
  margin: 0;
  padding: 0;
  text-align: center;
}
.partner_inp_inner .partner_file .notice_file_btn {
  /* display: block; */
  width: 100%;
  height: 100%;
}
.partner_inp_inner .partner_file .partner_file_inp {
  width: 100%;
  height: 100%;
  display: none;
}

.partner_inp_inner img {
  width: 100%;
  height: 100%;
}
.partner_inp_wrap span {
  font-size: 12px;
  width: 100%;
  color: #00c73c;
  text-align: center;
}
/* 관리자 정보 */
.mange_info_box {
  border-radius: 10px;
  border: 3px solid #00c73c;
  width: 800px;
  min-height: 500px;
  background-color: #fff;
  /* width: 100%; */
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
}

.profile_section_1 {
  width: 130px;
  height: 130px;
  position: relative;
}
.profile_section_1 .profile_img_div {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 50%;
}
.profile_section_1 .profile_img_div img {
  width: 100%;
  height: 100%;
}
.profile_section_1 .profile_camera {
  position: absolute;
  z-index: 3;
  width: 36px;
  height: 36px;
  background-color: #00c73c;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.profile_section_1 input {
  width: 100%;
  height: 100%;

  /* display: block; */
}

.profile_section_2 {
  width: 400px;
  margin-top: 20px;
  /* background-color: red; */
}
.profile_section_2 label {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.profile_section_2 input {
  border: 2px solid #dfdfdf;
  background-color: rgb(233, 233, 233);
  border-radius: 6px;
  width: 100%;
  height: 34px;
}
.profile_inp_box > input {
  background-color: transparent;
  border: 0;
}
.error_em {
  font-size: 12px;
  color: #00c73c;
  /* height: 20px; */
  line-height: 20px;
  height: 20px;
  /* background-color: red; */
}
.noerror_em {
  font-size: 12px;
  color: #00c73c;
  height: 20px;
  line-height: 20px;
}
.admin_info_flex {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.mypage_password_confirm {
  background-color: red;

  width: 140px;
  height: 34px;
  margin: 0;
  background-color: #00c73c;
  border-radius: 3px;
  font-weight: 600;
  color: #fff;
}

/* 1:1 문의 내용 */

/* 공지사항 */
.qna_write_wrap {
  width: 100%;
  height: auto;
  padding: 20px 10px;
}
.qna_write_wrap .qna_write_inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 10px 30px;
}
.qna_write_wrap .qna_write_inner .qna_content_title {
  font-size: 18px;
  font-weight: 600;
}

.qna_content_wrap_view {
  width: 100%;
  /* min-height: 600px; */
  /* background-color: red; */
  margin: 0 auto;
  margin-top: 30px;
  /* border-bottom: 1px solid #00c73c; */
}

.qna_content_wrap_view .qna_content_view:first-child {
  width: 100%;
  height: auto;
  border-top: 2px solid #00c73c;
  border-bottom: 2px solid #00c73c;
}
.qna_content_wrap_view .qna_content_view:last-child {
  width: 100%;
  height: auto;
  /* border-top: 2px solid #00c73c; */
  border-bottom: 2px solid #00c73c;
}

.qna_content_wrap_view .qna_content_list_view {
  height: auto;
  padding: 10px 20px;
  display: flex;
  margin: 0 10px;
  border-bottom: 1px solid #d1d1d1;
}
.qna_content_wrap_view .qna_content_list_view:first-child {
  align-items: center;
}

/* .qna_content_wrap_view .qna_content_list_view:first-child .qna_list_right_view {
  padding: 0 10px;
} */
.qna_content_wrap_view
  .qna_content_list_view:first-child
  .qna_right_inpdiv_view {
  width: 100%;
  height: auto;
  /* font-weight: 600; */
  /* background-color: red; */
  /* border: 1px solid #cacaca;
  border-radius: 5px; */
}

.qna_content_wrap_view .qna_content_list_view:nth-child(2) {
  align-items: flex-start;
}
.qna_content_wrap_view
  .qna_content_list_view:nth-child(2)
  .qna_right_inpdiv_view {
  width: 100%;
  height: 160px;
  /* border: 1px solid #cacaca; */
  border-radius: 5px;
  overflow: auto;
  padding: 0 4px;
}
.qna_content_wrap_view
  .qna_content_list_view:nth-child(2)
  .qna_right_inpdiv_view
  > div {
  height: 100%;
}
.qna_content_wrap_view
  .qna_content_list_view:nth-child(2)
  .qna_right_inpdiv_view
  textarea {
  resize: none;
  background: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 10px;
  font-size: 14px;
}

.qna_content_wrap_view .qna_content_list_view:last-child {
  border-bottom: 0;
}
.qna_content_wrap_view .qna_content_list_view .qna_list_left_view {
  /* font-size: 16px; */
  /* padding: 10px 0; */
  width: 100px;
  /* border-right: 1px solid #00c73c; */
}
.qna_content_wrap_view .qna_content_list_view .qna_list_right_view {
  /* background-color: red; */
  width: 100%;
}
.qna_content_wrap_view
  .qna_content_list_view
  .qna_list_right_view
  .qna_right_inpdiv_view {
  width: 100%;
}
.qna_content_wrap_view
  .qna_content_list_view
  .qna_list_right_view
  .qna_right_inpdiv_view
  input {
  width: 100%;
}
.qna_content_wrap_view .qna_content_list_view:last-child input {
  /* font-size: 12px; */

  height: 40px;
  /* border: 1px solid red; */
  /* line-height: 20px; */
  /* background-color: black; */
}
.qna_content_wrap_view .qna_content_list_view:last-child span {
  font-size: 12px;
  width: 100%;
  display: block;
  height: 20px;
  /* border: 1px solid red; */
  line-height: 20px;
  /* background-color: black; */
}
.qna_content_wrap_view
  .qna_content_view:last-child
  .qna_content_list_view
  .qna_right_inpdiv_view {
  width: 100%;
  height: auto;
}
.qna_content_wrap_view
  .qna_content_view:last-child
  .qna_content_list_view
  .qna_right_inpdiv_view
  textarea {
  resize: none;
  background: transparent;
  width: 100%;
  height: 160px;
  outline: none;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.qna_right_inpdiv_view_btn {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
.qna_right_inpdiv_view_btn button {
  width: 84px;
  height: 34px;
  background: #00c73c;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
}
.qna_right_inpdiv_view_btn .qna_view_cancel {
  margin-left: 5px;
  background: #3c3c3c;
}
.qna_content_wrap_view .qna_button_div_view {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  /* padding: 0 10px; */
}
.qna_button_div_view .qna_button_view {
  width: 120px;
  height: 38px;
  font-weight: 600;
  background-color: #00c73c;
  color: #fff;
  border-radius: 5px;
}

/* 팝업창 */

/* 공지사항 */
.popup_write_wrap {
  width: 100%;
  height: auto;
  padding: 20px 10px;
}
.popup_write_wrap .popup_write_inner {
  width: 80%;
  height: 100%;
  background-color: #fff;
}
.popup_write_wrap .popup_write_inner .popup_write_title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.popup_write_inner .popup_write_title h4 {
  font-size: 18px;
}
.popup_write_inner .popup_write_title .popup_write_button {
  width: 90px;
  height: 40px;
  background-color: #00c73c;
  border-radius: 5px;
}
.popup_write_inner .popup_write_button button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.popup_write_inner .popup_write_content {
  display: flex;
  width: 100%;
}
.popup_write_content .popup_write_left {
  width: 420px;
  /* flex: 0.5; */
  padding: 20px;
  border-top: 2px solid #c7c7c7;
  flex: none;
}
.popup_write_content .popup_write_left .popup_img_box {
  width: 360px;
  min-height: 240px;
  background-color: #ffffff;
  margin: 0 auto;
  border: 1px solid #cacaca;
}
.popup_write_content .popup_write_right {
  /* flex: 2; */
  /* background-color: rgb(159, 210, 165);
   */
  /* background: #a5cdbb; */
  background: #b7b7b7;
  /* background-color: #39dd6a; */
  padding: 20px;
  flex: 1;
  /* width: 260px; */
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* width: 100%; */
  /* flex: 2; */
}
/* .popup_write_content .popup_write_left .notice_write_inp {
  width: 100%;
  height: 50px;
  border: 1px solid #dadde6;
  text-indent: 10px;
  margin-bottom: 10px;
} */
.popup_write_content .popup_write_right .popup_file_box {
  text-align: center;
}
.popup_write_content .popup_write_right h4 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.popup_img_box > div {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* line-height: 110px; */
  /* font-size: 110px; */
  /* background-color: red; */
}

.popup_img_box > div .image_span {
  font-size: 24px;
  font-family: "Myriad-pro";
  color: #b8b8b8;
  font-weight: 700;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}

.popup_write_content .popup_write_left .popup_img_box img {
  /* object-fit: cover; */
  width: 100%;
  /* height: 100%; */
}
.popup_file_box .popup_file {
  width: 134px;
  height: 34px;
  /* position: relative; */
  font-weight: 400;
  font-size: 16px;
  background-color: #3c3c3c;
  color: #fff;
  line-height: 34px;
  /* margin-top: 10px; */
  cursor: pointer;
}
.popup_file_box .popup_file .notice_file_btn {
  /* display: block; */
  width: 100%;
  height: 100%;
}
.popup_file_box .popup_file .popup_file_inp {
  width: 100%;
  height: 100%;
  display: none;
}
.popup_link_box {
  margin-top: 20px;
  text-align: center;
}
.popup_link_box label {
  padding: 0 10px;
}
.popup_link_box input {
  background: transparent;
  /* border-radius: 4px; */
  width: 240px;
  border-bottom: 2px solid #fff;
}
.popup_open_box {
  margin-top: 20px;
  text-align: center;
}
.popup_open_box label {
  padding: 0 10px;
}
.popup_open_box label {
  cursor: pointer;
}
.popup_warning {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  /* color: #00c73c; */
}

.admin_banner_view {
  width: 100%;
  background: #fff;
  min-height: 500px;
}
.admin_banner_view .admin_banner_view_check {
  width: 100%;
  /* background-color: red; */
  height: 34px;
  border-bottom: 4px solid #f5f5f5;
}
.admin_banner_view .admin_banner_view_check > div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.admin_banner_view .admin_banner_view_check > div > label {
  font-size: 12px;
  padding: 0 5px;
}
.admin_banner_view .admin_banner_view_check > div > label input {
  margin-right: 4px;
}
.admin_banner_view .admin_banner_inner {
  width: 100%;
  padding: 20px;
}
.admin_banner_view .admin_banner_inner .admin_banner_change_button {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  padding: 0 10px;
  /* padding: 10px; */
}
.admin_banner_view .admin_banner_inner .admin_banner_change_button button {
  width: 90px;
  height: 24px;
}
.admin_banner_view .admin_banner_inner .admin_banner_change {
  display: flex;
  /* margin-top: 20px; */
  padding: 10px;
}
.admin_banner_view .admin_banner_inner .admin_intro_select_bottom {
  padding: 10px;
}
.admin_banner_view .admin_banner_inner .admin_intro_select_bottom_in {
  padding: 10px;
  border: 4px solid #f5f5f5;
  border-radius: 4px;
}
.admin_intro_select_bottom_in label:first-child {
  margin-right: 5px;
}
.admin_intro_select_bottom_in label {
  cursor: pointer;
}
.admin_banner_view .admin_banner_inner .admin_intro_select_bottom_in div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin_banner_view .admin_banner_inner .admin_intro_select_bottom_in h4 {
  width: 100%;
  text-align: center;
}
.admin_banner_view .admin_banner_inner .admin_banner_change > div {
  flex: 1;
  border: 4px solid #f5f5f5;
  border-radius: 4px;
  /* background-color: #f5f5f5; */
  /* border: 4px solid #f5f5f5; */
}
.admin_banner_view .admin_banner_inner .admin_banner_change > div > div {
  width: 100%;
  height: 280px;
}
.admin_banner_view .admin_banner_inner .admin_banner_change > div:first-child {
  margin-right: 5px;
}
.admin_banner_view .admin_banner_inner .admin_banner_change h4 {
  font-size: 18px;
  text-align: center;
  padding: 4px 0;
}

.admin_banner_view
  .admin_banner_inner
  .admin_banner_change
  .admin_bnner_change_in
  textarea {
  resize: none;
  width: 100%;
  height: 140px;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  padding: 4px;
  font-size: 14px;
}

.admin_banner_view
  .admin_banner_inner
  .admin_banner_change
  .admin_bnner_change_in.admin_bnner_change_detai
  textarea {
  resize: none;
  width: 100%;
  height: 70px;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  padding: 4px;
  font-size: 14px;
}

.admin_banner_view
  .admin_banner_inner
  .admin_banner_change
  .admin_bnner_change_in
  .admin_banner_img_box {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin_banner_change .btn_change {
  width: 100%;
  display: flex;
  justify-content: center;
}
.admin_banner_change button {
  width: 140px;
  height: 34px;
  /* background-color: red; */
  border: 1px solid #c5c5c5;
  border-radius: 4px;

  font-size: 14px;

  margin-top: 20px;

  /* background-color: red; */
}
.admin_banner_view
  .admin_banner_inner
  .admin_banner_change
  .admin_bnner_change_in {
  padding: 10px 20px;
  width: 100%;
}
.admin_banner_view
  .admin_banner_inner
  .admin_banner_change
  .admin_bnner_change_in
  .admin_banner_ref {
  font-size: 12px;
  margin-top: 10px;
  color: #7f7f7f;
  text-align: center;
}
.admin_banner_view
  .admin_banner_inner
  .admin_banner_change
  .admin_bnner_change_in
  .admin_banner_ref
  li {
  /* height: 14px; */
  line-height: 20px;
}
.admin_banner_view .admin_banner_inner > span {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #00c73c;
  font-weight: 600;
}
.admin_banner_view .admin_banner_content {
  width: 100%;
  height: 191.66666666666666px;
  position: relative;
  overflow: hidden;
}

.admin_banner_view .admin_banner_content::after {
  width: 100%;
  height: 100%;
  display: block;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
}
.admin_banner_content_in {
  max-width: 622.91666666664px;
  height: 191.66666666666666px;
  margin: 0 auto;
  position: relative;
  padding-left: 62.29166666666px;
  display: flex;
  align-items: center;
  color: #fff;
  z-index: 2;
}
.admin_banner_content_in .main_slick_content_text p {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}
.admin_banner_content_in .main_slick_content_text em {
  font-size: 20px;
  font-weight: 600;
}

.admin_banner_view .admin_banner_content_m {
  width: 660px;
  height: 528px;
  position: relative;
  margin: 0 auto;
}
.admin_banner_view .admin_banner_content_m::after {
  display: none;
}
.admin_banner_content_in_m {
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: #fff;
  z-index: 2;
  position: relative;
  padding-left: 48px;
  padding-top: 109px;
  align-items: flex-start;
}
.admin_banner_content_in_m .main_slick_content_text_m p {
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
}
.admin_banner_content_in_m .main_slick_content_text_m em {
  font-size: 60px;
  font-weight: 600;
}

.main_section3_wrap {
  width: 100%;
  height: 280px;

  /* padding: 0 20px; */
  margin: 0 0;
}
.main_section3_wrap .main_section3_imgbox {
  width: 100%;
  height: 100%;
  /* background: url("../assets/images/Layer\ 529.png") no-repeat; */
  /* background-position: center; */
  /* background-attachment: fixed; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* margin-bottom: 100px; */
}
/* .main_section3_wrap .main_section3_imgbox::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #333;
  opacity: 0.4;
} */
.main_section3_imgbox .main_section3_content {
  /* width: 40%; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  /* position: absolute;
  z-index: 2; */
}
.main_section3_content h3 {
  /* font-weight: 700; */
  font-size: 36px;
  color: #fff;
  margin-bottom: 15px;
  text-align: center;
  /* line-height: 240px; */
}
.main_section3_content p {
  font-size: 17px;
  color: #fff;
  margin-bottom: 25px;
}

.main_section3_content button {
  background-color: #00c73c;
  width: 150px;
  height: 48px;
  color: #fff;
  border-radius: 3px;
}
.main_section3_wrap .main_section3_imgbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.intro_banner_wrap {
  width: 100%;
  height: 277.9166666666667px;
  background-size: 100% 100%;
  /* position: relative; */
}

.intro_banner_wrap .intro_banner_inner {
  display: flex;
  max-width: 613.33333333336px;
  margin: 0 auto;
  height: 100%;
  /* height: 795px; */
  /* background-color: beige; */
  justify-content: flex-start;
  align-items: center;
  /* flex-direction: column; */
}
.intro_banner_wrap .intro_banner_inner .intro_banner_text {
  /* background-color: red; */
  /* height: auto; */
  padding-left: 67.08333333334px;
  padding-top: 71.875px;
  color: #fff;
}
.intro_banner_wrap .intro_banner_inner .intro_banner_text p {
  font-size: 22.52083333333px;
  line-height: 30px;
  font-weight: 700;
}
.intro_banner_wrap .intro_banner_inner .intro_banner_text span {
  font-size: 10px;
  line-height: 20px;
  margin-top: 10px;
  font-weight: 500;
}

.company_back_wrap {
  width: 100%;
  height: 355px;
}
.infomation {
  position: relative;
  padding: 23.95833333333px 11.5px 79.06250000001px;
  /* background-color: #fffffe; */
}
.infomation .info_text {
  position: relative;
  font-size: 10px;
  line-height: 11.5px;
  color: #4b5563;
  font-weight: 700;
}
.infomation .info_text strong {
  font-size: 14px;
  line-height: 15.33333333333px;
  color: #111827;
}
.infomation .info_text p {
  margin-top: 9px;
}
.infomation .info_num {
  position: relative;
  margin-top: 21.5625px;
}
.infomation .info_num li {
  margin-bottom: 16.77083333333px;
  font-size: 10px;
  line-height: 24px;
  color: #90989f;
}
.infomation .info_num li em {
  display: block;
  font-size: 17.25px;
  line-height: 3.35416666667px;
  color: #00c73c;
  font-weight: 700;
}
.info_link strong {
  font-size: 23px;
  line-height: 32px;
  font-weight: 700;
  color: #111827;
}
.info_link p {
  margin-top: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #4b5563;
  font-weight: 500;
  max-width: 319px;
}

.admin_banner_view
  .admin_banner_inner
  .admin_banner_change.admin_intro_change
  > div
  > div {
  width: 100%;
  height: 420px;
}
.admin_banner_view
  .admin_banner_inner
  .admin_banner_change.admin_intro_change
  .admin_intro_input_div {
  display: flex;
}
.admin_banner_view
  .admin_banner_inner
  .admin_banner_change.admin_intro_change
  .admin_intro_input_div
  > div {
  flex: 1;
}
.admin_banner_view
  .admin_banner_inner
  .admin_banner_change.admin_intro_change
  .admin_intro_input_div
  > div:first-child {
  margin-right: 5px;
}
.admin_banner_view
  .admin_banner_inner
  .admin_banner_change.admin_intro_change
  .admin_intro_input_div
  input {
  width: 100%;
  height: 24px;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  padding: 4px;
  font-size: 14px;
}
.admin_banner_view .admin_banner_inner .admin_bnner_change_detai input {
  width: 100%;
  height: 24px;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  padding: 4px;
  font-size: 14px;
}
.intro_common {
  /* margin-top: 125px; */
  /* max-width: 1010px; */
  max-width: 483.95833333336px;
  margin: 0 auto;
  margin-top: 60px;
}
.intro_common.intro_com_last {
  margin-top: 48px;
}
.intro_common .title {
  font-size: 10px;
  color: #00c73c;
  font-family: "Myriad-pro";
  font-weight: 600;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}
.intro_content {
  padding-bottom: 57px;
  display: flex;
  margin-top: 18px;
}
.intro_content.intro_content_last {
  padding-bottom: 48px;
}
.intro_content > div {
  flex: 1;
}
.intro_content .content_1 p {
  font-size: 14px;
  font-weight: 700;
}
.intro_content .content_2 .content_text {
  /* font-size: 30px; */
  max-width: 200px;
  font-size: 10px;
  font-weight: bold;
  line-height: 18px;
}
.intro_content .content_2 .content_text_detail {
  /* font-size: 30px; */
  max-width: 200px;
  font-size: 10px;
  margin-top: 20px;
}
.intro_content .content_2 .content_text_detail span {
  display: block;
  height: 24px;
}
.intro_content .content_2 .content_text_detail em {
  display: block;
  font-size: 17px;
  color: #00c73c;
  font-weight: bold;
}
.intro_common.intro_bottom_section .content_bottom_detail {
  height: auto;
  margin-bottom: 20px;
  font-size: 10px;
}
.intro_common.intro_bottom_section .content_bottom_detail span {
  display: block;
  font-size: 14px;
  color: #99a0a6;
}

.intro_common.intro_bottom_section .content_bottom_detail em {
  display: block;
  font-size: 17px;
  color: #00c73c;
  font-weight: bold;
}
.intro_common.intro_bottom_section .intro_img_box {
  border-radius: 5px;
  max-width: 133.20833333335px;
  overflow: hidden;
  /* cursor: pointer; */
}
.intro_customer_img_box {
  max-width: 440px;
  height: auto;
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.intro_customer_img_box img {
  max-width: 142px;
}

/* 브랜드 */
.wrap_content_box_body.brand_flex {
  display: flex;
  width: 600px;
  flex-direction: column;
  /* min-height: 320px; */
}

.wrap_content_box_body.brand_flex .brand_li2 label {
  width: 40px;
  margin-right: 10px;
}
.wrap_content_box_body.brand_flex .brand_li2 li input {
  width: 205px;
  font-size: 14px;
  text-indent: 3px;
}
.wrap_content_box_body.brand_flex .brand_li2 li:first-child input {
  width: 100px;
}
.wrap_content_box_body.brand_flex
  .brand_wrap:first-child
  .brand_li2
  li:first-child
  span:nth-child(2)
  input {
  color: #00c73c;
}

.wrap_content_box_body.brand_flex
  .brand_wrap:last-child
  .brand_li2
  li:first-child
  span:nth-child(3)
  input {
  color: #00c73c;
}

.wrap_content_box_body.brand_flex .brand_li2 li:first-child span:nth-child(2) {
  margin-right: 5px;
}
.info_write_wrap.brand_wrap {
  /* flex: 1; */
  height: 140px;
  width: 100%;
  /* height: 220px; */
}
.info_write_wrap.brand_wrap:first-child {
  margin-bottom: 10px;
}
.info_write_wrap.brand_wrap .info_write_content {
  width: 100%;
  height: 100%;
}
.info_write_wrap.brand_wrap ol {
  width: 100%;
  height: 100%;
}
.info_write_wrap.brand_wrap ol li.brand_li span {
  font-family: "Myriad-pro";
  font-weight: 600;
  font-size: 20px;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}
.info_write_wrap.brand_wrap ol li.brand_li {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}
.info_write_wrap.brand_wrap ol li.brand_li > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.info_write_wrap.brand_wrap ol li.brand_li2 {
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.info_write_wrap.brand_wrap:first-child ol li span em:nth-child(1) {
  color: #00c73c;
}

.info_write_wrap.brand_wrap:last-child ol li span em:nth-child(2) {
  color: #00c73c;
}

/* 푸터 */

.info_write_wrap.footer_wrap {
  width: 80%;
}
.info_write_wrap.footer_wrap .info_write_content > div {
  display: flex;
  margin-bottom: 5px;
}
.info_write_wrap.footer_wrap .info_write_content > div > label {
  width: 150px;
  text-align: right;
  font-weight: 500;
  margin-right: 20px;
  flex: none;
  line-height: 34px;
}
.info_write_wrap.footer_wrap .info_footer_div {
  border-bottom: 1px solid #d2d2d2;

  flex: 1;
}
.info_write_wrap.footer_wrap .info_footer_div .info_footer_inp {
  /* width: 140px; */

  height: 100%;
  box-shadow: none !important;
  font-size: 14px;
  color: #676767;
  width: 100%;
  /* border-bottom: 1px solid #d2d2d2; */
}
.info_write_wrap.footer_wrap .info_footer_div label {
  width: 50px;
  font-size: 14px;
  /* border: 1px solid #c6c6c6; */
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  font-weight: 400;
  margin-right: 0;
  line-height: 34px;
  flex: none;
  cursor: pointer;
  height: 100%;
  /* border-bottom: 1px solid #d2d2d2; */

  /* border-bottom: 1px solid #d2d2d2; */
}
/* 상세페이지 */
.admin_details_page_top {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
}
.admin_details_page_top label {
  font-size: 18px;
  font-weight: 600;
  display: block;
}
.admin_details_page_top select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  text-indent: 5px;
  margin-top: 10px;
}

.admin_details_view {
  width: 100%;
  background: #fff;
  min-height: 500px;
}
.admin_details_view .admin_details_view_check {
  width: 100%;
  /* background-color: red; */
  height: 34px;
  border-bottom: 4px solid #f5f5f5;
}
.admin_details_view .admin_details_view_check > div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.admin_details_view .admin_details_view_check > div > label {
  font-size: 12px;
  padding: 0 5px;
}
.admin_details_view .admin_details_view_check > div > label input {
  margin-right: 4px;
}
.admin_details_view .admin_details_inner {
  width: 100%;
  padding: 20px;
}
.admin_details_view .admin_details_inner .admin_details_change_button {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  padding: 0 10px;
  /* padding: 10px; */
}
.admin_details_view .admin_details_inner .admin_details_change_button button {
  width: 90px;
  height: 24px;
}
.admin_details_view .admin_details_inner .admin_details_change {
  display: flex;
  /* margin-top: 20px; */
  padding: 10px;
  flex-direction: column;
}
.admin_details_view .admin_details_inner .admin_details_change > div {
  flex: 1;
  border: 4px solid #f5f5f5;
  border-radius: 4px;
  /* background-color: #f5f5f5; */
  /* border: 4px solid #f5f5f5; */
}
.admin_details_view .admin_details_inner .admin_details_change > div > div {
  width: 100%;
  /* height: 280px; */
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  > div:first-child {
  margin-right: 5px;
}
.admin_details_view .admin_details_inner .admin_details_change h4 {
  font-size: 18px;
  text-align: center;
  padding: 4px 0;
}

.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_details_left
  > div {
  display: flex;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_details_left
  .admin_details_text_div {
  flex: 1;
  padding: 0 10px;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_details_left
  .admin_details_text_div:last-child {
  display: flex;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_details_left
  .admin_details_text_div:last-child
  > div {
  padding: 0 3px;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in
  textarea {
  resize: none;
  width: 100%;
  height: 100px;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  padding: 4px;
  font-size: 14px;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in
  p {
  margin-top: 10px;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in.admin_bnner_change_detai
  textarea {
  resize: none;
  width: 100%;
  height: 70px;
  border: 2px solid #f5f5f5;
  border-radius: 5px;
  outline: 0;
  padding: 4px;
  font-size: 14px;
}

.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in
  .admin_details_img_box {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.admin_details_img_box_warring {
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in
  .admin_details_img_box
  label {
  border-radius: 5px;
  /* height: 34px; */
  padding: 4px 5px;
  border: 1px solid #7f7f7f;
  margin-bottom: 5px;
  font-size: 14px;
  /* font-weight: 500; */
  /* background-color: #f5f5f5; */
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in
  .admin_details_img_box
  label:last-child {
  margin-bottom: 0;
}
.admin_details_change .btn_change {
  width: 100%;
  display: flex;
  justify-content: center;
}
.admin_details_change button {
  width: 140px;
  height: 34px;
  /* background-color: red; */
  border: 1px solid #c5c5c5;
  border-radius: 4px;

  font-size: 14px;

  margin-top: 20px;

  /* background-color: red; */
}
.admin_details_view .admin_details_inner .admin_details_change > div {
  padding: 10px 0;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in {
  padding: 10px 20px;
  width: 100%;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in
  .admin_details_ref {
  font-size: 12px;
  margin-top: 10px;
  color: #7f7f7f;
  text-align: center;
}
.admin_details_view
  .admin_details_inner
  .admin_details_change
  .admin_bnner_change_in
  .admin_details_ref
  li {
  /* height: 14px; */
  line-height: 20px;
}
.admin_details_view .admin_details_inner > span {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #00c73c;
  font-weight: 600;
}
.admin_details_view .admin_details_content {
  width: 100%;

  overflow: hidden;
}

.admin_details_content_in {
  width: 613.33333333336px;

  margin: 0 auto;
}
.admin_details_content_in .main_slick_content_text p {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}
.admin_details_content_in .main_slick_content_text em {
  font-size: 20px;
  font-weight: 600;
}

.invest {
  position: relative;
  padding: 0 11.5px;
  /* padding: 90px 24px 0px; */
  /* padding-bottom: 45px; */
  background-color: #fff;
  /* background: linear-gradient(#daf5e6, rgba(255, 255, 255, 0)); */
}

/* money */
.money {
  position: relative;
  /* padding: 40px 0 10px; */
  padding: 5px 0;
  /* background-color: #fff; */
}
.money_1 {
  width: 100%;
  padding: 14.375px 11.5px 10px;
  /* background-color: red; */
  border-top: 2px solid #ebeef3;
  /* padding-top: 30px; */
}
.money_2 {
  width: 100%;
  padding: 14.375px 11.5px;
  /* background-color: red; */
  border-top: 2px solid #ebeef3;
}
.money:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.money h2 {
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 600;
}
.money .money_text {
  /* margin-top: 24px; */
  font-size: 10px;
  line-height: 14px;
  color: #374151;
  font-weight: 400;
  text-align: center;
}
.money_list {
  padding-bottom: 8px;
  /* border-bottom: 1px solid #e5e7eb; */
  font-size: 0;
  line-height: 0;
}
.money_list li {
  display: inline-block;
  position: relative;
  width: 25%;
  padding: 8px 4px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.money_list li img {
  width: 38px;
}
.money_list.easy_list li {
  display: inline-block;
  position: relative;
  width: 33.333%;
  padding: 8px 4px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.money_list li:before {
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  right: 0;
  width: 8px;
  height: 14px;
  /* background: url(../assets/images//farm_arrow.png) center center no-repeat; */
}
.money_list li:last-child:before {
  display: none;
}
.money_list li span {
  margin-top: 7px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: #1f2937;
}

/* safe */
.safe {
  position: relative;
  padding: 19.16666666667px 23px 18.20833333333px;
  background-color: #fff;
}
.safe:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.safe h2 {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 600;
}
.safe .safe_text {
  font-size: 10px;
  line-height: 14px;
  color: #374151;
  font-weight: 500;
}
.safe .safe_list {
  padding: 16px 0;
}
.safe .safe_list .slick-slide {
  width: 260px;
  margin-right: 12px;
}
.safe .safe_item {
  height: 200px;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  margin-right: 10px;
}
.safe .safe_item .num {
  display: block;
  font-size: 20px;
  line-height: 28px;
  font-weight: 800;
  color: #bbb;
}
.safe .safe_item .title {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: #00c73c;
}
.safe .safe_item p {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  color: #374151;
  word-break: keep-all;
}

.protect {
  position: relative;
  padding: 19.16666666667px 23px 18.20833333333px;
  background-color: #fff;
}
.protect:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.protect h2 {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 600;
}
.protect .protect_text {
  font-size: 10px;
  line-height: 14px;
  color: #374151;
  font-weight: 500;
}
.protect .protect_list {
  margin-top: 23px;
}
.protect .protect_list li {
  margin-bottom: 24px;
  font-size: 10px;
  line-height: 22px;
  color: #4b5563;
}
.protect .protect_list li:last-child {
  margin-bottom: 0;
}
.protect .protect_list li > span {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #111827;
  font-weight: 700;
  height: auto;
}
.protect .protect_list li em {
  margin-right: 5px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #00c73c;
}
.protect .protect_list li p {
  margin-left: 26px;
  font-size: 10px;
  letter-spacing: -0.1px;
}

.easy {
  position: relative;
  padding: 10px 19.16666666667px 17px;
  background-color: #fff;
}
/* .easy:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
.easy h2 {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 600;
}
.easy .easy_text {
  font-size: 10px;
  line-height: 14px;
  color: #374151;
  font-weight: 500;
}
.easy .money_list {
  margin-top: 12px;
  border-bottom: 0;
}
.visual_back {
  position: relative;
}

.visual_front {
  position: relative;
  width: 100%;
  height: 100%;

  z-index: 0;
}

.visual_list {
  position: relative;
}
.visual_dots {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.visual_dots li {
  display: inline-block;
  margin: 0 5px;
  vertical-align: top;
}
.visual_dots li button {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #9ca3af;
}
.visual_dots li.slick-active button {
  background-color: #fff;
}
.visual_item {
  position: relative;
  width: 100%;

  height: 245.33333333336px;
  padding: 28.75px 11.5px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.visual_item:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 245.33333333336px;
  /* background-color: #000; */
  z-index: 0;
  opacity: 0.82;
}
.visual_item .visual_box {
  position: relative;
  padding: 0 11.5px;
}
.visual_item .visual_sub {
  padding: 2px 4px;
  background-color: #3eb649;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  color: #fff;
  font-weight: 700;
}
.visual_item .visual_title {
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  letter-spacing: -0.1px;
  font-weight: 600;
  margin-top: 5px;
}
.visual_inner {
  margin-top: 10.375px;
  padding: 10px 10.5px 0px;

  border-radius: 4px;
  background-color: #fff;
  height: 90px;
}
.visual_inner ul {
  margin-bottom: 10.25px;
}
.visual_inner ul li {
  display: inline-block;
  position: relative;
  width: calc(50% - 2px);
  vertical-align: top;
  text-align: center;
  /* height: 20px; */
}

.visual_inner ul li span {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #4b5563;
  letter-spacing: -0.14px;
  margin-bottom: 6.22916666664px;
}
.visual_inner ul li .num {
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #111827;
  letter-spacing: -0.1px;
}
.line_wrap {
  width: 100%;
  height: 6px;
  position: relative;
}
.visual_inner .line {
  position: relative;
  /* margin-top: 35px; */
  /* margin-bottom: 8px; */
  width: 100%;
  height: 6px;
  border-radius: 4px;
  background-color: #f3f4f6;
  font-size: 0;
  overflow: hidden;
}
.visual_inner .green_line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #00a370;
}
.visual_inner .line_text_box {
}
.visual_inner .line_text_box::after {
  content: "";
  display: block;
  clear: both;
}
.visual_inner .line_text_box .per {
  float: left;
  font-size: 10px;
  line-height: 14px;
  color: #00a370;
}
.visual_inner .line_text_box .per.n2 {
  float: right;
  color: #4b5563;
}
.visual_btn {
  margin-top: 16.77083333336px;
  height: 26.35416666664px;
}
.visual_btn .visual_go {
  width: 100%;
  height: 100%;
  /* padding: 12px 20px; */
  border-radius: 4px;
  background-color: #f3f4f6;
  font-size: 16px;
  line-height: 20px;
  color: #6b7280;
  font-weight: 700;
  text-align: center;
}
.progress {
  width: 100%;
  appearance: none;
  height: 3px;
  border: 0;
  position: absolute;
}
.progress::-webkit-progress-bar {
  background: #f3f4f6;
  border-radius: 5px;
  /* box-shadow: inset 3px 3px 10px #ccc; */
}
.progress::-webkit-progress-value {
  border-radius: 5px;
  background: #00a370;
  /* background: -webkit-linear-gradient(to right, #93f9b9, #1d976c);
  background: linear-gradient(to right, #93f9b9, #1d976c); */
}

/* 사업 규약 */

.wrap_content_box_body.ppdf_flex {
  display: flex;
  width: 600px;
  flex-direction: column;
  /* min-height: 320px; */
}
.wrap_content_box_body.ppdf_flex .ppdf_li li > label {
  width: 40px;
  margin-right: 10px;
  font-weight: 500;
  text-align: right;
}
.wrap_content_box_body.ppdf_flex .ppdf_li2 label {
  width: 40px;
  margin-right: 10px;
  font-weight: 500;
  text-align: right;
  font-size: 16px;
}
.wrap_content_box_body.ppdf_flex .ppdf_li2 li input {
  width: 205px;
  font-size: 18px;
  text-indent: 3px;
}
.wrap_content_box_body.ppdf_flex .ppdf_li .pdf_span {
  display: inline-block;
}
.wrap_content_box_body.ppdf_flex .ppdf_li .pdf_span > span {
  box-shadow: 0px 2px 0px #dfdfdf;
  width: 240px;
  /* display: flex; */
}
.wrap_content_box_body.ppdf_flex .ppdf_li .pdf_span > span > input {
  width: 180px;
  background-color: transparent;
}
.wrap_content_box_body.ppdf_flex .ppdf_li .pdf_span > span label {
  width: 50px;
  display: inline-block;
  margin-right: 0;
  font-size: 14px;
  font-weight: normal;
}
.wrap_content_box_body.ppdf_flex .ppdf_li input {
  /* box-shadow: 0px 2px 0px #dfdfdf; */
  width: 240px;
  /* box-shadow: none; */
}
.wrap_content_box_body.ppdf_flex .ppdf_li2 li:first-child input {
  width: 100px;
}
.wrap_content_box_body.ppdf_flex
  .ppdf_wrap:first-child
  .ppdf_li2
  li:first-child
  span:nth-child(2)
  input {
  color: #00c73c;
}

.wrap_content_box_body.ppdf_flex
  .ppdf_wrap:last-child
  .ppdf_li2
  li:first-child
  span:nth-child(3)
  input {
  color: #00c73c;
}

.wrap_content_box_body.ppdf_flex .ppdf_li2 li:first-child span:nth-child(2) {
  margin-right: 5px;
}
.info_write_wrap.ppdf_wrap {
  /* flex: 1; */
  min-height: 140px;
  width: 100%;
  /* height: 220px; */
}
.info_write_wrap.ppdf_wrap {
  margin-bottom: 10px;
}
.info_write_wrap.ppdf_wrap .info_write_content {
  width: 100%;
  height: 100%;
}
.info_write_wrap.ppdf_wrap ol {
  width: 100%;
  height: 100%;
}
.info_write_wrap.ppdf_wrap ol li.ppdf_li {
  padding: 10px 0;
}
.info_write_wrap.ppdf_wrap ol li.ppdf_li2 {
  padding: 10px 0;
}
.info_write_wrap.ppdf_wrap ol li.ppdf_li span {
  /* font-family: "Myriad-pro"; */
  font-weight: 500;
  font-size: 18px;
  /* src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype"); */
}
.info_write_wrap.ppdf_wrap ol li > p {
  font-size: 20px;
  font-weight: 600;
}
.info_write_wrap.ppdf_wrap ol li.ppdf_li > div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  /* width: 100%;
  height: 100%; */
  justify-content: space-between;
  margin-top: 10px;
}
.info_write_wrap.ppdf_wrap ol li.ppdf_li > div > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.info_write_wrap.ppdf_wrap ol li.ppdf_li2 > div {
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.n_mark {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: rgb(244, 98, 36);
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.n_mark span {
  /* display: inline-block; */
  height: auto;

  /* line-height: 0; */
  font-size: 10px;
  color: #fff;
  /* height: auto; */
  /* height: 100%; */
  /* line-height: 0px; */
}
