/* visual */
.visual {
  position: relative;
  /* background-color: #fffffe; */
  background-color: #000;
  /* background-color: red; */
}

.visual_list {
  position: relative;
}
.visual_dots {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.visual_dots li {
  display: inline-block;
  margin: 0 5px;
  vertical-align: top;
}
.visual_dots li button {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #9ca3af;
}
.visual_dots li.slick-active button {
  background-color: #fff;
}
.visual_item {
  position: relative;
  height: 512px;
  padding: 60px 24px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.visual_item:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 512px;
  /* background-color: #000; */
  z-index: 0;
  opacity: 0.82;
}
.visual_item .visual_box {
  position: relative;
}
.visual_item .visual_sub {
  padding: 4px 8px;
  background-color: #59a37e;
  border-bottom-left-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  font-weight: 700;
}
.visual_item .visual_title {
  font-size: 26px;
  line-height: 37px;
  color: #fff;
  letter-spacing: -0.1px;
  font-weight: 600;
}
.visual_inner {
  margin-top: 30px;
  padding: 30px 24px 24px;
  border-radius: 4px;
  background-color: #fff;
}
.visual_inner ul li {
  display: inline-block;
  position: relative;
  width: calc(50% - 2px);
  vertical-align: top;
  text-align: center;
}
.visual_inner ul li:before {
  display: block;
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 1px;
  height: 44px;
  background-color: #e6e6ea;
}
.visual_inner ul li:last-child:before {
  display: none;
}
.visual_inner ul li span {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #4b5563;
  letter-spacing: -0.14px;
  margin-bottom: 8px;
}
.visual_inner ul li .num {
  display: block;
  font-size: 21px;
  line-height: 30px;
  font-weight: 600;
  color: #111827;
  letter-spacing: -0.1px;
}
.visual_inner .line {
  position: relative;
  margin-top: 35px;
  margin-bottom: 8px;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  background-color: #f3f4f6;
  font-size: 0;
  overflow: hidden;
}
.visual_inner .green_line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #00a370;
}
.visual_inner .line_text_box {
}
.visual_inner .line_text_box .per {
  float: left;
  font-size: 12px;
  line-height: 16px;
  color: #00a370;
}
.visual_inner .line_text_box .per.n2 {
  float: right;
  color: #4b5563;
}
.visual_btn {
  margin-top: 35px;
}
.visual_btn .visual_go {
  width: 100%;
  padding: 12px 20px;
  border-radius: 4px;
  background-color: #f3f4f6;
  font-size: 18px;
  line-height: 27px;
  color: #6b7280;
  font-weight: 700;
  text-align: center;
}
.progress {
  width: 100%;
  appearance: none;
}
.progress::-webkit-progress-bar {
  background: #f0f0f0;
  border-radius: 10px;
  box-shadow: inset 3px 3px 10px #ccc;
}
.progress::-webkit-progress-value {
  border-radius: 10px;
  background: #00a370;
  /* background: -webkit-linear-gradient(to right, #93f9b9, #1d976c);
  background: linear-gradient(to right, #93f9b9, #1d976c); */
}

.rowgroup {
}
.tab {
  background-color: #fffffe;
}
.tab .tab_list {
  font-size: 0;
  line-height: 0;
}
.tab .tab_item {
  display: inline-block;
  width: 33.3%;
  vertical-align: top;
  text-align: center;
}
.tab .tab_item .tab_button {
  display: inline-block;
  width: 100%;
  padding: 20px 0 14px;
  border-bottom: 2.5px solid #e5e7eb;
  vertical-align: top;
  font-size: 16px;
  line-height: 18px;
}
.tab .tab_item.active .tab_button {
  color: #008a61;
  border-color: #008a61;
}
/* infomation */

.company_back_wrap {
  width: 100%;
  height: 741px;
  /* background: url("../assets//images//companybanner.png") no-repeat; */
  background-size: 100%;
}
.infomation {
  position: relative;
  padding: 50px 24px 165px;
  /* background-color: #fffffe; */
}

.infomation .info_text {
  position: relative;
  font-size: 15px;
  line-height: 24px;
  color: #4b5563;
  font-weight: 700;
}
.infomation .info_text strong {
  font-size: 23px;
  line-height: 32px;
  color: #111827;
}
.infomation .info_text p {
  margin-top: 15px;
}
.em_green {
  color: #0e9e5d;
}

.infomation .info_num {
  position: relative;
  margin-top: 45px;
}
.infomation .info_num li {
  margin-bottom: 35px;
  font-size: 12px;
  line-height: 20px;
  color: #90989f;
}
.infomation .info_num li em {
  display: block;
  font-size: 36px;
  line-height: 37px;
  color: #00c73c;
  font-weight: 700;
}

/* info_link */
.info_link {
  position: relative;
  padding: 45px 0 10px;
}
/* .info_link:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
.info_link .text_box {
  padding: 0 24px 24px;
  /* border-bottom: 1px solid #d1d5db; */
  background-color: #fff;
}
.info_link strong {
  font-size: 23px;
  line-height: 32px;
  font-weight: 700;
  color: #111827;
}
.info_link p {
  margin-top: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #4b5563;
  font-weight: 500;
  max-width: 319px;
}
.info_link .info_go {
  display: block;
  width: 100%;
  padding: 14px 0;
  text-align: center;
  font-size: 16px;
  line-height: 23px;
  color: #111827;
  font-weight: 700;
  background-color: #fff;
}

.info_link .info_num {
  position: relative;
  margin-top: 45px;
  padding-left: 24px;
}
.info_link .info_num li {
  margin-bottom: 35px;
  font-size: 12px;
  line-height: 20px;
  color: #90989f;
}
.info_link .info_num li em {
  display: block;
  font-size: 36px;
  line-height: 37px;
  color: #00c73c;
  font-weight: 700;
}

.info_move_box {
  width: 100%;
  height: 50px;
  border-top: 2px solid #ebeef3;
}
.info_move_box .info_move_intro {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* qna */
.qna {
  padding: 40px 24px 100px;
  background-color: #fffffe;
}
.qna h2 {
  margin-bottom: 28px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
}
.qna {
}
.qna .qna_list {
}
.qna .qna_item {
  position: relative;
  background-color: #fff;
  text-align: left;
}
.qna .qna_btn {
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 0;
  background-color: #ffff;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  letter-spacing: -0.2px;
  font-weight: 500;
  text-align: left;
}
.qna .qna_btn:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ededed;
}
.qna .qna_btn:after {
  display: block;
  position: absolute;
  top: calc(50% - 5px);
  right: 0;
  width: 18px;
  height: 10px;
  background: url(../assets/images/qna_arrow.png) center center no-repeat;
  transition: all 0.3s linear;
}
.qna .qna_btn.active:after {
  transform: rotate(180deg);
}
.qna .qna_body {
  /* display: none; */
  height: 0;
  padding: 0 14px;
  /* padding: 0 14px; */
  background-color: #f9fafb;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
  letter-spacing: -0.1px;
  font-weight: 500;
  transition: height 0.3s linear;
  transition: padding 0.4s linear;
  overflow: hidden;
  white-space: pre-line;
}
.qna .qna_body.active {
  /* display: block; */
  padding: 14px;
  transition: all 0.3s linear;
  height: auto;
}
/* news */
.news {
  position: relative;
  padding: 43px 24px 50px;
  background-color: #fff;
  /* background-color: red; */
}
/* .news:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
.news h2 {
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
}
.news_list {
  padding: 16px 0 0;
  /* background-color: red; */
  position: relative;
}
.press_arrow_left,
.press_arrow_right {
  position: absolute;
  z-index: 1;
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 50%;
  margin-top: -1.5px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  cursor: pointer;
}
.press_arrow_left {
  left: -17.5px;
}
.press_arrow_right {
  right: -17.5px;
}
.news_list .slick-slide {
  width: 282px;
  margin-right: 12px !important;
}
.news_list .news_item {
  border-radius: 10px;
  padding: 0 10px;
}
.news_list .news_anchor {
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  -webkit-user-drag: none;
  height: 252px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.news_list .news_img {
  width: 100%;
  aspect-ratio: 16 / 9;
  /* height: 150px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.news_list .news_text {
  padding: 16px 13px 14px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-left: 13px; */
  box-sizing: border-box;
}
.news_list .news_text .news_title {
  display: -webkit-box;
  /* height: 46px; */
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  line-height: 20px;
  color: #111827;
  font-weight: 600;
  letter-spacing: -0.1px;
  word-break: keep-all;
}
.news_list .news_text .text_box {
  /* margin-top: 5px; */
  line-height: 0;
  display: flex;
  justify-content: space-between;
}
.news_list .news_text .time {
  font-size: 14px;
  line-height: 22px;
  color: #9f9e9e;
  font-weight: 500;
  letter-spacing: -0.2px;
}
.news_list .news_text .news_name {
  /* width: 170px; */
  height: 22px;
  float: right;
  text-align: right;
  font-size: 15px;
  line-height: 22px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* review */
.review {
  position: relative;
  padding: 43px 24px 50px;
  background-color: #fffffe;
}
/* .review:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
.review h2 {
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
  /* margin-bottom: 28px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
  text-align: center; */
}
.review_list {
  position: relative;
  padding: 16px 0 0;
  /* background-color: red; */
}

.reivew_arrow_left,
.review_arrow_right {
  position: absolute;
  z-index: 1;
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 50%;
  margin-top: -16px;
  /* margin-top: -1.5px; */
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  cursor: pointer;
  color: #939393;
}
.reivew_arrow_left {
  left: -7px;
}
.review_arrow_right {
  right: -7px;
}
.review_list .review_item {
  padding: 3px 10px;
  height: 336px;
  /* height: auto; */
  cursor: pointer;
}

.review_list .review_item.view {
  padding: 3px 10px;
  /* height: 336px; */
  height: auto;
  /* background-color: black; */
}

.review_list .review_inner {
  padding: 27px 23px 30px 20px;
  /* border-radius: 4px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); */
  /* box-shadow: 3px 3px rgba(0, 0, 0, 0.12); */
  box-shadow: 0px 0px 8px 1px#EBEAEB;

  border-radius: 10px;
}
.review_list .review_title {
  font-size: 15px;
  line-height: 22px;
  color: #00c73c;
  letter-spacing: -0.2px;
  font-weight: 700;
}
.review_list .review_name {
  margin-bottom: 20px;
}
.review_list .review_name span {
  font-size: 14px;
  line-height: 22px;
  color: #898989;
  letter-spacing: -0.2px;
  font-weight: 400;
}
.review_list .review_item .review_text .text {
  display: -webkit-box;
  height: 202px;

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.26px;
  color: #4b5563;
}
.review_list .review_item.view .review_text .text {
  display: block;
  min-height: 202px;
  height: auto;
  overflow: visible;
  text-overflow: inherit;

  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.26px;
  color: #4b5563;
}

/* STATUS */

.status {
  position: relative;
  padding: 0 24px;
}
.status:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 176px;
  background: linear-gradient(rgba(153, 227, 192, 0.4), rgba(255, 255, 255, 0));
}
.status_text {
  padding-top: 50px;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: #4b5563;
}
.status_text strong {
  display: block;
  padding-bottom: 15px;
  font-size: 23px;
  line-height: 32px;
  color: #111827;
  font-weight: 700;
}
.status_num {
  margin-top: 24px;
  padding: 24px 0;
}
.status_num .status_list {
  font-size: 0;
  line-height: 0;
}
.status_num .status_list li {
  display: inline-block;
  width: 50%;
  font-size: 14px;
  line-height: 20px;
  color: #4b5563;
  font-weight: 400;
}
.status_num .status_list li span {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #4b5563;
  font-weight: 400;
}
.status_num .status_list.length2 {
  padding-bottom: 18px;
  border-bottom: 1px solid #d1d5db;
}
.status_num .status_list.length3 {
  padding-top: 18px;
}
.status_num .status_list.length3 li {
  width: 33.3%;
  font-size: 12px;
  line-height: 16px;
  color: #4b5563;
}
.status_num .status_list.length3 li span {
  font-size: 12px;
  line-height: 16px;
  color: #4b5563;
}
.status_num .status_list li em {
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 700;
}

/* farm */
.farm {
  padding: 12px;
  background-color: #ebeef3;
}
.farm_list {
  /* width: 120%; */
  /* background-color: red; */
}
.farm_list .slick-slide {
  /* width: 100%; */
  /* background-color: beige; */
  /* margin-right: 20px; */
}
/* .farm_list > div > div > div > .slick-slide {
  width: 200px;
  margin-right: 12px;
  background-color: red;
} */
.farm_item {
  width: 300px;
  padding: 0 5px;
  /* margin-right: 10px; */
}
.farm_item_box {
  border-radius: 4px;
  padding: 16px;
  background-color: #fff;
}
.farm_top {
  position: relative;
  padding-bottom: 12px;
  border-bottom: 1px solid #d1d5db;
}
.farm_top .title {
  padding-right: 50px;
  font-size: 18px;
  line-height: 36px;
  color: #111827;
  font-weight: 700;
}
.farm_top .time_box {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}
.farm_top .time_box span {
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 300;
}
.farm_top .time_box .date {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 600;
}
.farm_top .time_box .time {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #4b5563;
  font-weight: 500;
}
.farm_text {
  padding: 12px 0 20px;
  border-bottom: 1px solid #d1d5db;
}
.farm_text li {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #4b5563;
  font-weight: 500;
}
.farm_text li .title {
  display: inline-block;
  vertical-align: top;
}
.farm_text li .text {
  display: inline-block;
  float: right;
  vertical-align: top;
  text-align: right;
  color: #111827;
  font-size: 15px;
}
.farm_text li .text .em_green {
  color: #006e4d;
  font-size: 16px;
  font-weight: 600;
}
.farm_now {
  padding-top: 14px;
}
.farm_now .now_title {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 700;
}
.farm_now .now_list {
  font-size: 0;
  line-height: 0;
}
.farm_now .now_list li {
  display: inline-block;
  position: relative;
  width: 25%;
  padding: 12px 4px;
  text-align: center;
}
.farm_now .now_list li:before {
  display: block;
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
  width: 6px;
  height: 12px;
  /* background: url(/site/public/images/farm_arrow.png) center center no-repeat; */
}
.farm_now .now_list li:last-child:before {
  display: none;
}
.now_icon {
  font-size: 30px;
  color: rgb(0, 163, 112);
}

.now_icon.cc {
  animation: blink-effect 2s step-end infinite;
}
.now_icon.notcc {
  color: #8e8e8e !important;
}
@keyframes blink-effect {
  50% {
    color: #8e8e8e;
  }
}
.farm_now .now_list li span {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #1f2937;
  font-weight: 600;
}
.farm .btn_box {
  margin-top: 12px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.farm .btn_box .farm_body {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  height: 0px;
  overflow: hidden;
  transition: height 0.3s linear;
}
.farm .btn_box .farm_body.active {
  height: 135px;
  transition: height 0.3s linear;
}
.farm .btn_box .farm_body > .title {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 600;
}
.farm .btn_box .farm_body li {
  margin-bottom: 6px;
  font-weight: 300;
  font-size: 0;
  line-height: 0;
}
.farm .btn_box .farm_body li span {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}
.farm .btn_box .farm_body li .title {
  width: 55px;
  padding-right: 20px;
}
.farm .btn_box .farm_body li .text {
  float: right;
  text-align: right;
}
.farm .btn_box .farm_btn {
  display: block;
  width: 100%;
  text-align: center;
}
.farm .btn_box .farm_btn span {
  position: relative;
  padding-right: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #9f9e9e;
  letter-spacing: -0.25px;
  font-weight: 500;
}
.farm .btn_box .farm_btn span:before {
  display: block;
  position: absolute;
  top: 8px;
  right: 0;
  width: 10px;
  height: 6px;
  /* background: url(/site/public/images/farm_arrow2.png) center center no-repeat; */
}
/* campaign_anchor */
.campaign_go {
  border: 3px solid #006e4d;
  margin-bottom: 10px;
}
.campaign_go .campaign_anchor {
  display: block;
  position: relative;
  width: 100%;
  padding: 24px;
  background-color: #fff;
}
.campaign_go .anchor_text {
  position: relative;
  font-size: 0;
  line-height: 0;
}
.campaign_go .anchor_text:before {
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  width: 10px;
  height: 16px;
  /* background: url(/site/public/images/campaign_go_arrow.png) center center
    no-repeat; */
}
.campaign_go .campaign_anchor span {
  font-size: 15px;
  line-height: 22px;
  color: #111827;
  font-weight: 300;
}
.campaign_go .campaign_anchor em {
  display: block;
  font-size: 21px;
  line-height: 30px;
  color: #006e4d;
  font-weight: 700;
}
.farm_back {
  padding-bottom: 10px;
}
.farm_status {
  padding: 12px;
  background-color: #0b524a;
  color: white;
  /* margin-bottom: 10px; */
}
.farm_status .fs_title {
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
}
.farm_status .fs_subtitle {
  font-size: 24px;
  line-height: 40px;
  font-weight: bold;
}

.slider_item {
  padding: 10px;
}

.slider_title {
  background-color: #91bdb9;
  height: 40px;
  border-radius: 20px;
  padding-left: 25px;
  line-height: 40px;
  margin: 15px 0;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.15);
  font-size: 18px;
}
.slider_cont {
  overflow: hidden;
}
.slider_cont_date {
  float: left;
  font-size: 16px;
  font-weight: 400;
}
.slider_cont_datas {
  float: left;
  margin-left: 10px;
}
.slider_space {
  clear: both;
  height: 10px;
  margin-top: 10px;
}

.slider_img_area {
  margin: 10px;

  text-align: center;
}
.slider_img_area > a {
  width: 100%;
  height: 150px;
}
.slider_img_area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 나의 투자 */
.inv_my_content {
  width: 100%;
  min-height: 600px;
  background-color: #fff;
  padding: 30px 20px;
}
.inv_my_login {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inv_my_login > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #77b69a;
}
.inv_my_login > div > svg {
  font-size: 100px;
}
.inv_my_login > div > span {
  font-size: 20px;
  margin-top: 20px;
  font-weight: 600;
}
.inv_my_login > div > button {
  background-color: #0e9e5d;
  width: 172.5px;
  height: 34px;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 20px;
}
.inv_my_content .invest_have_wrap {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.invest_have_wrap h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.invest_have_wrap h3 > .em_green {
  font-size: 30px;
}
.invest_have_wrap .invest_ing_wrap,
.invest_have_wrap .invest_wait_wrap {
  width: 100%;
  height: auto;
}

.invest_ing_wrap .invest_ing_item,
.invest_wait_wrap .invest_wait_item {
  /* background-color: #006e4d; */
  padding: 10px 20px;
}
.invest_ing_wrap .invest_ing_item .invest_ing_box {
  /* background-color: #77b69a; */
  /* background-color: #00a370; */
  border-radius: 14px;
  height: 360px;
  padding: 16px;
  border: 2px solid #0e9e5d;
  color: #0e9e5d;
}

.invest_ing_item .invest_ing_box .invest_ing_title {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  /* color: #fff; */
  /* border-bottom: 2px solid #fff; */
  padding-bottom: 10px;
}
.invest_ing_item .invest_ing_box .invest_ing_con {
  font-size: 22px;
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 30px;
  /* align-items: center; */
}
.invest_ing_item .invest_ing_box .invest_ing_con label {
  width: 38%;
  text-align: right;
  position: relative;
  line-height: 36px;
  margin-right: 4px;
}
.invest_ing_item .invest_ing_box .invest_ing_con label::after {
  position: absolute;
  right: -7px;
  top: 9px;
  content: "";
  display: block;
  width: 2px;
  height: 22px;
  background-color: #0e9e5d;
}
.invest_ing_item .invest_ing_box .invest_ing_con span {
  text-indent: 18px;
  width: 38%;
  text-align: right;
  line-height: 36px;
}
.invest_ing_item .invest_ing_box .invest_ing_con > div {
  height: 36px;
}
.invest_ing_item .invest_ing_box .invest_ing_con > div:first-child {
  margin-bottom: 6px;
}

.invest_ing_item .invest_ing_box .invest_ing_btn {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 14px;
  flex-direction: column;
}
.invest_ing_item .invest_ing_box .invest_ing_btn button {
  background: #0e9e5d;
  color: #fff;
  font-size: 18px;

  padding: 10px 20px;
  border-radius: 14px;
  width: 100%;
  height: 48px;
}

.invest_wait_wrap .invest_wait_item .invest_wait_box {
  /* background-color: #77b69a; */
  /* background-color: #00a370; */
  border-radius: 14px;
  min-height: 240px;
  padding: 16px;
  border: 2px solid #cfcfcf;
  color: #969696;
}

.invest_wait_item .invest_wait_box .invest_wait_title {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  /* color: #fff; */
  /* border-bottom: 2px solid #fff; */
  padding-bottom: 10px;
}
.invest_wait_item .invest_wait_box .invest_wait_con {
  font-size: 22px;
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 30px 0;
  /* align-items: center; */
}

.invest_wait_item .invest_wait_box .invest_wait_con2 {
  /* font-size: 22px; */
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 10px 0;
  border-top: 1px solid #cfcfcf;
  /* align-items: center; */
}
.invest_wait_con2 h4 {
  text-align: center;
  margin-bottom: 9px;
}
.invest_wait_item .invest_wait_box .invest_wait_con label {
  width: 38%;
  text-align: right;
  position: relative;
  line-height: 36px;
  margin-right: 4px;
}
.invest_wait_item .invest_wait_box .invest_wait_con label::after {
  position: absolute;
  right: -7px;
  top: 9px;
  content: "";
  display: block;
  width: 2px;
  height: 22px;
  background-color: #969696;
}
.invest_wait_item .invest_wait_box .invest_wait_con span {
  text-indent: 18px;
  width: 38%;
  text-align: right;
  line-height: 36px;
}
.invest_wait_item .invest_wait_box .invest_wait_con > div {
  height: 36px;
}
.invest_wait_item .invest_wait_box .invest_wait_con > div:first-child {
  margin-bottom: 6px;
}
.invest_wait_item .invest_wait_info {
  display: flex;
  width: 100%;
}
.invest_wait_item .invest_wait_info .invest_wait_field {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}
.invest_wait_item .invest_wait_box .invest_wait_btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}
.invest_wait_item .invest_wait_box .invest_wait_btn button {
  background: #969696;
  color: #fff;
  font-size: 18px;

  padding: 10px 20px;
  border-radius: 14px;
  width: 100%;
}

/* 절차 */

/* invest */
.invest {
  position: relative;
  padding-bottom: 45px;
  background-color: #fff;
}
.invest:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.invest_top {
  display: block;
  padding: 20px 24px 0;
}
.invest_top .num {
  vertical-align: top;
}
.invest_top .invest_text {
  position: relative;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: #3f8f67;
}
.invest_top .invest_text em {
  display: block;
  margin-top: 8px;
  font-size: 23px;
  line-height: 32px;
  color: #111827;
  font-weight: 700;
}
.invest_top .invest_icon {
  position: absolute;
  top: 0;
  right: 0;
}
.invest .visual_inner {
  margin-top: 0;
}
.invest .visual_inner ul li {
  width: calc(33.3% - 3px);
}

/* money */
.money {
  position: relative;
  padding: 40px 24px 38px;
  background-color: #fff;
}
.money:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.money h2 {
  margin-bottom: 18px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.money .money_text {
  margin-top: 24px;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 500;
  text-align: center;
}
.money_list {
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e7eb;
  font-size: 0;
  line-height: 0;
}
.money_list li {
  display: inline-block;
  position: relative;
  width: 25%;
  padding: 12px 4px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.money_list li:before {
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  right: 0;
  width: 8px;
  height: 14px;
  /* background: url(/site/public/images/money_arrow.png) center center no-repeat; */
}
.money_list li:last-child:before {
  display: none;
}
.money_list li span {
  margin-top: 7px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #1f2937;
}

/* safe */
.safe {
  position: relative;
  padding: 40px 24px 38px;
  background-color: #fff;
}
.safe:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.safe h2 {
  margin-bottom: 8px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.safe .safe_text {
  font-size: 15px;
  line-height: 22px;
  color: #374151;
  font-weight: 500;
}
.safe .safe_list {
  padding: 16px 0;
}
.safe .safe_list .slick-slide {
  width: 260px;
  margin-right: 12px;
}
.safe .safe_item {
  height: 236px;
  padding: 18px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  margin-right: 20px;
}
.safe .safe_item .num {
  display: block;
  font-size: 26px;
  line-height: 37px;
  font-weight: 800;
  color: #bbb;
}
.safe .safe_item .title {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 700;
  color: #008a61;
}
.safe .safe_item p {
  font-size: 15px;
  line-height: 26px;
  font-weight: 500;
  color: #374151;
  word-break: keep-all;
}

/* protect */
.protect {
  position: relative;
  padding: 40px 24px 38px;
  background-color: #fff;
}
.protect:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.protect h2 {
  margin-bottom: 8px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.protect .protect_text {
  font-size: 15px;
  line-height: 22px;
  color: #374151;
  font-weight: 500;
}
.protect .protect_list {
  margin-top: 40px;
}
.protect .protect_list li {
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 22px;
  color: #4b5563;
}
.protect .protect_list li:last-child {
  margin-bottom: 0;
}
.protect .protect_list li span {
  display: block;
  font-size: 16px;
  line-height: 27px;
  color: #111827;
}
.protect .protect_list li em {
  margin-right: 12px;
  font-size: 18px;
  line-height: 27px;
  color: #00a370;
}
.protect .protect_list li p {
  margin-left: 26px;
  letter-spacing: -0.1px;
}

/* easy */
.easy {
  position: relative;
  padding: 32px 24px 42px;
  background-color: #fff;
}
.easy:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.easy h2 {
  margin-bottom: 8px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.easy .easy_text {
  font-size: 15px;
  line-height: 22px;
  color: #374151;
  font-weight: 500;
}
.easy .money_list {
  margin-top: 12px;
  border-bottom: 0;
}

/* income */
.income {
  position: relative;
  padding: 36px 24px 46px;
  background-color: #fff;
}
.income:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.income .income_text {
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 500;
}
.income .income_text em {
  display: block;
  margin-bottom: 5px;
  font-size: 30px;
  line-height: 32px;
  color: #3d8d65;
  font-weight: 700;
  letter-spacing: -0.45px;
}
.income .income_text span {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 27px;
}
.income .income_input {
  width: 100%;
  margin-top: 25px;
  padding: 17px 16px 15px 16px;
  border: 1px solid #000;
  border-radius: 8px;
}
.income .income_input .front_text {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #757575;
}
.income .income_input .text_box {
  display: inline-block;
  width: calc(100% - 60px);
  text-align: right;
  vertical-align: top;
}
.income .income_input .back_text {
}

.income .income_box {
  width: calc(100% - 32px);
  text-align: right;
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  color: #000;
}
.income .income_list {
  margin-top: 25px;
  font-size: 0;
  line-height: 0;
}
.income .income_list li {
  display: inline-block;
  width: calc(25% - 5px);
  margin-right: 5px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.income .income_list li:last-child {
  margin-right: 5px;
}
.income .income_btn {
  width: 100%;
  background-color: #ebeef3;
  border-radius: 8px;
  font-size: 14px;
  line-height: 50px;
  font-weight: 700;
  color: #000;
}

.payment_agree_wrap {
  width: 100%;
  padding: 24px;
  background-color: #fff;
  margin-bottom: 10px;
}
.payment_agree_wrap .payment_agree_title {
  font-size: 18px;
  font-weight: 700;
}
.payment_agree_step > label {
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.payment_agree_step_box {
  padding: 10px;
  width: 100%;
  height: auto;
  /* background-color: red; */
  /* border: 1px solid #374151; */
  border-radius: 5px;
  background-color: #d3f1e2;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.payment_agree_step .label_public_box {
  color: #919191;
  font-weight: 500;
}
.payment_agree_step .label_public_box.label_color {
  color: #000;
  font-weight: 600;
}

.payment_agree_step_box .label_public_box {
  color: #919191;
  font-size: 14px;
  /* font-weight: 500; */
}
.payment_agree_step_box .label_public_box.label_color {
  color: #000;
  font-size: 14px;
  /* font-weight: 600; */
}

.payment_agree_step .check_public_box {
  color: #919191;
}

.payment_agree_step .check_public_box.check_color {
  color: #006e4d;
}

.payment_agree_step_box .check_public_box {
  color: #919191;
  font-size: 18px;
}

.payment_agree_step_box .check_public_box.check_color {
  color: #006e4d;
  font-size: 18px;
}

.payment_agree_step_box > div {
  display: flex;
  justify-content: space-between;
}

.payment_agree_step_box > div > label {
  display: flex;
  cursor: pointer;
}

.make_payment_method {
  width: 100%;
  padding: 24px;
  background: #fff;
}
.make_payment_method_title {
  font-size: 18px;
  font-weight: 700;
}
.payment_method_div {
  display: flex;
  margin-top: 10px;
  height: 50px;
  width: 100%;
}
.payment_method_div > .payment_method {
  /* width: 200px; */
  width: 25%;
  margin-right: 5px;
  text-align: center;
  line-height: 50px;
  border: 2px solid #a2a2a2;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}
.payment_method.payment_color {
  border: 3px solid #000;
}
.payment_method_div > div > span {
  font-size: 20px;
  margin-right: 3px;
}
.make_product {
  width: 100%;
  padding: 24px;
  background-color: #3d8d65;
  margin-bottom: 10px;
}
.make_product a {
  width: 100%;
  height: 100%;
}
.make_product span {
  /* color: #fa7806; */
  font-size: 18px;
}
.make_product .em_white {
  width: 100%;
  height: 100%;
}
/* story */
.story {
  position: relative;
  padding: 56px 0 55px;
  background-color: #fff;
}
.story:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.story .story_item {
  padding-bottom: 100px;
}
.story .story_item:last-child {
  padding-bottom: 0;
}
.story .story_item.n2 {
  margin-left: 220px;
}
.story .story_item.n3 {
  width: 312px;
}
.story .story_title {
  margin-bottom: 70px;
  padding: 0 24px;
  font-size: 30px;
  line-height: 50px;
  color: #111827;
  font-weight: 700;
}
.story .story_text {
  padding: 0 24px;
}
.story .story_img {
  margin-bottom: 24px;
}
.story .story_item.n2 .story_img {
  margin-right: 25px;
  border-radius: 8px;
  overflow: hidden;
}
.story .story_item.n2 .story_img img {
  width: 100%;
}
.story .story_item.n3 .story_img {
  margin-left: 25px;
  border-radius: 8px;
  overflow: hidden;
}
.story .story_item.n3 .story_img img {
  width: 100%;
}
.story .story_text .title {
  margin-bottom: 24px;
  font-size: 26px;
  line-height: 40px;
  color: #6b7280;
  letter-spacing: -0.2px;
  font-weight: 700;
}
.story .story_text em {
  color: #00a370;
}
.story .story_text em.em_black {
  color: #111827;
}
.story .story_text p {
  font-size: 18px;
  line-height: 30px;
  color: #374151;
  font-weight: 500;
}
.story .story_text p + p {
  margin-top: 40px;
}

.story_arrow_left,
.story_arrow_right {
  position: absolute;
  z-index: 1;
  width: 32px;
  height: 32px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  top: 50%;
  margin-top: -16px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  cursor: pointer;
  color: #939393;
}
.story_arrow_left {
  left: -7px;
}
.story_arrow_right {
  right: -7px;
}
@media all and (max-width: 640px) {
  .story .story_item.n2 {
    margin-left: 0;
    text-align: right;
  }
}

/* building */
/* .building {
  position: relative;
  padding: 48px 24px 58px;
  background-color: #fff;
}
.building:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.building h2 {
  margin-bottom: 18px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.building_list {
  height: 220px;
  border-radius: 8px;
  overflow: hidden;
}
.building_list .building_item {
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.building .building_table {
  margin: 40px 0 20px;
}
.building .building_table .table {
  border-top: 2px solid #d1d5db;
}
.building .building_table .table th {
  padding: 10px 12px;
  background-color: #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  color: #111827;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
}
.building .building_table .table td {
  padding: 10px 12px;
  border-bottom: 1px solid #e5e7eb;
  color: #4b5563;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  text-align: left;
}
.building .building_table .table tr:last-child th,
.building .building_table .table tr:last-child td {
  border-left: 0;
  border-bottom: 0;
}
.building .building_text {
}
.building .building_text li {
  position: relative;
  margin-bottom: 6px;
  padding-left: 18px;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
  font-weight: 300;
}
.building .building_text li:before {
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #9ca3af;
} */

/* qna */
.qna.n2 {
  position: relative;
  background-color: #fff;
}
.qna.n2:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.qna.n3 {
  padding: 0 24px 80px;
  background-color: #fff;
}
.qna.n3 .qna_item ol {
  padding-left: 18px;
}
.qna.n3 .qna_item ol li {
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  letter-spacing: -0.1px;
  font-weight: 300;
}
.qna.n3 .qna_item ol > li > ol {
  margin-bottom: 6px;
}
.qna.n3 .qna_item ul {
}
.qna.n3 .qna_item ul li {
  width: 100%;
  padding: 12px 0;
  font-size: 0;
  line-height: 0;
}
.qna.n3 .qna_item ul li span {
  display: inline-block;
  width: calc(100% - 40px);
  vertical-align: top;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #424342;
}
.qna.n3 .qna_item ul li .qna_down {
  display: inline-block;
  width: 40px;
  vertical-align: top;
  font-size: 13px;
  line-height: 18px;
  color: #757575;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;
}

/* 오픈 페이지 */
.invest_open_page {
  width: 100%;
  min-height: 600px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  padding: 50px 0;
  /* align-items: center; */
  /* background-color: #0a9d71; */
}
.invest_open_wrap {
  width: 100%;
  height: 500px;
  display: flex;
  align-content: center;
}
.invest_open_page .invest_open_left {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invest_open_page h4 {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 60%;
  height: 100px;
  line-height: 100px;
  /* border-style: double;
  border-width: 4px;
  border-color: #fff; */
}
.invest_open_page .invest_open_right {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #0a9d71;
}

.invest_open_page .invest_open_right_box {
  background-color: #fff;
  width: auto;
  /* text-align: center; */
  height: auto;
  padding: 20px 30px 20px 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.invest_open_right .invest_open_title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-indent: 10px;
}

.invest_open_right .invest_open_subtit {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
  text-indent: 16px;
}
.em_white {
  font-weight: 600;
  color: #fff;
}
@media all and (max-width: 1400px) {
  .company_back_wrap {
    height: auto;
  }
  .infomation {
    padding: 50px 24px 90px;
    /* background-color: #fffffe; */
  }
  /* .news_list .news_text {
    padding: 8px 7px 9px;
    height: 100px;
    box-sizing: border-box;
  } */
}
@media all and (max-width: 1100px) {
  .company_back_wrap {
    width: 100%;
    height: 441px;
    /* background: url("../assets//images//companybanner.png") no-repeat; */
    background-size: cover;
  }
  .infomation {
    position: relative;
    padding: 20px 24px 165px;
    /* background-color: #fffffe; */
  }

  .infomation .info_text {
    /* position: relative; */
    font-size: 13px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 700;
  }
  .infomation .info_text strong {
    font-size: 20px;
    line-height: 28px;
    color: #111827;
  }
  .infomation .info_text p {
    margin-top: 15px;
  }
  .em_green {
    color: #0e9e5d;
  }

  .infomation .info_num {
    position: relative;
    margin-top: 45px;
  }
  .infomation .info_num li {
    margin-bottom: 35px;
    font-size: 12px;
    line-height: 20px;
    color: #90989f;
  }
  .infomation .info_num li em {
    display: block;
    font-size: 32px;
    line-height: 37px;
    color: #00c73c;
    font-weight: 700;
  }

  /* info_link */
  .info_link {
    position: relative;
    padding: 45px 0 10px;
  }
  /* .info_link:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  } */
  .info_link .text_box {
    padding: 0 24px 24px;
    /* border-bottom: 1px solid #d1d5db; */
    background-color: #fff;
  }
  .info_link strong {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: #111827;
  }
  .info_link p {
    margin-top: 12px;
    font-size: 13px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 500;
    max-width: 319px;
  }
  .info_link .info_go {
    display: block;
    width: 100%;
    padding: 14px 0;
    text-align: center;
    font-size: 16px;
    line-height: 23px;
    color: #111827;
    font-weight: 700;
    background-color: #fff;
  }

  .info_link .info_num {
    position: relative;
    margin-top: 45px;
    padding-left: 24px;
  }
  .info_link .info_num li {
    margin-bottom: 35px;
    font-size: 12px;
    line-height: 20px;
    color: #90989f;
  }
  .info_link .info_num li em {
    display: block;
    font-size: 32px;
    line-height: 37px;
    color: #00c73c;
    font-weight: 700;
  }

  .info_move_box {
    width: 100%;
    height: 50px;
    border-top: 2px solid #ebeef3;
  }
  .info_move_box .info_move_intro {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* qna */
  .qna {
    padding: 36px 24px 100px;
    background-color: #fffffe;
  }
  .qna h2 {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .qna {
  }
  .qna .qna_list {
  }
  .qna .qna_item {
    position: relative;
    background-color: #fff;
    text-align: left;
  }
  .qna .qna_btn {
    display: block;
    position: relative;
    width: 100%;
    padding: 16px 0;
    background-color: #ffff;
    font-size: 14px;
    line-height: 24px;
    color: #000;
    letter-spacing: -0.2px;
    font-weight: 500;
    text-align: left;
  }
  .qna .qna_btn:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ededed;
  }
  .qna .qna_btn:after {
    display: block;
    position: absolute;
    top: calc(50% - 5px);
    right: 0;
    width: 18px;
    height: 10px;
    background: url(../assets/images/qna_arrow.png) center center no-repeat;
    transition: all 0.3s linear;
  }
  .qna .qna_btn.active:after {
    transform: rotate(180deg);
  }
  .qna .qna_body {
    /* display: none; */
    height: 0;
    padding: 0 14px;
    /* padding: 0 14px; */
    background-color: #f9fafb;
    font-size: 12px;
    line-height: 20px;
    color: #6b7280;
    letter-spacing: -0.1px;
    font-weight: 500;
    transition: height 0.3s linear;
    transition: padding 0.4s linear;
    overflow: hidden;
    white-space: pre-line;
  }
  .qna .qna_body.active {
    /* display: block; */
    padding: 14px;
    transition: all 0.3s linear;
    height: auto;
  }
  /* news */
  .news {
    position: relative;
    padding: 35px 20px 52px;
    background-color: #fff;
    /* background-color: red; */
  }
  /* .news:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  } */
  .news h2 {
    font-size: 18px;
    line-height: 20px;
  }
  .news_list {
    padding: 16px 0 0;
    /* background-color: red; */
    position: relative;
  }
  .press_arrow_left,
  .press_arrow_right {
    position: absolute;
    z-index: 1;
    width: 32px;
    height: 32px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: 50%;
    margin-top: -1.5px;
    font-size: 26px;
    box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
    cursor: pointer;
  }
  .press_arrow_left {
    left: -17.5px;
  }
  .press_arrow_right {
    right: -17.5px;
  }
  .news_list .slick-slide {
    width: 282px;
    margin-right: 12px !important;
  }
  .news_list .news_item {
    border-radius: 10px;
    padding: 0 6px;
  }
  .news_list .news_anchor {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-user-drag: none;
    /* height: 252px; */
    border: 1px solid #d1d5db;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .news_list .news_img {
    /* height: 150px; */
  }
  .news_list .news_text {
    padding: 12px 7px 10px;
    height: 90px;
    /* padding-left: 13px; */
    box-sizing: border-box;
  }
  .news_list .news_text .news_title {
    font-size: 14px;
    line-height: 18px;
  }
  .news_list .news_text .text_box {
    margin-top: 5px;
    line-height: 0;
  }
  .news_list .news_text .time {
    font-size: 10px;
    line-height: 22px;
    color: #9f9e9e;
    font-weight: 500;
    letter-spacing: -0.2px;
  }
  .news_list .news_text .news_name {
    /* width: 170px; */
    height: 22px;
    float: right;
    text-align: right;
    font-size: 12px;
    line-height: 22px;
    color: #111827;
    font-weight: 700;
    letter-spacing: -0.1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* review */
  .review {
    position: relative;
    padding: 35px 20px 52px;
    background-color: #fffffe;
  }
  /* .review:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  } */
  .review h2 {
    font-size: 18px;
    line-height: 20px;
  }
  .review_list {
    position: relative;
    padding: 16px 0 0;
    /* background-color: red; */
  }

  .reivew_arrow_left,
  .review_arrow_right {
    position: absolute;
    z-index: 1;
    width: 32px;
    height: 32px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: 50%;
    margin-top: -16px;
    /* margin-top: -1.5px; */
    font-size: 26px;
    box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
    cursor: pointer;
    color: #939393;
  }
  .reivew_arrow_left {
    left: -7px;
  }
  .review_arrow_right {
    right: -7px;
  }

  .review_list .review_item {
    padding: 3px 10px;
  }
  .review_list .review_item.view {
    padding: 3px 10px;
    /* height: 336px; */
    height: auto;
    /* background-color: black; */
  }

  .review_list .review_inner {
    padding: 27px 23px 30px 20px;
    /* border-radius: 4px; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); */
    /* box-shadow: 3px 3px rgba(0, 0, 0, 0.12); */
    box-shadow: 0px 0px 8px 1px#EBEAEB;

    border-radius: 10px;
  }
  .review_list .review_title {
    font-size: 13px;
    line-height: 20px;
  }
  .review_list .review_name {
    margin-bottom: 18px;
  }
  .review_list .review_name span {
    font-size: 12px;
    line-height: 22px;
    color: #898989;
    letter-spacing: -0.2px;
    font-weight: 400;
  }
  .review_list .review_text .text {
    display: -webkit-box;
    height: 202px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.26px;
    color: #4b5563;
  }
  .review_list .review_item.view .review_text .text {
    display: block;
    min-height: 202px;
    height: auto;
    overflow: visible;
    text-overflow: inherit;

    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.26px;
    color: #4b5563;
  }
  /* STATUS */

  .status {
    position: relative;
    padding: 0 24px;
  }
  .status:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 176px;
    background: linear-gradient(
      rgba(153, 227, 192, 0.4),
      rgba(255, 255, 255, 0)
    );
  }
  .status_text {
    padding-top: 50px;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    color: #4b5563;
  }
  .status_text strong {
    display: block;
    padding-bottom: 15px;
    font-size: 23px;
    line-height: 32px;
    color: #111827;
    font-weight: 700;
  }
  .status_num {
    margin-top: 24px;
    padding: 24px 0;
  }
  .status_num .status_list {
    font-size: 0;
    line-height: 0;
  }
  .status_num .status_list li {
    display: inline-block;
    width: 50%;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 400;
  }
  .status_num .status_list li span {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 400;
  }
  .status_num .status_list.length2 {
    padding-bottom: 18px;
    border-bottom: 1px solid #d1d5db;
  }
  .status_num .status_list.length3 {
    padding-top: 18px;
  }
  .status_num .status_list.length3 li {
    width: 33.3%;
    font-size: 12px;
    line-height: 16px;
    color: #4b5563;
  }
  .status_num .status_list.length3 li span {
    font-size: 12px;
    line-height: 16px;
    color: #4b5563;
  }
  .status_num .status_list li em {
    font-size: 21px;
    line-height: 30px;
    color: #111827;
    font-weight: 700;
  }

  /* farm */
  .farm {
    padding: 12px;
    background-color: #ebeef3;
  }
  .farm_list {
    /* width: 120%; */
    /* background-color: red; */
  }
  .farm_list .slick-slide {
    /* width: 100%; */
    /* background-color: beige; */
    /* margin-right: 20px; */
  }
  /* .farm_list > div > div > div > .slick-slide {
    width: 200px;
    margin-right: 12px;
    background-color: red;
  } */
  .farm_item {
    width: 300px;
    padding: 0 5px;
    /* margin-right: 10px; */
  }
  .farm_item_box {
    border-radius: 4px;
    padding: 16px;
    background-color: #fff;
  }
  .farm_top {
    position: relative;
    padding-bottom: 12px;
    border-bottom: 1px solid #d1d5db;
  }
  .farm_top .title {
    padding-right: 50px;
    font-size: 18px;
    line-height: 36px;
    color: #111827;
    font-weight: 700;
  }
  .farm_top .time_box {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
  }
  .farm_top .time_box span {
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    font-weight: 300;
  }
  .farm_top .time_box .date {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    font-weight: 600;
  }
  .farm_top .time_box .time {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #4b5563;
    font-weight: 500;
  }
  .farm_text {
    padding: 12px 0 20px;
    border-bottom: 1px solid #d1d5db;
  }
  .farm_text li {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 500;
  }
  .farm_text li .title {
    display: inline-block;
    vertical-align: top;
  }
  .farm_text li .text {
    display: inline-block;
    float: right;
    vertical-align: top;
    text-align: right;
    color: #111827;
    font-size: 15px;
  }
  .farm_text li .text .em_green {
    color: #006e4d;
    font-size: 16px;
    font-weight: 600;
  }
  .farm_now {
    padding-top: 14px;
  }
  .farm_now .now_title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    font-weight: 700;
  }
  .farm_now .now_list {
    font-size: 0;
    line-height: 0;
  }
  .farm_now .now_list li {
    display: inline-block;
    position: relative;
    width: 25%;
    padding: 12px 4px;
    text-align: center;
  }
  .farm_now .now_list li:before {
    display: block;
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
    width: 6px;
    height: 12px;
    /* background: url(/site/public/images/farm_arrow.png) center center no-repeat; */
  }
  .farm_now .now_list li:last-child:before {
    display: none;
  }
  .now_icon {
    font-size: 30px;
    color: rgb(0, 163, 112);
  }

  .now_icon.cc {
    animation: blink-effect 2s step-end infinite;
  }
  .now_icon.notcc {
    color: #8e8e8e !important;
  }
  @keyframes blink-effect {
    50% {
      color: #8e8e8e;
    }
  }
  .farm_now .now_list li span {
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #1f2937;
    font-weight: 600;
  }
  .farm .btn_box {
    margin-top: 12px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }
  .farm .btn_box .farm_body {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    height: 0px;
    overflow: hidden;
    transition: height 0.3s linear;
  }
  .farm .btn_box .farm_body.active {
    height: 135px;
    transition: height 0.3s linear;
  }
  .farm .btn_box .farm_body > .title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    font-weight: 600;
  }
  .farm .btn_box .farm_body li {
    margin-bottom: 6px;
    font-weight: 300;
    font-size: 0;
    line-height: 0;
  }
  .farm .btn_box .farm_body li span {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
  }
  .farm .btn_box .farm_body li .title {
    width: 55px;
    padding-right: 20px;
  }
  .farm .btn_box .farm_body li .text {
    float: right;
    text-align: right;
  }
  .farm .btn_box .farm_btn {
    display: block;
    width: 100%;
    text-align: center;
  }
  .farm .btn_box .farm_btn span {
    position: relative;
    padding-right: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #9f9e9e;
    letter-spacing: -0.25px;
    font-weight: 500;
  }
  .farm .btn_box .farm_btn span:before {
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    width: 10px;
    height: 6px;
    /* background: url(/site/public/images/farm_arrow2.png) center center no-repeat; */
  }
  /* campaign_anchor */
  .campaign_go {
    border: 3px solid #006e4d;
    margin-bottom: 10px;
  }
  .campaign_go .campaign_anchor {
    display: block;
    position: relative;
    width: 100%;
    padding: 24px;
    background-color: #fff;
  }
  .campaign_go .anchor_text {
    position: relative;
    font-size: 0;
    line-height: 0;
  }
  .campaign_go .anchor_text:before {
    display: block;
    position: absolute;
    top: calc(50% - 8px);
    right: 0;
    width: 10px;
    height: 16px;
    /* background: url(/site/public/images/campaign_go_arrow.png) center center
      no-repeat; */
  }
  .campaign_go .campaign_anchor span {
    font-size: 15px;
    line-height: 22px;
    color: #111827;
    font-weight: 300;
  }
  .campaign_go .campaign_anchor em {
    display: block;
    font-size: 21px;
    line-height: 30px;
    color: #006e4d;
    font-weight: 700;
  }
  .farm_back {
    padding-bottom: 10px;
  }
  .farm_status {
    padding: 12px;
    background-color: #0b524a;
    color: white;
    /* margin-bottom: 10px; */
  }
  .farm_status .fs_title {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
  }
  .farm_status .fs_subtitle {
    font-size: 24px;
    line-height: 40px;
    font-weight: bold;
  }

  .slider_item {
    padding: 10px;
  }

  .slider_title {
    background-color: #91bdb9;
    height: 40px;
    border-radius: 20px;
    padding-left: 25px;
    line-height: 40px;
    margin: 15px 0;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.15);
    font-size: 18px;
  }
  .slider_cont {
    overflow: hidden;
  }
  .slider_cont_date {
    float: left;
    font-size: 16px;
    font-weight: 400;
  }
  .slider_cont_datas {
    float: left;
    margin-left: 10px;
  }
  .slider_space {
    clear: both;
    height: 10px;
    margin-top: 10px;
  }

  .slider_img_area {
    margin: 10px;

    text-align: center;
  }
  .slider_img_area > a {
    width: 100%;
    height: 150px;
  }
  .slider_img_area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* 나의 투자 */
  .inv_my_content {
    width: 100%;
    min-height: 600px;
    background-color: #fff;
    padding: 30px 20px;
  }
  .inv_my_login {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inv_my_login > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #77b69a;
  }
  .inv_my_login > div > svg {
    font-size: 100px;
  }
  .inv_my_login > div > span {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .inv_my_login > div > button {
    background-color: #0e9e5d;
    width: 172.5px;
    height: 34px;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 20px;
  }
  .inv_my_content .invest_have_wrap {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
  .invest_have_wrap h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .invest_have_wrap h3 > .em_green {
    font-size: 30px;
  }
  .invest_have_wrap .invest_ing_wrap,
  .invest_have_wrap .invest_wait_wrap {
    width: 100%;
    height: auto;
  }

  .invest_ing_wrap .invest_ing_item,
  .invest_wait_wrap .invest_wait_item {
    /* background-color: #006e4d; */
    padding: 10px 20px;
  }
  .invest_ing_wrap .invest_ing_item .invest_ing_box {
    /* background-color: #77b69a; */
    /* background-color: #00a370; */
    border-radius: 14px;
    height: 360px;
    padding: 16px;
    border: 2px solid #0e9e5d;
    color: #0e9e5d;
  }

  .invest_ing_item .invest_ing_box .invest_ing_title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    /* color: #fff; */
    /* border-bottom: 2px solid #fff; */
    padding-bottom: 10px;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con {
    font-size: 22px;
    /* color: #fff; */
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-top: 30px;
    /* align-items: center; */
  }
  .invest_ing_item .invest_ing_box .invest_ing_con label {
    width: 38%;
    text-align: right;
    position: relative;
    line-height: 36px;
    margin-right: 4px;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con label::after {
    position: absolute;
    right: -7px;
    top: 9px;
    content: "";
    display: block;
    width: 2px;
    height: 22px;
    background-color: #0e9e5d;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con span {
    text-indent: 18px;
    width: 38%;
    text-align: right;
    line-height: 36px;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con > div {
    height: 36px;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con > div:first-child {
    margin-bottom: 6px;
  }

  .invest_ing_item .invest_ing_box .invest_ing_btn {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 14px;
    flex-direction: column;
  }
  .invest_ing_item .invest_ing_box .invest_ing_btn button {
    background: #0e9e5d;
    color: #fff;
    font-size: 18px;

    padding: 10px 20px;
    border-radius: 14px;
    width: 100%;
    height: 48px;
  }

  .invest_wait_wrap .invest_wait_item .invest_wait_box {
    /* background-color: #77b69a; */
    /* background-color: #00a370; */
    border-radius: 14px;
    min-height: 240px;
    padding: 16px;
    border: 2px solid #cfcfcf;
    color: #969696;
  }

  .invest_wait_item .invest_wait_box .invest_wait_title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    /* color: #fff; */
    /* border-bottom: 2px solid #fff; */
    padding-bottom: 10px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con {
    font-size: 22px;
    /* color: #fff; */
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 30px 0;
    /* align-items: center; */
  }

  .invest_wait_item .invest_wait_box .invest_wait_con2 {
    /* font-size: 22px; */
    /* color: #fff; */
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 10px 0;
    border-top: 1px solid #cfcfcf;
    /* align-items: center; */
  }
  .invest_wait_con2 h4 {
    text-align: center;
    margin-bottom: 9px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con label {
    width: 38%;
    text-align: right;
    position: relative;
    line-height: 36px;
    margin-right: 4px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con label::after {
    position: absolute;
    right: -7px;
    top: 9px;
    content: "";
    display: block;
    width: 2px;
    height: 22px;
    background-color: #969696;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con span {
    text-indent: 18px;
    width: 38%;
    text-align: right;
    line-height: 36px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con > div {
    height: 36px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con > div:first-child {
    margin-bottom: 6px;
  }
  .invest_wait_item .invest_wait_info {
    display: flex;
    width: 100%;
  }
  .invest_wait_item .invest_wait_info .invest_wait_field {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
  }
  .invest_wait_item .invest_wait_box .invest_wait_btn {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_btn button {
    background: #969696;
    color: #fff;
    font-size: 18px;

    padding: 10px 20px;
    border-radius: 14px;
    width: 100%;
  }

  /* income */
  .income {
    position: relative;
    padding: 36px 24px 46px;
    background-color: #fff;
  }
  .income:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  }
  .income .income_text {
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    font-weight: 500;
  }
  .income .income_text em {
    display: block;
    margin-bottom: 5px;
    font-size: 30px;
    line-height: 32px;
    color: #3d8d65;
    font-weight: 700;
    letter-spacing: -0.45px;
  }
  .income .income_text span {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 27px;
  }
  .income .income_input {
    width: 100%;
    margin-top: 25px;
    padding: 17px 16px 15px 16px;
    border: 1px solid #000;
    border-radius: 8px;
  }
  .income .income_input .front_text {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: #757575;
  }
  .income .income_input .text_box {
    display: inline-block;
    width: calc(100% - 60px);
    text-align: right;
    vertical-align: top;
  }
  .income .income_input .back_text {
  }

  .income .income_box {
    width: calc(100% - 32px);
    text-align: right;
    font-size: 19px;
    line-height: 24px;
    font-weight: 700;
    color: #000;
  }
  .income .income_list {
    margin-top: 25px;
    font-size: 0;
    line-height: 0;
  }
  .income .income_list li {
    display: inline-block;
    width: calc(25% - 5px);
    margin-right: 5px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }
  .income .income_list li:last-child {
    margin-right: 5px;
  }
  .income .income_btn {
    width: 100%;
    background-color: #ebeef3;
    border-radius: 8px;
    font-size: 14px;
    line-height: 50px;
    font-weight: 700;
    color: #000;
  }

  /* story */
  .story {
    position: relative;
    padding: 56px 0 55px;
    background-color: #fff;
  }
  .story:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  }
  .story .story_item {
    padding-bottom: 100px;
  }
  .story .story_item:last-child {
    padding-bottom: 0;
  }
  .story .story_item.n2 {
    margin-left: 220px;
  }
  .story .story_item.n3 {
    width: 312px;
  }
  .story .story_title {
    margin-bottom: 70px;
    padding: 0 24px;
    font-size: 30px;
    line-height: 50px;
    color: #111827;
    font-weight: 700;
  }
  .story .story_text {
    padding: 0 24px;
  }
  .story .story_img {
    margin-bottom: 24px;
  }
  .story .story_item.n2 .story_img {
    margin-right: 25px;
    border-radius: 8px;
    overflow: hidden;
  }
  .story .story_item.n2 .story_img img {
    width: 100%;
  }
  .story .story_item.n3 .story_img {
    margin-left: 25px;
    border-radius: 8px;
    overflow: hidden;
  }
  .story .story_item.n3 .story_img img {
    width: 100%;
  }
  .story .story_text .title {
    margin-bottom: 24px;
    font-size: 26px;
    line-height: 40px;
    color: #6b7280;
    letter-spacing: -0.2px;
    font-weight: 700;
  }
  .story .story_text em {
    color: #00a370;
  }
  .story .story_text em.em_black {
    color: #111827;
  }
  .story .story_text p {
    font-size: 18px;
    line-height: 30px;
    color: #374151;
    font-weight: 500;
  }
  .story .story_text p + p {
    margin-top: 40px;
  }

  .story_arrow_left,
  .story_arrow_right {
    position: absolute;
    z-index: 1;
    width: 32px;
    height: 32px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: 50%;
    margin-top: -16px;
    font-size: 26px;
    box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
    cursor: pointer;
    color: #939393;
  }
  .story_arrow_left {
    left: -7px;
  }
  .story_arrow_right {
    right: -7px;
  }

  /* qna */
  .qna.n2 {
    position: relative;
    background-color: #fff;
  }
  .qna.n2:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  }
  .qna.n3 {
    padding: 0 24px 80px;
    background-color: #fff;
  }
  .qna.n3 .qna_item ol {
    padding-left: 18px;
  }
  .qna.n3 .qna_item ol li {
    font-size: 14px;
    line-height: 24px;
    color: #111827;
    letter-spacing: -0.1px;
    font-weight: 300;
  }
  .qna.n3 .qna_item ol > li > ol {
    margin-bottom: 6px;
  }
  .qna.n3 .qna_item ul {
  }
  .qna.n3 .qna_item ul li {
    width: 100%;
    padding: 12px 0;
    font-size: 0;
    line-height: 0;
  }
  .qna.n3 .qna_item ul li span {
    display: inline-block;
    width: calc(100% - 40px);
    vertical-align: top;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    color: #424342;
  }
  .qna.n3 .qna_item ul li .qna_down {
    display: inline-block;
    width: 40px;
    vertical-align: top;
    font-size: 13px;
    line-height: 18px;
    color: #757575;
    font-weight: 500;
    text-decoration: underline;
    text-align: center;
  }
}

@media all and (max-width: 660px) {
  .company_back_wrap {
    height: 400px;
  }
  .infomation {
    position: relative;
    padding: 20px 20px 0px;
    /* background-color: #fffffe; */
  }
  .infomation .info_text p {
    margin-top: 13px;
  }
  .infomation .info_num {
    margin-top: 28px;
  }
  .infomation .info_num li {
    margin-bottom: 26px;
  }
  .infomation .info_num li em {
    font-size: 26px;
  }
  .info_link {
    padding: 30px 0 10px;
  }
  .info_link .text_box {
    padding: 0 20px 18px;
  }
  .info_link .info_num {
    margin-top: 26px;
  }
  /* .infomation .info_num li {
    margin-bottom: 26px;
  } */
  .info_link .info_num li {
    margin-bottom: 26px;
  }
  .info_link .info_num li em {
    font-size: 26px;
  }
  .info_move_box {
    height: 42px;
  }
  .info_move_box .info_move_intro {
    height: 42px;
  }
  .info_move_box .info_move_intro a {
    height: 42px;
    font-size: 14px;
    line-height: 42px;
    width: 100%;
    text-align: center;
  }
  .news {
    padding: 20px 20px 32px;

    /* background-color: red; */
  }
  .news h2 {
    font-size: 16px;
  }
  .news_list {
    padding: 13px 0 0;
  }
  .news_list .news_text {
    padding: 8px 7px 8px;
    height: 80px;
  }
  .news_list .news_text .news_title {
    font-size: 14px;
    line-height: 16px;
  }
  .review {
    padding: 20px 20px 32px;
  }
  .review_list {
    padding: 13px 0 0;
  }
  .review h2 {
    font-size: 16px;
  }
  .review_list .review_item {
    padding: 3px 10px;
    height: auto;
  }
  .review_list .review_inner {
    padding: 18px 20px 24px 18px;
    /* border-radius: 4px; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5); */
    /* box-shadow: 3px 3px rgba(0, 0, 0, 0.12); */
  }
  .review_list .review_title {
    font-size: 14px;
    line-height: 20px;
  }
  .review_list .review_name {
    margin-bottom: 16px;
  }
  .review_list .review_name span {
    font-size: 12px;
    line-height: 18px;
  }
  .review_list .review_text .text {
    display: -webkit-box;
    height: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.26px;
    color: #4b5563;
  }
}
@media all and (max-width: 440px) {
  .review_list .review_text .text {
    display: -webkit-box;
    height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.26px;
    color: #4b5563;
  }
}
@media all and (max-width: 360px) {
  .info_link br {
    display: none;
  }
}
