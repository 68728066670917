.qna_wrap_color {
  /* background-color: rgba(219, 219, 219, 0.626); */
  /* padding: 40px 0; */
  border-top: 1px solid #b6b6b6b5;
}

.qna_wrap {
  width: 1024px;
  min-height: 700px;
  margin: 0 auto;
  /* background-color: red; */
  padding: 40px 0;
  background-color: #fff;
}

.qna_wrap h2 {
  font-size: 38px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 40px;
  /* background: red; */
}

.qna_content_wrap {
  width: 100%;
  /* min-height: 600px; */
  /* background-color: red; */
  margin: 0 auto;
  /* border-bottom: 1px solid #00c73c; */
}

.qna_content {
  width: 100%;
  height: auto;
  border-top: 2px solid #00c73c;
  border-bottom: 2px solid #00c73c;
}

.qna_content_wrap .qna_content_list {
  height: auto;
  padding: 10px 40px;
  display: flex;

  /* border-bottom: 1px solid #00c73c; */
}
.qna_content_wrap .qna_content_list:first-child,
.qna_content_wrap .qna_content_list:nth-child(2) {
  align-items: center;
}

.qna_content_wrap .qna_content_list:first-child .qna_list_right {
  padding: 0 10px;
}
.qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv {
  width: 100%;
  height: 34px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv input {
  font-size: 14px;
  padding: 0 10px;
  width: 100%;
  height: 100%;
  background: transparent;
}

.qna_content_wrap .qna_content_list:nth-child(3) {
  align-items: flex-start;
}
.qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv {
  width: 100%;
  height: 160px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv textarea {
  resize: none;
  background: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 10px;
  font-size: 14px;
}

.qna_content_wrap .qna_content_list:last-child {
  border-bottom: 0;
}
.qna_content_wrap .qna_content_list .qna_list_left {
  /* font-size: 16px; */
  /* padding: 10px 0; */
  width: 100px;
  /* border-right: 1px solid #00c73c; */
}
.qna_content_wrap .qna_content_list .qna_list_right {
  /* background-color: red; */
  width: 100%;
}
.qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv {
  width: 100%;
}
.qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv input {
  width: 100%;
}
.qna_content_wrap .qna_content_list:last-child input {
  /* font-size: 12px; */

  height: 40px;
  /* border: 1px solid red; */
  /* line-height: 20px; */
  /* background-color: black; */
}
.qna_content_wrap .qna_content_list:last-child span {
  font-size: 12px;
  width: 100%;
  display: block;
  height: 20px;
  /* border: 1px solid red; */
  line-height: 20px;
  /* background-color: black; */
}

.qna_content_wrap .qna_button_div {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  /* padding: 0 10px; */
}
.qna_button_div .qna_button {
  width: 120px;
  height: 38px;
  font-weight: 600;
  background-color: #00c73c;
  color: #fff;
  border-radius: 5px;
}
@media all and (max-width: 1100px) {
  .qna_wrap_color {
    /* background-color: rgba(219, 219, 219, 0.626); */
    /* padding: 40px 0; */
    border-top: none;
  }
  .qna_wrap {
    width: 100%;
    min-height: 600px;
    margin: 0 auto;

    padding: 30px 0;
    background-color: #fff;
  }
  .qna_wrap h2 {
    display: none;
  }

  .qna_content_wrap {
    width: 100%;

    margin: 0 auto;
  }

  .qna_content {
    width: 100%;
    height: auto;
    border-top: 2px solid #00c73c;
    border-bottom: 2px solid #00c73c;
  }

  .qna_content_wrap .qna_content_list {
    height: auto;
    padding: 10px 40px;
    display: flex;

    /* border-bottom: 1px solid #00c73c; */
  }
  .qna_content_wrap .qna_content_list:first-child,
  .qna_content_wrap .qna_content_list:nth-child(2) {
    align-items: center;
  }

  .qna_content_wrap .qna_content_list:first-child .qna_list_right {
    padding: 0 10px;
  }
  .qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv {
    width: 100%;
    height: 34px;
    border: 1px solid #cacaca;
    border-radius: 5px;
  }
  .qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv input {
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  .qna_content_wrap .qna_content_list:nth-child(3) {
    align-items: flex-start;
  }
  .qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv {
    width: 100%;
    height: 160px;
    border: 1px solid #cacaca;
    border-radius: 5px;
  }
  .qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv textarea {
    resize: none;
    background: transparent;
    width: 100%;
    height: 100%;
    outline: none;
    padding: 10px;
    font-size: 14px;
  }

  .qna_content_wrap .qna_content_list:last-child {
    border-bottom: 0;
  }
  .qna_content_wrap .qna_content_list .qna_list_left {
    /* font-size: 16px; */
    /* padding: 10px 0; */
    width: 100px;
    /* border-right: 1px solid #00c73c; */
  }
  .qna_content_wrap .qna_content_list .qna_list_right {
    /* background-color: red; */
    width: 100%;
  }
  .qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv {
    width: 100%;
  }
  .qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv input {
    width: 100%;
  }
  .qna_content_wrap .qna_content_list:last-child input {
    /* font-size: 12px; */

    height: 40px;
    /* border: 1px solid red; */
    /* line-height: 20px; */
    /* background-color: black; */
  }
  .qna_content_wrap .qna_content_list:last-child span {
    font-size: 12px;
    width: 100%;
    display: block;
    height: 20px;
    /* border: 1px solid red; */
    line-height: 20px;
    /* background-color: black; */
  }

  .qna_content_wrap .qna_button_div {
    width: 100%;
    text-align: right;
    margin-top: 5px;
    /* padding-right: 20px; */
    padding: 0 20px;
  }
  .qna_button_div .qna_button {
    width: 120px;
    height: 38px;
    font-weight: 600;
    background-color: #00c73c;
    color: #fff;
    border-radius: 5px;
    /* margin: 0 auto; */
  }
}
@media all and (max-width: 800px) {
  .qna_wrap {
    min-height: 540px;

    padding: 22px 0;
  }

  .qna_content_wrap {
    width: 100%;

    margin: 0 auto;
  }

  .qna_content {
    width: 100%;
    height: auto;
    border-top: 2px solid #00c73c;
    border-bottom: 2px solid #00c73c;
  }

  .qna_content_wrap .qna_content_list {
    padding: 6px 30px;

    /* border-bottom: 1px solid #00c73c; */
  }
  .qna_content_wrap .qna_content_list:first-child,
  .qna_content_wrap .qna_content_list:nth-child(2) {
    align-items: center;
  }

  .qna_content_wrap .qna_content_list:first-child .qna_list_right {
    padding: 0 10px;
  }
  .qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv {
    width: 100%;
    height: 34px;
    border: 1px solid #cacaca;
    border-radius: 5px;
  }
  .qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv input {
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  .qna_content_wrap .qna_content_list:nth-child(3) {
    align-items: flex-start;
  }
  .qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv {
    width: 100%;
    height: 160px;
    border: 1px solid #cacaca;
    border-radius: 5px;
  }
  .qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv textarea {
    resize: none;
    background: transparent;
    width: 100%;
    height: 100%;
    outline: none;
    padding: 10px;
    font-size: 14px;
  }

  .qna_content_wrap .qna_content_list:last-child {
    border-bottom: 0;
  }
  .qna_content_wrap .qna_content_list .qna_list_left {
    /* font-size: 16px; */
    /* padding: 10px 0; */
    width: 90px;
    /* border-right: 1px solid #00c73c; */
  }
  .qna_content_wrap .qna_content_list .qna_list_left p {
    font-size: 14px;
  }
  .qna_content_wrap .qna_content_list .qna_list_right {
    /* background-color: red; */
    width: 100%;
  }
  .qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv {
    width: 100%;
  }
  .qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv input {
    width: 100%;
  }

  .qna_content_wrap .qna_content_list:first-child span {
    font-size: 14px;
  }

  .qna_content_wrap .qna_content_list:last-child input {
    /* font-size: 12px; */

    font-size: 14px;
    height: 34px;
    /* border: 1px solid red; */
    /* line-height: 20px; */
    /* background-color: black; */
  }
  .qna_content_wrap .qna_content_list:last-child span {
    font-size: 12px;
    width: 100%;
    display: block;
    height: 20px;
    /* border: 1px solid red; */
    line-height: 20px;
    /* background-color: black; */
  }

  .qna_content_wrap .qna_button_div {
    width: 100%;
    text-align: right;
    margin-top: 5px;
    /* padding-right: 30px; */
    /* padding: 0 10px; */
  }
  .qna_button_div .qna_button {
    width: 110px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    background-color: #00c73c;
    color: #fff;
    border-radius: 5px;
    /* margin: 0 auto; */
  }
}
@media all and (max-width: 600px) {
}
