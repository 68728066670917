@media screen and (min-width: 1097px) {
  .policy_wrap {
    width: 744px;
    height: 100%;
    margin: auto;
  }
}

@media screen and (min-width: 769px) {
  .policy_wrap {
    width: 680px;
    height: 100%;
    margin: auto;
  }
}

.policy_wrap {
  position: relative;
  margin: 0 16px;
  padding-top: 48px;
}

.policy_wrap h1 {
  line-height: 40px;
  font-size: 32px;
  margin-bottom: 12px;
}
.policy_wrap p {
  margin-bottom: 24px;
}
.policy_wrap > .policy_update_div > h5 {
  margin-bottom: 6px;
}
.policy_wrap h5 {
  font-size: 17px;
  line-height: 28px;
  margin: 24px 0 6px;
}
.policy_wrap ul {
  margin: 16px 0 16px 16px;
}
.policy_wrap li {
  margin-bottom: 6px;
  line-height: 24px;
  word-break: keep-all;
  font-size: 15px;
}
.policy_wrap > .policy_update_div > ul {
  margin-top: 8px;
}
.policy_wrap ul > li {
  position: relative;
  padding-left: 12px;
  font-size: 15px;
}
.policy_wrap ul > li::before {
  display: block;
  position: absolute;
  top: 0.55em;
  left: 0;
  border-radius: 50%;
  background: #44484b;
  width: 3px;
  height: 3px;
  content: "";
}
.policy_wrap > .policy_update_div li {
  margin-bottom: 4px;
  line-height: 18px;
  font-size: 13px;
}

.policy_wrap > .policy_subtitle {
  margin: 24px 0 16px;
}

.policy_wrap > .policy_subtitle > li {
  margin-left: 0;
  list-style: none;
  color: #00c73c;
  cursor: pointer;
  font-size: 15px;
}

.policy_wrap > hr {
  margin: 30px 0 -16px;
  border: 0;
  background: #dde2e6;
  height: 1px;
}

.policy_wrap h2 {
  position: relative;
  line-height: 32px;
  font-size: 24px;
  font-weight: 700;
  margin: 48px 0 16px;
}

.policy_wrap h3 {
  margin: 32px 0 8px;
  font-weight: bold;
  font-size: 1.17em;
}

.policy_wrap p {
  font-size: 15px;
  font-weight: 400;
  margin: 16px 0;
  line-height: 24px;
  word-break: keep-all;
  margin-top: 0;
}

.policy_wrap ol {
  margin: 16px 0 16px 16px;
}

.policy_wrap ol > li {
  margin-left: 17px;
  list-style: decimal outside;
  font-size: 15px;
}
.policy_wrap ol > li::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}

.policy_wrap ol > li > ol {
  margin: 8px 0 12px 16px;
}
.policy_wrap ol > li > ol > li {
  list-style-type: lower-alpha;
  font-size: 15px;
}

.policy_table {
  margin: 24px -16px 32px;
  /* margin-left: calc(100% - 100vw + 16px); */
  overflow-x: auto;
}

.policy_wrap > .policy_table table {
  margin: 0 16px;
  border-width: 1px 0 0 1px;
  min-width: 680px;
  border-collapse: collapse;
  border-spacing: 0;
}

.policy_wrap > .policy_table table,
.policy_wrap > .policy_table th,
.policy_wrap > .policy_table td {
  border-style: solid;
  border-color: #dde2e6;
  border-width: 0 1px 1px 0;
  text-align: left;
  font-size: 15px;
}
.policy_wrap > .policy_table th {
  border-width: 0 1px 1px 0;
  text-align: left;
  font-size: 15px;
  border-style: solid;
  border-color: #dde2e6;
  background: #f0f2f5;
  padding: 12px;
  vertical-align: middle;
  font-weight: 500;
}
.policy_wrap > .policy_table td {
  border-width: 0 1px 1px 0;
  text-align: left;
  font-size: 15px;
  padding: 16px;
  vertical-align: top;
  word-break: keep-all;
  border-style: solid;
  border-color: #dde2e6;
}

.policy_wrap > .policy_table td ul {
  margin: 8px 0 12px;
}

.policy_wrap > .policy_table td ul:last-child {
  margin-bottom: 0;
}
