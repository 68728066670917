.ch_fixed {
  width: 350px;
  height: 500px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px 1px gray;
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: white;
}

.ch_fixed > .ch_header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: rgba(240, 240, 240, 0.8);
}

.ch_fixed > .ch_header > img {
  width: 15px;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}

.ch_fixed > .render-chat {
  height: 300px;
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 20px;
  -ms-overflow-style: none; /* IE or Edge */
  scrollbar-width: none; /* FireFox */
}
/* chrome or safari */
.ch_fixed > .render-chat::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}

.ch_fixed > .render-chat > .restart-btn-area {
  text-align: center;
}

.ch_fixed > .render-chat > .restart-btn-area > .restart-btn {
  background: white;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
}

.ch_fixed > h1 {
  text-align: center;
}

.ch_fixed .ch_words {
  display: flex;
  background-color: white;
  margin-bottom: 5px;
  align-items: center;
}
.ch_fixed .ch_words.ch_user {
  justify-content: flex-end;
}
.ch_fixed .ch_words > .ch_name {
  width: 38px;
  padding-left: 10px;
}

.ch_fixed .ch_words > .ch_msg {
  max-width: 300px;
  padding: 8px 10px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.85);
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 18px;
}

.ch_fixed .ch_words > .ch_time {
  font-size: 13px;
  min-width: 80px;
  margin: 0 5px;
}

.ch_fixed .ch_out_msg {
  text-align: center;
  border: 0;
  background: rgb(211, 211, 211);
  margin: 0 50px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 300;
}

.ch_fixed .ch_write_area {
  width: 90%;
  margin: auto;
  height: 35px;
  background-color: rgba(240, 240, 240, 0.8);
  border-radius: 10px;
}
.ch_fixed .ch_write_area > input {
  width: 250px;
  height: 35px;
  border: 0;
  line-height: 35px;
  margin-left: 15px;
  box-sizing: border-box;
  background-color: rgba(240, 240, 240, 0.8);
}

.ch_fixed .ch_write_area > input:focus {
  border: 0;
  outline: none;
}
