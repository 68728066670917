.footer-wrap {
  width: 100%;
  /* height: 360px; */

  min-height: 280px;
  /* border-bottom: 3px solid rgb(46, 107, 60); */
}
.footer-top {
  width: 100%;
  height: 40px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.footer-box {
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;

  /* background-color: red; */
}

.footer-box.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-box.bottom.flex {
  padding: 40px 0 60px 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.footer-top-left {
  width: auto;
}
.footer-box-ul {
  display: flex;
}
.footer-box-ul li {
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.footer-box-ul li svg {
  font-size: 20px;
  margin-left: 4px;
}
.footer-box-ul li a {
  cursor: pointer;
}

.footer-bottom-left {
  width: 265px;
  height: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.footer-service-box h4 {
  font-size: 18px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
}
.footer-service-box h4 svg {
  margin-right: 4px;
}
.footer-service-box li {
  font-size: 16px;
  margin-bottom: 15px;
  height: 30px;
}
.footer-service-box a {
  font-size: 16px;
  margin-bottom: 15px;
  height: 30px;
}
.footer-service-date {
  font-size: 14px;
  padding-top: 20px;
  /* background: red; */
  border-top: 1px solid #e2e2e2;
}
.footer-bottom-right {
  padding-left: 64px;
}
.footer-bottom-right address ul {
  display: flex;
  font-size: 13px;
  color: #000;
  width: 100%;
  flex-wrap: wrap;
}
.footer-bottom-right address ul li {
  position: relative;
  padding: 0 12px 3px 0;
}

.footer-bottom-right address ul li::after {
  position: absolute;
  top: 0;
  right: 0px;

  content: "│";
  display: block;
}
.footer-bottom-right address ul li:last-child::after {
  position: absolute;
  top: 0;
  right: -6px;

  content: "│";
  display: none;
}
.footer-bottom-right address ul.footer-info-bottom li:nth-child(2)::after {
  position: absolute;
  top: 0;
  right: -6px;

  content: "│";
  display: none;
}
.footer-bottom-right .footer-info-p {
  color: #90989f;
  font-size: 13px;
  line-height: 20px;
}
.footer-bottom-right .footer-info-pview {
  display: none;
  color: #90989f;
  font-size: 13px;
  line-height: 18px;
}
.footer-info-app {
  margin-top: 20px;
}
.footer-info-app ul {
  display: flex;
}
.footer-info-app ul li {
  margin-right: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.footer-info-app ul li svg {
  font-size: 16px;
  margin-right: 5px;
}

.ft-li-1,
.ft-li-2,
.ft-li-3,
.ft-li-4 {
  position: relative;
}

.footer-popup1,
.footer-popup2,
.footer-popup3,
.footer-popup4 {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 0px 8px 1px#EBEAEB;
  /* width: 140px; */

  bottom: -60px;
  border: 1px solid #e2e2e2;
  border-radius: 7px;
  padding: 5px 4px;
}
.footer-popup1 {
  width: 240px;
}
.footer-popup4 {
  width: 140px;
  left: -80px;
  bottom: -140px;
}
.footer-popup2 {
  width: 220px;
  bottom: -34px;
}
.footer-popup1 li,
.footer-popup2 li,
.footer-popup3 li,
.footer-popup4 li {
  padding: 0px 4px;
  width: 100%;
}
.footer-popup1 a,
.footer-popup2 a,
.footer-popup3 a,
.footer-popup4 a {
  display: block;
  width: 100%;
  text-indent: 14px;
  /* width: 100%; */
  font-size: 14px;
  text-align: left;
}

@media all and (max-width: 1300px) {
  .footer-box {
    max-width: 100%;
    padding: 0 20px;

    /* background-color: red; */
  }
  .footer-box.bottom.flex {
    padding: 40px 20px 60px;
  }
}
@media all and (max-width: 1100px) {
  .footer-wrap {
    height: auto;
  }
  .footer-bottom-right {
    padding-left: 24px;
  }
  .footer-box.bottom.flex {
    padding: 40px 20px 90px;
  }
}
@media all and (max-width: 760px) {
  .footer-wrap {
    height: auto;
  }
  .footer-top {
    height: auto;
  }
  .footer-top .footer-box {
    padding: 10px 0;
  }
  .footer-box.flex {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-top-left {
    width: 100%;
  }
  .footer-top .footer-box-ul {
    width: 560px;
    margin: 0 auto;
  }
  .footer-top .footer-box-ul li {
    /* height: px; */
  }
  .footer-box-ul {
    flex-wrap: wrap;
  }
  .footer-box-ul li {
    width: calc(50% - 10px);
    padding: 3px 8px;
    font-size: 14px;
  }
  .footer-box-ul li svg {
    font-size: 16px;
  }
  .footer-service-box h4 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .footer-service-box li {
    font-size: 14px;
    margin-bottom: 4px;
    height: 30px;
  }
  .footer-service-date {
    font-size: 12px;
  }
  .footer-top-right {
    width: 100%;
  }
  .footer-box.bottom.flex {
    padding: 10px 0 70px 0;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .footer-bottom-left {
    width: 560px;
    height: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .footer-service-date {
    padding: 10px 0;
  }
  .footer-bottom-right {
    padding: 10px 0 20px;
    width: 100%;
    border-top: 2px solid #e2e2e2;
  }
  .footer-bottom-right address {
    width: 560px;
    margin: 0 auto;
    font-size: 11px;
  }
  .footer-bottom-right .footer-info-p {
    width: 560px;
    margin: 0 auto;
  }
  .footer-bottom-right .footer-info-app {
    display: none;
  }
  .footer-bottom-right address ul {
    flex-wrap: wrap;
  }
  .footer-bottom-right address ul li {
    /* width: calc(33.3% - 10px); */
  }
  .footer-bottom-right .footer-info-p {
    color: #90989f;
    font-size: 10px;
    display: none;
  }
  .footer-bottom-right .footer-info-pview {
    display: block;
    width: 560px;
    margin: 0 auto;
    font-size: 10px;
  }

  .ft-li-1,
  .ft-li-2,
  .ft-li-3,
  .ft-li-4 {
    position: relative;
  }

  .footer-popup1,
  .footer-popup2,
  .footer-popup3,
  .footer-popup4 {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0px 0px 8px 1px#EBEAEB;
    /* width: 140px; */

    bottom: -60px;
    border: 1px solid #e2e2e2;
    border-radius: 7px;
    padding: 5px 4px;
  }
  .footer-popup1 {
    width: 240px;
  }
  .footer-popup4 {
    width: 140px;
    left: -80px;
    bottom: -140px;
  }
  .footer-popup2 {
    width: 220px;
    bottom: -34px;
  }
  .footer-popup1 li,
  .footer-popup2 li,
  .footer-popup3 li,
  .footer-popup4 li {
    padding: 0px 4px;
    width: 100%;
  }
  .footer-popup1 a,
  .footer-popup2 a,
  .footer-popup3 a,
  .footer-popup4 a {
    display: block;
    width: 100%;
    text-indent: 14px;
    /* width: 100%; */
    font-size: 14px;
    text-align: left;
  }
}
@media all and (max-width: 660px) {
  .footer-top .footer-box {
    padding: 10px 20px;
  }
  .footer-top .footer-box-ul {
    width: 100%;
  }

  .footer-box.bottom.flex {
    padding: 10px 20px 50px;
  }
  .footer-bottom-left {
    width: 100%;
  }
  .footer-bottom-right {
    padding: 10px 0 20px;
  }
  .footer-bottom-right address {
    width: 100%;
  }
  .footer-bottom-right .footer-info-pview {
    width: 100%;
    padding-left: 4px;
  }
}
