.funding_wrap {
  width: 1024px;
  height: auto;
  margin: 0 auto;
  padding: 40px 0 0px;
  margin-bottom: 100px;
  /* background-color: red; */
  /* padding: 30px 20px 40px; */
  /* background-color: rgba(219, 219, 219, 0.626); */
}
.funding_category_section {
  position: relative;
}
.funding_category_content {
  /* width: 100%; */
  width: 966px;
  height: 60px;
  margin: 0 auto;
  /* position: relative; */
}
.funding_category_section .funding_arrow_left {
  left: 0;
  position: absolute;
  z-index: 1;
  top: 24px;
  font-size: 24px;
  cursor: pointer;
  /* background-color: red; */
}
.funding_category_section .funding_arrow_right {
  right: 0;
  position: absolute;
  z-index: 1;
  top: 24px;
  font-size: 24px;
  cursor: pointer;
  /* background-color: red; */
}
.funding_category_content {
  /* background-color: red;  */
}

.funding_category_content .funding_category_li {
  width: 78px !important;
  height: 60px;
  text-align: center;
  cursor: pointer;
}
.funding_category_content .funding_category_li .funding_category_img {
  width: 70px;
  height: 40px;
  /* background-color: #f2f4f6; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 auto;
  /* line-height: 40px; */
}
.funding_category_content .funding_category_li .funding_category_img.active {
  border: 2px solid #00c73c;
  box-sizing: border-box;
}
.funding_category_content .funding_category_li .funding_category_img img {
  width: 100%;
  height: 100%;
}

/* .funding_category_content .funding_category_li .funding_category_img img {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
} */
.funding_category_content .funding_category_li .funding_category_h3 {
  font-size: 11px;
  padding: 3px 0;
  cursor: pointer;
  font-weight: 400;
}
.funding_content_section {
  margin-top: 38px;
  font-weight: 600;
}
.funding_content_section .funding_content_top {
  display: flex;
  justify-content: space-between;
}
.funding_content_section .funding_content_top .funding_content_title {
  font-size: 20px;
}
.funding_content_nav .funding_content_ul {
  display: flex;
  width: 300px;
  justify-content: space-between;
  /* background-color: red; */
  /* width: 380px; */
}
.funding_content_nav .funding_content_ul .funding_content_li {
  position: relative;
  padding: 0 4px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  /* background-color: red; */
}
.funding_content_nav
  .funding_content_ul
  .funding_content_li.funding_content_li_active {
  font-weight: 700;
  /* background-color: red; */
}
.funding_content_nav .funding_content_ul .funding_content_li::after {
  content: "｜";
  display: block;
  position: absolute;
  right: -8px;
  top: 0;
}
.funding_content_nav .funding_content_ul .funding_content_li:last-child::after {
  display: none;
}
.funding_content_inner {
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-top: 15px;
  /* margin: 0 auto; */
}
.funding_inner_list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 30px;
  /* padding: ; */
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: space-around; */
}
.funding_inner_list.content_not {
  width: 100%;
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  /* padding: ; */
  /* display: flex;
  flex-wrap: wrap; */
  /* justify-content: space-around; */
}
/* 
.funding_inner_list::after {
  content: "";
  display: block;
  clear: both;
} */

.funding_inner_item {
  /* width: 32%; */
  /* display: inline-block; */
  /* float: left; */
  /* width: calc(33.3% - 10px); */
  /* height: auto; */
  /* padding: 0 5px; */
  /* background-color: red; */
  cursor: pointer;
}

.funding_inner_item .funding_inner_imgbox {
  width: 100%;
  height: auto;
  padding-top: calc(185 / 325 * 100%);
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  /* margin: 22px 0; */
}
.funding_inner_item .funding_inner_imgbox img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  /* width: 100%; */
  object-fit: cover;
}
.funding_inner_imgbox .funding_detail_one {
  font-size: 10px;
  background-color: #00c73c;
  position: absolute;
  z-index: 1;
  top: 4px;
  left: 4px;
  color: #fff;
  padding: 0;
  line-height: 20px;
  padding: 0 5px;
  border-radius: 3px;
  height: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.funding_inner_imgbox .funding_detail_one > span {
  line-height: 20px !important;
  /* width: 100%;
  height: 100%; */
}
.funding_inner_imgbox .funding_heart {
  /* background-color: red; */
  z-index: 5;
  width: 25px;
  height: 25px;
  right: 15px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.funding_inner_imgbox .funding_heart_color {
  /* background-color: red; */
  z-index: 5;
  width: 25px;
  height: 25px;
  right: 15px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.funding_inner_imgbox .funding_heart svg {
  width: 24px;
  height: 24px;
  color: rgb(0, 0, 0, 0.4);
  transition: 0.5s;
}
.funding_inner_imgbox .funding_heart path {
  stroke: #fff;
  stroke-width: 60px;
}

.funding_inner_imgbox .funding_heart svg:hover {
  transition: 0.5s;
  /* color: red; */
  color: #00c73c;
}

.funding_inner_imgbox .funding_heart_color svg {
  width: 24px;
  height: 24px;
  /* color: red; */
  color: #00c73c;
  transition: 0.5s;
}
.funding_inner_imgbox .funding_heart_color path {
  stroke: #fff;
  stroke-width: 60px;
}

.funding_inner_imgbox .funding_heart_color svg:hover {
  transition: 0.5s;

  color: rgb(0, 0, 0, 0.4);
}

.funding_inner_imgbox .funding_detail_two {
  /* background-color: red; */
  height: 20px;

  /* background-color: #00c73c; */
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 15px;
  color: #fff;
  font-weight: 400;
  width: 65px;
}

.funding_inner_imgbox .funding_detail_back {
  width: 100%;
  height: 100%;
  position: relative;

  line-height: 18px;
  text-align: center;
}
.funding_inner_imgbox .funding_detail_back::before {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  border: 1px solid #fff;
  content: "";
  display: block;
  border-radius: 14px;
  opacity: 0.5;
}
.funding_inner_imgbox .funding_detail_back .funding_detail_back_1 {
  border-radius: 14px;
  border: 1px solid #fff;
  opacity: 0.5;
  width: 100%;
  height: 100%;
}
.funding_inner_imgbox .funding_detail_two span {
  /* padding-bottom: 2px; */
  line-height: 0;
  font-size: 18px;
  background-color: red;
  /* line-height: 1; */
  /* position: absolute;
  z-index: 1;
  top: 0;
  left: 0; */
  /* width: 65px;
  height: 20px; */
  /* margin-left: -32.5px; */
  /* line-height: 12p; */
  /* height: 20p; */
}

.funding_inner_imgbox .funding_detail_three {
  font-size: 16px;
  /* background-color: red; */

  /* background-color: #00c73c; */
  position: absolute;
  z-index: 1;
  bottom: 15px;
  right: 15px;
  color: #fff;
  font-weight: 400;
  /* padding: 0 10px; */
  background-color: #00c73c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
}
.funding_inner_imgbox .funding_detail_three span {
  line-height: 18px;
}
.funding_inner_cont {
  height: auto;
  padding: 10px 0;
}

.funding_inner_cont .funding_inner_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.funding_inner_left {
  /* font-weight: bold; */
}

.funding_span_green {
  font-size: 19px;
  color: #00c73c;
  margin-right: 3px;
}
.funding_inner_left em {
  font-weight: bold;
}
.funding_span_default {
  color: #b1b1b1;
  font-size: 13px;
}

.funding_inner_right {
  color: #929191;
  background: #f2f4f6;
  font-size: 13px;
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
}
.funding_inner_right span {
  line-height: 0;
  font-size: 10px;
}
.funding_inner_middle {
  font-size: 16px;
  margin-top: 12px;
  font-weight: normal;
}
.funding_inner_middle span {
  line-height: 30px;
  word-break: break-word;
}

.funding_inner_bottom {
  font-size: 14px;
  color: #929191;
}
.funding_inner_bottom span {
  font-weight: normal;
}

.funding_inner_item .funding_inner_imgbox.fund_skeleton_img {
  background-color: #f2f2f2;
  position: relative;
}

.funding_inner_item .funding_inner_imgbox.fund_skeleton_img::after {
  content: "";
  display: block;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd);
  animation: loading 2s infinite linear;
  z-index: 1;
}

.funding_inner_cont .funding_inner_top.fund_skeleton_div {
  background-color: #f2f2f2;
  position: relative;
  height: 30px;
  overflow: hidden;
}
.main_news_wrap
  .main_news_item
  .main_news_content
  .main_news_cont.main_skeleton_cont::after {
  content: "";
  display: block;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd);
  animation: loading 2s infinite linear;
  z-index: 1;
}
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  50%,
  100% {
    transform: translateX(460px);
  }
}

@media all and (max-width: 1100px) {
  .funding_wrap {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 40px 20px 0px;
    margin-bottom: 100px;
    /* background-color: red; */
    /* padding: 30px 20px 40px; */
    /* background-color: rgba(219, 219, 219, 0.626); */
  }
  .funding_category_section {
    position: relative;
  }
  .funding_category_content {
    /* width: 100%; */
    width: 100%;
    height: 60px;
    margin: 0 auto;
    /* position: relative; */
  }
  .funding_category_section .funding_arrow_left {
    display: none;
    /* background-color: red; */
  }
  .funding_category_section .funding_arrow_right {
    display: none;
    /* background-color: red; */
  }
  .funding_category_content {
    /* background-color: red;  */
  }

  .funding_category_content .funding_category_li {
    width: 78px !important;
    height: 60px;
    text-align: center;
    cursor: pointer;
  }
  .funding_category_content .funding_category_li .funding_category_img {
    width: 70px;
    height: 40px;
    background-color: #f2f4f6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    /* line-height: 40px; */
  }
  .funding_category_content .funding_category_li .funding_category_img.active {
    border: 2px solid #00c73c;
    box-sizing: border-box;
  }
  .funding_category_content .funding_category_li .funding_category_img svg {
    width: 29px;
    height: 29px;
  }

  .funding_category_content .funding_category_li .funding_category_img img {
    /* width: 100%;
    height: 40px; */
    box-sizing: border-box;
  }
  .funding_category_content .funding_category_li .funding_category_h3 {
    font-size: 11px;
    padding: 3px 0;
    cursor: pointer;
    font-weight: 400;
  }
  .funding_content_section {
    margin-top: 38px;
    font-weight: 600;
  }
  .funding_content_section .funding_content_top {
    display: flex;
    justify-content: space-between;
  }
  .funding_content_section .funding_content_top .funding_content_title {
    font-size: 20px;
  }
  .funding_content_nav .funding_content_ul {
    display: flex;
    width: 300px;
    justify-content: space-between;
    /* background-color: red; */
    /* width: 380px; */
  }
  .funding_content_nav .funding_content_ul .funding_content_li {
    position: relative;
    padding: 0 4px;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    /* background-color: red; */
  }
  .funding_content_nav
    .funding_content_ul
    .funding_content_li.funding_content_li_active {
    font-weight: 700;
    /* background-color: red; */
  }
  .funding_content_nav .funding_content_ul .funding_content_li::after {
    content: "｜";
    display: block;
    position: absolute;
    right: -8px;
    top: 0;
  }
  .funding_content_nav
    .funding_content_ul
    .funding_content_li:last-child::after {
    display: none;
  }
  .funding_content_inner {
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-top: 15px;
    /* margin: 0 auto; */
  }
  .funding_inner_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 30px;
    /* padding: ; */
    /* display: flex;
    flex-wrap: wrap; */
    /* justify-content: space-around; */
  }
  .funding_inner_list.content_not {
    width: 100%;
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;
    /* padding: ; */
    /* display: flex;
    flex-wrap: wrap; */
    /* justify-content: space-around; */
  }
  /* 
  .funding_inner_list::after {
    content: "";
    display: block;
    clear: both;
  } */

  .funding_inner_item {
    /* width: 32%; */
    /* display: inline-block; */
    /* float: left; */
    /* width: calc(33.3% - 10px); */
    /* height: auto; */
    /* padding: 0 5px; */
    /* background-color: red; */
    cursor: pointer;
  }

  .funding_inner_item .funding_inner_imgbox {
    width: 100%;
    height: auto;
    padding-top: calc(185 / 325 * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    /* margin: 22px 0; */
  }
  .funding_inner_item .funding_inner_imgbox img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* object-fit: contain; */
  }
  .funding_inner_imgbox .funding_detail_one {
    font-size: 10px;
    background-color: #00c73c;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    color: #fff;
    padding: 0;
    line-height: 20px;
    padding: 0 5px;
    border-radius: 3px;
    height: 20px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .funding_inner_imgbox .funding_detail_one > span {
    line-height: 20px !important;
    /* width: 100%;
    height: 100%; */
  }
  .funding_inner_imgbox .funding_heart {
    width: 22px;
    height: 22px;
    right: 13px;
    top: 13px;
  }
  .funding_inner_imgbox .funding_heart_color {
    width: 22px;
    height: 22px;
    right: 13px;
    top: 13px;
  }
  .funding_inner_imgbox .funding_heart svg {
    width: 21px;
    height: 21px;
    color: rgb(0, 0, 0, 0.4);
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart svg:hover {
    transition: 0.5s;
    color: #00c73c;
  }

  .funding_inner_imgbox .funding_heart_color svg {
    width: 24px;
    height: 24px;
    color: #00c73c;
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart_color path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart_color svg:hover {
    transition: 0.5s;

    color: rgb(0, 0, 0, 0.4);
  }

  .funding_inner_imgbox .funding_detail_two {
    /* background-color: red; */
    height: 20px;

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    width: 65px;
  }

  .funding_inner_imgbox .funding_detail_back {
    width: 100%;
    height: 100%;
    position: relative;

    line-height: 18px;
    text-align: center;
  }
  .funding_inner_imgbox .funding_detail_back::before {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border: 1px solid #fff;
    content: "";
    display: block;
    border-radius: 14px;
    opacity: 0.5;
  }
  .funding_inner_imgbox .funding_detail_back .funding_detail_back_1 {
    border-radius: 14px;
    border: 1px solid #fff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  .funding_inner_imgbox .funding_detail_two span {
    /* padding-bottom: 2px; */
    line-height: 0;
    font-size: 18px;
    background-color: red;
    /* line-height: 1; */
    /* position: absolute;
    z-index: 1;
    top: 0;
    left: 0; */
    /* width: 65px;
    height: 20px; */
    /* margin-left: -32.5px; */
    /* line-height: 12p; */
    /* height: 20p; */
  }

  .funding_inner_imgbox .funding_detail_three {
    font-size: 16px;
    /* background-color: red; */

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    /* padding: 0 10px; */
    background-color: #00c73c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
  }
  .funding_inner_imgbox .funding_detail_three span {
    line-height: 18px;
  }
  .funding_inner_cont {
    padding: 6px 0;
  }

  .funding_span_green {
    font-size: 17px;
  }

  .funding_span_default {
    color: #b1b1b1;
    font-size: 13px;
  }

  .funding_inner_right {
    color: #929191;
    background: #f2f4f6;
    font-size: 13px;
    padding: 0 10px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
  }
  .funding_inner_right span {
    line-height: 0;
    font-size: 10px;
  }
  .funding_inner_middle {
    font-size: 16px;
    margin-top: 7px;
    font-weight: normal;
  }
  .funding_inner_middle span {
    line-height: 24px;
  }

  .funding_inner_bottom {
    font-size: 14px;
    color: #929191;
  }

  .funding_inner_item .funding_inner_imgbox.fund_skeleton_img {
    background-color: #f2f2f2;
    position: relative;
  }

  .funding_inner_item .funding_inner_imgbox.fund_skeleton_img::after {
    content: "";
    display: block;
    width: 20%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #f2f2f2, #ddd);
    animation: loading 2s infinite linear;
    z-index: 1;
  }

  .funding_inner_cont .funding_inner_top.fund_skeleton_div {
    background-color: #f2f2f2;
    position: relative;
    height: 30px;
    overflow: hidden;
  }
  .main_news_wrap
    .main_news_item
    .main_news_content
    .main_news_cont.main_skeleton_cont::after {
    content: "";
    display: block;
    width: 20%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #f2f2f2, #ddd);
    animation: loading 2s infinite linear;
    z-index: 1;
  }
}
@media all and (max-width: 900px) {
  .funding_wrap {
    padding: 30px 20px 0px;
    margin-bottom: 80px;
  }

  .funding_category_content {
    /* width: 100%; */
    width: 100%;
    /* height: 52px; */

    overflow: hidden;
    /* position: relative; */
  }
  .funding_cate_slide {
    /* width: 120%; */
  }
  .funding_category_section .funding_arrow_left {
    display: none;
    /* background-color: red; */
  }
  .funding_category_section .funding_arrow_right {
    display: none;
    /* background-color: red; */
  }
  .funding_category_content {
    /* background-color: red;  */
  }

  .funding_category_content .funding_category_li {
    text-align: center;
    cursor: pointer;
  }
  .funding_category_content .funding_category_li .funding_category_img {
    width: 70px;

    border-radius: 6px;

    /* line-height: 40px; */
  }
  .funding_category_content .funding_category_li .funding_category_img.active {
    border: 2px solid #00c73c;
    box-sizing: border-box;
  }
  .funding_category_content .funding_category_li .funding_category_img svg {
  }

  .funding_category_content .funding_category_li .funding_category_img img {
    /* width: 100%; */
    /* height: 34px; */
  }
  .funding_category_content .funding_category_li .funding_category_h3 {
    font-size: 11px;
    padding: 3px 0;
  }
  .funding_content_section {
    margin-top: 34px;
  }

  .funding_content_section .funding_content_top .funding_content_title {
    font-size: 18px;
  }
  .funding_content_nav .funding_content_ul {
    display: flex;
    width: 260px;
    justify-content: space-between;
    /* background-color: red; */
    /* width: 380px; */
  }
  .funding_content_nav .funding_content_ul .funding_content_li {
    padding: 0 4px;

    font-size: 12px;
    font-weight: 400;

    /* background-color: red; */
  }
  .funding_content_nav
    .funding_content_ul
    .funding_content_li.funding_content_li_active {
    font-weight: 600;
    /* background-color: red; */
  }
  .funding_content_nav .funding_content_ul .funding_content_li::after {
    content: "｜";
    display: block;
    position: absolute;
    /* right: -10px; */
    top: 0;
  }
  .funding_content_nav
    .funding_content_ul
    .funding_content_li:last-child::after {
    display: none;
  }
  .funding_content_inner {
    margin-top: 13px;
    /* margin: 0 auto; */
  }
  .funding_inner_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 24px;
  }
  .funding_inner_list.content_not {
    width: 100%;
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;
  }
  /* 
  .funding_inner_list::after {
    content: "";
    display: block;
    clear: both;
  } */

  .funding_inner_item {
    cursor: pointer;
  }

  .funding_inner_item .funding_inner_imgbox {
    width: 100%;
    height: auto;
    padding-top: calc(185 / 325 * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    /* margin: 22px 0; */
  }
  .funding_inner_item .funding_inner_imgbox img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* object-fit: contain; */
  }
  .funding_inner_imgbox .funding_detail_one {
    font-size: 10px;
    background-color: #00c73c;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    color: #fff;
    padding: 0;
    line-height: 20px;
    padding: 0 5px;
    border-radius: 3px;
    height: 20px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .funding_inner_imgbox .funding_detail_one > span {
    line-height: 20px !important;
    /* width: 100%;
    height: 100%; */
  }
  .funding_inner_imgbox .funding_heart {
    /* background-color: red; */
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
  .funding_inner_imgbox .funding_heart_color {
    /* background-color: red; */
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
  .funding_inner_imgbox .funding_heart svg {
    width: 19px;
    height: 19px;
    color: rgb(0, 0, 0, 0.4);
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart svg:hover {
    transition: 0.5s;
    color: #00c73c;
  }

  .funding_inner_imgbox .funding_heart_color svg {
    width: 24px;
    height: 24px;
    color: #00c73c;
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart_color path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart_color svg:hover {
    transition: 0.5s;

    color: rgb(0, 0, 0, 0.4);
  }

  .funding_inner_imgbox .funding_detail_two {
    /* background-color: red; */
    height: 20px;

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    width: 65px;
  }

  .funding_inner_imgbox .funding_detail_back {
    width: 100%;
    height: 100%;
    position: relative;

    line-height: 18px;
    text-align: center;
  }
  .funding_inner_imgbox .funding_detail_back::before {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border: 1px solid #fff;
    content: "";
    display: block;
    border-radius: 14px;
    opacity: 0.5;
  }
  .funding_inner_imgbox .funding_detail_back .funding_detail_back_1 {
    border-radius: 14px;
    border: 1px solid #fff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  .funding_inner_imgbox .funding_detail_two span {
    /* padding-bottom: 2px; */
    line-height: 0;
    font-size: 18px;
    background-color: red;
    /* line-height: 1; */
    /* position: absolute;
    z-index: 1;
    top: 0;
    left: 0; */
    /* width: 65px;
    height: 20px; */
    /* margin-left: -32.5px; */
    /* line-height: 12p; */
    /* height: 20p; */
  }

  .funding_inner_imgbox .funding_detail_three {
    font-size: 16px;
    /* background-color: red; */

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    /* padding: 0 10px; */
    background-color: #00c73c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
  }
  .funding_inner_imgbox .funding_detail_three span {
    line-height: 18px;
  }
  .funding_inner_cont {
    padding: 6px 0;
  }

  .funding_span_green {
    font-size: 16px;
  }

  .funding_span_default {
    color: #b1b1b1;
    font-size: 11px;
  }

  .funding_inner_right {
    font-size: 10px;
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
  }
  .funding_inner_right span {
    line-height: 0;
    font-size: 10px;
  }
  .funding_inner_middle {
    font-size: 14px;
    margin-top: 7px;
    font-weight: normal;
  }
  .funding_inner_middle span {
    line-height: 20px;
  }

  .funding_inner_bottom {
    font-size: 12px;
    /* color: #929191; */
  }

  .funding_inner_bottom span {
    height: 22px;
    line-height: 22px;
    /* background-color: red; */
  }
}

@media all and (max-width: 760px) {
  .funding_wrap {
    padding: 30px 20px 0px;
    margin-bottom: 80px;
  }

  .funding_category_content {
    /* width: 100%; */
    width: 100%;

    overflow: hidden;
    /* position: relative; */
  }
  .funding_cate_slide {
    /* width: 160%; */
  }

  .funding_category_content .funding_category_li {
    text-align: center;
    cursor: pointer;
  }
  .funding_category_content .funding_category_li .funding_category_img {
    width: 70px;

    border-radius: 6px;

    /* line-height: 40px; */
  }
  .funding_category_content .funding_category_li .funding_category_img.active {
    border: 2px solid #00c73c;
    box-sizing: border-box;
  }
  .funding_category_content .funding_category_li .funding_category_img svg {
  }

  .funding_category_content .funding_category_li .funding_category_img img {
    /* width: 100%;
    height: 34px; */
  }
  .funding_category_content .funding_category_li .funding_category_h3 {
    font-size: 11px;
    padding: 3px 0;
  }
  .funding_content_section {
    margin-top: 24px;
  }

  .funding_content_section .funding_content_top .funding_content_title {
    font-size: 18px;
  }
  .funding_content_nav .funding_content_ul {
    display: flex;
    width: 260px;
    justify-content: space-between;
    /* background-color: red; */
    /* width: 380px; */
  }
  .funding_content_nav .funding_content_ul .funding_content_li {
    padding: 0 4px;

    font-size: 12px;
    font-weight: 400;

    /* background-color: red; */
  }
  .funding_content_nav
    .funding_content_ul
    .funding_content_li.funding_content_li_active {
    font-weight: 600;
    /* background-color: red; */
  }
  .funding_content_nav .funding_content_ul .funding_content_li::after {
    content: "｜";
    display: block;
    position: absolute;
    /* right: -10px; */
    top: 0;
  }
  .funding_content_nav
    .funding_content_ul
    .funding_content_li:last-child::after {
    display: none;
  }
  .funding_content_inner {
    margin-top: 13px;
    /* margin: 0 auto; */
  }
  .funding_inner_list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px 24px;
  }
  .funding_inner_list.content_not {
    width: 100%;
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;
  }
  /* 
  .funding_inner_list::after {
    content: "";
    display: block;
    clear: both;
  } */

  .funding_inner_item {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .funding_inner_item .funding_inner_imgbox {
    max-width: 160px;
    height: 100px;
    padding-top: 0;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    /* margin: 22px 0; */
  }
  .funding_inner_item .funding_inner_imgbox img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* object-fit: contain; */
  }
  .funding_inner_imgbox .funding_detail_one {
    font-size: 10px;
    background-color: #00c73c;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 4px;
    color: #fff;
    padding: 0;
    line-height: 20px;
    padding: 0 5px;
    border-radius: 3px;
    height: 20px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .funding_inner_imgbox .funding_detail_one > span {
    line-height: 20px !important;
    /* width: 100%;
    height: 100%; */
  }
  .funding_inner_imgbox .funding_heart {
    /* background-color: red; */
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
  .funding_inner_imgbox .funding_heart_color {
    /* background-color: red; */
    width: 20px;
    height: 20px;
    right: 10px;
    top: 10px;
  }
  .funding_inner_imgbox .funding_heart svg {
    width: 19px;
    height: 19px;
    color: rgb(0, 0, 0, 0.4);
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart svg:hover {
    transition: 0.5s;
    color: #00c73c;
  }

  .funding_inner_imgbox .funding_heart_color svg {
    width: 24px;
    height: 24px;
    color: #00c73c;
    transition: 0.5s;
  }
  .funding_inner_imgbox .funding_heart_color path {
    stroke: #fff;
    stroke-width: 60px;
  }

  .funding_inner_imgbox .funding_heart_color svg:hover {
    transition: 0.5s;

    color: rgb(0, 0, 0, 0.4);
  }

  .funding_inner_imgbox .funding_detail_two {
    /* background-color: red; */
    height: 20px;

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    width: 65px;
  }

  .funding_inner_imgbox .funding_detail_back {
    width: 100%;
    height: 100%;
    position: relative;

    line-height: 18px;
    text-align: center;
  }
  .funding_inner_imgbox .funding_detail_back::before {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    border: 1px solid #fff;
    content: "";
    display: block;
    border-radius: 14px;
    opacity: 0.5;
  }
  .funding_inner_imgbox .funding_detail_back .funding_detail_back_1 {
    border-radius: 14px;
    border: 1px solid #fff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  .funding_inner_imgbox .funding_detail_two span {
    /* padding-bottom: 2px; */
    line-height: 0;
    font-size: 18px;
    background-color: red;
    /* line-height: 1; */
    /* position: absolute;
    z-index: 1;
    top: 0;
    left: 0; */
    /* width: 65px;
    height: 20px; */
    /* margin-left: -32.5px; */
    /* line-height: 12p; */
    /* height: 20p; */
  }

  .funding_inner_imgbox .funding_detail_three {
    font-size: 16px;
    /* background-color: red; */

    /* background-color: #00c73c; */
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    color: #fff;
    font-weight: 400;
    /* padding: 0 10px; */
    background-color: #00c73c;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 58px;
    height: 58px;
  }
  .funding_inner_imgbox .funding_detail_three span {
    line-height: 18px;
  }

  .funding_inner_cont {
    padding: 6px 0;
    padding-left: 14px;
    width: 100%;
  }

  .funding_span_green {
    font-size: 14px;
  }

  .funding_span_default {
    color: #b1b1b1;
    font-size: 11px;
  }

  .funding_inner_right {
    font-size: 10px;
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
  }
  .funding_inner_right span {
    line-height: 0;
    font-size: 10px;
  }
  .funding_inner_middle {
    font-size: 14px;
    margin-top: 7px;
    font-weight: normal;
  }
  .funding_inner_middle span {
    line-height: 18px;
    font-size: 14px;
  }

  .funding_inner_bottom {
    font-size: 12px;
    /* color: #929191; */
  }

  .funding_inner_bottom span {
    height: 22px;
    line-height: 22px;
    /* background-color: red; */
  }
  .funding_inner_left span {
    line-height: 18px;
    /* font-weight: bold; */
  }
}
@media all and (max-width: 600px) {
  .funding_cate_slide {
    /* width: 210%; */
  }
}
@media all and (max-width: 400px) {
  .funding_inner_right {
    font-size: 10px;
    padding: 0 8px;
    height: 20px;
    line-height: 20px;
    border-radius: 2px;
  }
  .funding_inner_right span {
    line-height: 0;
    font-size: 8px;
  }
}
