.notice_wrap_color {
  /* background-color: rgba(219, 219, 219, 0.626); */
  /* padding: 40px 0; */
  /* border-top: 1px solid #b6b6b6b5; */
}

.notice_wrap {
  width: 1024px;
  min-height: 1120px;
  margin: 0 auto;
  /* background-color: red; */
  padding: 40px 0;
  background-color: #fff;
}

.notice_wrap h2 {
  font-size: 32px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 40px;
  /* background: red; */
}

.notice_content_wrap {
  width: 100%;
  /* min-height: 600px; */
  /* background-color: red; */
  margin: 0 auto;
  /* border-bottom: 1px solid #00c73c; */
}
.notice_tit ul {
  /* margin: 0 auto; */
  display: flex;
  width: 100%;
  height: 45px;
  justify-content: flex-start;
  font-size: 18px;
}
.notice_tit ul li {
  padding: 0 10px;

  /* background: beige; */
  /* text-align: center; */
  /* border: 1px solid red; */
}
.notice_tit_span.active::before {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  background-color: #00c73c;
  position: absolute;
  bottom: -3px;
  /* padding: 0 10px; */
}
.notice_tit_span {
  cursor: pointer;
  position: relative;

  /* width: 100%; */
  /* background-color: red; */
}
.notice_tit_span.active > a {
  color: #00c73c;
  font-weight: 500;
}
.notice_content_bottom {
  width: 80%;
  height: 35px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.notice_content_bottom button {
  width: 140px;
  height: 35px;
  margin: 0 auto;
  border: 1px solid #a9a9a9;
  color: #a9a9a9;
}
.notice_content {
  width: 100%;
  /* min-height: 600px; */
  border-top: 2px solid #00c73c;
  border-bottom: 2px solid #00c73c;
}
.notice_content_inner {
  width: 100%;
  /* height: 600px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #898989;
  /* background-color: red; */
  font-size: 15px;
  font-weight: 600;
}
.notice_content_inner.notice_content_no_data {
  height: 600px;
}
.notice_content .table_style {
  width: 100%;
  /* border-top: 1px solid #00c73c;
  border-bottom: 1px solid #00c73c; */
}
.notice_content .table_style tbody {
  /* display: block; */
}

.table_style .table_tr {
  border-bottom: 1px solid #cbcbcb;
  padding: 8px 20px;
  display: flex;
  width: 100%;

  /* display: table-row; */
}
.table_style .table_tr.table_tr_story {
  border-bottom: none;
  padding: 0;
  /* display: flex; */
  width: 100%;

  /* display: table-row; */
}

.table_style .table_tr.table_tr_notice {
  width: 100%;
  /* border-bottom: none; */
  /* padding: 0; */
}
.table_style .table_tr.table_tr_notice > div:nth-child(2) > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table_style .table_tr_story a {
  border-bottom: 1px solid #cbcbcb;
  padding: 8px 20px;
  display: flex;
  width: 100%;

  /* display: table-row; */
}
.table_style .table_tr.table_tr_story a > div:nth-child(2) > div {
  /* background-color: red; */
  /* width: 100%; */
  /* height: auto; */
  /* line-height: 50px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.table_style .table_tr.table_tr_story a > div:nth-child(3) {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
}

.table_style .table_tr .table_td {
  height: 82px;
  cursor: pointer;
}

.table_style tr td {
}
.table_style .table_tr .table_td:first-child {
  /* flex-basis: 134.2px; */
  /* float: left; */
  /* margin-left: 10px; */
  flex-shrink: 0;
  /* width: 20%; */
  width: 110px;
}
.table_style .table_tr .table_td:first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.table_style .table_tr .table_td:nth-child(2) {
  /* float: left; */
  /* flex-basis: 50%; */
  font-size: 20px;
  padding: 0 15px;
  flex-grow: 1;
  font-weight: 400;
  /* background: red; */
}
.table_style .table_tr .table_td:nth-child(3) {
  flex-shrink: 0;
  width: 120px;
  height: 70px;
  /* background-color: red; */
  /* flex-basis: 120px; */
  /* text-align: center; */
  /* line-height: 100px; */
}
.table_style .table_tr.table_tr_story .table_td:last-child {
  flex-shrink: 0;
  width: 120px;
  height: auto;
}
.table_pagination {
  width: 100%;
  /* margin: 0 auto; */
  text-align: center;
  margin-top: 5px;
}
.notice_content_title {
  /* height: 100%; */
  width: 100%;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.notice_content_preview {
  overflow: hidden;
  height: auto;
  text-overflow: ellipsis;
  display: -webkit-box;
  margin-top: 3px;
  line-height: 20px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-weight: normal !important;
}
/* 공지 뷰 페이지 */
.notice_content_view {
  width: 100%;
  /* border-top: 1px solid #00c73c; */
  border-bottom: 1px solid #00c73c;

  overflow: hidden;
}

.notice_view_tite {
  border-bottom: 1px solid #00c73c;
  display: flex;
  height: auto;
  align-items: center;
  padding: 10px;
  /* background-color: #00c73c; */
  color: black;
}
/* .notice_view_bottom {
  height: 50px;

  background-color: #00c73c;
} */
/* .notice_view_tite > div {
  flex-shrink: 0;
  width: 70px;
  position: relative;
}
.notice_view_tite > div::before {
  content: "";
  display: block;
  width: 2px;
  height: 50px;
  background: #fff;
  right: 0;
  bottom: -5px;

  position: absolute;
}
.notice_view_tite > div > span {
  width: 60px;
  height: 40px;
  margin: 0 auto;
  display: block;
}
.notice_view_tite > div > span > img {
  object-fit: contain;
  width: 100%;
} */
.notice_view_tite p {
  padding-left: 10px;

  font-weight: 500;
  line-height: 30px;
  font-size: 24px;
  flex-grow: 1;
}
.notice_view_tite > span {
  flex-shrink: 0;
  width: 90px;
  /* background: beige; */
  text-align: right;
  height: 40px;
  line-height: 40px;
  /* line-height: 80px; */
}
.notice_view_cont {
  padding: 10px 20px;
  min-height: 460px;
  /* text-align: center; */
}
.notice_date_div {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.notice_date_em1 {
  color: #00c73c;
  font-weight: 600;
  font-size: 12px;
  margin-right: 4px;
}
.notice_date_em2 {
  color: rgb(164, 164, 164);
  font-weight: 600;
  font-size: 14px;
  margin-right: 4px;
}
@media all and (max-width: 1100px) {
  .notice_wrap_color {
    /* background-color: rgba(219, 219, 219, 0.626); */
    /* padding: 40px 0; */
    border-top: none;
  }

  .notice_wrap {
    min-height: 1000px;
    width: 100%;
    /* background-color: red; */
    padding: 30px 0;
    background-color: #fff;
  }

  .notice_wrap h2 {
    display: none;
    /* background: red; */
  }

  .notice_content_wrap {
    width: 100%;
    /* min-height: 600px; */
    /* background-color: red; */
    margin: 0 auto;
    /* border-bottom: 1px solid #00c73c; */
  }
  .notice_tit ul {
    /* margin: 0 auto; */
    display: flex;
    width: 100%;
    height: 45px;
    justify-content: flex-start;
    font-size: 18px;
    padding: 0 20px;
  }
  .notice_tit ul li {
    padding: 0 10px;

    /* background: beige; */
    /* text-align: center; */
    /* border: 1px solid red; */
  }
  .notice_tit_span.active::before {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: #00c73c;
    position: absolute;
    bottom: -3px;
    /* padding: 0 10px; */
  }
  .notice_tit_span {
    cursor: pointer;
    position: relative;

    /* width: 100%; */
    /* background-color: red; */
  }
  .notice_tit_span.active > a {
    color: #00c73c;
    font-weight: 500;
  }
  .notice_content_bottom {
    width: 80%;
    height: 35px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .notice_content_bottom button {
    width: 140px;
    height: 35px;
    margin: 0 auto;
    border: 1px solid #a9a9a9;
    color: #a9a9a9;
  }
  .notice_content {
    width: 100%;
    /* min-height: 600px; */
    border-top: 2px solid #00c73c;
    border-bottom: 2px solid #00c73c;
  }
  .notice_content_inner {
    width: 100%;
    /* height: 600px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #898989;
    /* background-color: red; */
    font-size: 15px;
    font-weight: 600;
  }

  .notice_content .table_style {
    width: 100%;
    /* border-top: 1px solid #00c73c;
    border-bottom: 1px solid #00c73c; */
  }
  .notice_content .table_style tbody {
    /* display: block; */
  }

  .table_style .table_tr {
    border-bottom: 1px solid #cbcbcb;
    padding: 8px 20px;
    display: flex;
    width: 100%;

    /* display: table-row; */
  }
  .table_style .table_tr .table_td {
    height: 82px;
    cursor: pointer;
  }

  .table_style tr td {
  }
  .table_style .table_tr .table_td:first-child {
    /* flex-basis: 134.2px; */
    /* float: left; */
    /* margin-left: 10px; */
    flex-shrink: 0;
    /* width: 20%; */
    width: 110px;
  }
  .table_style .table_tr .table_td:first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .table_style .table_tr .table_td:nth-child(2) {
    /* float: left; */
    /* flex-basis: 50%; */
    font-size: 20px;
    padding: 0 15px;
    flex-grow: 1;
    font-weight: 400;
    /* width: 90%; */
    /* background-color: red; */
    /* background: red; */
  }
  .table_style .table_tr .table_td:nth-child(3) {
    flex-shrink: 0;
    width: 120px;
    height: 70px;
    /* background-color: red; */
    /* flex-basis: 120px; */
    /* text-align: center; */
    /* line-height: 100px; */
  }

  .table_pagination {
    width: 100%;
    /* margin: 0 auto; */
    text-align: center;
    margin-top: 5px;
  }
  .notice_content_title {
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  /* 공지 뷰 페이지 */
  .notice_content_view {
    width: 100%;
    border-top: none;
    border-bottom: none;
    border-radius: 0;
    overflow: hidden;
    padding: 0 20px;
  }

  .notice_view_tite {
    border-bottom: 1px solid #00c73c;
    display: flex;
    height: auto;
    align-items: center;
    padding: 7px 10px;
    /* background-color: #00c73c;
    color: #fff; */
  }
  .notice_view_bottom {
    height: 50px;

    /* background-color: #00c73c; */
  }
  .notice_view_tite > div {
    flex-shrink: 0;
    width: 60px;
    position: relative;
  }
  .notice_view_tite > div::before {
    content: "";
    display: block;
    width: 2px;
    height: 50px;
    background: #fff;
    right: 0;
    bottom: -5px;
    /* top: 50%;
    margin-top: -15px; */
    position: absolute;
  }
  .notice_view_tite > div > span {
    width: 60px;
    height: 40px;
    margin: 0 auto;
    display: block;
  }
  .notice_view_tite > div > span > img {
    object-fit: contain;
    width: 100%;
  }
  .notice_view_tite p {
    /* padding-left: 20px; */
    /* text-indent: 20px; */
    font-weight: 500;
    line-height: 30px;
    font-size: 20px;
    flex-grow: 1;
  }
  .notice_view_tite > span {
    flex-shrink: 0;
    width: 90px;
    /* background: beige; */
    text-align: right;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    /* line-height: 80px; */
  }
  .notice_view_cont {
    padding: 10px 5px;
    min-height: 460px;
    text-align: center;
    border-bottom: 1px solid #00c73c;
  }
  .notice_date_div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .notice_date_em1 {
    color: #00c73c;
    font-weight: 600;
    font-size: 12px;
    margin-right: 4px;
  }
  .notice_date_em2 {
    color: rgb(164, 164, 164);
    font-weight: 600;
    font-size: 14px;
    margin-right: 4px;
  }
}
@media all and (max-width: 800px) {
  .notice_wrap {
    min-height: 860px;

    padding: 22px 0;
  }

  .notice_tit ul {
    /* margin: 0 auto; */
    display: flex;
    width: 100%;
    height: 36px;
    justify-content: flex-start;
    font-size: 14px;
    padding: 0 14px;
  }
  .notice_tit ul li {
    padding: 0 6px;

    /* background: beige; */
    /* text-align: center; */
    /* border: 1px solid red; */
  }
  .notice_tit_span.active::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #00c73c;
    position: absolute;
    bottom: 0px;
    /* padding: 0 10px; */
  }
  .notice_tit_span {
    cursor: pointer;
    position: relative;

    /* width: 100%; */
    /* background-color: red; */
  }
  .notice_tit_span.active > a {
    color: #00c73c;
    font-weight: 500;
  }
  .notice_content_bottom {
    width: 80%;
    height: 35px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .notice_content_bottom button {
    width: 140px;
    height: 35px;
    margin: 0 auto;
    border: 1px solid #a9a9a9;
    color: #a9a9a9;
  }
  .notice_content {
    width: 100%;
    /* min-height: 600px; */
    border-top: 2px solid #00c73c;
    border-bottom: 2px solid #00c73c;
  }
  .notice_content_inner {
    width: 100%;
    /* height: 600px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #898989;
    /* background-color: red; */
    font-size: 15px;
    font-weight: 600;
  }

  .notice_content .table_style {
    width: 100%;
    /* border-top: 1px solid #00c73c;
    border-bottom: 1px solid #00c73c; */
  }
  .notice_content .table_style tbody {
    /* display: block; */
  }

  .table_style .table_tr {
    border-bottom: 1px solid #cbcbcb;
    padding: 8px 20px;
    display: flex;
    width: 100%;

    /* display: table-row; */
  }
  .table_style .table_tr .table_td {
    height: 82px;
    cursor: pointer;
    /* background-color: red; */
  }

  .table_style tr td {
  }
  .table_style .table_tr .table_td:first-child {
    /* flex-basis: 134.2px; */
    /* float: left; */
    /* margin-left: 10px; */
    flex-shrink: 0;
    /* width: 20%; */
    width: 110px;
  }
  .table_style .table_tr .table_td:first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .table_style .table_tr .table_td:nth-child(2) {
    /* float: left; */
    /* flex-basis: 50%; */
    font-size: 16px;
    padding: 0 15px;
    flex-grow: 1;
    font-weight: 400;
    /* line-height: 40px; */
    /* width: 90%; */
    /* background-color: red; */
    /* background: red; */
  }
  .table_style .table_tr .table_td:nth-child(3) {
    flex-shrink: 0;
    width: 120px;
    height: 70px;
    /* background-color: red; */
    /* flex-basis: 120px; */
    /* text-align: center; */
    /* line-height: 100px; */
  }

  .table_pagination {
    width: 100%;
    /* margin: 0 auto; */
    text-align: center;
    margin-top: 5px;
  }
  .notice_content_title {
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  /* 공지 뷰 페이지 */
  .notice_content_view {
    width: 100%;

    overflow: hidden;
  }

  .notice_view_tite {
    border-bottom: 1px solid #00c73c;
    display: flex;
    height: auto;
    align-items: center;
    padding: 5px 10px;
    /* background-color: #00c73c; */
    /* color: #fff; */
  }
  .notice_view_bottom {
    height: 40px;

    background-color: #00c73c;
  }
  .notice_view_tite > div {
    flex-shrink: 0;
    /* width: 70px; */
    position: relative;
  }
  .notice_view_tite > div::before {
    content: "";
    display: block;
    width: 2px;
    height: 50px;
    background: #fff;
    right: 0;
    bottom: -5px;
    /* top: 50%;
    margin-top: -15px; */
    position: absolute;
  }
  .notice_view_tite > div > span {
    width: 60px;
    height: 30px;
    margin: 0 auto;
    display: block;
  }
  .notice_view_tite > div > span > img {
    object-fit: contain;
    width: 100%;
  }
  .notice_view_tite p {
    /* padding-left: 20px; */
    /* text-indent: 20px; */
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
    flex-grow: 1;
  }
  .notice_view_tite > span {
    flex-shrink: 0;
    width: 90px;
    /* background: beige; */
    text-align: right;
    height: 40px;
    line-height: 40px;
    /* line-height: 80px; */
  }
  .notice_view_cont {
    padding: 10px 5px;
    min-height: 460px;
    text-align: center;
  }
  .notice_date_div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  .notice_date_em1 {
    color: #00c73c;
    font-weight: 600;
    font-size: 12px;
    margin-right: 4px;
  }
  .notice_date_em2 {
    color: rgb(164, 164, 164);
    font-weight: 600;
    font-size: 14px;
    margin-right: 4px;
  }
}
@media all and (max-width: 600px) {
  .notice_wrap {
    min-height: auto;

    padding: 10px 0;
    /* background-color: red; */
  }

  .notice_tit ul {
    /* margin: 0 auto; */
    display: flex;
    width: 100%;
    height: 36px;
    justify-content: flex-start;
    font-size: 14px;
    padding: 0 10px;
  }
  .notice_tit ul li {
    padding: 0 6px;

    /* background: beige; */
    /* text-align: center; */
    /* border: 1px solid red; */
  }
  .notice_tit_span.active::before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #00c73c;
    position: absolute;
    bottom: 0px;
    /* padding: 0 10px; */
  }
  .notice_tit_span {
    cursor: pointer;
    position: relative;

    /* width: 100%; */
    /* background-color: red; */
  }
  .notice_tit_span.active > a {
    color: #00c73c;
    font-weight: 500;
  }
  .notice_content_bottom {
    width: 80%;
    height: 35px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .notice_content_bottom button {
    width: 140px;
    height: 35px;
    margin: 0 auto;
    border: 1px solid #a9a9a9;
    color: #a9a9a9;
  }
  .notice_content {
    width: 100%;
    /* min-height: auto; */
    border-top: 2px solid #00c73c;
    border-bottom: 2px solid #00c73c;
    /* background-color: red; */
  }
  .notice_content_inner {
    width: 100%;
    /* height: 500px; */

    display: flex;
    justify-content: center;
    align-items: center;
    color: #898989;
    /* background-color: red; */
    font-size: 15px;
    font-weight: 600;
  }
  .notice_content_inner.notice_content_no_data {
    height: 500px;
  }
  .notice_content .table_style {
    width: 100%;
    /* border-top: 1px solid #00c73c;
    border-bottom: 1px solid #00c73c; */
  }
  .notice_content .table_style tbody {
    /* display: block; */
  }

  .table_style .table_tr {
    border-bottom: 1px solid #cbcbcb;
    padding: 8px 20px;
    display: flex;
    width: 100%;

    /* display: table-row; */
  }
  .table_style .table_tr .table_td {
    height: 60px;
    cursor: pointer;
    /* background-color: red; */
  }

  .table_style tr td {
  }
  .table_style .table_tr .table_td:first-child {
    /* flex-basis: 134.2px; */
    /* float: left; */
    /* margin-left: 10px; */
    flex-shrink: 0;
    /* width: 20%; */
    width: 80px;
  }
  .table_style .table_tr .table_td:first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .table_style .table_tr .table_td:nth-child(2) {
    /* float: left; */
    /* flex-basis: 50%; */
    font-size: 14px;
    padding: 0 10px;
    flex-grow: 1;
    font-weight: 400;
    /* line-height: 40px; */
    /* width: 90%; */
    /* background-color: red; */
    /* background: red; */
  }
  .table_style .table_tr .table_td:nth-child(3) {
    flex-shrink: 0;
    width: 60px;
    height: auto;
    /* background-color: red; */
    /* flex-basis: 120px; */
    /* text-align: center; */
    /* line-height: 100px; */
  }
  .table_style .table_tr.table_tr_story .table_td:last-child {
    flex-shrink: 0;
    width: 60px;
    height: auto;
    /* background-color: red; */
    /* flex-basis: 120px; */
    /* text-align: center; */
    /* line-height: 100px; */
  }

  .table_pagination {
    width: 100%;
    /* margin: 0 auto; */
    text-align: center;
    margin-top: 5px;
  }
  .notice_content_title {
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  /* 공지 뷰 페이지 */
  .notice_content_view {
    width: 100%;

    overflow: hidden;
  }

  .notice_view_tite {
    border-bottom: 1px solid #00c73c;
    display: flex;
    height: auto;
    align-items: center;
    padding: 5px 5px;
    /* background-color: #00c73c; */
    /* color: #fff; */
  }
  .notice_view_bottom {
    height: 40px;

    background-color: #00c73c;
  }
  .notice_view_tite > div {
    flex-shrink: 0;
    /* width: 70px; */
    position: relative;
  }
  .notice_view_tite > div::before {
    content: "";
    display: block;
    width: 2px;
    height: 40px;
    background: #fff;
    right: 0;
    bottom: -5px;
    /* top: 50%;
    margin-top: -15px; */
    position: absolute;
  }
  .notice_view_tite > div > span {
    width: 40px;
    /* background-color: red; */
    height: 30px;
    margin: 0 auto;
    display: block;
  }
  .notice_view_tite > div > span > img {
    object-fit: contain;
    width: 100%;
  }
  .notice_view_tite p {
    padding-left: 5px;
    /* text-indent: 20px; */
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    flex-grow: 1;
  }
  .notice_view_tite > span {
    flex-shrink: 0;
    width: 55px;
    /* background-color: red; */
    /* background: beige; */
    text-align: right;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    /* line-height: 80px; */
  }
  .notice_view_cont {
    padding: 10px 5px;
    min-height: 460px;
    text-align: center;
  }
  .notice_date_div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    /* background-color: red; */
  }
  .notice_date_em1 {
    color: #00c73c;
    font-weight: 600;
    font-size: 10px;
    margin-right: 0px;
    /* background-color: black; */
  }
  .notice_date_em2 {
    color: rgb(164, 164, 164);
    font-weight: 600;
    font-size: 10px;
    margin-right: 0px;
  }
  .notice_date_div time {
    font-size: 12px;
  }
}
