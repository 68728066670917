.intro_wrap {
  width: 100%;
  min-height: 800px;
  /* padding: 70px 20px 40px; */
  /* background-color: rgba(219, 219, 219, 0.626); */
}
.intro_banner_wrap {
  width: 100%;
  height: 580px;
  /* background: url("../assets//images//Canvas\ –\ 1.png") no-repeat;
  background-size: cover; */
  /* position: relative; */
}

.intro_banner_wrap .intro_banner_inner {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  height: 100%;
  /* height: 795px; */
  /* background-color: beige; */
  justify-content: flex-start;
  align-items: center;
  /* flex-direction: column; */
}
.intro_banner_wrap .intro_banner_inner .intro_banner_text {
  /* background-color: red; */
  /* height: auto; */
  padding-left: 140px;
  padding-top: 150px;
  color: #fff;
}
.intro_banner_wrap .intro_banner_inner .intro_banner_text p {
  font-size: 47px;
  line-height: 55px;
  font-weight: 700;
}
.intro_banner_wrap .intro_banner_inner .intro_banner_text span {
  font-size: 17px;
  margin-top: 10px;
  font-weight: 500;
}
.intro_common_wrap {
  border-bottom: 1px solid rgb(202, 202, 202);
}

.intro_common_wrap.intro_common_last {
  border-bottom: none;
  height: auto;
}

.intro_common {
  /* margin-top: 125px; */
  max-width: 1010px;
  margin: 0 auto;
  margin-top: 124px;
}
.intro_common.intro_com_last {
  margin-top: 100px;
}
.intro_common .title {
  font-size: 17px;
  color: #00c73c;
  font-family: "Myriad-pro";
  font-weight: 600;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
}
.intro_content {
  padding-bottom: 120px;
  display: flex;
  margin-top: 38px;
}
.intro_content.intro_content_last {
  padding-bottom: 100px;
}
.intro_content > div {
  flex: 1;
}
.intro_content .content_1 p {
  font-size: 30px;
  font-weight: 700;
}
.intro_content .content_2 .content_text {
  /* font-size: 30px; */
  max-width: 320px;
  font-size: 16px;
  font-weight: bold;
}
.intro_content .content_2 .content_text_detail {
  /* font-size: 30px; */
  max-width: 320px;
  font-size: 12px;
  margin-top: 42px;
}
.intro_content .content_2 .content_text_detail span {
  display: block;
}
.intro_content .content_2 .content_text_detail em {
  display: block;
  font-size: 36px;
  color: #00c73c;
  font-weight: bold;
}

.intro_common.intro_bottom_section {
  margin-top: 70px;
}

.intro_common.intro_bottom_section .intro_content {
  padding-bottom: 70px;
}

/* .intro_content {
  padding-bottom: 120px;
  display: flex;
  margin-top: 25px;
} */

.intro_common.intro_bottom_section .content_bottom_detail {
  height: auto;
  margin-bottom: 20px;
}
.intro_common.intro_bottom_section .content_bottom_detail span {
  display: block;
  font-size: 14px;
  color: #99a0a6;
}

.intro_common.intro_bottom_section .content_bottom_detail em {
  display: block;
  font-size: 36px;
  color: #00c73c;
  font-weight: bold;
}
.intro_common.intro_bottom_section .intro_img_box {
  border-radius: 5px;
  max-width: 278px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #ededed;
}
.intro_customer_img_box {
  max-width: 440px;
  height: auto;
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.intro_customer_img_box img {
  max-width: 142px;
}
/* .intro_customer_img_box .intro_img1 {
  max-width: 127px;
}
.intro_customer_img_box .intro_img2 {
  max-width: 142px;
} */

@media all and (max-width: 1300px) {
  .intro_banner_wrap {
    width: 100%;
    height: 400px;
    background: url("../assets//images//Canvas\ –\ 1.png") no-repeat;
    background-size: cover;
    /* position: relative; */
  }

  .intro_banner_wrap .intro_banner_inner {
    max-width: 100%;
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text {
    /* background-color: red; */
    /* height: auto; */
    padding-left: 80px;
    padding-top: 90px;
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text p {
    font-size: 42px;
    line-height: 50px;
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text span {
    font-size: 14px;
    margin-top: 8px;

    line-height: 20px;
  }

  .intro_common {
    /* margin-top: 125px; */
    max-width: 100%;
    margin: 0 auto;
    margin-top: 100px;
  }
  .intro_common.intro_com_last {
    margin-top: 100px;
  }
  .intro_common .title {
    font-size: 17px;
    color: #00c73c;
    font-family: "Myriad-pro";
    font-weight: 600;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
    padding-left: 80px;
  }
  .intro_content {
    padding-bottom: 120px;
    display: flex;
    margin-top: 30px;
  }
  .intro_content.intro_content_last {
    padding-bottom: 100px;
  }
  .intro_content > div {
    flex: 1;
  }
  .intro_content .content_1 p {
    font-size: 30px;
    font-weight: 700;
    text-indent: 80px;
  }
  .intro_content .content_2 .content_text {
    /* font-size: 30px; */
    max-width: 320px;
    font-size: 16px;
    font-weight: bold;
  }
  .intro_content .content_2 .content_text_detail {
    /* font-size: 30px; */
    max-width: 320px;
    font-size: 12px;
    margin-top: 42px;
  }
  .intro_content .content_2 .content_text_detail span {
    display: block;
  }
  .intro_content .content_2 .content_text_detail em {
    display: block;
    font-size: 36px;
    color: #00c73c;
    font-weight: bold;
  }

  .intro_common.intro_bottom_section {
    margin-top: 70px;
  }

  .intro_common.intro_bottom_section .intro_content {
    padding-bottom: 70px;
  }

  /* .intro_content {
    padding-bottom: 120px;
    display: flex;
    margin-top: 25px;
  } */

  .intro_content .content_1 .content_bottom_detail,
  .intro_content .content_1 .intro_img_box {
    margin-left: 80px;
  }

  .intro_common.intro_bottom_section .content_bottom_detail {
    height: auto;
    margin-bottom: 20px;
  }
  .intro_common.intro_bottom_section .content_bottom_detail span {
    display: block;
    font-size: 14px;
    color: #99a0a6;
  }

  .intro_common.intro_bottom_section .content_bottom_detail em {
    display: block;
    font-size: 36px;
    color: #00c73c;
    font-weight: bold;
  }
  .intro_common.intro_bottom_section .intro_img_box {
    border-radius: 5px;
    max-width: 278px;
    overflow: hidden;
    cursor: pointer;
  }
  .intro_customer_img_box {
    max-width: 440px;
    height: auto;
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
  }
  .intro_customer_img_box img {
    max-width: 142px;
  }

  /* .intro_customer_img_box .intro_img1 {
    max-width: 127px;
  }
  .intro_customer_img_box .intro_img2 {
    max-width: 142px;
  } */
}
@media all and (max-width: 1100px) {
  .intro_banner_wrap {
    width: 100%;
    height: 380px;
    background: url("../assets//images//Canvas\ –\ 1.png") no-repeat;
    background-size: cover;
    /* position: relative; */
  }

  .intro_banner_wrap .intro_banner_inner {
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text {
    /* background-color: red; */
    /* height: auto; */
    padding-left: 80px;
    padding-top: 50px;
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text p {
    font-size: 30px;
    line-height: 40px;
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text span {
    font-size: 14px;
    margin-top: 8px;

    line-height: 20px;
  }

  .intro_common {
    /* margin-top: 125px; */
    max-width: 100%;
    margin: 0 auto;
    margin-top: 60px;
  }
  .intro_common.intro_com_last {
    margin-top: 60px;
  }
  .intro_common .title {
    font-size: 14px;
    color: #00c73c;
    font-family: "Myriad-pro";
    font-weight: 600;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
    padding-left: 80px;
  }
  .intro_content {
    padding-bottom: 80px;
    display: flex;
    margin-top: 30px;
    padding-left: 80px;
  }
  .intro_content.intro_content_last {
    padding-bottom: 60px;
  }
  .intro_content > div {
    flex: 1;
  }
  .intro_content .content_1 p {
    font-size: 26px;
    font-weight: 700;
    text-indent: 0;
  }
  .intro_content .content_2 .content_text {
    /* font-size: 30px; */
    max-width: 280px;
    font-size: 15px;
    font-weight: bold;
  }
  .intro_content .content_2 .content_text_detail {
    /* font-size: 30px; */
    max-width: 320px;
    font-size: 12px;
    margin-top: 35px;
  }
  .intro_content .content_2 .content_text_detail span {
    display: block;
  }
  .intro_content .content_2 .content_text_detail em {
    display: block;
    font-size: 30px;
    color: #00c73c;
    font-weight: bold;
  }

  .intro_common.intro_bottom_section {
    margin-top: 40px;
  }

  .intro_common.intro_bottom_section .intro_content {
    padding-bottom: 40px;
  }

  /* .intro_content {
  padding-bottom: 120px;
  display: flex;
  margin-top: 25px;
} */

  .intro_content .content_1 .content_bottom_detail,
  .intro_content .content_1 .intro_img_box {
    margin-left: 0;
  }

  .intro_common.intro_bottom_section .content_bottom_detail {
    height: auto;
    margin-bottom: 20px;
  }
  .intro_common.intro_bottom_section .content_bottom_detail span {
    display: block;
    font-size: 14px;
    color: #99a0a6;
  }

  .intro_common.intro_bottom_section .content_bottom_detail em {
    display: block;
    font-size: 30px;
    color: #00c73c;
    font-weight: bold;
  }
  .intro_common.intro_bottom_section .intro_img_box {
    border-radius: 5px;
    width: 278px;
    overflow: hidden;
    cursor: pointer;
    /* background-color: red; */
  }
  .intro_customer_img_box {
    max-width: 287px;
    height: auto;
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .intro_customer_img_box img {
    max-width: 142px;
  }
  /* .intro_customer_img_box .intro_img1 {
    max-width: 127px;
  }
  .intro_customer_img_box .intro_img2 {
    max-width: 142px;
  } */
}
@media all and (max-width: 700px) {
  .intro_banner_wrap {
    width: 100%;
    height: 280px;
    background: url("../assets//images//Canvas\ –\ 1.png") no-repeat;
    background-size: cover;
    /* position: relative; */
  }

  .intro_banner_wrap .intro_banner_inner {
    display: flex;
    align-items: center;
    padding-left: 24px;
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text {
    /* background-color: red; */
    /* height: auto; */
    padding-left: 0px;
    padding-top: 0;
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text p {
    font-size: 24px;
    line-height: 30px;
  }
  .intro_banner_wrap .intro_banner_inner .intro_banner_text span {
    font-size: 12px;
    margin-top: 8px;

    line-height: 20px;
  }

  .intro_common {
    /* margin-top: 125px; */
    max-width: 100%;
    margin: 0 auto;
    margin-top: 40px;
  }
  .intro_common.intro_com_last {
    margin-top: 40px;
  }
  .intro_common .title {
    font-size: 14px;
    color: #00c73c;
    font-family: "Myriad-pro";
    font-weight: 600;
    src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
    padding-left: 24px;
  }
  .intro_content {
    padding-bottom: 60px !important;
    display: flex;

    margin-top: 20px;
    padding: 0 24px;
  }
  .intro_content.intro_content_last {
    padding-bottom: 40px;
  }
  .intro_content > div {
    flex: 1;
  }
  .intro_content .content_1 p {
    font-size: 22px;
    font-weight: 700;
    text-indent: 0;
  }
  .intro_content .content_2 .content_text {
    /* font-size: 30px; */
    max-width: 240px;
    font-size: 13px;
    font-weight: bold;
  }
  .intro_content .content_2 .content_text_detail {
    /* font-size: 30px; */
    max-width: 320px;
    font-size: 12px;
    margin-top: 35px;
  }
  .intro_content .content_2 .content_text_detail span {
    display: block;
  }
  .intro_content .content_2 .content_text_detail em {
    display: block;
    font-size: 24px;
    color: #00c73c;
    font-weight: bold;
  }

  .intro_common.intro_bottom_section {
    margin-top: 30px;
  }

  .intro_common.intro_bottom_section .intro_content {
    padding-bottom: 30px;
  }

  /* .intro_content {
  padding-bottom: 120px;
  display: flex;
  margin-top: 25px;
} */

  .intro_content .content_1 .content_bottom_detail,
  .intro_content .content_1 .intro_img_box {
    margin-left: 0;
  }

  .intro_common.intro_bottom_section .content_bottom_detail {
    height: auto;
    margin-bottom: 20px;
  }
  .intro_common.intro_bottom_section .content_bottom_detail span {
    display: block;
    font-size: 14px;
    color: #99a0a6;
  }

  .intro_common.intro_bottom_section .content_bottom_detail em {
    display: block;
    font-size: 26px;
    color: #00c73c;
    font-weight: bold;
  }
  .intro_common.intro_bottom_section .intro_img_box {
    border-radius: 5px;
    width: 160px;
    overflow: hidden;
    cursor: pointer;
    /* background-color: red; */
  }
  .intro_customer_img_box {
    max-width: 160;
    height: auto;
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .intro_customer_img_box img {
    max-width: 142px;
  }
}
