.common_invest_wrap {
  min-height: 1348px;
  background-color: #fff;
}

.not_user_wrap {
  width: 100%;
  height: 624px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not_user_wrap h4 {
  text-align: center;
  color: #898989;
  font-size: 15px;
}
.not_user_wrap button {
  font-size: 16px;
  color: #fff;
  width: 177px;
  height: 42px;
  line-height: 42px;
  background-color: #00c73c;
  border-radius: 5px;
  margin-top: 28px;
}
/* visual */
.visual_back {
  position: relative;
}

.visual_front {
  position: relative;
  width: 100%;
  height: 100%;

  z-index: 0;
}

.visual_list {
  position: relative;
}
.visual_dots {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.visual_dots li {
  display: inline-block;
  margin: 0 5px;
  vertical-align: top;
}
.visual_dots li button {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #9ca3af;
}
.visual_dots li.slick-active button {
  background-color: #fff;
}
.visual_item {
  position: relative;
  width: 100%;

  height: 512px;
  padding: 60px 24px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.visual_item:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 512px;
  /* background-color: #000; */
  z-index: 0;
  opacity: 0.82;
}
.visual_item .visual_box {
  position: relative;
  padding: 0 24px;
}
.visual_item .visual_sub {
  padding: 4px 8px;
  background-color: #3eb649;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
  font-weight: 700;
}
.visual_item .visual_title {
  font-size: 26px;
  line-height: 37px;
  color: #fff;
  letter-spacing: -0.1px;
  font-weight: 600;
  margin-top: 10px;
}
.visual_inner {
  margin-top: 30px;
  padding: 30px 24px 0px;

  border-radius: 4px;
  background-color: #fff;
  height: 173px;
}
.visual_inner ul {
  margin-bottom: 36px;
}
.visual_inner ul li {
  display: inline-block;
  position: relative;
  width: calc(50% - 2px);
  vertical-align: top;
  text-align: center;
}

.visual_inner ul li span {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #4b5563;
  letter-spacing: -0.14px;
  margin-bottom: 13px;
}
.visual_inner ul li .num {
  display: block;
  font-size: 21px;
  line-height: 30px;
  font-weight: 600;
  color: #111827;
  letter-spacing: -0.1px;
}
.line_wrap {
  width: 100%;
  height: 6px;
  position: relative;
}
.visual_inner .line {
  position: relative;
  /* margin-top: 35px; */
  /* margin-bottom: 8px; */
  width: 100%;
  height: 6px;
  border-radius: 4px;
  background-color: #f3f4f6;
  font-size: 0;
  overflow: hidden;
}
.visual_inner .green_line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #00a370;
}
.visual_inner .line_text_box {
}
.visual_inner .line_text_box::after {
  content: "";
  display: block;
  clear: both;
}
.visual_inner .line_text_box .per {
  float: left;
  font-size: 12px;
  line-height: 16px;
  color: #00a370;
}
.visual_inner .line_text_box .per.n2 {
  float: right;
  color: #4b5563;
}
.visual_btn {
  margin-top: 35px;
  height: 55px;
}
.visual_btn .visual_go {
  width: 100%;
  height: 100%;
  /* padding: 12px 20px; */
  border-radius: 4px;
  background-color: #f3f4f6;
  font-size: 20px;
  line-height: 50px;
  color: #6b7280;
  font-weight: 700;
  text-align: center;
}
.progress {
  width: 100%;
  appearance: none;
  height: 6px;
  border: 0;
  position: absolute;
}
.progress::-webkit-progress-bar {
  background: #f3f4f6;
  border-radius: 10px;
  /* box-shadow: inset 3px 3px 10px #ccc; */
}
.progress::-webkit-progress-value {
  border-radius: 10px;
  background: #00a370;
  /* background: -webkit-linear-gradient(to right, #93f9b9, #1d976c);
  background: linear-gradient(to right, #93f9b9, #1d976c); */
}

.rowgroup {
}
.tab {
  background-color: #fffffe;
}
.tab .tab_list {
  font-size: 0;
  line-height: 0;
}
.tab .tab_item {
  display: inline-block;
  width: 33.3%;
  vertical-align: top;
  text-align: center;
}
.tab .tab_item .tab_button {
  display: inline-block;
  width: 100%;
  padding: 20px 0 14px;
  border-bottom: 2.5px solid #e5e7eb;
  vertical-align: top;
  font-size: 16px;
  line-height: 18px;
}
.tab .tab_item.active .tab_button {
  color: #00c73c;
  border-color: #00c73c;
}
@media all and (max-width: 1100px) {
  .common_invest_wrap {
    min-height: auto;
  }
  .not_user_wrap {
    width: 100%;
    height: 550px;
    /* background-color: red; */
  }
  .not_user_wrap h4 {
    font-size: 14px;
  }
  .not_user_wrap button {
    font-size: 15px;

    width: 164px;
    height: 38px;
    line-height: 38px;

    margin-top: 28px;
  }
  .visual_item {
    position: relative;
    width: 100%;

    height: 420px;
    padding: 30px 20px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .visual_item:before {
    height: 420px;
  }
  .visual_item .visual_box {
    position: relative;
    padding: 0 20px;
  }
  .visual_item .visual_sub {
    padding: 4px 8px;
    background-color: #3eb649;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    font-weight: 600;
  }
  .visual_item .visual_title {
    font-size: 22px;
    line-height: 28px;
  }
  .visual_inner {
    margin-top: 24px;
    padding: 24px 24px 0px;

    height: 153px;
  }
  .visual_inner ul {
    margin-bottom: 26px;
  }
  .visual_inner ul li span {
    line-height: 14px;

    margin-bottom: 9px;
  }
  .visual_inner ul li .num {
    font-size: 18px;
    line-height: 26px;
  }
  .visual_btn {
    margin-top: 30px;
    height: 44px;
  }
  .visual_btn .visual_go {
    font-size: 18px;
    line-height: 44px;
  }
  .tab .tab_item .tab_button {
    padding: 16px 0 10px;

    font-size: 14px;
    line-height: 14px;
  }
}
@media all and (max-width: 660px) {
  .not_user_wrap {
    width: 100%;
    height: 400px;
    /* background-color: red; */
  }
  .not_user_wrap h4 {
    font-size: 12px;
  }
  .not_user_wrap button {
    font-size: 12px;

    width: 138px;
    height: 33px;
    line-height: 33px;

    margin-top: 18px;
  }
  .visual_item {
    position: relative;
    width: 100%;

    height: 320px;
    padding: 24px 20px 0;
  }
  .visual_item:before {
    height: 320px;
  }
  .visual_item .visual_box {
    position: relative;
    padding: 0;
  }
  .visual_item .visual_sub {
    padding: 4px 12px;

    border-radius: 3px;
    font-size: 10px;
    line-height: 10px;

    font-weight: 500;
  }
  .visual_item .visual_title {
    font-size: 18px;
    line-height: 22px;
  }
  .visual_inner {
    margin-top: 18px;
    padding: 16px 24px 0px;

    height: 123px;
  }
  .visual_inner ul {
    margin-bottom: 20px;
  }
  .visual_inner ul li span {
    line-height: 12px;

    margin-bottom: 9px;
  }
  .visual_inner ul li .num {
    font-size: 16px;
    line-height: 22px;
  }
  .line_wrap {
    height: 3px;
  }
  .visual_inner .line {
    height: 3px;
    border-radius: 2px;
  }
  .visual_inner .green_line {
    border-radius: 2px;
  }
  .visual_inner .line_text_box .per {
    float: left;
    font-size: 10px;
    line-height: 12px;
    color: #00a370;
  }
  .visual_inner .line_text_box .per.n2 {
    line-height: 28px;
  }
  .visual_btn {
    margin-top: 20px;
    height: 38px;
  }
  .visual_btn .visual_go {
    font-size: 16px;
    line-height: 38px;
  }
  .tab .tab_item .tab_button {
    padding: 12px 0 6px;

    font-size: 12px;
    line-height: 14px;
  }
}

/* qna */
.qna {
  padding: 40px 24px;
  background-color: #fffffe;
}
.qna h2 {
  margin-bottom: 28px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
}
.qna {
}
.qna .qna_list {
}
.qna .qna_item {
  position: relative;
  background-color: #fff;
  text-align: left;
}
.qna .qna_btn {
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 0;
  background-color: #ffff;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  letter-spacing: -0.2px;
  font-weight: 500;
  text-align: left;
}
.qna .qna_btn:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ededed;
}
.qna .qna_btn:after {
  display: block;
  position: absolute;
  top: calc(50% - 5px);
  right: 0;
  width: 18px;
  height: 10px;
  background: url(../assets/images/qna_arrow.png) center center no-repeat;
  transition: all 0.3s linear;
}
.qna .qna_btn.active:after {
  transform: rotate(180deg);
}
.qna .qna_body {
  /* display: none; */
  height: 0;
  padding: 0 14px;
  /* padding: 0 14px; */
  background-color: #f9fafb;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
  letter-spacing: -0.1px;
  font-weight: 500;
  transition: height 0.3s linear;
  transition: padding 0.4s linear;
  overflow: hidden;
  white-space: pre-line;
}
.qna .qna_body.active {
  /* display: block; */
  padding: 14px;
  transition: all 0.3s linear;
  height: auto;
}
/* news */
.news {
  position: relative;
  padding: 40px 24px 24px;
  background-color: #fffffe;
}
.news:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.news h2 {
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
}
.news_list {
  padding: 16px 0;
}
.news_list .slick-slide {
  width: 282px;
  margin-right: 12px !important;
}
.news_list .news_item {
  padding: 10px;
}
.news_list .news_anchor {
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  -webkit-user-drag: none;
  height: 252px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.news_list .news_img {
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.news_list .news_text {
  padding: 12px;
}
.news_list .news_text .news_title {
  display: -webkit-box;
  height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
  line-height: 23px;
  color: #111827;
  font-weight: 600;
  letter-spacing: -0.1px;
  word-break: keep-all;
}
.news_list .news_text .text_box {
  margin-top: 5px;
  line-height: 0;
}
.news_list .news_text .time {
  font-size: 14px;
  line-height: 22px;
  color: #9f9e9e;
  font-weight: 500;
  letter-spacing: -0.2px;
}
.news_list .news_text .news_name {
  width: 170px;
  height: 22px;
  float: right;
  text-align: right;
  font-size: 15px;
  line-height: 22px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* review */
.review {
  position: relative;
  padding: 70px 0 80px;
  background-color: #fffffe;
}
.review:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.review h2 {
  margin-bottom: 28px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 700;
  letter-spacing: -0.1px;
  text-align: center;
}
.review_list {
}
.review_list .slick-list {
  padding: 16px 24px;
}
.review_list .slick-slide {
  width: 295px;
  height: 337px;
  margin-right: 12px;
}
.review_list .review_item {
  padding: 27px 23px 30px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
}
.review_list .review_title {
  font-size: 14px;
  line-height: 22px;
  color: #588d9e;
  letter-spacing: -0.2px;
  font-weight: 700;
}
.review_list .review_name {
  margin-bottom: 15px;
}
.review_list .review_name span {
  font-size: 12px;
  line-height: 22px;
  color: #828282;
  letter-spacing: -0.2px;
  font-weight: 400;
}
.review_list .review_text .text {
  display: -webkit-box;
  height: 202px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.26px;
  color: #4b5563;
}

/* STATUS */

.status {
  /* margin-top: 40px; */
  margin-bottom: 20px;
  position: relative;
  padding: 65px 24px 50px;
  /* background: linear-gradient(#daf5e6, rgba(255, 255, 255, 0)); */
}
/* 
.status:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 176px;
  z-index: -1;
  background: linear-gradient(#daf5e6, rgba(255, 255, 255, 0));
} */
.status_text {
  /* padding-top: 50px; */
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: #4b5563;
}
.status_text strong {
  display: block;
  padding-bottom: 15px;
  font-size: 27px;
  line-height: 32px;
  color: #111827;
  font-weight: 700;
}
.status_num {
  margin-top: 24px;
  padding: 24px 0;
}
.status_num .status_list {
  font-size: 0;
  line-height: 0;
}
.status_num .status_list li {
  display: inline-block;
  /* width: 50%; */
  font-size: 14px;
  line-height: 20px;
  color: #4b5563;
  font-weight: 400;
}
.status_num .status_list li span {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #90989f;
  font-weight: 400;
}
.status_first {
  padding-bottom: 18px;
  border-bottom: 1px solid #d1d5db;
  width: 100%;
}
.status_num .status_list.length2 {
  display: flex;

  width: 51%;
  justify-content: space-between;
}
.status_num .status_list.length3 {
  padding-top: 18px;
  display: flex;
  justify-content: space-between;
}
.status_num .status_list.length3 li {
  /* width: 33.3%; */
  width: 200px;
  font-size: 11px;
  line-height: 16px;
  color: #90989f;
}
.status_num .status_list.length3 li:nth-child(2) {
  /* text-align: center; */
}
.status_num .status_list.length3 li:nth-child(3) {
  text-align: right;
}
.status_num .status_list.length3 li span {
  font-size: 12px;
  line-height: 16px;
}
.status_num .status_list li em {
  font-size: 36px;
  line-height: 30px;
  color: #00c73c;
  font-weight: 700;
}

/* farm */
.farm_title {
  width: 100%;
  padding: 5px 24px;
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 20px;
}
.farm_title.farm_title_second {
  margin-top: 24px;
}
.farm {
  padding: 30px 16px;
  background-color: #ebeef3;
  border-radius: 20px;
  /* background-color: red; */
}
.farm_list {
  position: relative;
  /* width: 120%; */
  /* background-color: red; */
}
.farm.farm_list_div {
  margin-bottom: 50px;
}
.farm_list .slick-slide {
  /* width: 100%; */
  /* background-color: beige; */
  /* margin-right: 20px; */
}
/* .farm_list > div > div > div > .slick-slide {
  width: 200px;
  margin-right: 12px;
  background-color: red;
} */
.farm_item {
  /* width: 300px; */
  padding: 0 6px;
  /* margin-right: 10px; */
}
.farm_item a {
  width: 100%;
  height: 100%;
}
.farm_item_box {
  border-radius: 4px;
  padding: 16px;
  background-color: #fff;
}
.farm_top {
  position: relative;
  padding-bottom: 12px;
  border-bottom: 1px solid #d1d5db;
}
.farm_top .title {
  padding-right: 50px;
  font-size: 18px;
  line-height: 36px;
  color: #111827;
  font-weight: 700;
}
.farm_top .time_box {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}
.farm_top .time_box span {
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 300;
}
.farm_top .time_box .date {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 600;
}
.farm_top .time_box .time {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #4b5563;
  font-weight: 500;
}
.farm_text {
  padding: 12px 0 20px;
  border-bottom: 1px solid #d1d5db;
}
.farm_text li {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #4b5563;
  font-weight: 500;
}
.farm_text li .title {
  display: inline-block;
  vertical-align: top;
}
.farm_text li .text {
  display: inline-block;
  float: right;
  vertical-align: top;
  text-align: right;
  color: #111827;
  font-size: 15px;
}

.farm_text li .text .em_green {
  color: #00c73c;
  font-size: 16px;
  font-weight: 600;
}
.farm_now {
  padding-top: 14px;
  border-bottom: 1px solid #d1d5db;
}
.farm_now .now_title {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 700;
}
.farm_now .now_list {
  font-size: 0;
  line-height: 0;
}
.farm_now .now_list li {
  display: inline-block;
  position: relative;
  width: 25%;
  padding: 12px 4px;
  text-align: center;
}
.farm_now .now_list li:before {
  display: block;
  position: absolute;
  top: calc(50% - 6px);
  right: 0;
  width: 6px;
  height: 12px;
  background: url(../assets/images/farm_arrow.png) center center no-repeat;
}
.farm_now .now_list li:last-child:before {
  display: none;
}
.now_icon {
  /* font-size: 30px;
  color: rgb(0, 163, 112); */
  /* position: relative; */
}

.now_icon img {
  margin: 0 auto;
}
.now_icon.cc {
  /* animation: blink-effect 2s step-end infinite; */
}
.now_icon.notcc {
  /* color: #8e8e8e !important; */
}

@media all and (max-width: 1100px) {
  .rowgroup {
    padding: 0 20px;
  }
  .status {
    position: relative;
    padding: 54px 24px 40px;
    /* background: linear-gradient(#daf5e6, rgba(255, 255, 255, 0)); */
  }

  .status_text {
    /* padding-top: 50px; */
    font-size: 15px;
    line-height: 20px;
  }
  .status_text strong {
    padding-bottom: 10px;
    font-size: 22px;
    line-height: 24px;

    font-weight: 600;
  }
  .status_num {
    margin-top: 24px;
    padding: 24px 0;
  }
  .status_num .status_list {
    font-size: 0;
    line-height: 0;
  }
  .status_num .status_list li {
    display: inline-block;
    /* width: 50%; */
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 400;
  }
  .status_num .status_list li span {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #90989f;
    font-weight: 400;
  }
  .status_first {
    padding-bottom: 18px;
    border-bottom: 1px solid #d1d5db;
    width: 100%;
  }
  .status_num .status_list.length2 {
    display: flex;

    width: 51%;
    justify-content: space-between;
  }
  .status_num .status_list.length3 {
    padding-top: 18px;
    display: flex;
    justify-content: space-between;
  }
  .status_num .status_list.length3 li {
    /* width: 33.3%; */
    width: 200px;
    font-size: 11px;
    line-height: 16px;
    color: #90989f;
  }
  .status_num .status_list.length3 li:nth-child(2) {
    /* text-align: center; */
  }
  .status_num .status_list.length3 li:nth-child(3) {
    text-align: right;
  }
  .status_num .status_list.length3 li span {
    font-size: 12px;
    line-height: 16px;
  }
  .status_num .status_list li em {
    font-size: 30px;
    line-height: 20px;
    color: #00c73c;
    font-weight: 700;
  }

  /* farm */
  .farm {
    padding: 30px 16px;
    background-color: #ebeef3;
  }
  .farm_list {
    /* width: 120%; */
    /* background-color: red; */
  }
  .farm_list .slick-slide {
  }

  .farm_item {
    /* width: 300px; */
    padding: 0 6px;
    /* margin-right: 10px; */
  }
  .farm_item_box {
    border-radius: 4px;
    padding: 14px;
    background-color: #fff;
  }
  .farm_top {
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d1d5db;
  }
  .farm_top .title {
    padding-right: 0px;
    font-size: 16px;
    line-height: 30px;
    color: #111827;
    font-weight: 700;
  }
  .farm_top .time_box {
  }
  .farm_top .time_box span {
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    font-weight: 300;
  }
  .farm_top .time_box .date {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    font-weight: 600;
  }
  .farm_top .time_box .time {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #4b5563;
    font-weight: 500;
  }
  .farm_text {
    padding: 9px 0 17px;
    border-bottom: 1px solid #d1d5db;
  }
  .farm_text li {
    margin-top: 9px;
    font-size: 12px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 500;
    height: 24px;
  }
  .farm_text li .title {
    display: inline-block;
    vertical-align: top;
  }
  .farm_text li .text {
    font-size: 13px;
  }

  .farm_text li .text .em_green {
    color: #00c73c;
    font-size: 14px;
    font-weight: 600;
  }
  .farm_now {
    padding-top: 10px;
  }
  .farm_now .now_title {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 18px;
  }

  .farm_now .now_list li {
    display: inline-block;
    position: relative;
    width: 25%;
    padding: 8px 4px;
    text-align: center;
  }
  .farm_now .now_list li:before {
    display: block;
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
    width: 6px;
    height: 12px;
    background: url(../assets/images/farm_arrow.png) center center no-repeat;
  }
  .farm_now .now_list li:last-child:before {
    display: none;
  }
  .farm_now .now_list li span {
    font-size: 12px !important;
  }
  .now_icon {
    /* font-size: 30px;
    color: rgb(0, 163, 112); */
    /* position: relative; */
  }

  .now_icon img {
    margin: 0 auto;
  }
  .now_icon.cc {
    /* animation: blink-effect 2s step-end infinite; */
  }
  .now_icon.notcc {
    /* color: #8e8e8e !important; */
  }

  .campaign_go {
    /* border: 3px solid #006e4d; */
    /* margin-bottom: 10px; */
    background-color: #00c73c;
    color: #fff;
    margin-bottom: 100px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .campaign_go .campaign_anchor {
    display: block;
    position: relative;
    width: 100%;
    padding: 24px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .campaign_go .campaign_anchor svg {
    font-size: 24px;
  }
  .campaign_go .anchor_text {
    position: relative;
    font-size: 0;
    line-height: 0;
    color: #fff;
  }
  .campaign_go .anchor_text:before {
    display: block;
    position: absolute;
    top: calc(50% - 8px);
    right: 0;
    width: 10px;
    height: 16px;
    /* background: url(/site/public/images/campaign_go_arrow.png) center center
    no-repeat; */
  }
  .campaign_go .campaign_anchor span {
    font-size: 12px;
    line-height: 22px;
    /* color: #111827; */
    font-weight: 300;
  }
  .campaign_go .campaign_anchor em {
    display: block;
    font-size: 18px;
    line-height: 30px;
    /* color: #006e4d; */
    font-weight: 700;
  }
}
@media all and (max-width: 700px) {
  .status {
    position: relative;
    padding: 44px 24px 34px;
    /* background: linear-gradient(#daf5e6, rgba(255, 255, 255, 0)); */
  }

  .status_text {
    /* padding-top: 50px; */
    font-size: 13px;
    line-height: 20px;
  }
  .status_text strong {
    padding-bottom: 8px;
    font-size: 20px;
    line-height: 22px;

    font-weight: 600;
  }
  .status_num {
    margin-top: 15px;
    padding: 10px 0;
  }

  .status_num .status_list li {
    display: inline-block;
    /* width: 50%; */
    font-size: 12px;
    line-height: 20px;
  }
  .status_num .status_list li span {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 20px;
    color: #90989f;
    font-weight: 400;
  }
  .status_first {
    padding-bottom: 14px;
  }
  .status_num .status_list.length2 {
    display: flex;

    width: 51%;
    justify-content: space-between;
  }
  .status_num .status_list.length3 {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .status_num .status_list.length3 li {
    /* width: 33.3%; */
    width: 160px;

    font-size: 11px;
    line-height: 16px;
    color: #90989f;
  }
  .status_num .status_list.length3 li:nth-child(2) {
    /* text-align: center; */
  }
  .status_num .status_list.length3 li:nth-child(3) {
    text-align: right;
  }
  .status_num .status_list.length3 li span {
    font-size: 12px;
    line-height: 16px;
  }
  .status_num .status_list li em {
    font-size: 24px;
    line-height: 20px;
    color: #00c73c;
    font-weight: 700;
  }

  /* farm */
  .farm {
    padding: 20px 16px;
    background-color: #ebeef3;
  }
  .farm_list {
    /* width: 120%; */
    /* background-color: red; */
  }
  .farm_list .slick-slide {
  }

  .farm_item {
    /* width: 300px; */
    padding: 0 6px;
    /* margin-right: 10px; */
  }
  .farm_item_box {
    border-radius: 4px;
    padding: 14px;
    background-color: #fff;
  }
  .farm_top {
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d1d5db;
  }
  .farm_top .title {
    padding-right: 0px;
    font-size: 16px;
    line-height: 30px;
    color: #111827;
    font-weight: 700;
  }
  .farm_top .time_box {
  }
  .farm_top .time_box span {
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    font-weight: 300;
  }
  .farm_top .time_box .date {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    font-weight: 600;
  }
  .farm_top .time_box .time {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #4b5563;
    font-weight: 500;
  }
  .farm_text {
    padding: 9px 0 17px;
    border-bottom: 1px solid #d1d5db;
  }
  .farm_text li {
    margin-top: 9px;
    font-size: 12px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 500;
    height: 24px;
  }
  .farm_text li .title {
    display: inline-block;
    vertical-align: top;
  }
  .farm_text li .text {
    font-size: 13px;
  }

  .farm_text li .text .em_green {
    color: #00c73c;
    font-size: 14px;
    font-weight: 600;
  }
  .farm_now {
    padding-top: 10px;
  }
  .farm_now .now_title {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 18px;
  }

  .farm_now .now_list li {
    display: inline-block;
    position: relative;
    width: 25%;
    padding: 8px 4px;
    text-align: center;
  }
  .farm_now .now_list li:before {
    display: block;
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
    width: 6px;
    height: 12px;
    background: url(../assets/images/farm_arrow.png) center center no-repeat;
  }
  .farm_now .now_list li:last-child:before {
    display: none;
  }
  .farm_now .now_list li span {
    font-size: 12px !important;
  }
  .now_icon {
    /* font-size: 30px;
    color: rgb(0, 163, 112); */
    /* position: relative; */
  }

  .now_icon img {
    margin: 0 auto;
  }
  .now_icon.cc {
    /* animation: blink-effect 2s step-end infinite; */
  }
  .now_icon.notcc {
    /* color: #8e8e8e !important; */
  }
}

@media all and (max-width: 520px) {
  .status {
    position: relative;
    padding: 24px;
    /* background: linear-gradient(#daf5e6, rgba(255, 255, 255, 0)); */
  }

  .status_text {
    /* padding-top: 50px; */
    font-size: 13px;
    line-height: 16px;
  }
  .status_text strong {
    padding-bottom: 6px;
    font-size: 18px;
    line-height: 22px;

    font-weight: 600;
  }
  .status_num {
    margin-top: 12px;
    padding: 8px 0;
  }

  .status_num .status_list li {
    display: inline-block;
    /* width: 50%; */
    font-size: 12px;
    line-height: 20px;
  }
  .status_num .status_list li span {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 20px;
    color: #90989f;
    font-weight: 400;
  }
  .status_first {
    padding-bottom: 12px;
  }
  .status_num .status_list.length2 {
    display: flex;

    width: 51%;
    justify-content: space-between;
  }
  .status_num .status_list.length3 {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .status_num .status_list.length3 li {
    /* width: 33.3%; */
    width: 140px;

    font-size: 11px;
    line-height: 16px;
    color: #90989f;
  }
  .status_num .status_list.length3 li:nth-child(2) {
    /* text-align: center; */
  }
  .status_num .status_list.length3 li:nth-child(3) {
    text-align: right;
  }
  .status_num .status_list.length3 li span {
    font-size: 12px;
    line-height: 14px;
  }
  .status_num .status_list li em {
    font-size: 18px;
    line-height: 20px;
    color: #00c73c;
    font-weight: 700;
  }

  /* farm */
  .farm {
    padding: 20px 16px;
    background-color: #ebeef3;
  }
  .farm_list {
    /* width: 120%; */
    /* background-color: red; */
  }
  .farm_list .slick-slide {
  }

  .farm_item {
    /* width: 300px; */
    padding: 0 6px;
    /* margin-right: 10px; */
  }
  .farm_item_box {
    border-radius: 4px;
    padding: 14px;
    background-color: #fff;
  }
  .farm_top {
    position: relative;
    padding-bottom: 10px;
    border-bottom: 1px solid #d1d5db;
  }
  .farm_top .title {
    padding-right: 0px;
    font-size: 16px;
    line-height: 30px;
    color: #111827;
    font-weight: 700;
  }
  .farm_top .time_box {
  }
  .farm_top .time_box span {
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    font-weight: 300;
  }
  .farm_top .time_box .date {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    font-weight: 600;
  }
  .farm_top .time_box .time {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: #4b5563;
    font-weight: 500;
  }
  .farm_text {
    padding: 9px 0 17px;
    border-bottom: 1px solid #d1d5db;
  }
  .farm_text li {
    margin-top: 9px;
    font-size: 12px;
    line-height: 20px;
    color: #4b5563;
    font-weight: 500;
    height: 24px;
  }
  .farm_text li .title {
    display: inline-block;
    vertical-align: top;
  }
  .farm_text li .text {
    font-size: 13px;
  }

  .farm_text li .text .em_green {
    color: #00c73c;
    font-size: 14px;
    font-weight: 600;
  }
  .farm_now {
    padding-top: 10px;
  }
  .farm_now .now_title {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 18px;
  }

  .farm_now .now_list li {
    display: inline-block;
    position: relative;
    width: 25%;
    padding: 8px 4px;
    text-align: center;
  }
  .farm_now .now_list li:before {
    display: block;
    position: absolute;
    top: calc(50% - 6px);
    right: 0;
    width: 6px;
    height: 12px;
    background: url(../assets/images/farm_arrow.png) center center no-repeat;
  }
  .farm_now .now_list li:last-child:before {
    display: none;
  }
  .farm_now .now_list li span {
    font-size: 12px !important;
  }
  .now_icon {
    /* font-size: 30px;
    color: rgb(0, 163, 112); */
    /* position: relative; */
  }

  .now_icon img {
    margin: 0 auto;
  }
  .now_icon.cc {
    /* animation: blink-effect 2s step-end infinite; */
  }
  .now_icon.notcc {
    /* color: #8e8e8e !important; */
  }
}
@keyframes blink-effect {
  50% {
    color: #8e8e8e;
  }
}
.farm_now .now_list li span {
  margin-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #1f2937;
  font-weight: 600;
}
.farm .btn_box {
  margin-top: 12px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}

.farm .btn_box .farm_body {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  height: 135px;
}
.farm .btn_box .farm_body.active {
  height: 135px;
  transition: height 0.3s linear;
}

/* .farm .btn_box .farm_body {
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  height: 0px;
  overflow: hidden;
  transition: height 0.3s linear;
}
.farm .btn_box .farm_body.active {
  height: 135px;
  transition: height 0.3s linear;
} */
.farm .btn_box .farm_body > .title {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 600;
}
.farm .btn_box .farm_body li {
  margin-bottom: 6px;
  font-weight: 300;
  font-size: 0;
  line-height: 0;
}
.farm .btn_box .farm_body li span {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}
.farm .btn_box .farm_body li .title {
  width: 55px;
  padding-right: 20px;
}
.farm .btn_box .farm_body li .text {
  float: right;
  text-align: right;
}
.farm .btn_box .farm_body li .text > span:first-child {
  position: relative;
  padding-right: 7px;
}

.farm .btn_box .farm_body li .text > span:first-child::before {
  position: absolute;
  content: "┃";
  display: block;
  right: -3px;
  top: 0;
  color: #00c73c;
}
.farm .btn_box .farm_btn {
  display: block;
  width: 100%;
  text-align: center;
}
.farm .btn_box .farm_btn span {
  position: relative;
  padding-right: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #9f9e9e;
  letter-spacing: -0.25px;
  font-weight: 500;
}
.farm .btn_box .farm_btn span:before {
  display: block;
  position: absolute;
  top: 8px;
  right: 0;
  width: 10px;
  height: 6px;
  /* background: url(/site/public/images/farm_arrow2.png) center center no-repeat; */
}

.farm_item .farm_myriad_em {
  /* font-family: "Myriad-pro";
  font-weight: 600;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype"); */
}
/* campaign_anchor */
.campaign_go {
  /* border: 3px solid #006e4d; */
  /* margin-bottom: 10px; */
  background-color: #00c73c;
  color: #fff;
  margin-bottom: 100px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.campaign_go .campaign_anchor {
  display: block;
  position: relative;
  width: 100%;
  padding: 24px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.campaign_go .campaign_anchor svg {
  font-size: 30px;
}
.campaign_go .anchor_text {
  position: relative;
  font-size: 0;
  line-height: 0;
  color: #fff;
}
.campaign_go .anchor_text:before {
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  width: 10px;
  height: 16px;
  /* background: url(/site/public/images/campaign_go_arrow.png) center center
    no-repeat; */
}
.campaign_go .campaign_anchor span {
  font-size: 15px;
  line-height: 22px;
  /* color: #111827; */
  font-weight: 300;
}
.campaign_go .campaign_anchor em {
  display: block;
  font-size: 21px;
  line-height: 30px;
  /* color: #006e4d; */
  font-weight: 700;
}
.farm_back {
  padding-bottom: 10px;
}
.farm_status {
  padding: 12px;
  background-color: #0b524a;
  color: white;
  /* margin-bottom: 10px; */
}
.farm_status .fs_title {
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
}
.farm_status .fs_subtitle {
  font-size: 24px;
  line-height: 40px;
  font-weight: bold;
}

.slider_item {
  padding: 10px;
}

.slider_title {
  background-color: #91bdb9;
  height: 40px;
  border-radius: 20px;
  padding-left: 25px;
  line-height: 40px;
  margin: 15px 0;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.15);
  font-size: 18px;
}
.slider_cont {
  overflow: hidden;
}
.slider_cont_date {
  float: left;
  font-size: 16px;
  font-weight: 400;
}
.slider_cont_datas {
  float: left;
  margin-left: 10px;
}
.slider_space {
  clear: both;
  height: 10px;
  margin-top: 10px;
}

.slider_img_area {
  margin: 10px;

  text-align: center;
}
.slider_img_area > a {
  width: 100%;
  height: 150px;
}
.slider_img_area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 나의 투자 */
.inv_my_content {
  width: 100%;
  min-height: 600px;
  background-color: #fff;
  padding: 30px 20px;
}
.inv_my_login {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inv_my_login > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #77b69a;
}
.inv_my_login > div > svg {
  font-size: 100px;
}
.inv_my_login > div > span {
  font-size: 20px;
  margin-top: 20px;
  font-weight: 600;
}
.inv_my_login > div > button {
  background-color: #0e9e5d;
  width: 172.5px;
  height: 34px;
  color: #fff;
  font-weight: 600;
  border-radius: 10px;
  margin-top: 20px;
}
.inv_my_content .invest_have_wrap {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.invest_have_wrap h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}
.invest_have_wrap h3 > .em_green {
  font-size: 30px;
}
.invest_have_wrap .invest_ing_wrap,
.invest_have_wrap .invest_wait_wrap {
  width: 100%;
  height: auto;
}

.invest_ing_wrap .invest_ing_item,
.invest_wait_wrap .invest_wait_item {
  /* background-color: #006e4d; */
  padding: 10px 20px;
}
.invest_ing_wrap .invest_ing_item .invest_ing_box {
  /* background-color: #77b69a; */
  /* background-color: #00a370; */
  border-radius: 14px;
  height: 360px;
  padding: 16px;
  border: 2px solid #0e9e5d;
  color: #0e9e5d;
}

.invest_ing_item .invest_ing_box .invest_ing_title {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  /* color: #fff; */
  /* border-bottom: 2px solid #fff; */
  padding-bottom: 10px;
}
.invest_ing_item .invest_ing_box .invest_ing_con {
  font-size: 22px;
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 30px;
  /* align-items: center; */
}
.invest_ing_item .invest_ing_box .invest_ing_con label {
  width: 38%;
  text-align: right;
  position: relative;
  line-height: 36px;
  margin-right: 4px;
}
.invest_ing_item .invest_ing_box .invest_ing_con label::after {
  position: absolute;
  right: -7px;
  top: 9px;
  content: "";
  display: block;
  width: 2px;
  height: 22px;
  background-color: #0e9e5d;
}
.invest_ing_item .invest_ing_box .invest_ing_con span {
  text-indent: 18px;
  width: 38%;
  text-align: right;
  line-height: 36px;
}
.invest_ing_item .invest_ing_box .invest_ing_con > div {
  height: 36px;
}
.invest_ing_item .invest_ing_box .invest_ing_con > div:first-child {
  margin-bottom: 6px;
}

.invest_ing_item .invest_ing_box .invest_ing_btn {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 14px;
  flex-direction: column;
}
.invest_ing_item .invest_ing_box .invest_ing_btn button {
  background: #0e9e5d;
  color: #fff;
  font-size: 18px;

  padding: 10px 20px;
  border-radius: 14px;
  width: 100%;
  height: 48px;
}

.invest_wait_wrap .invest_wait_item .invest_wait_box {
  /* background-color: #77b69a; */
  /* background-color: #00a370; */
  border-radius: 14px;
  min-height: 240px;
  padding: 16px;
  border: 2px solid #cfcfcf;
  color: #969696;
}

.invest_wait_item .invest_wait_box .invest_wait_title {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  /* color: #fff; */
  /* border-bottom: 2px solid #fff; */
  padding-bottom: 10px;
}
.invest_wait_item .invest_wait_box .invest_wait_con {
  font-size: 22px;
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 30px 0;
  /* align-items: center; */
}

.invest_wait_item .invest_wait_box .invest_wait_con2 {
  /* font-size: 22px; */
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 10px 0;
  border-top: 1px solid #cfcfcf;
  /* align-items: center; */
}
.invest_wait_con2 h4 {
  text-align: center;
  margin-bottom: 9px;
}
.invest_wait_item .invest_wait_box .invest_wait_con label {
  width: 38%;
  text-align: right;
  position: relative;
  line-height: 36px;
  margin-right: 4px;
}
.invest_wait_item .invest_wait_box .invest_wait_con label::after {
  position: absolute;
  right: -7px;
  top: 9px;
  content: "";
  display: block;
  width: 2px;
  height: 22px;
  background-color: #969696;
}
.invest_wait_item .invest_wait_box .invest_wait_con span {
  text-indent: 18px;
  width: 38%;
  text-align: right;
  line-height: 36px;
}
.invest_wait_item .invest_wait_box .invest_wait_con > div {
  height: 36px;
}
.invest_wait_item .invest_wait_box .invest_wait_con > div:first-child {
  margin-bottom: 6px;
}
.invest_wait_item .invest_wait_info {
  display: flex;
  width: 100%;
}
.invest_wait_item .invest_wait_info .invest_wait_field {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}
.invest_wait_item .invest_wait_box .invest_wait_btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}
.invest_wait_item .invest_wait_box .invest_wait_btn button {
  background: #969696;
  color: #fff;
  font-size: 18px;

  padding: 10px 20px;
  border-radius: 14px;
  width: 100%;
}

@media all and (max-width: 1100px) {
  .inv_my_content {
    width: 100%;
    min-height: 600px;
    background-color: #fff;
    padding: 30px 20px;
  }
  .inv_my_login {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inv_my_login > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #77b69a;
  }
  .inv_my_login > div > svg {
    font-size: 100px;
  }
  .inv_my_login > div > span {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
  }
  .inv_my_login > div > button {
    background-color: #0e9e5d;
    width: 172.5px;
    height: 34px;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    margin-top: 20px;
  }
  .inv_my_content .invest_have_wrap {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
  .invest_have_wrap h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .invest_have_wrap h3 > .em_green {
    font-size: 30px;
  }
  .invest_have_wrap .invest_ing_wrap,
  .invest_have_wrap .invest_wait_wrap {
    width: 100%;
    height: auto;
  }

  .invest_ing_wrap .invest_ing_item,
  .invest_wait_wrap .invest_wait_item {
    /* background-color: #006e4d; */
    padding: 10px 20px;
  }
  .invest_ing_wrap .invest_ing_item .invest_ing_box {
    /* background-color: #77b69a; */
    /* background-color: #00a370; */
    border-radius: 14px;
    height: 360px;
    padding: 16px;
    border: 2px solid #0e9e5d;
    color: #0e9e5d;
  }

  .invest_ing_item .invest_ing_box .invest_ing_title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    /* color: #fff; */
    /* border-bottom: 2px solid #fff; */
    padding-bottom: 10px;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con {
    font-size: 22px;
    /* color: #fff; */
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-top: 30px;
    /* align-items: center; */
  }
  .invest_ing_item .invest_ing_box .invest_ing_con label {
    width: 38%;
    text-align: right;
    position: relative;
    line-height: 36px;
    margin-right: 4px;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con label::after {
    position: absolute;
    right: -7px;
    top: 9px;
    content: "";
    display: block;
    width: 2px;
    height: 22px;
    background-color: #0e9e5d;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con span {
    text-indent: 18px;
    width: 38%;
    text-align: right;
    line-height: 36px;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con > div {
    height: 36px;
  }
  .invest_ing_item .invest_ing_box .invest_ing_con > div:first-child {
    margin-bottom: 6px;
  }

  .invest_ing_item .invest_ing_box .invest_ing_btn {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 14px;
    flex-direction: column;
  }
  .invest_ing_item .invest_ing_box .invest_ing_btn button {
    background: #0e9e5d;
    color: #fff;
    font-size: 18px;

    padding: 10px 20px;
    border-radius: 14px;
    width: 100%;
    height: 48px;
  }

  .invest_wait_wrap .invest_wait_item .invest_wait_box {
    /* background-color: #77b69a; */
    /* background-color: #00a370; */
    border-radius: 14px;
    min-height: 240px;
    padding: 16px;
    border: 2px solid #cfcfcf;
    color: #969696;
  }

  .invest_wait_item .invest_wait_box .invest_wait_title {
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    /* color: #fff; */
    /* border-bottom: 2px solid #fff; */
    padding-bottom: 10px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con {
    font-size: 22px;
    /* color: #fff; */
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 30px 0;
    /* align-items: center; */
  }

  .invest_wait_item .invest_wait_box .invest_wait_con2 {
    /* font-size: 22px; */
    /* color: #fff; */
    font-weight: 500;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 10px 0;
    border-top: 1px solid #cfcfcf;
    /* align-items: center; */
  }
  .invest_wait_con2 h4 {
    text-align: center;
    margin-bottom: 9px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con label {
    width: 38%;
    text-align: right;
    position: relative;
    line-height: 36px;
    margin-right: 4px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con label::after {
    position: absolute;
    right: -7px;
    top: 9px;
    content: "";
    display: block;
    width: 2px;
    height: 22px;
    background-color: #969696;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con span {
    text-indent: 18px;
    width: 38%;
    text-align: right;
    line-height: 36px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con > div {
    height: 36px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_con > div:first-child {
    margin-bottom: 6px;
  }
  .invest_wait_item .invest_wait_info {
    display: flex;
    width: 100%;
  }
  .invest_wait_item .invest_wait_info .invest_wait_field {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
  }
  .invest_wait_item .invest_wait_box .invest_wait_btn {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
  }
  .invest_wait_item .invest_wait_box .invest_wait_btn button {
    background: #969696;
    color: #fff;
    font-size: 18px;

    padding: 10px 20px;
    border-radius: 14px;
    width: 100%;
  }
}

/* 절차 */

/* invest */
.invest {
  position: relative;
  padding: 0 24px;
  /* padding: 90px 24px 0px; */
  /* padding-bottom: 45px; */
  background-color: #fff;
  /* background: linear-gradient(#daf5e6, rgba(255, 255, 255, 0)); */
}
.invest_in {
  padding: 40px 0;
}

.invest_top {
  display: block;
  padding: 20px 24px 0;
}
.invest_top .num {
  vertical-align: top;
}
.invest_top .invest_text {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #444442;
}
.invest_top .invest_text em {
  display: block;
  margin-top: 8px;
  font-size: 25px;
  line-height: 32px;
  color: #111827;
  font-weight: 700;
}
.invest_top .invest_icon {
  position: absolute;
  top: 0;
  right: 0;
}

.invest_make_visual {
  padding: 30px 24px 0px;
}

.invest_make_visual > ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.invest_make_visual > ul > li > span {
  display: block;
  font-size: 14px;
}
.invest_make_visual > ul > li em {
  font-size: 24px;
  font-weight: bold;
}
.invest_make_visual > ul > li svg {
  font-size: 20px;
  color: #00c73c;
}
.invest_make_visual > ul > li:first-child {
  text-align: left;
}
.invest_make_visual > ul > li .num {
  display: flex;
  align-items: center;
}
.invest_make_visual > ul > li:nth-child(2) {
  text-align: left;
}
.invest_make_visual > ul > li:last-child {
  text-align: right;
}
.invest_make_visual > .line_wrap {
  width: 100%;
  height: 6px;
  position: relative;
}
.invest_make_visual > .line_wrap > .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* background-color: #00c73c; */
  height: 6px;
}

.invest_make_visual > .line_wrap > .progress::-webkit-progress-bar {
  background: #f3f4f6;
  border-radius: 10px;
  /* box-shadow: inset 3px 3px 10px #ccc; */
}
.invest_make_visual > .line_wrap > progress::-webkit-progress-value {
  border-radius: 10px;
  background: #00c73c;
  /* background: -webkit-linear-gradient(to right, #93f9b9, #1d976c);
  background: linear-gradient(to right, #93f9b9, #1d976c); */
}
.invest_make_visual > .line_text_box {
  display: flex;
  justify-content: space-between;
}
.invest_make_visual > .line_text_box > span:first-child {
  color: #00c73c;
  font-size: 13px;
}
.invest_make_visual > .line_text_box > span:last-child {
  /* color: #00c73c; */
  font-size: 12px;
}

/* 
.invest .visual_inner {
  margin-top: 0;
  height: auto;
}
.invest .visual_inner ul li {
  width: calc(33.3% - 3px);
} */

/* money */
.money {
  position: relative;
  /* padding: 40px 0 10px; */
  padding: 10px 0;
  /* background-color: #fff; */
}
.money_1 {
  width: 100%;
  padding: 30px 24px 20px;
  /* background-color: red; */
  border-top: 2px solid #ebeef3;
  /* padding-top: 30px; */
}
.money_2 {
  width: 100%;
  padding: 30px 24px;
  /* background-color: red; */
  border-top: 2px solid #ebeef3;
}
.money:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.money h2 {
  margin-bottom: 18px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.money .money_text {
  /* margin-top: 24px; */
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  font-weight: 400;
  text-align: center;
}
.money_list {
  padding-bottom: 10px;
  /* border-bottom: 1px solid #e5e7eb; */
  font-size: 0;
  line-height: 0;
}
.money_list li {
  display: inline-block;
  position: relative;
  width: 25%;
  padding: 12px 4px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}

.money_list.easy_list li {
  display: inline-block;
  position: relative;
  width: 33.333%;
  padding: 12px 4px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.money_list li:before {
  display: block;
  position: absolute;
  top: calc(50% - 7px);
  right: 0;
  width: 8px;
  height: 14px;
  background: url(../assets/images//farm_arrow.png) center center no-repeat;
}
.money_list li:last-child:before {
  display: none;
}
.money_list li span {
  margin-top: 7px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #1f2937;
}

/* safe */
.safe {
  position: relative;
  padding: 40px 48px 38px;
  background-color: #fff;
}
.safe:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.safe h2 {
  margin-bottom: 8px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.safe .safe_text {
  font-size: 15px;
  line-height: 22px;
  color: #374151;
  font-weight: 500;
}
.safe .safe_list {
  padding: 16px 0;
}
.safe .safe_list .slick-slide {
  width: 260px;
  margin-right: 12px;
}
.safe .safe_item {
  height: 236px;
  padding: 18px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  margin-right: 20px;
}
.safe .safe_item .num {
  display: block;
  font-size: 26px;
  line-height: 37px;
  font-weight: 800;
  color: #bbb;
}
.safe .safe_item .title {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 700;
  color: #00c73c;
}
.safe .safe_item p {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #374151;
  word-break: keep-all;
}

/* protect */
.protect {
  position: relative;
  padding: 40px 48px 38px;
  background-color: #fff;
}
.protect:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.protect h2 {
  margin-bottom: 8px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.protect .protect_text {
  font-size: 15px;
  line-height: 22px;
  color: #374151;
  font-weight: 400;
}
.protect .protect_list {
  margin-top: 40px;
}
.protect .protect_list li {
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 22px;
  color: #4b5563;
}
.protect .protect_list li:last-child {
  margin-bottom: 0;
}
.protect .protect_list li span {
  display: block;
  font-size: 17px;
  line-height: 27px;
  color: #111827;
  font-weight: 700;
}
.protect .protect_list li em {
  margin-right: 7px;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  color: #00c73c;
}
.protect .protect_list li p {
  margin-left: 26px;
  font-size: 15px;
  letter-spacing: -0.1px;
}

/* easy */
.easy {
  position: relative;
  padding: 32px 48px 42px;
  background-color: #fff;
}
/* .easy:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
.easy h2 {
  margin-bottom: 8px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.easy .easy_text {
  font-size: 15px;
  line-height: 22px;
  color: #374151;
  font-weight: 400;
}
.easy .money_list {
  margin-top: 12px;
  border-bottom: 0;
}

@media all and (max-width: 1100px) {
  /* invest */
  .invest {
    position: relative;
    padding: 0px;
    /* padding: 90px 24px 0px; */
    /* padding-bottom: 45px; */
    background-color: #fff;
    /* background: linear-gradient(#daf5e6, rgba(255, 255, 255, 0)); */
  }
  .invest_in {
    padding: 40px 0;
  }

  .invest_top {
    display: block;
    padding: 16px 24px 0;
  }
  .invest_top .num {
    vertical-align: top;
  }
  .invest_top .invest_text {
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: #444442;
  }
  .invest_top .invest_text em {
    display: block;
    margin-top: 8px;
    font-size: 25px;
    line-height: 32px;
    color: #111827;
    font-weight: 700;
  }
  .invest_top .invest_icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  .invest_make_visual {
    padding: 30px 24px 0px;
  }

  .invest_make_visual > ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .invest_make_visual > ul > li > span {
    display: block;
    font-size: 14px;
  }
  .invest_make_visual > ul > li em {
    font-size: 24px;
    font-weight: bold;
  }
  .invest_make_visual > ul > li svg {
    font-size: 20px;
    color: #00c73c;
  }
  .invest_make_visual > ul > li:first-child {
    text-align: left;
  }
  .invest_make_visual > ul > li .num {
    display: flex;
    align-items: center;
  }
  .invest_make_visual > ul > li:nth-child(2) {
    text-align: left;
  }
  .invest_make_visual > ul > li:last-child {
    text-align: right;
  }
  .invest_make_visual > .line_wrap {
    width: 100%;
    height: 6px;
    position: relative;
  }
  .invest_make_visual > .line_wrap > .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* background-color: #00c73c; */
    height: 6px;
  }

  .invest_make_visual > .line_wrap > .progress::-webkit-progress-bar {
    background: #f3f4f6;
    border-radius: 10px;
  }
  .invest_make_visual > .line_wrap > progress::-webkit-progress-value {
    border-radius: 10px;
    background: #00c73c;
  }
  .invest_make_visual > .line_text_box {
    display: flex;
    justify-content: space-between;
  }
  .invest_make_visual > .line_text_box > span:first-child {
    color: #00c73c;
    font-size: 13px;
  }
  .invest_make_visual > .line_text_box > span:last-child {
    /* color: #00c73c; */
    font-size: 12px;
  }

  /* money */
  .money {
    position: relative;
    /* padding: 40px 0 10px; */
    padding: 10px 20px;
    /* background-color: #fff; */
  }
  .money_1 {
    width: 100%;
    padding: 26px 24px 14px;
    /* background-color: red; */
    border-top: 2px solid #ebeef3;
    /* padding-top: 30px; */
  }
  .money_2 {
    width: 100%;
    padding: 24px 20px;
    /* background-color: red; */
    border-top: 2px solid #ebeef3;
  }
  .money:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  }
  .money h2 {
    margin-bottom: 18px;
    font-size: 21px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .money .money_text {
    /* margin-top: 24px; */
    font-size: 14px;
    line-height: 20px;
    color: #374151;
    font-weight: 400;
    text-align: center;
  }
  .money_list {
    padding-bottom: 10px;
    /* border-bottom: 1px solid #e5e7eb; */
    font-size: 0;
    line-height: 0;
  }
  .money_list li {
    display: inline-block;
    position: relative;
    width: 25%;
    padding: 12px 4px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }

  .money_list.easy_list li {
    display: inline-block;
    position: relative;
    width: 33.333%;
    padding: 12px 4px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }
  .money_list li:before {
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    right: 0;
    width: 8px;
    height: 14px;
    background: url(../assets/images//farm_arrow.png) center center no-repeat;
  }
  .money_list li:last-child:before {
    display: none;
  }
  .money_list li span {
    margin-top: 7px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #1f2937;
  }

  /* safe */
  .safe {
    position: relative;
    padding: 36px 48px 34px;
    /* background-color: red;; */
  }
  .safe:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  }
  .safe h2 {
    margin-bottom: 8px;
    font-size: 21px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .safe .safe_text {
    font-size: 15px;
    line-height: 22px;
    color: #374151;
    font-weight: 500;
  }
  .safe .safe_list {
    padding: 16px 0;
  }
  .safe .safe_list .slick-slide {
    width: 260px;
    margin-right: 12px;
  }
  .safe .safe_item {
    height: 236px;
    padding: 18px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    margin-right: 20px;
  }
  .safe .safe_item .num {
    display: block;
    font-size: 26px;
    line-height: 37px;
    font-weight: 800;
    color: #bbb;
  }
  .safe .safe_item .title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 23px;
    font-weight: 700;
    color: #00c73c;
  }
  .safe .safe_item p {
    font-size: 15px;
    line-height: 26px;
    font-weight: 400;
    color: #374151;
    word-break: keep-all;
  }

  /* protect */
  .protect {
    position: relative;
    padding: 36px 48px 34px;
    background-color: #fff;
  }
  .protect:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  }
  .protect h2 {
    margin-bottom: 8px;
    font-size: 21px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .protect .protect_text {
    font-size: 15px;
    line-height: 22px;
    color: #374151;
    font-weight: 400;
  }
  .protect .protect_list {
    margin-top: 40px;
  }
  .protect .protect_list li {
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 22px;
    color: #4b5563;
  }
  .protect .protect_list li:last-child {
    margin-bottom: 0;
  }
  .protect .protect_list li span {
    display: block;
    font-size: 17px;
    line-height: 27px;
    color: #111827;
    font-weight: 700;
  }
  .protect .protect_list li em {
    margin-right: 7px;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    color: #00c73c;
  }
  .protect .protect_list li p {
    margin-left: 26px;
    font-size: 15px;
    letter-spacing: -0.1px;
  }

  /* easy */
  .easy {
    position: relative;
    padding: 28px 48px 38px;
    background-color: #fff;
  }
  /* .easy:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
  .easy h2 {
    margin-bottom: 8px;
    font-size: 21px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .easy .easy_text {
    font-size: 15px;
    line-height: 22px;
    color: #374151;
    font-weight: 400;
  }
  .easy .money_list {
    margin-top: 12px;
    border-bottom: 0;
  }
}
@media all and (max-width: 890px) {
  /* invest */
  .invest {
  }
  .invest_in {
    padding: 40px 0;
  }

  .invest_top {
    display: block;
    padding: 20px 24px 0;
  }
  .invest_top .num {
    vertical-align: top;
  }
  .invest_top .invest_text {
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: #444442;
  }
  .invest_top .invest_text em {
    display: block;
    margin-top: 8px;
    font-size: 25px;
    line-height: 32px;
    color: #111827;
    font-weight: 700;
  }
  .invest_top .invest_icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  .invest_make_visual {
    padding: 30px 24px 0px;
  }

  .invest_make_visual > ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .invest_make_visual > ul > li > span {
    display: block;
    font-size: 14px;
  }
  .invest_make_visual > ul > li em {
    font-size: 24px;
    font-weight: bold;
  }
  .invest_make_visual > ul > li svg {
    font-size: 20px;
    color: #00c73c;
  }
  .invest_make_visual > ul > li:first-child {
    text-align: left;
  }
  .invest_make_visual > ul > li .num {
    display: flex;
    align-items: center;
  }
  .invest_make_visual > ul > li:nth-child(2) {
    text-align: left;
  }
  .invest_make_visual > ul > li:last-child {
    text-align: right;
  }
  .invest_make_visual > .line_wrap {
    width: 100%;
    height: 6px;
    position: relative;
  }
  .invest_make_visual > .line_wrap > .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* background-color: #00c73c; */
    height: 6px;
  }

  .invest_make_visual > .line_wrap > .progress::-webkit-progress-bar {
    background: #f3f4f6;
    border-radius: 10px;
  }
  .invest_make_visual > .line_wrap > progress::-webkit-progress-value {
    border-radius: 10px;
    background: #00c73c;
  }
  .invest_make_visual > .line_text_box {
    display: flex;
    justify-content: space-between;
  }
  .invest_make_visual > .line_text_box > span:first-child {
    color: #00c73c;
    font-size: 13px;
  }
  .invest_make_visual > .line_text_box > span:last-child {
    /* color: #00c73c; */
    font-size: 12px;
  }

  /* money */
  .money {
    position: relative;
    /* padding: 40px 0 10px; */
    padding: 10px 20px;
    /* background-color: #fff; */
  }
  .money_1 {
    width: 100%;
    padding: 20px 24px 10px;
    /* background-color: red; */
    border-top: 2px solid #ebeef3;
    /* padding-top: 30px; */
  }
  .money_2 {
    width: 100%;
    padding: 20px 20px;
    /* background-color: red; */
    border-top: 2px solid #ebeef3;
  }
  .money:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #ebeef3;
  }
  .money h2 {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .money .money_text {
    /* margin-top: 24px; */
    font-size: 12px;
    line-height: 20px;
    color: #374151;
    font-weight: 400;
    text-align: center;
  }
  .money_list {
    padding-bottom: 8px;
  }
  .money_list li {
    display: inline-block;
    position: relative;
    width: 25%;
    padding: 10px 4px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }

  .money_list.easy_list li {
    display: inline-block;
    position: relative;
    width: 33.333%;
    padding: 12px 4px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }
  .money_list li:before {
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    right: 0;
    width: 8px;
    height: 14px;
    background: url(../assets/images//farm_arrow.png) center center no-repeat;
  }
  .money_list li:last-child:before {
    display: none;
  }
  .money_list li span {
    margin-top: 7px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #1f2937;
  }

  /* safe */
  .safe {
    position: relative;
    padding: 30px 48px 30px;
    /* background-color: red;; */
  }
  .safe:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #ebeef3;
  }
  .safe h2 {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .safe .safe_text {
    font-size: 12px;
    line-height: 20px;
    /* color: #374151;
    font-weight: 500; */
  }
  .safe .safe_list {
    padding: 16px 0;
  }

  .safe .safe_item {
    height: 236px;
    padding: 18px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    margin-right: 20px;
  }
  .safe .safe_item .num {
    display: block;
    font-size: 26px;
    line-height: 37px;
    font-weight: 800;
    color: #bbb;
  }
  .safe .safe_item .title {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 23px;
    font-weight: 700;
    color: #00c73c;
  }
  .safe .safe_item p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #374151;
    word-break: keep-all;
  }

  /* protect */
  .protect {
    position: relative;
    padding: 30px 48px 28px;
    background-color: #fff;
  }
  .protect:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #ebeef3;
  }
  .protect h2 {
    margin-bottom: 6px;
    font-size: 18px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .protect .protect_text {
    font-size: 12px;
    line-height: 20px;
    color: #374151;
    font-weight: 400;
  }
  .protect .protect_list {
    margin-top: 20px;
  }
  .protect .protect_list li {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #4b5563;
  }
  .protect .protect_list li:last-child {
    margin-bottom: 0;
  }
  .protect .protect_list li span {
    display: block;
    font-size: 15px;
    line-height: 27px;
    color: #111827;
    font-weight: 700;
  }
  .protect .protect_list li em {
    margin-right: 7px;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    color: #00c73c;
  }
  .protect .protect_list li p {
    margin-left: 26px;
    font-size: 15px;
    letter-spacing: -0.1px;
  }

  /* easy */
  .easy {
    position: relative;
    padding: 26px 48px 34px;
    background-color: #fff;
  }
  /* .easy:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
  .easy h2 {
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .easy .easy_text {
    font-size: 12px;
    line-height: 20px;
    /* color: #374151;
    font-weight: 400; */
  }
  .easy .money_list {
    margin-top: 10px;
    border-bottom: 0;
  }
}

@media all and (max-width: 660px) {
  /* invest */
  .invest {
  }
  .invest_in {
    padding: 40px 0;
  }

  .invest_top {
    display: block;
    padding: 20px 24px 0;
  }
  .invest_top .num {
    vertical-align: top;
  }
  .invest_top .invest_text {
    position: relative;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: #444442;
  }
  .invest_top .invest_text em {
    display: block;
    margin-top: 8px;
    font-size: 25px;
    line-height: 32px;
    color: #111827;
    font-weight: 700;
  }
  .invest_top .invest_icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  .invest_make_visual {
    padding: 30px 24px 0px;
  }

  .invest_make_visual > ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .invest_make_visual > ul > li > span {
    display: block;
    font-size: 14px;
  }
  .invest_make_visual > ul > li em {
    font-size: 24px;
    font-weight: bold;
  }
  .invest_make_visual > ul > li svg {
    font-size: 20px;
    color: #00c73c;
  }
  .invest_make_visual > ul > li:first-child {
    text-align: left;
  }
  .invest_make_visual > ul > li .num {
    display: flex;
    align-items: center;
  }
  .invest_make_visual > ul > li:nth-child(2) {
    text-align: left;
  }
  .invest_make_visual > ul > li:last-child {
    text-align: right;
  }
  .invest_make_visual > .line_wrap {
    width: 100%;
    height: 6px;
    position: relative;
  }
  .invest_make_visual > .line_wrap > .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* background-color: #00c73c; */
    height: 6px;
  }

  .invest_make_visual > .line_wrap > .progress::-webkit-progress-bar {
    background: #f3f4f6;
    border-radius: 10px;
  }
  .invest_make_visual > .line_wrap > progress::-webkit-progress-value {
    border-radius: 10px;
    background: #00c73c;
  }
  .invest_make_visual > .line_text_box {
    display: flex;
    justify-content: space-between;
  }
  .invest_make_visual > .line_text_box > span:first-child {
    color: #00c73c;
    font-size: 13px;
  }
  .invest_make_visual > .line_text_box > span:last-child {
    /* color: #00c73c; */
    font-size: 12px;
  }

  /* money */
  .money {
    /* position: relative; */
    /* padding: 40px 0 10px; */
    padding: 10px 20px;
    /* background-color: red; */
    /* background-color: #fff; */
  }
  .money_1 {
    width: 100%;
    padding: 10px 4px 6px;
    /* background-color: red; */
    border-top: 2px solid #ebeef3;
    /* padding-top: 30px; */
  }
  .money_2 {
    width: 100%;
    padding: 20px 20px;
    /* background-color: red; */
    border-top: 2px solid #ebeef3;
  }
  .money:before {
    height: 5px;
  }
  .money h2 {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
  }
  .money .money_text {
    /* margin-top: 24px; */
    font-size: 12px;
    line-height: 20px;
  }
  .money_list {
    padding-bottom: 6px;
  }
  .money_list li {
    display: inline-block;
    position: relative;
    width: 25%;
    padding: 10px 4px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }

  .money_list.easy_list li {
    display: inline-block;
    position: relative;
    width: 33.333%;
    padding: 12px 4px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }
  .money_list li:before {
    display: block;
    position: absolute;
    top: calc(50% - 7px);
    right: 0;
    width: 8px;
    height: 14px;
    background: url(../assets/images//farm_arrow.png) center center no-repeat;
  }
  .money_list li:last-child:before {
    display: none;
  }
  .money_list li span {
    margin-top: 7px;
    font-size: 12px;
  }

  /* safe */
  .safe {
    position: relative;
    padding: 30px 24px 30px;
    /* background-color: red;; */
  }
  .safe:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #ebeef3;
  }
  .safe h2 {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    font-weight: 600;
  }
  .safe .safe_text {
    font-size: 10px;
    line-height: 15px;
    /* color: #374151;
    font-weight: 500; */
  }
  .safe .safe_list {
    padding: 14px 0;
  }

  .safe .safe_item {
    height: 200px;
    padding: 14px;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    margin-right: 14px;
  }
  .safe .safe_item .num {
    display: block;
    font-size: 24px;
    line-height: 37px;
    font-weight: 800;
    color: #bbb;
  }
  .safe .safe_item .title {
    margin-bottom: 4px;
    font-size: 10px;
    line-height: 23px;
    font-weight: 700;
    color: #00c73c;
  }
  .safe .safe_item p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #374151;
    word-break: keep-all;
  }

  /* protect */
  .protect {
    position: relative;
    padding: 24px 24px 22px;
    background-color: #fff;
  }
  .protect:before {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #ebeef3;
  }
  .protect h2 {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .protect .protect_text {
    font-size: 12px;
    line-height: 20px;
    color: #374151;
    font-weight: 400;
  }
  .protect .protect_list {
    margin-top: 20px;
  }
  .protect .protect_list li {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 20px;
    color: #4b5563;
  }
  .protect .protect_list li:last-child {
    margin-bottom: 0;
  }
  .protect .protect_list li span {
    display: block;
    font-size: 12px;
    line-height: 27px;
    color: #111827;
    font-weight: 700;
  }
  .protect .protect_list li em {
    margin-right: 7px;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: #00c73c;
  }
  .protect .protect_list li p {
    margin-left: 26px;
    font-size: 13px;
    letter-spacing: -0.1px;
  }

  /* easy */
  .easy {
    position: relative;
    padding: 26px 24px 34px;
    background-color: #fff;
  }
  /* .easy:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
  .easy h2 {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 30px;
    color: #111827;
    font-weight: 600;
  }
  .easy .easy_text {
    font-size: 10px;
    line-height: 20px;
    /* color: #374151;
    font-weight: 400; */
  }
  .easy .money_list {
    margin-top: 10px;
    border-bottom: 0;
  }
}

/* income */
.income {
  position: relative;
  padding: 50px 48px;
  background-color: #ebeef3;
}
.income_bottom {
  padding: 20px 0;
  margin-top: 40px;
  border-top: 2px solid #fff;
}
/* .income:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
.income .income_text_span {
  font-size: 12px;
  color: #00c73c;
  width: 100%;
  text-align: right;
}
.income .income_text {
  font-size: 14px;
  line-height: 20px;
  color: #111827;
  font-weight: 500;
  background-color: #fff;
  padding: 15px 10px;
  border-radius: 8px;
  width: 100%;
}
.income .income_text em {
  /* display: block; */
  /* margin-bottom: 5px; */
  font-size: 30px;
  line-height: 32px;
  color: #00c73c;
  font-weight: 700;
  letter-spacing: -0.45px;
  /* texa */
}
.income .income_text em span {
  /* display: block; */
  /* margin-bottom: 5px; */
  font-size: 30px;

  font-weight: 700;
}
.income .income_text span {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 27px;
  /* width: 100%; */
}
.income .income_text > div {
  text-align: right;
}
.income .income_text > div em {
  width: 80%;
}
.income .income_input {
  width: 100%;
  margin-top: 20px;
  padding: 17px 16px 15px 16px;
  /* border: 1px solid #000; */
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
}
.income .income_input .front_text {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #757575;
  padding-top: 2px;
}
.income .income_input .text_box {
  /* display: inline-block; */
  display: flex;
  width: calc(100% - 60px);
  text-align: right;
  align-items: center;
  /* vertical-align: top; */
  /* background: black; */
}

.income .income_input .back_text {
  /* padding-top: 2px; */
}

.income .income_box {
  width: calc(100% - 32px);
  text-align: right;
  height: 100%;
  font-size: 19px;
  /* line-height: 10px; */
  /* font-weight: 700; */
  line-height: 0;
  color: #000;
  /* background-color: red; */
}
.income .income_list {
  margin-top: 25px;
  font-size: 0;
  line-height: 0;
}
.income .income_list li {
  display: inline-block;
  width: calc(25% - 5px);
  margin-right: 5px;
  text-align: center;
  font-size: 0;
  line-height: 0;
}
.income .income_list li:last-child {
  margin-right: 5px;
}
.income .income_btn {
  width: 100%;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
  line-height: 50px;
  font-weight: 700;
  color: #000;
}
.income h4 {
  font-size: 24px;
  font-weight: 600;
}
.income h4 span {
  font-size: 12px;
  color: #00c73c;
}
@media all and (max-width: 890px) {
  .income {
    padding: 30px 48px;
  }
  .income_bottom {
    padding: 14px 0;
    margin-top: 30px;
  }
  /* .income:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
  .income .income_text {
    font-size: 12px;
    /* background-color: red !important; */
    line-height: 20px;
    color: #111827;
    font-weight: 500;
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
  }
  .income .income_text em {
    /* display: block; */
    /* margin-bottom: 5px; */
    font-size: 25px;
    line-height: 32px;
    color: #00c73c;
    font-weight: 700;
    letter-spacing: -0.45px;
    /* texa */
  }
  .income .income_text span {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 27px;
    /* width: 100%; */
  }
  .income .income_text > div {
    text-align: right;
  }
  .income .income_text > div em {
    width: 80%;
  }
  .income .income_input {
    width: 100%;
    margin-top: 20px;
    padding: 15px 14px 13px 14px;
    /* border: 1px solid #000; */
    border-radius: 6px;
  }
  .income .income_input .front_text {
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    color: #757575;
    padding-top: 2px;
    /* background-color: red; */
  }
  .income .income_input .text_box {
    /* display: inline-block; */
    width: calc(100% - 60px);
    text-align: right;
    /* vertical-align: top; */
  }
  .income .income_input .back_text {
    padding-top: 2px;
  }

  .income .income_box {
    width: calc(100% - 32px);
    text-align: right;
    font-size: 18px;
    line-height: 24px;
    /* font-weight: 700; */
    color: #000;
    /* background-color: red; */
  }
  .income .income_list {
    margin-top: 16px;
    font-size: 0;
    line-height: 0;
  }
  .income .income_list li {
    display: inline-block;
    width: calc(25% - 5px);
    margin-right: 5px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }
  .income .income_list li:last-child {
    margin-right: 5px;
  }
  .income .income_btn {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    font-size: 12px;
    line-height: 50px;
    font-weight: 700;
    color: #000;
  }
  .income h4 {
    font-size: 24px;
    font-weight: 600;
  }
}

@media all and (max-width: 660px) {
  .income {
    padding: 24px 24px;
  }
  .income_bottom {
    padding: 10px 0;
    margin-top: 24px;
  }
  /* .income:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
} */
  .income .income_text {
    font-size: 12px;
    /* background-color: red !important; */
    line-height: 20px;
    color: #111827;
    font-weight: 500;
    background-color: #fff;
    padding: 8px;
    border-radius: 6px;
    width: 100%;
  }
  .income .income_text em {
    /* display: block; */
    /* margin-bottom: 5px; */
    font-size: 20px;
    line-height: 32px;
    color: #00c73c;
    font-weight: 700;
    letter-spacing: -0.45px;
    /* texa */
  }
  .income .income_text span {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 27px;
    /* width: 100%; */
  }
  .income .income_text > div {
    text-align: right;
  }
  .income .income_text > div em {
    width: 80%;
  }
  .income .income_input {
    width: 100%;
    margin-top: 16px;
    padding: 15px 14px 13px 14px;
    /* border: 1px solid #000; */
    border-radius: 6px;
  }
  .income .income_input .front_text {
    font-size: 12px;
    line-height: 19px;
    font-weight: 500;
    color: #757575;
    padding-top: 2px;
    /* background-color: red; */
  }
  .income .income_input .text_box {
    /* display: inline-block; */
    width: calc(100% - 60px);
    text-align: right;
    /* vertical-align: top; */
  }
  .income .income_input .back_text {
    padding-top: 2px;
  }

  .income .income_box {
    width: calc(100% - 32px);
    text-align: right;
    font-size: 16px;
    line-height: 24px;
    /* font-weight: 700; */
    color: #000;
    /* background-color: red; */
  }
  .income .income_list {
    margin-top: 12px;
    font-size: 0;
    line-height: 0;
  }
  .income .income_list li {
    display: inline-block;
    width: calc(25% - 5px);
    margin-right: 5px;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }
  .income .income_list li:last-child {
    margin-right: 5px;
  }
  .income .income_btn {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    font-size: 12px;
    line-height: 50px;
    font-weight: 700;
    color: #000;
  }
  .income h4 {
    font-size: 24px;
    font-weight: 600;
  }
}

.payment_agree_wrap {
  width: 100%;
  padding: 24px;
  background-color: #fff;
  margin-bottom: 10px;
}
.payment_agree_wrap .payment_agree_title {
  font-size: 18px;
  font-weight: 700;
}
.payment_agree_step > label {
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.payment_agree_step_box {
  padding: 10px;
  width: 100%;
  height: auto;
  /* background-color: red; */
  /* border: 1px solid #374151; */
  border-radius: 5px;
  background-color: #d3f1e2;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.payment_agree_step .label_public_box {
  color: #919191;
  font-weight: 500;
}
.payment_agree_step .label_public_box.label_color {
  color: #000;
  font-weight: 600;
}

.payment_agree_step_box .label_public_box {
  color: #919191;
  font-size: 14px;
  /* font-weight: 500; */
}
.payment_agree_step_box .label_public_box.label_color {
  color: #000;
  font-size: 14px;
  /* font-weight: 600; */
}

.payment_agree_step .check_public_box {
  color: #919191;
}

.payment_agree_step .check_public_box.check_color {
  color: #00c73c;
}

.payment_agree_step_box .check_public_box {
  color: #919191;
  font-size: 18px;
}

.payment_agree_step_box .check_public_box.check_color {
  color: #00c73c;
  font-size: 18px;
}

.payment_agree_step_box > div {
  display: flex;
  justify-content: space-between;
}

.payment_agree_step_box > div > label {
  display: flex;
  cursor: pointer;
}

.make_payment_method {
  width: 100%;
  padding: 24px;
  background: #fff;
}
.make_payment_method_title {
  font-size: 18px;
  font-weight: 700;
}
.payment_method_div {
  display: flex;
  margin-top: 10px;
  height: 50px;
  width: 100%;
}
.payment_method_div > .payment_method {
  /* width: 200px; */
  width: 25%;
  margin-right: 5px;
  text-align: center;
  line-height: 50px;
  border: 2px solid #a2a2a2;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}
.payment_method.payment_color {
  border: 3px solid #000;
}
.payment_method_div > div > span {
  font-size: 20px;
  margin-right: 3px;
}

/* campaign_anchor */
.make_product {
  /* border: 3px solid #006e4d; */
  /* margin-bottom: 10px; */
  background-color: #00c73c;
  color: #fff;
  margin-bottom: 100px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.make_product .make_product_a {
  display: block;
  position: relative;
  width: 100%;
  padding: 24px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.make_product_a > em {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.make_product svg {
  font-size: 30px;
}
.make_product .anchor_text {
  position: relative;
  font-size: 0;
  line-height: 0;
  color: #fff;
}
.make_product .anchor_text:before {
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  width: 10px;
  height: 16px;
  /* background: url(/site/public/images/campaign_go_arrow.png) center center
    no-repeat; */
}
.make_product .anchor_text span {
  font-size: 15px;
  line-height: 22px;
  /* color: #111827; */
  font-weight: 300;
}
.make_product .anchor_text em {
  display: block;
  font-size: 21px;
  line-height: 30px;
  /* color: #006e4d; */
  font-weight: 700;
}

/* story */
.story {
  position: relative;
  padding: 56px 0 55px;
  background-color: #fff;
}
.story:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.story .story_item {
  padding-bottom: 100px;
}
.story .story_item:last-child {
  padding-bottom: 0;
}
.story .story_item.n2 {
  margin-left: 220px;
}
.story .story_item.n3 {
  width: 312px;
}
.story .story_title {
  margin-bottom: 70px;
  padding: 0 24px;
  font-size: 30px;
  line-height: 50px;
  color: #111827;
  font-weight: 700;
}
.story .story_text {
  padding: 0 24px;
}
.story .story_img {
  margin-bottom: 24px;
}
.story .story_item.n2 .story_img {
  margin-right: 25px;
  border-radius: 8px;
  overflow: hidden;
}
.story .story_item.n2 .story_img img {
  width: 100%;
}
.story .story_item.n3 .story_img {
  margin-left: 25px;
  border-radius: 8px;
  overflow: hidden;
}
.story .story_item.n3 .story_img img {
  width: 100%;
}
.story .story_text .title {
  margin-bottom: 24px;
  font-size: 26px;
  line-height: 40px;
  color: #6b7280;
  letter-spacing: -0.2px;
  font-weight: 700;
}
.story .story_text em {
  color: #00a370;
}
.story .story_text em.em_black {
  color: #111827;
}
.story .story_text p {
  font-size: 18px;
  line-height: 30px;
  color: #374151;
  font-weight: 500;
}
.story .story_text p + p {
  margin-top: 40px;
}

@media all and (max-width: 640px) {
  .story .story_item.n2 {
    margin-left: 0;
    text-align: right;
  }
}

/* building */
.building {
  position: relative;
  padding: 48px 24px 58px;
  background-color: #fff;
}
.building:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.building h2 {
  margin-bottom: 18px;
  font-size: 21px;
  line-height: 30px;
  color: #111827;
  font-weight: 600;
}
.building_list {
  height: 220px;
  border-radius: 8px;
  overflow: hidden;
}
.building_list .building_item {
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.building .building_table {
  margin: 40px 0 20px;
}
.building .building_table .table {
  border-top: 2px solid #d1d5db;
}
.building .building_table .table th {
  padding: 10px 12px;
  background-color: #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  color: #111827;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
}
.building .building_table .table td {
  padding: 10px 12px;
  border-bottom: 1px solid #e5e7eb;
  color: #4b5563;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  text-align: left;
}
.building .building_table .table tr:last-child th,
.building .building_table .table tr:last-child td {
  border-left: 0;
  border-bottom: 0;
}
.building .building_text {
}
.building .building_text li {
  position: relative;
  margin-bottom: 6px;
  padding-left: 18px;
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
  font-weight: 300;
}
.building .building_text li:before {
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #9ca3af;
}

/* qna */
.qna.n2 {
  position: relative;
  background-color: #fff;
}
.qna.n2:before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: #ebeef3;
}
.qna.n3 {
  padding: 0 24px 80px;
  background-color: #fff;
}
.qna.n3 .qna_item ol {
  padding-left: 18px;
}
.qna.n3 .qna_item ol li {
  font-size: 14px;
  line-height: 24px;
  color: #111827;
  letter-spacing: -0.1px;
  font-weight: 300;
}
.qna.n3 .qna_item ol > li > ol {
  margin-bottom: 6px;
}
.qna.n3 .qna_item ul {
}
.qna.n3 .qna_item ul li {
  width: 100%;
  padding: 12px 0;
  font-size: 0;
  line-height: 0;
}
.qna.n3 .qna_item ul li span {
  display: inline-block;
  width: calc(100% - 40px);
  vertical-align: top;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  color: #424342;
}
.qna.n3 .qna_item ul li .qna_down {
  display: inline-block;
  width: 40px;
  vertical-align: top;
  font-size: 13px;
  line-height: 18px;
  color: #757575;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;
}

/* 오픈 페이지 */
.invest_open_page {
  width: 100%;
  min-height: 600px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  padding: 50px 0;
  /* align-items: center; */
  /* background-color: #0a9d71; */
}
.invest_open_wrap {
  width: 100%;
  height: 500px;
  display: flex;
  align-content: center;
}
.invest_open_page .invest_open_left {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invest_open_page h4 {
  font-size: 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  width: 60%;
  height: 100px;
  line-height: 100px;
  /* border-style: double;
  border-width: 4px;
  border-color: #fff; */
}
.invest_open_page .invest_open_right {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #0a9d71;
}

.invest_open_page .invest_open_right_box {
  background-color: #fff;
  width: auto;
  /* text-align: center; */
  height: auto;
  padding: 20px 30px 20px 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.invest_open_right .invest_open_title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-indent: 10px;
}

.invest_open_right .invest_open_subtit {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
  text-indent: 16px;
}

/* 나의 투자 보기 */
.invest_my_wrap {
}
.invest_my_content {
  padding-top: 40px;
}
.invest_my_content.invest_my_content_cancel {
  min-height: 1030px;
}
.invest_my_top {
  /* width: 407px; */
  width: 100%;
  margin: 0 auto;
}
.invest_my_top h4 {
  font-size: 27px;
  text-align: center;
}
.invest_my_top h4 em {
  color: #00c73c;
}
.invest_my_data_modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(177, 177, 177, 0.244);
  z-index: 998;
}

/* 투자 가입 상품 내역 스켈레톤 */
.skeleton_data_box {
  width: 100%;
  display: flex;
  height: 360px;
  justify-content: space-between;
}
.invest_my_data_box.invest_my_data_box_sk {
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden;
  width: calc(33.3% - 10px);
}
.invest_my_data_box.invest_my_data_box_sk::after {
  content: "";
  display: block;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #f2f2f2, #ddd);
  animation: loading 2s infinite linear;
  z-index: 1;
}

@media all and (max-width: 970px) {
  .skeleton_data_box {
    width: 100%;
    display: flex;
    height: 360px;
    justify-content: space-between;
  }
  .invest_my_data_box.invest_my_data_box_sk {
    background-color: #f2f2f2;
    position: relative;
    overflow: hidden;
    width: calc(50% - 10px);
  }
  .invest_my_data_box_sk3 {
    display: none;
  }
}
@media all and (max-width: 520px) {
  .skeleton_data_box {
    width: 100%;
    display: flex;
    height: 320px;
    justify-content: space-between;
  }
  .invest_my_data_box.invest_my_data_box_sk {
    background-color: #f2f2f2;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .invest_my_data_box_sk2 {
    display: none;
  }
}

/* 투자 가입 내역이 없을떄 */
.invest_my_data_not {
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #898989;
  font-size: 15px;
  /* background-color: red; */
}
.invest_my_data_not h4 {
  display: flex;
  align-items: center;
}
/* 투자가입상품내역 */
.invest_my_top_cont {
  margin-top: 30px;
  position: relative;
  /* background-color: red; */
}
.invest_my_data_box {
  /* width: 407px; */
  /* width: calc(33%-10px); */
  background: #00c73c;
  height: auto;
  border-radius: 4px;
  padding: 30px 20px;
  color: #fff;
  margin-right: 10px;
  /* margin-top: 30px; */
}

.invest_my_data_box .invest_my_data_box_1 {
  margin-bottom: 40px;
  margin-top: 10px;
}
.invest_my_data_box_2 {
  margin-bottom: 30px;
  line-height: 30px;
}
.invest_my_data_box span {
  font-size: 15px;
}
.invest_my_data_box p {
  font-size: 21px;
}
.invest_my_data_box em {
  font-size: 31px;
  font-weight: 600;
}
.invest_my_data_box em span {
  font-size: 31px;
  font-weight: 600;
}
.invest_my_data_top {
  width: 100%;
  border-bottom: 2px solid #fff;
  padding-bottom: 9px;
}
.invest_my_data_top_div {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.invest_my_data_top_div > div {
  line-height: 9px;
  flex: 2;
}
.invest_my_data_top_div > div > span {
  font-size: 12px;
  line-height: 18px;
  display: block;
}
.invest_my_data_top_div > span {
  font-size: 12px;
}
.invest_my_data_box .invest_my_data_btn button {
  width: 100%;
  height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  color: #000;
  margin-bottom: 3px;
}

.invest_my_bottom {
  width: 100%;
  height: auto;
  margin-top: 30px;

  /* padding-top: 5px; */
}
.invest_my_bottom h4 {
  /* width: 407px; */
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
  /* text-align: center; */
}
.invest_my_bottom .invest_my_list_box {
  width: 100%;
  height: auto;

  position: relative;
}
.invest_my_wrap .invest_myList_item {
  border: 2px solid #00c73c;
  border-radius: 5px;
  margin-right: 4px;
  padding: 40px 20px;
}
.invest_my_wrap .invest_myList_item .invest_myList_title {
  font-size: 19px;
}

.invest_my_wrap .invest_myList_item .invest_myList_join {
  font-size: 14px;
}
.invest_my_wrap .invest_myList_item ul {
  margin-top: 25px;
  font-size: 15px;
}
.invest_my_wrap .invest_myList_item ul li {
  display: flex;
  justify-content: space-between;
}
.invest_my_wrap .invest_myList_item .myList_value {
  font-size: 18px;
  font-weight: 500;
}
.myList_account_box button {
  width: 100%;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00a370;
  margin-top: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #00a370;
}

.invest_my_bottom .invest_my_list_box {
  width: 100%;
  height: auto;

  position: relative;
}
.invest_my_wrap .invest_myList_end_item {
  border: 2px solid #00c73c;
  border-radius: 5px;
  margin-right: 4px;
  padding: 40px 20px;
}
.invest_my_wrap .invest_myList_end_item .invest_myList_end_title {
  font-size: 19px;
}

.invest_my_wrap .invest_myList_end_item .invest_myList_end_join {
  font-size: 14px;
}
.invest_my_wrap .invest_myList_end_item ul {
  margin-top: 25px;
  font-size: 15px;
}
.invest_my_wrap .invest_myList_end_item ul li {
  display: flex;
  justify-content: space-between;
}
.invest_my_wrap .invest_myList_end_item ._myList_end_value {
  font-size: 18px;
  font-weight: 500;
}
.invest_myList_end_item button {
  width: 100%;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00a370;
  margin-top: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #00a370;
}
.invest_my_data_end_not {
  width: 100%;
  aspect-ratio: 3/0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #898989;
  font-size: 15px;
}
.em_white {
  font-weight: 600;
  color: #fff;
}
@media all and (max-width: 1100px) {
  .invest_my_content {
    padding: 30px 20px 0;
  }
  .invest_my_content.invest_my_content_cancel {
    min-height: 1030px;
  }
  .invest_my_top {
  }
  .invest_my_top h4 {
    font-size: 23px;
    /* text-align: center; */
  }

  .invest_my_bottom {
    margin-top: 24px;
  }
  .invest_my_bottom h4 {
    margin-bottom: 12px;
    font-size: 16px;
  }
  .invest_my_bottom .invest_my_list_box {
  }
  .invest_my_wrap .invest_myList_item {
    border-radius: 5px;
    margin-right: 4px;
    padding: 30px 20px;
  }
  .invest_my_wrap .invest_myList_item .invest_myList_title {
    font-size: 17px;
  }

  .invest_my_wrap .invest_myList_item .invest_myList_join {
    font-size: 13px;
  }
  .invest_my_wrap .invest_myList_item ul {
    margin-top: 18px;
    font-size: 15px;
  }
  .invest_my_wrap .invest_myList_item ul li {
  }
  .invest_my_wrap .invest_myList_item .myList_value {
    font-size: 14px;
    font-weight: 500;
  }
  .myList_account_box button {
    margin-top: 8px;
  }
  .invest_my_top_cont {
    margin-top: 24px;
  }

  .invest_my_content.invest_my_content_join {
    min-height: 730px;
  }

  .invest_my_data_box {
    background: #00c73c;
    height: auto;
    border-radius: 4px;
    padding: 20px 20px;
    color: #fff;
    /* margin-top: 30px; */
  }

  .invest_my_data_box .invest_my_data_box_1 {
    margin-bottom: 22px;
    margin-top: 10px;
  }
  .invest_my_data_box_2 {
    margin-bottom: 22px;
  }
  .invest_my_data_box span {
    font-size: 15px;
  }
  .invest_my_data_box p {
    font-size: 18px;
  }
  .invest_my_data_box em {
    font-size: 27px;
    font-weight: 600;
  }
  .invest_my_data_box em span {
    font-size: 27px;
    font-weight: 600;
  }
  .invest_my_data_top {
    width: 100%;
    border-bottom: 2px solid #fff;
    padding-bottom: 6px;
  }
  .invest_my_data_top_div {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }
  .invest_my_data_top_div > div {
    line-height: 9px;
    flex: 2;
  }
  .invest_my_data_top_div > div > span {
    font-size: 12px;
    line-height: 18px;
    display: block;
  }
  .invest_my_data_top_div > span {
    font-size: 12px;
  }
  .invest_my_data_box .invest_my_data_btn button {
    width: 100%;
    height: 36px;
    text-align: center;
    background: #fff;
    border-radius: 4px;
    color: #000;
    margin-bottom: 3px;
  }
  /* 
  .invest_my_bottom {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }
  .invest_my_bottom h4 {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
    text-align: center;
  }
  .invest_my_bottom .invest_my_list_box {
    width: 100%;
    height: auto;

    position: relative;
  }
  .invest_my_wrap .invest_myList_item {
    border: 2px solid #00c73c;
    border-radius: 5px;
    margin-right: 4px;
    padding: 40px 20px;
  }
  .invest_my_wrap .invest_myList_item .invest_myList_title {
    font-size: 19px;
  }

  .invest_my_wrap .invest_myList_item .invest_myList_join {
    font-size: 14px;
  }
  .invest_my_wrap .invest_myList_item ul {
    margin-top: 25px;
    font-size: 15px;
  }
  .invest_my_wrap .invest_myList_item ul li {
    display: flex;
    justify-content: space-between;
  }
  .invest_my_wrap .invest_myList_item .myList_value {
    font-size: 18px;
    font-weight: 500;
  } */
  /* .myList_account_box button {
    width: 100%;
    height: 34px;
    line-height: 34px;
    border: 1px solid #00a370;
    margin-top: 10px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    color: #00a370;
  } */
}
@media all and (max-width: 660px) {
  .invest_my_content {
    padding: 24px 20px 0;
  }
  .invest_my_content.invest_my_content_cancel {
    min-height: 780px;
  }
  .invest_my_top {
  }
  .invest_my_top h4 {
    font-size: 18px;
    /* text-align: center; */
  }

  .invest_my_bottom {
    margin-top: 16px;
  }
  .invest_my_bottom h4 {
    margin-bottom: 9px;
    font-size: 14px;
  }
  .invest_my_bottom .invest_my_list_box {
  }
  .invest_my_wrap .invest_myList_item {
    border-radius: 5px;
    margin-right: 4px;
    padding: 16px 12px;
  }
  .invest_my_wrap .invest_myList_item .invest_myList_title {
    font-size: 15px;
  }

  .invest_my_wrap .invest_myList_item .invest_myList_join {
    font-size: 14px;
  }
  .invest_my_wrap .invest_myList_item ul {
    margin-top: 8px;
    font-size: 14px;
  }
  .invest_my_wrap .invest_myList_item ul li {
    height: 24px;
  }
  .invest_my_wrap .invest_myList_item .myList_value {
    font-size: 14px;
    font-weight: 500;
  }
  .myList_account_box button {
    margin-top: 6px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .invest_my_content.invest_my_content_join {
    min-height: 530px;
  }

  .invest_my_data_box {
    background: #00c73c;
    height: auto;
    border-radius: 4px;
    padding: 16px 12px;
    color: #fff;
    /* margin-top: 30px; */
  }

  .invest_my_data_box .invest_my_data_box_1 {
    margin-bottom: 7px;
    margin-top: 3px;
  }
  .invest_my_data_box_2 {
    margin-bottom: 10px;
    line-height: 20px;
  }
  .invest_my_data_box span {
    font-size: 14px;
  }
  .invest_my_data_box p {
    font-size: 16px;
  }
  .invest_my_data_box em {
    font-size: 22px;
    font-weight: 600;
  }
  .invest_my_data_box em span {
    font-size: 22px;
    font-weight: 600;
  }
  .invest_my_data_top {
    width: 100%;
    border-bottom: 2px solid #fff;
    padding-bottom: 6px;
  }
  .invest_my_data_top_div {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }
  .invest_my_data_top_div > div {
    line-height: 9px;
    flex: 2;
  }
  .invest_my_data_top_div > div > span {
    font-size: 12px;
    line-height: 18px;
    display: block;
  }
  .invest_my_data_top_div > span {
    font-size: 12px;
  }
  .invest_my_data_box .invest_my_data_btn button {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
}
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  50%,
  100% {
    transform: translateX(460px);
  }
}
