@charset "UTF-8";

@import url("https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Noto+Sans+KR:wght@300;400;500;700&display=swap");

/* 본고딕 */
@font-face {
  font-family: "NotoKr";
  font-weight: 200;
  src: url("../assets/fonts/NotoSansCJKkr-Light.woff2") format("woff2"),
    url("../assets/fonts/NotoSansCJKkr-Light.woff") format("woff");
}
@font-face {
  font-family: "NotoKr";
  font-weight: 300;
  src: url("../assets/fonts/NotoSansCJKkr-DemiLight.woff2") format("woff2"),
    url("../assets/fonts/NotoSansCJKkr-DemiLight.woff") format("woff");
}
@font-face {
  font-family: "NotoKr";
  font-weight: 400;
  src: url("../assets/fonts/NotoSansCJKkr-Regular.woff2") format("woff2"),
    url("../assets/fonts/NotoSansCJKkr-Regular.woff") format("woff");
}
@font-face {
  font-family: "NotoKr";
  font-weight: 500;
  src: url("../assets/fonts/NotoSansCJKkr-Medium.woff2") format("woff2"),
    url("../assets/fonts/NotoSansCJKkr-Medium.woff") format("woff");
}
@font-face {
  font-family: "NotoKr";
  font-weight: 600;
  src: url("../assets/fonts/NotoSansCJKkr-Bold.woff2") format("woff2"),
    url("../assets/fonts/NotoSansCJKkr-Bold.woff") format("woff");
}

/* @font-face {
  font-family: "yoon";
  font-weight: 200;
  src: url("../assets/fonts/-윤고딕130.ttf") format("truetype");
}
@font-face {
  font-family: "yoon";
  font-weight: 300;
  src: url("../assets/fonts/-윤고딕140.ttf") format("truetype");
}
@font-face {
  font-family: "yoon";
  font-weight: 400;
  src: url("../assets/fonts/-윤고딕150.ttf") format("truetype");
}
@font-face {
  font-family: "yoon";
  font-weight: 500;
  src: url("../assets/fonts/-윤고딕160.ttf") format("truetype");
}
@font-face {
  font-family: "yoon";
  font-weight: 600;
  src: url("../assets/fonts/-윤고딕310.ttf") format("truetype");
} */

/* @charset "UTF-8"; */

/* @font-face {
  font-family: "Myriad-pro";
  font-weight: 400;
  src: url("../assets/fonts/MyriadPro-Regular.otf") format("opentype");
} */
/* @font-face {
  font-family: "Myriad-pro";
  font-weight: 500;
  src: url("../assets/fonts/MyriadPro-Regular.otf") format("opentype"),

} */
/* @font-face {
  font-family: "Myriad-pro";
  font-weight: 500;
  src: url("../assets/fonts/MyriadPro-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "Myriad-pro";
  font-weight: 600;
  src: url("../assets//fonts//MyriadPro-BoldCond.otf") format("opentype");
} */
