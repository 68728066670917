.mypage_wrap {
  width: 100%;
  min-height: 800px;
  background-color: #ebeef3;
  margin-bottom: 50px;
}
.mypage_wrap .mypage_inner_wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 40px 30px;
}
.mypage_inner_wrap .mypage_content_wrap {
}
.mypage_content_wrap .mypage_h2 {
  font-size: 26px;
  font-weight: 700;
  height: 50px;
  /* text-indent: 20px; */
  text-align: center;
  width: 100%;
}
.mypage_content_wrap .mypage_content {
  width: 100%;
  min-height: 670px;
  /* background-color: #fff; */
  /* border-radius: 15px; */
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  display: flex;
  /* border: 2px solid rgba(189, 189, 189, 0.842); */
}
.mypage_content_wrap .mypage_content_left {
  width: 320px;
  height: 670px;
  /* position: relative; */
  padding: 30px 0;

  border-radius: 8px;
  /* height: 100%; */
  background-color: #fff;
  /* background-color: red; */
}

.store_arrow_left {
  position: absolute;
  top: -50px;
  /* margin-top: -20px; */
  /* left: -50px; */
  z-index: 3;

  left: 50%;

  /* background-color: #fff; */
  width: 40px;
  height: 40px;
  color: #5c5c5c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  cursor: pointer;
  /* border-radius: 50%; */
}
.store_arrow_right {
  position: absolute;
  left: 50%;
  /* margin-top: -20px; */
  bottom: -50px;
  z-index: 3;
  /* background-color: #fff; */
  width: 40px;
  height: 40px;
  color: #5c5c5c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 50%;
  cursor: pointer;
}

/* .mypage_content_left::after {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 640px;
  background-color: #5c5c5c28;
  right: 0;
  top: 30px;
} */
.mypage_content_left .mypage_left_info {
  width: 100%;
  height: 320px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.mypage_left_info .mypage_info_img {
  width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 50%;
}
.mypage_left_info .mypage_info_img img {
  width: 100%;
  height: 100%;
}
/* .mypage_left_info .mypage_info_img svg {
  width: 100%;
  height: 100%;
} */
.mypage_left_info_box {
  width: 100%;
}
.mypage_icon_type {
  width: 20px;
  height: 20px;
  background: url("../assets/images/notice_default.jpg") no-repeat;
  background-size: 100%;
  border-radius: 50%;
  margin-left: 5px;
}
.mypage_left_info .mypage_info_name {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mypage_left_info .mypage_info_logout {
  width: 160px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #5c5c5c;

  text-align: center;
  line-height: 22px;
  margin: 0 auto;
  margin-top: 15px;
}
.mypage_info_logout button {
  width: 100%;
  height: 100%;
}
.mypage_menu {
  width: 100%;
  height: auto;
}
.mypage_menu ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mypage_menu .mypage_menu_li {
  width: 40%;
  margin-bottom: 13px;
  /* width: 100%; */
  padding: 0 4px 5px;
  border-bottom: 1px solid #c1c1c1;
}
.mypage_menu .mypage_menu_li a {
  font-weight: 500;
  width: 100%;
  text-align: center;
}
.mypage_content_wrap .mypage_content_right {
  flex: 1;
  border-radius: 8px;
  min-height: 670px;
  background-color: #fff;
  margin-left: 20px;
  padding: 30px;
}
.mypage_content_right.right_profile {
  position: relative;
}

.mypage_content_right.right_profile .phone_modal_wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInDown 0.4s;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.phone_modal_wrap .phone_modal_box {
  width: 510px;
  min-height: 340px;
  background-color: #fff;
  border-radius: 10px;

  /* position: relative; */
  /* animation-duration: 0.1s;
  animation-timing-function: ease-out;

  animation-name: modalAnimation; */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.phone_modal_box .info_modal_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
}

.info_modal_close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  /* background-color: #fff; */
  z-index: 1;
  text-align: center;
  font-size: 25px;
}

.info_modal_img {
  width: 160px;
  height: 70px;

  /* border-radius: 100px; */
  background: url(../assets//images/m12AiLogo.png) no-repeat;
  background-size: contain;
}

.info_modal_content h3 {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  /* line-height: 30px; */
}
.info_modal_content .modal_mobile_updatebox {
  display: flex;
  height: 36px;
  flex-direction: column;
  width: 60%;
}
.modal_step_1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modal_step_1 .modal_step1_1 {
  display: flex;
  height: 34px;
  width: 100%;
}
.modal_step_1 input {
  background-color: #fff;
  text-indent: 5px;
  border: 1px solid #ccc;
  /* border-left: 0; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 34px;
  width: 0;
  /* width: 100%; */
  /* flex-grow: 1; */
  /* flex: 1; */
  /* width: inherit; */
  /* flex-grow: 1; */
  /* width: calc(100%-8px) !important;
  display: inline-block; */
  /* box-sizing: border-box; */
  /* width: 60%; */
}

.modal_step_1 input[type="text"] {
  /* width: -webkit-calc(60% - 20px); */
  /* flex-grow: 4; */
  flex-grow: 1;
}

.modal_step_1 button {
  /* flex-grow: 1; */
  /* flex-shrink: 0;
  width: 100px; */
  /* flex: 1; */
  flex-shrink: 0;
  width: 90px;
  background-color: #d6d6d6;
  margin-left: 4px;
  border-radius: 5px;
}
.modal_step_2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.modal_step_2 .modal_step2_1 {
  display: flex;
  height: 34px;
  width: 100%;
}
.modal_step2_1 .modal_step2_inp {
  border: 1px solid #ccc;
  /* border-left: 0; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 34px;
  display: flex;
  /* flex-grow: 2; */
  /* flex: 1; */
  flex-grow: 1;
  /* position: relative; */
}

.modal_step2_inp .modal_step2_span {
  flex-shrink: 0;
  width: 50px;
  text-align: center;
  background-color: #fff;
  /* position: absolute;
  right: 0;
  top: 0;
  z-index: 1; */
  /* flex-grow: 1; */
  /* background-color: red; */
  /* width: 42px; */
  /* padding: 0 5px;
  text-align: center; */
}
.modal_step2_inp .modal_step2_span.step2_span_color {
  background-color: #ebebeb;
}
.modal_step2_inp > input {
  width: 0;
  /* width: 4%; */
  /* width: inherit; */
  /* flex-grow: 0.2; */
  /* flex: 1; */
  /* padding-right: 20px; */
  /* box-sizing: border-box !important; */
}
.modal_step2_inp > input[type="text"] {
  /* width: -webkit-calc(60% - 20px); */
  /* flex-grow: 4; */
  flex-grow: 1;
}

.modal_step2_1 button {
  flex-shrink: 0;
  width: 90px;
  /* flex-shrink: 0;
  width: 100px; */
  /* flex: 1; */
  background-color: #d6d6d6;
  margin-left: 4px;
  border-radius: 5px;
}

.error_modal_p {
  font-size: 12px;
  text-indent: 5px;
  color: #e85757;
}

.info_modal_btn {
  height: 40px;
  margin-top: 20px;
  display: flex;
  /* background-color: red; */
  /* padding: 0 20px;
  justify-content: space-between; */
  justify-content: center;
}
.info_modal_btn > button {
  width: 100px;
  border-radius: 5px;
  height: 34px;
  /* border:; */
}

.info_modal_btn .btn_mobile_cencel {
  background-color: #ebebeb;
  width: 100px;

  margin-right: 14px;
  color: black;
}
.info_modal_btn .btn_mobile_save {
  background-color: #00c73c;
  width: 100px;
  color: #fff;
}

@keyframes modalAnimation {
  from {
    width: 410px;
    height: 240px;
  }
  to {
    width: 510px;
    min-height: 340px;
  }
}

.mypage_content_right .mypage_content_title {
  font-size: 24px;
  font-weight: 600;
}

.mypage_content_inner {
  width: 100%;
  padding: 20px;
  height: 560px;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}
.mypage_content_inner.profile_wrap {
  height: auto;
  /* min-height: 560px; */
}
.mypage_activity {
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  overflow: hidden;
}

.mypage_activity ul {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
}
.mypage_activity li {
  width: 30%;
  height: 100%;
  margin-right: 20px;
  /* border: 1px solid #77b69a; */
  border-radius: 10px;
}
.mypage_activity .mypage_activity_icon {
  width: 100%;
  height: auto;
  font-size: 70px;
  text-align: center;
  padding: 20px 0;
  color: #00c73c;
  border-bottom: 1px solid #00c73c;
}

.mypage_activity .mypage_activity_title {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  color: #00c73c;
  font-weight: 700;
  font-size: 22px;
  border-bottom: 1px solid #00c73c;
}
.mypage_activity .mypage_activity_cnt {
  padding: 30px 0;
  text-align: center;
  color: #00c73c;
  font-weight: 700;
  font-size: 40px;
}

/* 나의 정보변경 */
.mypage_info_step_1 {
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px 55px;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.mypage_info_step_1 ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 240px;
  position: relative;
}
.mypage_info_step_1 li {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* background-color: red; */
  /* border: 1px solid #77b69a; */
  border-radius: 10px;
  cursor: pointer;
  /* background: red; */
}
.mypage_info_step_1 li:first-child::before {
  display: block;
  width: 3px;
  height: 240px;
  background-color: #00c73c;
  position: absolute;
  right: 50%;
}
.mypage_info_step_1 .mypage_info_icon {
  width: 100%;
  height: auto;
  /* font-size: 100px; */
  text-align: center;
  padding: 20px 0;
  color: #00c73c;
  margin-bottom: 10px;
  /* border-bottom: 1px solid #77b69a; */
}
.mypage_info_step_1 .mypage_info_icon svg {
  width: 100px;
  height: 100px;
}
.mypage_info_step_1 .mypage_info_icon img {
  width: 100px;
  height: 100px;
}
.mypage_info_step_1 .mypage_info_title {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  color: #00c73c;
  font-weight: 700;
  font-size: 22px;

  /* border-bottom: 1px solid #77b69a; */
}
.mypage_info_step_1 .mypage_info_delete {
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 40px;
}
.mypage_info_step_1 .mypage_info_delete button {
  /* text-decoration: underline; */
  font-size: 12px;
  color: #848383;
}
/* .mypage_activity h5 {
  font-size: 20px;
  width: 90%;
  margin: 0 auto;
  height: auto;
  text-align: center;

  padding: 10px 0;
  border-bottom: 1px solid #a9a9a9;
  color: #5f5f5f;
}

.mypage_activity_box {
  width: 90%;
  margin: 0 auto;
  height: 160px;
  background: rgb(236, 236, 236);
}
.mypage_activity_1 {
  margin-bottom: 50px;
  border-bottom: 1px solid #a9a9a9;
  border: 1px solid #a9a9a9;
  border-radius: 10px;
} */

/* 프로필 편집 */
.mypage_profile_wrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;
}
.profile_section {
  display: flex;
  margin-bottom: 40px;
  width: 66%;
  justify-content: space-between;
}

.profile_section label {
  /* background-color: red; */
  font-weight: 600;
  width: 90px;
  height: 34px;
  line-height: 30px;
  text-indent: 10px;
}
.profile_section .profile_section_right {
  width: 70%;
  height: auto;
  /* margin-left: 10px; */
}
.profile_section .profile_input_div {
  width: 100%;
}
.profile_section .profile_inp_box {
  /* border: 1px solid #5c5c5c; */
  width: 100%;
  height: 34px;
  display: flex;
  border: 2px solid #dfdfdf;
  background-color: rgb(233, 233, 233);
  border-radius: 6px;
}
.profile_inp_box input {
  flex: 1;
  text-indent: 5px;
  font-size: 13px;
  background-color: transparent;
  height: 34px;

  /* background-color: rgb(233, 233, 233); */
}
.profile_inp_box span {
  flex-basis: 40px;
  text-align: right;
  font-size: 12px;
  padding-right: 5px;
  height: 34px;
  /* background-color: red; */
}

.profile_section_1 {
  width: 130px;
  height: 130px;
  position: relative;
}
.profile_section_1 .profile_img_div {
  width: 130px;
  height: 130px;
  overflow: hidden;
  border-radius: 50%;
}
.profile_section_1 .profile_img_div img {
  width: 100%;
  height: 100%;
}
.profile_section_1 .profile_camera {
  position: absolute;
  z-index: 3;
  width: 36px;
  height: 36px;
  background-color: #00c73c;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid #00c73c;
}
.profile_section_1 input {
  width: 100%;
  height: 100%;

  /* display: block; */
}
.profile_section_3 .profile_input_div,
.profile_section_4 .profile_input_div {
  display: flex;
  width: 100%;
}
.profile_section_3 .profile_input_div .profile_inp_box,
.profile_section_4 .profile_input_div .profile_inp_box {
  flex: 1;
  width: auto;
}
.profile_section_3 .profile_inp_box,
.profile_section_4 .profile_inp_box {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.profile_section_3 .profile_input_div button,
.profile_section_4 .profile_input_div button {
  flex-basis: 80px;
  font-size: 14px;
  height: 34px;
  background-color: #00c73c;
  font-weight: 500;
  color: #fff;
  padding: 0 10px;
  /* border-radius: 10 px; */
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-left: 4px;
}
.profile_section.profile_section_7 {
  margin-bottom: 0;
}
.profile_section.profile_section_4 {
  margin-bottom: 0;
}
.profile_section_span.profile_phone_span {
}
.profile_section_span {
  margin-bottom: 40px;
  margin-top: 5px;

  width: 66%;
  display: flex;
  justify-content: space-between;
}
.profile_section_span > label {
  width: 90px;
}
.profile_section_span > span {
  font-size: 12px;
  color: #00c73c;
  width: 70%;
  line-height: 18px;
}
.profile_section_7 .profile_input_address {
  display: flex;
  margin-top: 4px;
}
.profile_section_7 .profile_input_address1 {
  margin-top: 0;
}
.profile_section_7 .profile_input_address input {
  text-indent: 5px;
}
.profile_section_7 .profile_input_address input {
  width: 100%;
  height: 34px;
  display: flex;
  border: 2px solid #dfdfdf;
  background-color: rgb(233, 233, 233);
  border-radius: 6px;
  flex: 1;
  font-size: 13px;
}
.mypage_address_btn {
  position: absolute;
  top: -30px;
  width: 60px;
  text-align: center;
  line-height: 30px;
  height: 30px;
  right: 0;
  background-color: rgb(178, 178, 178);
  font-weight: 600;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  cursor: pointer;
  /* z-index: 1001; */
}
.profile_section_7 .profile_input_address_1 input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-indent: 5px;
  /* padding-left: 5px; */
  /* background-color: red; */
}

.profile_section_7 .profile_input_address_1 button {
  /* flex-basis: 80px; */
  width: 80px;
  font-size: 14px;
  height: 34px;
  background-color: #00c73c;
  font-weight: 500;
  color: #fff;
  padding: 0 10px;
  /* border-radius: 10 px; */
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-left: 4px;
  flex: none;
}

.profile_section.profile_section_5 {
  width: 66%;
  position: relative;
  height: 90px;
  /* background-color: red; */
  padding-top: 20px;
  border-top: 1px solid #c3c3c3;
  display: flex;
}
.profile_section.profile_section_5 .profile_info_span {
  width: 160px;
  height: 30px;
  background-color: #fff;
  position: absolute;
  top: -15px;
  right: 50%;
  margin-right: -80px;
  /* line-height: 15px; */
  line-height: 30px;
  text-align: center;
  font-weight: 500;
}

.profile_section.profile_section_5 .profile_info_div {
  flex: 1;
}
.profile_info_div > div {
  /* padding-left: 5px; */
  text-align: center;
}
.profile_section.profile_section_5 .profile_info_div span {
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
  font-weight: 500;
  /* text-indent: 2px; */
}
.profile_section.profile_section_5 label {
  /* background-color: red; */
  font-weight: 400;
  width: 50px;
  height: 26px;
  line-height: 26px;
  text-indent: 0px;
  /* background: red; */

  /* float: left; */
}
.profile_section.profile_section_5 select {
  width: 90%;
}

/* 계좌 정보 */
.profile_section.profile_section_6 {
  width: 66%;
  position: relative;
  height: auto;
  /* background-color: red; */
  padding-top: 20px;
  border-top: 1px solid #c3c3c3;
  display: flex;
  flex-direction: column;
}
.profile_section.profile_section_6 .profile_info_span {
  width: 160px;
  height: 30px;
  background-color: #fff;
  position: absolute;
  top: -15px;
  right: 50%;
  margin-right: -80px;
  /* line-height: 15px; */
  line-height: 30px;
  text-align: center;
  font-weight: 500;
}

.profile_section_6 .profile_account_div.top_box {
  /* display: flex; */
  width: 100%;
}
.profile_section_6 .profile_account_div.top_box > div {
  flex: 1 !important;
}
.profile_section_6 .profile_account_div.top_box > div:first-child {
  /* padding-right: 5px; */
}
.profile_section_6 .profile_account_div.top_box > div:last-child {
  /* padding-left: 5px; */
}
.profile_section_6 .profile_account_div.bottom_box > div {
  flex: 1;
}
.profile_section_6 .profile_account_div .profile_account_inp {
  border: 2px solid #dfdfdf;
  background-color: rgb(233, 233, 233);
  border-radius: 6px;
  display: flex;
  width: 100%;
}
.profile_section_6 .profile_account_div .profile_account_inp input {
  flex: 1;
  text-indent: 5px;
  font-size: 13px;
  background-color: transparent;
  height: 34px;
  /* width: auto !important; */
}
.profile_section_6 .profile_account_div .profile_account_inp span {
  /* flex-basis: 40px; */
  /* display: block; */
  width: 30px;
  flex: none !important;
  text-align: center;
  font-size: 12px;
  /* padding-right: 5px; */
  height: 34px;
}
.mypage_profile_wrap .btn_box {
  width: 66%;
}
.mypage_profile_wrap .btn_box button {
  height: 34px;
  width: 100%;
  padding: 0 10px;
  font-size: 16px;
  background-color: #00c73c;
  line-height: 34px;
  color: #fff;
  border-radius: 6px;
}

/* 비밀번호 변경 */
.mypage_password_wrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 30px;
}
.mypage_password_wrap .mypage_password_inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  width: 60%;
}
.mypage_password_wrap .mypage_password_inner input {
  text-indent: 5px;
  font-size: 13px;
  background-color: transparent;
  height: 34px;
  border: 2px solid #dfdfdf;
  background-color: rgb(233, 233, 233);
  border-radius: 6px;
  width: 100%;
}
.mypage_password_confirm {
  height: 34px;
  width: 100%;
  padding: 0 10px;
  font-size: 16px;
  background-color: #00c73c;
  line-height: 34px;
  color: #fff;
  border-radius: 6px;
}

.mypage_password_wrap .mypage_password_inner_1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  width: 60%;
}
.mypage_password_wrap .mypage_password_inner_1 .mypage_password_section {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.mypage_password_wrap .mypage_password_inner_1 h4 {
  width: 100%;
  text-align: left;
  margin-bottom: 6px;
}
.mypage_password_wrap .mypage_password_inner_1 input {
  text-indent: 5px;
  font-size: 13px;
  background-color: transparent;
  height: 34px;
  border: 2px solid #dfdfdf;
  background-color: rgb(233, 233, 233);
  border-radius: 6px;
  width: 100%;
}

/* 나의 투자정보 */

.mypage_content_inner.ivest_wrap {
  /* justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%; */

  /* height: 260px; */
}
.mypage_content_inner.ivest_wrap h4 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 5px;
  /* background-color: #77b69a; */
  background-color: #00c73c;
  padding: 5px 0;
  color: #fff;
  border-radius: 4px;
}

.mypage_content_inner .mypage_content_invest,
.mypage_content_inner .mypage_content_wait {
  width: 100%;
  height: 320px;
}

.mypage_content_invest_wrap {
  width: 100%;
}
.mypage_content_invest_wrap .mypage_content_invest_ul {
  display: flex;
  margin-bottom: 10px;
}
.mypage_content_invest_wrap .mypage_content_invest_ul li {
  font-size: 18px;
  font-weight: 500;
  margin-right: 12px;
}
.mypage_content_invest_wrap .mypage_content_invest_ul .invest_ul_li {
  position: relative;
  padding: 4px 0;
  cursor: pointer;
}
.mypage_content_invest_wrap
  .mypage_content_invest_ul
  .invest_ul_li.invest_ul_active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  background-color: #00c73c;
  height: 3px;
  border-radius: 2px;
  z-index: 1;
}
.mypage_content_invest_wrap .mypage_content_invest_top {
  display: flex;
}

.mypage_content_invest .mypage_invest_table,
.mypage_content_wait .mypage_wait_table {
  /* border-bottom: 1px solid #5c5c5c; */

  width: 100%;
}
.mypage_invest_table table,
.mypage_wait_table table {
  width: 96%;
}
.mypage_invest_table .invest_table_head,
.mypage_wait_table .invest_table_head {
  border-bottom: 2px solid #00c73c;
  height: 50px;
}
.mypage_invest_table .invest_table_head th,
.mypage_wait_table .invest_table_head th {
  font-weight: 500;
}
.mypage_invest_table .invest_table_tr,
.mypage_wait_table .invest_table_tr {
  /* display: flex; */
  text-align: center;
}
.invest_table_tbody .invest_table_tr {
  border-bottom: 1px solid #c1c1c1;
}
.ivest_wrap .table_pagination {
  text-align: center;
  margin-top: 5px;
}
.ivest_wrap .table_not_data {
  width: 100%;
  text-align: center;
  padding: 6px 0;
}

.ivest_wrap .mypage_content_invest_box .mypage_invest_price_box {
  margin-left: 5px;
}
.ivest_wrap
  .mypage_content_invest_box
  .mypage_invest_price_box
  .mypage_invest_datepicker {
  border: 1px solid #dadde6;
  margin: 0 10px;
}
.ivest_wrap .mypage_content_invest_box .mypage_invest_price_box input {
  /* text-align: right; */
  width: 90px;
  text-align: center;
  font-size: 14px;
}
.ivest_wrap
  .mypage_content_invest_box
  .mypage_invest_price_box
  .mypage_invest_datepicker_ico {
  padding: 0 3px;
  font-size: 12px;
  margin: 0 5px;
  /* background-color: red; */
}
.ivest_wrap .mypage_content_invest_box .mypage_invest_filter_box .filter_text {
  margin: 0 2px;
  font-size: 12px;
  width: 54px;
  border-radius: 4px;
}
.ivest_wrap
  .mypage_content_invest_box
  .mypage_invest_filter_box
  .filter_text.filter_active {
  background: #00c73c;
  color: #fffdff;
  font-weight: 600;
}

.ivest_wrap .mypage_content_invest_box .filter_text_active {
  background-color: #5e5e5e;
  color: #fff;
  font-weight: 700;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  line-height: 2.4em;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
}

.ivest_wrap .mypage_content_invest_box .filter_text {
  border: 1px solid #e0e0e0;
  font-size: 12px;
  line-height: 2.4em;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
}
@media all and (max-width: 1200px) {
  .mypage_content_invest_wrap .mypage_content_invest_top {
    flex-wrap: wrap;
  }
  .mypage_invest_filter_box {
    margin-top: 10px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_filter_box
    .filter_text {
    margin: 0 2px;
    font-size: 12px;
    width: 64px;
    border-radius: 4px;
  }
}
/* 투자 상품 상세 */
.mypage_investdetail_wrap {
  width: 100%;
  height: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mypage_investdetail_wrap .mypage_investdetail_box {
  width: 100%;
  /* height: 100%; */
  margin: 0 auto;
}
.invest_ing_item,
.invest_wait_item {
  /* background-color: #006e4d; */
  padding: 10px 20px;
  width: 100%;
}
.invest_ing_item .invest_ing_box {
  /* background-color: #77b69a; */
  /* background-color: #00a370; */
  border-radius: 14px;
  height: 300px;
  padding: 16px;
  border: 2px solid #0e9e5d;
  color: #0e9e5d;
}

.invest_ing_item .invest_ing_box .invest_ing_title {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  /* color: #fff; */
  /* border-bottom: 2px solid #fff; */
  padding-bottom: 10px;
}
.invest_ing_item .invest_ing_box .invest_ing_con {
  font-size: 22px;
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 30px;
  /* align-items: center; */
}
.invest_ing_item .invest_ing_box .invest_ing_con label {
  width: 38%;
  text-align: right;
  position: relative;
  line-height: 36px;
  margin-right: 4px;
}
.invest_ing_item .invest_ing_box .invest_ing_con label::after {
  position: absolute;
  right: -7px;
  top: 9px;
  content: "";
  display: block;
  width: 2px;
  height: 22px;
  background-color: #0e9e5d;
}
.invest_ing_item .invest_ing_box .invest_ing_con span {
  text-indent: 18px;
  width: 38%;
  text-align: right;
  line-height: 36px;
}
.invest_ing_item .invest_ing_box .invest_ing_con > div {
  height: 36px;
}
.invest_ing_item .invest_ing_box .invest_ing_con > div:first-child {
  margin-bottom: 6px;
}

.invest_ing_item .invest_ing_box .invest_ing_btn {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 14px;
  flex-direction: column;
}
.invest_ing_item .invest_ing_box .invest_ing_btn button {
  background: #0e9e5d;
  color: #fff;
  font-size: 18px;

  padding: 10px 20px;
  border-radius: 14px;
  width: 100%;
  height: 48px;
}

.invest_wait_item .invest_wait_box {
  /* background-color: #77b69a; */
  /* background-color: #00a370; */
  border-radius: 14px;
  min-height: 240px;
  padding: 16px;
  border: 2px solid #cfcfcf;
  color: #969696;
}

.invest_wait_item .invest_wait_box .invest_wait_title {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  /* color: #fff; */
  /* border-bottom: 2px solid #fff; */
  padding-bottom: 10px;
}
.invest_wait_item .invest_wait_box .invest_wait_con {
  font-size: 22px;
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 30px 0;
  /* align-items: center; */
}

.invest_wait_item .invest_wait_box .invest_wait_con2 {
  /* font-size: 22px; */
  /* color: #fff; */
  font-weight: 500;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 10px 0;
  border-top: 1px solid #cfcfcf;
  /* align-items: center; */
}
.invest_wait_con2 h4 {
  text-align: center;
  margin-bottom: 9px;
}
.invest_wait_item .invest_wait_box .invest_wait_con label {
  width: 38%;
  text-align: right;
  position: relative;
  line-height: 36px;
  margin-right: 4px;
}
.invest_wait_item .invest_wait_box .invest_wait_con label::after {
  position: absolute;
  right: -7px;
  top: 9px;
  content: "";
  display: block;
  width: 2px;
  height: 22px;
  background-color: #969696;
}
.invest_wait_item .invest_wait_box .invest_wait_con span {
  text-indent: 18px;
  width: 38%;
  text-align: right;
  line-height: 36px;
}
.invest_wait_item .invest_wait_box .invest_wait_con > div {
  height: 36px;
}
.invest_wait_item .invest_wait_box .invest_wait_con > div:first-child {
  margin-bottom: 6px;
}
.invest_wait_item .invest_wait_info {
  display: flex;
  width: 100%;
}
.invest_wait_item .invest_wait_info .invest_wait_field {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}
.invest_wait_item .invest_wait_box .invest_wait_btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}
.invest_wait_item .invest_wait_box .invest_wait_btn button {
  background: #969696;
  color: #fff;
  font-size: 18px;

  padding: 10px 20px;
  border-radius: 14px;
  width: 100%;
}

/* 나의 관심상품 */
.mypage_interest_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mypage_interest_wrap .mypage_interest_box {
  width: 500px;
  height: auto;
  /* height: 350px; */
  position: relative;
  /* background-color: red; */
  /* background-color: red; */
  /* background-color: red; */
  /* overflow-y: auto; */
}
/* .mypage_interest_wrap .slick_item_div {
  width: 272px;
  height: 192px;
} */

.mypage_interest_wrap .not_heart {
  text-align: center;
  font-size: 14px;
  color: #898989;
  font-weight: 600;
}
.mypage_interest_wrap .mySwiper {
  height: 120px;
  position: relative;
}
.mypage_interest_wrap .mySwiper.mySwiper_two {
  height: 240px;
}
.mypage_interest_wrap .mySwiper.mySwiper_three {
  height: 360px;
}
.main_news_item {
  width: 100%;

  border: 1px solid #e2e2e2;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 6px 6px rgba(145, 145, 145, 0.23);

  cursor: pointer;
}

.main_news_inner {
  display: flex;
  /* border: 1px solid rgb(227, 227, 227); */
}
.main_news_inner .main_news_img {
  /* width: 100%;
  height: 60px; */
  /* aspect-ratio: 16 / 9; */
  /* height: 130px; */
  flex: none;
  width: 160px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
  /* padding-top: calc(185 / 325 * 100%); */
  /* position: relative; */
}

.main_news_item .main_news_img img {
  object-fit: cover;
  /* object-position: 50%; */
  /* transition: all 0.5s; */
  width: 100%;
  height: 100%;
  /* transition: all 0.3s ease-in-out;
  box-sizing: border-box; */
}

.main_news_item:hover .main_news_img img {
}
.main_news_item .main_news_content {
  width: 100%;
  height: auto;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-top: 10px; */
}
.main_news_item .main_news_content .main_news_title {
  font-weight: 600;
  /* height: auto; */
  width: 100%;
  height: 30px;
  /* background-color: red; */
  padding: 3px 0;
  font-size: 20px;
  /* max-width: 100%; */
  /* overflow: hidden; */
}
.main_news_content .main_news_title span {
  width: 100%;
  height: 100%;
}

.main_news_item .main_news_content .main_news_date {
  width: 100%;
  font-size: 13px;
  color: #848383;
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
  /* text-align: right; */
}
.main_news_item .main_news_content .main_news_date em {
  color: #00c73c;
  font-weight: bold;
  font-size: 18px;
  margin-right: 3px;
}
.main_news_item .main_news_content .main_news_cont {
  font-size: 15px;
  width: 100%;
  height: auto;
  line-height: 20px;
}
.main_news_inner .main_news_img.main_skeleton_img {
  background-color: #f2f2f2;
  position: relative;
}

.slick_item_div2 {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(227, 227, 227);
  /* position: relative; */
  cursor: pointer;
}
.slick_item_div2 .slick_item_link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.slick_item_div .slick_item_imgbox {
  width: 100%;
  padding-top: 60%;
  /* background-position: center center; */
  /* flex: 1; */

  /* height: 100%; */
  /* height: 180px; */
  /* background-color: red; */
  /* object-fit: cover; */
  /* overflow: hidden; */
}
.slick_item_div .slick_item_imgbox img {
  /* max-height: 180px; */
  /* object-fit: cover; */
  /* object-fit: contain; */
}

.slick_item_div .slick_item_contbox {
  padding: 5px 0;
  /* flex: 1; */
}
.slick_item_contbox .slick_item_title {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
.error_em {
  font-size: 10px;
  color: red;
  /* height: 20px; */
  line-height: 20px;
  height: 20px;
  /* background-color: red; */
}
.noerror_em {
  font-size: 10px;
  color: #00c73c;
  height: 20px;
  line-height: 20px;
}
@media all and (max-width: 1100px) {
  .mypage_wrap {
    width: 100%;
    min-height: 740px;
    background-color: #ebeef3;
  }
  .mypage_wrap .mypage_inner_wrap {
    max-width: 100%;

    padding: 20px 20px 20px;
  }
  .mypage_inner_wrap .mypage_content_wrap {
  }

  .mypage_content_wrap .mypage_content {
    width: 100%;
    min-height: 620px;
    /* background-color: #fff; */
    /* border-radius: 15px; */
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
    display: flex;
    /* border: 2px solid rgba(189, 189, 189, 0.842); */
  }
  .mypage_content_wrap .mypage_content_left {
    width: 26%;
    height: 620px;
    /* position: relative; */
    padding: 24px 30px;

    /* height: 100%; */
    background-color: #fff;
    /* background-color: red; */
  }

  .mypage_content_left .mypage_left_info {
    width: 100%;
    height: 320px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .mypage_left_info .mypage_info_img {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
  }
  .mypage_left_info .mypage_info_img img {
    width: 100%;
    height: 100%;
  }
  /* .mypage_left_info .mypage_info_img svg {
    width: 100%;
    height: 100%;
  } */
  .mypage_left_info .mypage_info_name {
    margin-top: 8px;
    font-size: 18px;
    /* font-weight: 600; */
  }
  .mypage_left_info .mypage_info_logout {
    width: 60%;
    height: 35px;
    /* border-radius: 16px; */

    margin-top: 8px;
  }
  .mypage_info_logout button {
    width: 100%;
    font-size: 15px;
    height: 100%;
  }
  .mypage_menu {
    width: 100%;
    height: auto;
  }
  .mypage_menu ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mypage_menu .mypage_menu_li {
    width: 100%;
    margin-bottom: 10px;
    /* width: 100%; */
    padding: 0 4px 5px;
    border-bottom: 0;
    text-align: center;
    font-size: 15px;
  }
  .mypage_menu .mypage_menu_li a {
    font-weight: 500;
    width: auto;
    text-align: center;
    border-bottom: 1px solid #c1c1c1;
    padding: 0 10px;
    /* margin: 0 auto; */
  }
  .mypage_content_wrap .mypage_content_right {
    flex: 1;

    min-height: 620px;
    background-color: #fff;
    margin-left: 20px;
    padding: 24px;
  }

  .mypage_content_right .mypage_content_title {
    font-size: 22px;
    font-weight: 600;
  }

  .mypage_content_inner {
    width: 100%;
    padding: 20px;
    height: 540px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }
  .mypage_content_inner.profile_wrap {
    height: auto;
    min-height: 520px;
  }
  .mypage_activity {
    padding: 10px 20px;
    justify-content: center;
  }

  .mypage_activity ul {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
  }
  .mypage_activity li {
    width: 40%;
    height: 100%;
    margin-right: 20px;
    /* border: 1px solid #77b69a; */
    border-radius: 10px;
  }
  .mypage_activity .mypage_activity_icon {
    width: 100%;
    height: auto;
    font-size: 70px;
    text-align: center;
    padding: 20px 0;
    color: #00c73c;
    border-bottom: 1px solid #00c73c;
  }

  .mypage_activity .mypage_activity_title {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 22px;
    border-bottom: 1px solid #00c73c;
  }
  .mypage_activity .mypage_activity_cnt {
    padding: 30px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 40px;
  }

  /* 나의 정보변경 */
  .mypage_info_step_1 {
    padding: 10px 30px;
    justify-content: center;
  }

  .mypage_info_step_1 ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 240px;
    position: relative;
  }
  .mypage_info_step_1 li {
    width: 40%;
    height: 100%;
    /* border: 1px solid #77b69a; */
    border-radius: 10px;
    cursor: pointer;
    /* background: red; */
  }
  .mypage_info_step_1 li:first-child::before {
    display: block;
    width: 3px;
    height: 240px;
    background-color: #00c73c;
    position: absolute;
    right: 50%;
  }
  .mypage_info_step_1 .mypage_info_icon {
    width: 100%;
    height: auto;
    font-size: 100px;
    text-align: center;
    padding: 20px 0;
    color: #00c73c;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #77b69a; */
  }
  .mypage_info_step_1 .mypage_info_icon svg {
    width: 100px;
    height: 100px;
  }

  .mypage_info_step_1 .mypage_info_title {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 22px;

    /* border-bottom: 1px solid #77b69a; */
  }

  /* 프로필 편집 */
  .mypage_profile_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 30px;
    /* background-color: red !important; */
  }
  .profile_section {
    display: flex;
    margin-bottom: 40px;
    /* background-color: red; */
    width: 66%;
    justify-content: space-between;
  }

  .profile_section label {
    /* background-color: red; */
    font-weight: 600;
    width: 90px;
    height: 34px;
    line-height: 30px;
    text-indent: 10px;
  }
  .profile_section .profile_section_right {
    width: 70%;
    height: auto;
    /* margin-left: 10px; */
  }
  .profile_section .profile_input_div {
    width: 100%;
  }
  .profile_section .profile_inp_box {
    /* border: 1px solid #5c5c5c; */
    width: 100%;
    height: 34px;
    display: flex;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
  }
  .profile_inp_box input {
    flex: 1;
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;

    /* background-color: rgb(233, 233, 233); */
  }
  .profile_inp_box span {
    flex-basis: 40px;
    text-align: right;
    font-size: 12px;
    padding-right: 5px;
    height: 34px;
    /* background-color: red; */
  }

  .profile_section_1 {
    width: 130px;
    height: 130px;
    position: relative;
  }
  .profile_section_1 .profile_img_div {
    width: 130px;
    height: 130px;
    overflow: hidden;
    border-radius: 50%;
  }
  .profile_section_1 .profile_img_div img {
    width: 100%;
    height: 100%;
  }
  .profile_section_1 .profile_camera {
    position: absolute;
    z-index: 3;
    width: 36px;
    height: 36px;
    background-color: #00c73c;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .profile_section_1 input {
    width: 100%;
    height: 100%;

    /* display: block; */
  }
  .profile_section_3 .profile_input_div,
  .profile_section_4 .profile_input_div {
    display: flex;
    width: 100%;
  }
  .profile_section_3 .profile_input_div .profile_inp_box,
  .profile_section_4 .profile_input_div .profile_inp_box {
    flex: 1;
    width: auto;
  }
  .profile_section_3 .profile_inp_box,
  .profile_section_4 .profile_inp_box {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .profile_section_3 .profile_input_div button,
  .profile_section_4 .profile_input_div button {
    flex-basis: 80px;
    font-size: 14px;
    height: 34px;
    background-color: #00c73c;
    font-weight: 500;
    color: #fff;
    padding: 0 10px;
    /* border-radius: 10 px; */
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-left: 4px;
  }

  .profile_section.profile_section_5 {
    width: 66%;
    position: relative;
    height: 90px;
    /* background-color: red; */
    padding-top: 20px;
    border-top: 1px solid #c3c3c3;
    display: flex;
  }
  .profile_section.profile_section_5 .profile_info_span {
    width: 160px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: -15px;
    right: 50%;
    margin-right: -80px;
    /* line-height: 15px; */
    line-height: 30px;
    text-align: center;
    font-weight: 500;
  }

  .profile_section.profile_section_5 .profile_info_div {
    flex: 1;
  }
  .profile_info_div > div {
    /* padding-left: 5px; */
    text-align: center;
  }
  .profile_section.profile_section_5 .profile_info_div span {
    width: 100%;
    text-align: center;
    margin-bottom: 4px;
    font-weight: 500;
    /* text-indent: 2px; */
  }
  .profile_section.profile_section_5 label {
    /* background-color: red; */
    font-weight: 400;
    width: 50px;
    height: 26px;
    line-height: 26px;
    text-indent: 0px;
    /* background: red; */

    /* float: left; */
  }
  .profile_section.profile_section_5 select {
    width: 90%;
  }
  .mypage_profile_wrap .btn_box {
    width: 66%;
  }
  .mypage_profile_wrap .btn_box button {
    height: 34px;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: #00c73c;
    line-height: 34px;
    color: #fff;
    border-radius: 6px;
  }

  .profile_section.profile_section_6 {
    width: 66%;
    position: relative;
    height: auto;
    /* background-color: red; */
    padding-top: 20px;
    border-top: 1px solid #c3c3c3;
    display: flex;
    flex-direction: column;
  }
  .profile_section.profile_section_6 .profile_info_span {
    width: 160px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: -15px;
    right: 50%;
    margin-right: -80px;
    /* line-height: 15px; */
    line-height: 30px;
    text-align: center;
    font-weight: 500;
  }

  .profile_section_6 .profile_account_div.top_box {
    /* display: flex; */
    width: 100%;
  }
  .profile_section_6 .profile_account_div.top_box > div {
    flex: 1;
  }
  .profile_section_6 .profile_account_div.top_box > div:first-child {
    padding-right: 2px;
  }
  .profile_section_6 .profile_account_div.top_box > div:last-child {
    padding-left: 2px;
  }
  .profile_section_6 .profile_account_div.bottom_box > div {
    flex: 1;
  }
  .profile_section_6 .profile_account_div .profile_account_inp {
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    display: flex;
    width: 100%;
  }
  .profile_section_6 .profile_account_div .profile_account_inp input {
    flex: 1;
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    /* width: auto !important; */
  }
  .profile_section_6 .profile_account_div .profile_account_inp span {
    /* flex-basis: 40px; */
    /* display: block; */
    width: 30px;
    flex: none !important;
    text-align: center;
    font-size: 12px;
    /* padding-right: 5px; */
    height: 34px;
  }
  /* 비밀번호 변경 */
  .mypage_password_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 30px;
  }
  .mypage_password_wrap .mypage_password_inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 120px;
    width: 60%;
  }
  .mypage_password_wrap .mypage_password_inner input {
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    width: 100%;
  }
  .mypage_password_confirm {
    height: 34px;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: #00c73c;
    line-height: 34px;
    color: #fff;
    border-radius: 6px;
  }

  .mypage_password_wrap .mypage_password_inner_1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 120px;
    width: 60%;
  }
  .mypage_password_wrap .mypage_password_inner_1 .mypage_password_section {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .mypage_password_wrap .mypage_password_inner_1 h4 {
    width: 100%;
    text-align: left;
    margin-bottom: 6px;
  }
  .mypage_password_wrap .mypage_password_inner_1 input {
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    width: 100%;
  }

  /* 나의 투자정보 */

  .mypage_content_inner.ivest_wrap {
    /* justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%; */

    /* height: 260px; */
  }
  .mypage_content_inner.ivest_wrap h4 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 5px;
    /* background-color: #77b69a; */
    background-color: #00c73c;
    padding: 5px 0;
    color: #fff;
    border-radius: 4px;
  }
  .mypage_content_inner .mypage_content_invest,
  .mypage_content_inner .mypage_content_wait {
    width: 100%;
    height: 320px;
  }

  .mypage_content_invest_wrap {
    width: 100%;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul {
    display: flex;
    margin-bottom: 10px;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul li {
    font-size: 18px;
    font-weight: 500;
    margin-right: 12px;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul .invest_ul_li {
    position: relative;
    padding: 4px 0;
    cursor: pointer;
  }
  .mypage_content_invest_wrap
    .mypage_content_invest_ul
    .invest_ul_li.invest_ul_active::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    background-color: #00c73c;
    height: 3px;
    border-radius: 2px;
    z-index: 1;
  }

  .mypage_content_invest .mypage_invest_table,
  .mypage_content_wait .mypage_wait_table {
    /* border-bottom: 1px solid #5c5c5c; */

    width: 100%;
  }
  .mypage_invest_table table,
  .mypage_wait_table table {
    width: 96%;
  }
  .mypage_invest_table .invest_table_head,
  .mypage_wait_table .invest_table_head {
    border-bottom: 2px solid #00c73c;
    height: 50px;
  }
  .mypage_invest_table .invest_table_head th,
  .mypage_wait_table .invest_table_head th {
    font-weight: 500;
  }
  .mypage_invest_table .invest_table_tr,
  .mypage_wait_table .invest_table_tr {
    /* display: flex; */
    text-align: center;
  }
  .invest_table_tbody .invest_table_tr {
    border-bottom: 1px solid #c1c1c1;
  }
  .ivest_wrap .table_pagination {
    text-align: center;
  }
  .ivest_wrap .table_not_data {
    width: 100%;
    text-align: center;
    padding: 6px 0;
  }

  .ivest_wrap .mypage_content_invest_box .mypage_invest_price_box {
    margin-left: 5px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_price_box
    .mypage_invest_datepicker {
    border: 1px solid #dadde6;
    margin: 0 10px;
  }
  .ivest_wrap .mypage_content_invest_box .mypage_invest_price_box input {
    /* text-align: right; */
    width: 90px;
    text-align: center;
    font-size: 14px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_price_box
    .mypage_invest_datepicker_ico {
    padding: 0 3px;
    font-size: 12px;
    margin: 0 5px;
    /* background-color: red; */
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_filter_box
    .filter_text {
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_filter_box
    .filter_text.filter_active {
    background: #00c73c;
    color: #fffdff;
    font-weight: 600;
  }

  .ivest_wrap .mypage_content_invest_box .filter_text_active {
    background-color: #5e5e5e;
    color: #fff;
    font-weight: 700;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    line-height: 2.4em;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
  }

  .ivest_wrap .mypage_content_invest_box .filter_text {
    border: 1px solid #e0e0e0;
    font-size: 12px;
    line-height: 2.4em;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
  }
}

@media all and (max-width: 880px) {
  .mypage_wrap {
    width: 100%;
    min-height: 740px;
    background-color: #ebeef3;
  }
  .mypage_wrap .mypage_inner_wrap {
    max-width: 100%;

    padding: 20px 20px 20px;
  }
  .mypage_inner_wrap .mypage_content_wrap {
  }

  .mypage_content_wrap .mypage_content {
    width: 100%;
    min-height: 620px;
    /* background-color: #fff; */
    /* border-radius: 15px; */
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
    display: flex;
    flex-direction: column;

    /* border: 2px solid rgba(189, 189, 189, 0.842); */
  }
  .mypage_content_wrap .mypage_content_left {
    width: 100%;
    height: 120px;
    /* position: relative; */
    padding: 0 24px;

    /* height: 100%; */
    background-color: #fff;
    display: flex;
    align-items: center;
    /* background-color: red; */
  }

  .mypage_content_left .mypage_left_info {
    flex: 1;
    height: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
  }
  .mypage_left_info .mypage_info_img {
    max-width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    /* background-color: red; */
  }
  .mypage_left_info .mypage_info_img img {
    width: 100%;
    height: 100%;
  }
  /* .mypage_left_info .mypage_info_img svg {
    width: 100%;
    height: 100%;
  } */

  .mypage_left_info_box {
    width: auto;
    padding-left: 20px;
    /* width: calc(25%-20px); */
    /* padding-left: 20px; */
  }
  .mypage_left_info .mypage_info_name {
    margin-top: 0px;
    font-size: 16px;
    display: flex;
    align-items: center;
    /* font-weight: 600; */
  }
  .mypage_left_info .mypage_info_logout {
    width: 90px;
    height: 24px;
    border-radius: 5px;
    border: 1px solid #5c5c5c;
    margin: 0;
    line-height: 14px;
    margin-top: 0px;
  }
  .mypage_info_logout button {
    width: 100%;
    font-size: 13px;
    height: 100%;
  }
  .mypage_menu {
    flex: 1;
    height: auto;
  }
  .mypage_menu ul {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .mypage_menu .mypage_menu_li {
    width: 50%;
    margin-bottom: 0;
    /* width: 100%; */
    padding: 0 10px;
    border-bottom: 0;
    text-align: center;
    font-size: 15px;
  }
  .mypage_menu .mypage_menu_li a {
    font-weight: 500;
    width: auto;
    text-align: center;
    border-bottom: 1px solid #c1c1c1;
    padding: 0 10px;
    font-size: 14px;
    line-height: 17px;
    /* margin: 0 auto; */
  }
  .mypage_content_wrap .mypage_content_right {
    flex: 1;

    min-height: 500px;
    background-color: #fff;
    margin-left: 0px;
    padding: 24px;
    margin-top: 20px;
  }

  .mypage_content_right .mypage_content_title {
    font-size: 22px;
    font-weight: 600;
  }

  .mypage_content_inner {
    width: 100%;
    padding: 20px;
    height: 440px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }

  .mypage_content_inner.profile_wrap {
    height: auto;
    min-height: 400px;
  }
  .mypage_activity {
    padding: 10px 20px;
    justify-content: center;
  }

  .mypage_activity ul {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 300px;
  }
  .mypage_activity li {
    width: 40%;
    height: 100%;
    margin-right: 20px;
    /* border: 1px solid #77b69a; */
    border-radius: 10px;
  }
  .mypage_activity .mypage_activity_icon {
    width: 100%;
    height: auto;
    font-size: 70px;
    text-align: center;
    padding: 20px 0;
    color: #00c73c;
    border-bottom: 1px solid #00c73c;
  }

  .mypage_activity .mypage_activity_title {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 22px;
    border-bottom: 1px solid #00c73c;
  }
  .mypage_activity .mypage_activity_cnt {
    padding: 30px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 40px;
  } /* 나의 정보변경 */
  .mypage_info_step_1 {
    padding: 10px 30px;
    justify-content: center;
  }

  .mypage_info_step_1 ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 240px;
    position: relative;
  }
  .mypage_info_step_1 li {
    width: 40%;
    height: 100%;
    /* border: 1px solid #77b69a; */
    border-radius: 10px;
    cursor: pointer;
    /* background: red; */
  }
  .mypage_info_step_1 li:first-child::before {
    display: block;
    width: 3px;
    height: 240px;
    background-color: #00c73c;
    position: absolute;
    right: 50%;
  }
  .mypage_info_step_1 .mypage_info_icon {
    width: 100%;
    height: auto;
    font-size: 100px;
    text-align: center;
    padding: 20px 0;
    color: #00c73c;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #77b69a; */
  }
  .mypage_info_step_1 .mypage_info_icon svg {
    width: 100px;
    height: 100px;
  }

  .mypage_info_step_1 .mypage_info_title {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 22px;

    /* border-bottom: 1px solid #77b69a; */
  }
  /* 프로필 편집 */
  .mypage_profile_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 0;
    /* background-color: red !important; */
  }
  .profile_section {
    display: flex;
    margin-bottom: 20px;
    /* background-color: red; */
    width: 66%;
    justify-content: space-between;
  }

  .profile_section label {
    /* background-color: red; */
    font-weight: 600;
    width: 90px;
    height: 34px;
    line-height: 30px;
    text-indent: 10px;
    font-size: 14px;
  }
  .profile_section .profile_section_right {
    width: 70%;
    height: auto;
    /* margin-left: 10px; */
  }
  .profile_section .profile_input_div {
    width: 100%;
  }
  .profile_section .profile_inp_box {
    /* border: 1px solid #5c5c5c; */
    width: 100%;
    height: 34px;
    display: flex;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
  }
  .profile_inp_box input {
    flex: 1;
    text-indent: 5px;
    font-size: 11px;
    background-color: transparent;
    height: 34px;

    /* background-color: rgb(233, 233, 233); */
  }
  .profile_inp_box span {
    flex-basis: 40px;
    text-align: right;
    font-size: 12px;
    padding-right: 5px;
    height: 34px;
    /* background-color: red; */
  }

  .profile_section_1 {
    width: 100px;
    height: 100px;
  }
  .profile_section_1 .profile_img_div {
    width: 100px;
    height: 100px;
  }

  .profile_section_1 .profile_camera {
    width: 30px;
    height: 30px;

    font-size: 16px;
  }

  .profile_section_3 .profile_input_div button,
  .profile_section_4 .profile_input_div button {
    flex-basis: 70px;
    font-size: 14px;
    height: 34px;
    background-color: #00c73c;
    font-weight: 500;
    color: #fff;
    padding: 0 10px;
    /* border-radius: 10 px; */
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-left: 4px;
  }

  .profile_section.profile_section_5 {
    width: 66%;
    position: relative;
    height: 90px;
    /* background-color: red; */
    padding-top: 20px;
    border-top: 1px solid #c3c3c3;
    display: flex;
  }
  .profile_section.profile_section_5 .profile_info_span {
    width: 140px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: -15px;
    right: 50%;
    margin-right: -70px;
    /* line-height: 15px; */
    line-height: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
  }

  .profile_section.profile_section_5 .profile_info_div {
    flex: 1;
  }
  .profile_info_div > div {
    /* padding-left: 5px; */
    text-align: center;
    font-size: 14px;
  }
  .profile_section.profile_section_5 .profile_info_div span {
    width: 100%;
    text-align: center;
    margin-bottom: 4px;
    font-weight: 500;
    /* text-indent: 2px; */
  }
  .profile_section.profile_section_5 label {
    /* background-color: red; */
    font-weight: 400;
    width: 50px;
    height: 26px;
    line-height: 26px;
    text-indent: 0px;
    /* background: red; */

    /* float: left; */
  }
  .profile_section.profile_section_5 select {
    width: 90%;
  }
  .mypage_profile_wrap .btn_box {
    width: 66%;
  }
  .mypage_profile_wrap .btn_box button {
    height: 34px;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: #00c73c;
    line-height: 34px;
    color: #fff;
    border-radius: 6px;
  }

  /* 비밀번호 변경 */
  .mypage_password_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 30px;
  }
  .mypage_password_wrap .mypage_password_inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 120px;
    width: 60%;
  }
  .mypage_password_wrap .mypage_password_inner input {
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    width: 100%;
  }
  .mypage_password_confirm {
    height: 34px;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: #00c73c;
    line-height: 34px;
    color: #fff;
    border-radius: 6px;
  }

  .mypage_password_wrap .mypage_password_inner_1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 120px;
    width: 60%;
  }
  .mypage_password_wrap .mypage_password_inner_1 .mypage_password_section {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .mypage_password_wrap .mypage_password_inner_1 h4 {
    width: 100%;
    text-align: left;
    margin-bottom: 6px;
  }
  .mypage_password_wrap .mypage_password_inner_1 input {
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    width: 100%;
  }

  .mypage_content_inner.ivest_wrap {
    min-height: 520px;
    padding: 10px;
    /* justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%; */

    /* height: 260px; */
  }

  .mypage_content_inner .mypage_content_invest,
  .mypage_content_inner .mypage_content_wait {
    width: 100%;
    height: 320px;
  }
  /* 나의 투자정보 */
  .mypage_content_invest_box .mypage_invest_top_span {
    font-size: 14px;
  }
  .mypage_content_invest_wrap {
    width: 100%;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul {
    display: flex;
    margin-bottom: 10px;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul li {
    font-size: 16px;

    margin-right: 10px;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul .invest_ul_li {
    position: relative;
    padding: 4px 0;
    cursor: pointer;
  }

  .mypage_content_invest_wrap
    .mypage_content_invest_ul
    .invest_ul_li.invest_ul_active::after {
  }

  .mypage_content_invest .mypage_invest_table,
  .mypage_content_wait .mypage_wait_table {
    /* border-bottom: 1px solid #5c5c5c; */

    width: 100%;
  }
  .mypage_invest_table table,
  .mypage_wait_table table {
    width: 100%;
  }
  .mypage_invest_table .invest_table_head,
  .mypage_wait_table .invest_table_head {
    height: 40px;
    font-size: 14px;
  }
  .mypage_invest_table .invest_table_head th,
  .mypage_wait_table .invest_table_head th {
  }
  .mypage_invest_table .invest_table_tr,
  .mypage_wait_table .invest_table_tr {
    /* display: flex; */
    text-align: center;
  }
  .invest_table_tbody .invest_table_tr {
    border-bottom: 1px solid #c1c1c1;
    font-size: 14px;
    /* height: 20px; */
  }
  .ivest_wrap .table_pagination {
    text-align: center;
  }
  .ivest_wrap .table_not_data {
    width: 100%;
    text-align: center;
    padding: 6px 0;
  }

  .ivest_wrap .mypage_content_invest_box .mypage_invest_price_box {
    margin-left: 5px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_price_box
    .mypage_invest_datepicker {
    border: 1px solid #dadde6;
    margin: 0 10px;
  }
  .ivest_wrap .mypage_content_invest_box .mypage_invest_price_box input {
    /* text-align: right; */
    width: 90px;
    text-align: center;
    font-size: 14px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_price_box
    .mypage_invest_datepicker_ico {
    padding: 0 3px;
    font-size: 12px;
    margin: 0 5px;
    /* background-color: red; */
  }
  .ivest_wrap .mypage_content_invest_box .mypage_invest_filter_box {
    width: 100%;
    margin-top: 5px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_filter_box
    .filter_text {
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_filter_box
    .filter_text.filter_active {
    background: #00c73c;
    color: #fffdff;
    font-weight: 600;
  }

  .ivest_wrap .mypage_content_invest_box .filter_text_active {
    background-color: #5e5e5e;
    color: #fff;
    font-weight: 700;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    line-height: 2.4em;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
  }

  .ivest_wrap .mypage_content_invest_box .filter_text {
    border: 1px solid #e0e0e0;
    font-size: 12px;
    line-height: 2.4em;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
  }

  /* 나의 관심상품 */
  .mypage_interest_wrap {
  }

  .mypage_interest_wrap .mypage_interest_box {
    width: 84%;
  }

  .mypage_interest_wrap .mySwiper {
    height: 110px;
    position: relative;
  }
  .mypage_interest_wrap .mySwiper.mySwiper_two {
    height: 220px;
  }
  .mypage_interest_wrap .mySwiper.mySwiper_three {
    height: 330px;
  }

  .main_news_item {
    width: 100%;

    cursor: pointer;
  }

  .main_news_inner {
    /* border: 1px solid rgb(227, 227, 227); */
  }
  .main_news_inner .main_news_img {
    width: 144px;
    height: 90px;
  }

  .main_news_item .main_news_img img {
  }

  .main_news_item:hover .main_news_img img {
  }
  .main_news_item .main_news_content {
    width: 100%;
    height: auto;
    /* margin-top: 10px; */
  }
  .main_news_item .main_news_content .main_news_title {
    font-weight: 600;
    /* height: auto; */
    width: 100%;
    height: auto;
    line-height: 20px;
    /* background-color: red; */
    padding: 3px 0;
    font-size: 18px;
    /* max-width: 100%; */
    /* overflow: hidden; */
  }
  .main_news_content .main_news_title span {
    width: 100%;
    height: 100%;
  }

  .main_news_item .main_news_content .main_news_date {
    width: 100%;
    font-size: 13px;
    color: #848383;
    /* text-align: right; */
  }
  .main_news_item .main_news_content .main_news_date em {
    color: #00c73c;
    font-weight: bold;
    font-size: 14px;
    margin-right: 3px;
  }
  .main_news_item .main_news_content .main_news_cont {
    font-size: 13px;

    line-height: 18px;
  }

  /* qna - 880 */
  .mypage_content_inner.qna_wrap {
    height: 500px;
  }
  .mypage_content_inner.qna_wrap h4 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 5px;
    /* background-color: #77b69a; */
    background-color: #00c73c;
    padding: 5px 0;
    color: #fff;
    border-radius: 4px;
  }

  .mypage_content_qna_wrap {
    width: 100%;
    margin-top: 10px;
  }
  .mypage_content_qna_wrap .mypage_content_qna_ul {
    display: flex;
    margin-bottom: 10px;
  }
  .mypage_content_qna_wrap .mypage_content_qna_ul li {
    font-size: 18px;
    font-weight: 500;
    margin-right: 12px;
  }
  .mypage_content_qna_wrap .mypage_content_qna_ul .qna_ul_li {
    position: relative;
    padding: 4px 0;
    cursor: pointer;
  }
  .mypage_content_qna_wrap
    .mypage_content_qna_ul
    .qna_ul_li.qna_ul_active::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    background-color: #00c73c;
    height: 3px;
    border-radius: 2px;
    z-index: 1;
  }
  .qna_wrap .mypage_content_qna_box {
    width: 90%;
  }
  .mypage_content_qna_wrap .mypage_content_qna_top {
    display: flex;
  }

  .mypage_content_qna .mypage_qna_table,
  .mypage_content_wait .mypage_wait_table {
    /* border-bottom: 1px solid #5c5c5c; */

    width: 100%;
  }
  .mypage_qna_table table,
  .mypage_wait_table table {
    width: 96%;
  }
  .mypage_qna_table .qna_table_head,
  .mypage_wait_table .qna_table_head {
    border-bottom: 2px solid #00c73c;
    height: 50px;
  }
  .mypage_qna_table .qna_table_head th,
  .mypage_wait_table .qna_table_head th {
    font-weight: 500;
  }
  .mypage_qna_table .qna_table_tr,
  .mypage_wait_table .qna_table_tr {
    /* display: flex; */
    text-align: center;
  }
  .qna_table_tbody .qna_table_tr {
    border-bottom: 1px solid #c1c1c1;
  }
  .qna_wrap .table_pagination {
    text-align: center;
  }
  .qna_wrap .table_not_data_wrap {
    width: 100%;
    height: 410px;
    border-bottom: 2px solid #00c73c;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .qna_wrap .table_not_data {
    width: 100%;
    text-align: center;
    padding: 6px 0;
  }

  .qna_wrap .mypage_content_qna_box .mypage_qna_price_box {
    margin-left: 5px;
  }
  .qna_wrap
    .mypage_content_qna_box
    .mypage_qna_price_box
    .mypage_qna_datepicker {
    border: 1px solid #dadde6;
    margin: 0 10px;
  }
  .qna_wrap .mypage_content_qna_box .mypage_qna_price_box input {
    /* text-align: right; */
    width: 90px;
    text-align: center;
    font-size: 14px;
  }
  .qna_wrap
    .mypage_content_qna_box
    .mypage_qna_price_box
    .mypage_qna_datepicker_ico {
    padding: 0 3px;
    font-size: 12px;
    margin: 0 5px;
    /* background-color: red; */
  }
  .qna_wrap .mypage_content_qna_box .mypage_qna_filter_box .filter_text {
    margin: 0 2px;
    font-size: 12px;
    width: 54px;
    border-radius: 4px;
  }
  .qna_wrap
    .mypage_content_qna_box
    .mypage_qna_filter_box
    .filter_text.filter_active {
    background: #00c73c;
    color: #fffdff;
    font-weight: 600;
  }

  .qna_wrap .mypage_content_qna_box .filter_text_active {
    background-color: #5e5e5e;
    color: #fff;
    font-weight: 700;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    line-height: 2.4em;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
  }

  .qna_wrap .mypage_content_qna_box .filter_text {
    border: 1px solid #e0e0e0;
    font-size: 12px;
    line-height: 2.4em;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
  }
  /* @media all and (max-width: 1200px) {
    .mypage_content_qna_wrap .mypage_content_qna_top {
      flex-wrap: wrap;
    }
    .mypage_qna_filter_box {
      margin-top: 10px;
    }
    .ivest_wrap
      .mypage_content_qna_box
      .mypage_invest_filter_box
      .filter_text {
      margin: 0 2px;
      font-size: 12px;
      width: 64px;
      border-radius: 4px;
    }
  } */

  .qna_content_wrap {
    width: 100%;
    /* min-height: 600px; */
    /* background-color: red; */
    margin: 0 auto;
    margin-top: 30px;
    /* border-bottom: 1px solid #00c73c; */
  }

  .qna_content {
    width: 100%;
    height: auto;
    border-top: 2px solid #00c73c;
    border-bottom: 2px solid #00c73c;
  }

  .qna_content_wrap .qna_content_list {
    height: auto;
    padding: 10px 20px;
    display: flex;

    /* border-bottom: 1px solid #00c73c; */
  }
  .qna_content_wrap .qna_content_list:first-child,
  .qna_content_wrap .qna_content_list:nth-child(2) {
    align-items: center;
  }

  .qna_content_wrap .qna_content_list:first-child .qna_list_right {
    padding: 0 10px;
  }
  .qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv {
    width: 100%;
    height: 34px;
    border: 1px solid #cacaca;
    border-radius: 5px;
  }
  .qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv input {
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  .qna_content_wrap .qna_content_list:nth-child(3) {
    align-items: flex-start;
  }
  .qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv {
    width: 100%;
    height: 160px;
    border: 1px solid #cacaca;
    border-radius: 5px;
  }
  .qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv textarea {
    resize: none;
    background: transparent;
    width: 100%;
    height: 100%;
    outline: none;
    padding: 10px;
    font-size: 14px;
  }

  .qna_content_wrap .qna_content_list:last-child {
    border-bottom: 0;
  }
  .qna_content_wrap .qna_content_list .qna_list_left {
    /* font-size: 16px; */
    /* padding: 10px 0; */
    width: 100px;
    /* border-right: 1px solid #00c73c; */
  }
  .qna_content_wrap .qna_content_list .qna_list_right {
    /* background-color: red; */
    width: 100%;
  }
  .qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv {
    width: 100%;
  }
  .qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv input {
    width: 100%;
  }
  .qna_content_wrap .qna_content_list:last-child input {
    /* font-size: 12px; */

    height: 40px;
    /* border: 1px solid red; */
    /* line-height: 20px; */
    /* background-color: black; */
  }
  .qna_content_wrap .qna_content_list:last-child span {
    font-size: 12px;
    width: 100%;
    display: block;
    height: 20px;
    /* border: 1px solid red; */
    line-height: 20px;
    /* background-color: black; */
  }

  .qna_content_wrap .qna_button_div {
    width: 100%;
    text-align: right;
    margin-top: 5px;
    /* padding: 0 10px; */
  }
  .qna_button_div .qna_button {
    width: 120px;
    height: 38px;
    font-weight: 600;
    background-color: #00c73c;
    color: #fff;
    border-radius: 5px;
  }
  /* 1:1 문의 내용 */

  .qna_content_wrap_view {
    width: 100%;
    /* min-height: 600px; */
    /* background-color: red; */
    margin: 0 auto;
    margin-top: 30px;
    /* border-bottom: 1px solid #00c73c; */
  }

  .qna_content_wrap_view .qna_content_view:first-child {
    width: 100%;
    height: auto;
    border-top: 2px solid #00c73c;
    border-bottom: 2px solid #00c73c;
  }
  .qna_content_wrap_view .qna_content_view:last-child {
    width: 100%;
    height: auto;
    /* border-top: 2px solid #00c73c; */
    border-bottom: 2px solid #00c73c;
  }

  .qna_content_wrap_view .qna_content_list_view {
    height: auto;
    padding: 10px 20px;
    display: flex;
    margin: 0 10px;
    border-bottom: 1px solid #d1d1d1;
  }
  .qna_content_wrap_view .qna_content_list_view:first-child {
    align-items: center;
  }

  /* .qna_content_wrap_view .qna_content_list_view:first-child .qna_list_right_view {
    padding: 0 10px;
  } */
  .qna_content_wrap_view
    .qna_content_list_view:first-child
    .qna_right_inpdiv_view {
    width: 100%;
    height: auto;
    /* font-weight: 600; */
    /* background-color: red; */
    /* border: 1px solid #cacaca;
    border-radius: 5px; */
  }

  .qna_content_wrap_view .qna_content_list_view:nth-child(2) {
    align-items: flex-start;
  }
  .qna_content_wrap_view
    .qna_content_list_view:nth-child(2)
    .qna_right_inpdiv_view {
    width: 100%;
    height: 160px;
    /* border: 1px solid #cacaca; */
    border-radius: 5px;
    overflow: auto;
    padding: 0 4px;
  }
  .qna_content_wrap_view
    .qna_content_list_view:nth-child(2)
    .qna_right_inpdiv_view
    > div {
    height: 100%;
  }
  .qna_content_wrap_view
    .qna_content_list_view:nth-child(2)
    .qna_right_inpdiv_view
    textarea {
    resize: none;
    background: transparent;
    width: 100%;
    height: 100%;
    outline: none;
    padding: 10px;
    font-size: 14px;
  }

  .qna_content_wrap_view .qna_content_list_view:last-child {
    border-bottom: 0;
  }
  .qna_content_wrap_view .qna_content_list_view .qna_list_left_view {
    /* font-size: 16px; */
    /* padding: 10px 0; */
    width: 100px;
    /* border-right: 1px solid #00c73c; */
  }
  .qna_content_wrap_view .qna_content_list_view .qna_list_right_view {
    /* background-color: red; */
    width: 100%;
  }
  .qna_content_wrap_view
    .qna_content_list_view
    .qna_list_right_view
    .qna_right_inpdiv_view {
    width: 100%;
  }
  .qna_content_wrap_view
    .qna_content_list_view
    .qna_list_right_view
    .qna_right_inpdiv_view
    input {
    width: 100%;
  }
  .qna_content_wrap_view .qna_content_list_view:last-child input {
    /* font-size: 12px; */

    height: 40px;
    /* border: 1px solid red; */
    /* line-height: 20px; */
    /* background-color: black; */
  }
  .qna_content_wrap_view .qna_content_list_view:last-child span {
    font-size: 12px;
    width: 100%;
    display: block;
    height: 20px;
    /* border: 1px solid red; */
    line-height: 20px;
    /* background-color: black; */
  }

  .qna_content_wrap_view .qna_button_div_view {
    width: 100%;
    text-align: right;
    margin-top: 5px;
    /* padding: 0 10px; */
  }
  .qna_button_div_view .qna_button_view {
    width: 120px;
    height: 38px;
    font-weight: 600;
    background-color: #00c73c;
    color: #fff;
    border-radius: 5px;
  }
}

@media all and (max-width: 650px) {
  .mypage_wrap {
    width: 100%;
    min-height: 640px;
    background-color: #ebeef3;
  }
  .mypage_wrap .mypage_inner_wrap {
    max-width: 100%;

    padding: 20px 20px 20px;
  }
  .mypage_inner_wrap .mypage_content_wrap {
  }

  .mypage_content_wrap .mypage_content {
    min-height: 520px;

    display: flex;
    flex-direction: column;
  }
  .mypage_content_wrap .mypage_content_left {
    width: 100%;
    /* height: 100px; */
    height: 140px;
    flex-direction: column;
    padding: 0;
  }

  .mypage_content_left .mypage_left_info {
    height: 100px !important;
    flex: auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c1c1c1;
    padding-left: 24px;
  }
  .mypage_left_info .mypage_info_img {
    max-width: 60px;
    height: 60px;
  }

  .mypage_left_info_box {
    /* padding-left: 15px; */
    padding-left: 0;
    padding-right: 24px;
    /* text-align: right;
     */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .mypage_left_info .mypage_info_name {
    font-size: 14px;
  }
  .mypage_left_info .mypage_info_logout {
    /* width: 80px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #5c5c5c;
    margin: 0;
    line-height: 14px;
    margin-top: 0px; */
    width: 80px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #5c5c5c;
    margin: 0;
    line-height: 14px;
    margin-top: 0px;
  }
  .mypage_info_logout button {
    width: 100%;
    font-size: 11px;
    height: 100%;
  }
  .mypage_icon_type {
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
  .mypage_menu {
    flex: auto;
    height: 40px;
    height: auto;
    /* flex: 1;
    height: auto; */
  }
  .mypage_menu ul {
    flex-direction: row;
    align-items: center;
    height: 100%;
    /* flex-direction: row;
    align-items: center; */
  }
  .mypage_menu .mypage_menu_li {
    /* width: 100%;
    margin-bottom: 0;

    padding: 0 10px;
    border-bottom: 0;
    text-align: center;
    font-size: 15px; */
    margin-bottom: 0;
    /* width: 100%; */
    padding: 0;
    border-bottom: 0;
    text-align: center;
    font-size: 15px;
    flex: 1;
  }
  .mypage_menu .mypage_menu_li a {
    /* font-weight: 500;
    width: auto;
    text-align: center;
    border-bottom: 1px solid #c1c1c1;
    padding: 0 10px;
    font-size: 12px;
    line-height: 17px; */
    font-weight: 500;
    width: 100%;
    text-align: center;
    border-bottom: 0;
    padding: 0;
    font-size: 12px;
    line-height: 17px;
    /* margin: 0 auto; */
  }
  .mypage_content_wrap .mypage_content_right {
    /* flex: 1;

    min-height: 420px;
    background-color: #fff;
    margin-left: 0px;
    padding: 18px 24px;
    margin-top: 20px; */
    flex: 1;

    min-height: 280px;
    margin-left: 0px;
    padding: 15px 20px;
    margin-top: 20px;
  }

  .mypage_content_right .mypage_content_title {
    font-size: 19px;
    font-weight: 600;
  }

  .mypage_content_inner {
    width: 100%;
    padding: 20px;
    height: 380px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }
  .mypage_content_inner.profile_wrap {
    height: auto;
    height: 820px;
  }
  .mypage_activity {
    padding: 10px 20px;
    justify-content: center;
  }

  .mypage_activity ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 240px;
  }
  .mypage_activity li {
    width: 40%;
    height: auto;
    margin-right: 30px;
    /* border: 1px solid #77b69a; */
    border-radius: 10px;
  }
  .mypage_activity .mypage_activity_icon {
    font-size: 50px;

    padding: 14px 0;
  }

  .mypage_activity .mypage_activity_title {
    width: 100%;
    padding: 5px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #00c73c;
  }
  .mypage_activity .mypage_activity_cnt {
    padding: 30px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 40px;
  }
  .mypage_info_step_1 {
    padding: 10px 30px;
    justify-content: center;
  }

  .mypage_info_step_1 ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 200px;
    position: relative;
  }
  .mypage_info_step_1 li {
    width: 40%;
    height: 100%;
    /* border: 1px solid #77b69a; */
    border-radius: 10px;
    cursor: pointer;
    /* background: red; */
  }
  .mypage_info_step_1 li:first-child::before {
    display: block;
    width: 3px;
    height: 200px;
    background-color: #00c73c;
    position: absolute;
    right: 50%;
  }
  .mypage_info_step_1 .mypage_info_icon {
    width: 100%;
    height: auto;
    /* font-size: 20px; */
    text-align: center;
    padding: 20px 0;
    color: #00c73c;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #77b69a; */
  }
  .mypage_info_step_1 .mypage_info_icon svg {
    width: 70px;
    height: 70px;
  }
  .mypage_info_step_1 .mypage_info_icon img {
    width: 70px;
    height: 70px;
  }

  .mypage_info_step_1 .mypage_info_title {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 18px;

    /* border-bottom: 1px solid #77b69a; */
  }
  /* 프로필 편집 */
  .mypage_profile_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 0;
    /* background-color: red !important; */
  }
  .profile_section {
    display: flex;
    margin-bottom: 25px;
    /* background-color: red; */
    width: 80%;
    justify-content: space-between;
  }

  .profile_section label {
    /* background-color: red; */
    font-weight: 600;
    width: 90px;
    height: 34px;
    line-height: 30px;
    text-indent: 10px;
    font-size: 13px;
  }
  .profile_section .profile_section_right {
    width: 70%;
    height: auto;
    /* margin-left: 10px; */
  }
  .profile_section .profile_input_div {
    width: 100%;
  }
  .profile_section .profile_inp_box {
    /* border: 1px solid #5c5c5c; */
    width: 100%;
    height: 34px;
    display: flex;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
  }
  .profile_inp_box input {
    flex: 1;
    text-indent: 5px;
    font-size: 11px;
    background-color: transparent;
    height: 34px;

    /* background-color: rgb(233, 233, 233); */
  }
  .profile_inp_box span {
    flex-basis: 40px;
    text-align: right;
    font-size: 12px;
    padding-right: 5px;
    height: 34px;
    /* background-color: red; */
  }

  .profile_section_1 {
    width: 90px;
    height: 90px;
  }
  .profile_section_1 .profile_img_div {
    width: 90px;
    height: 90px;
  }

  .profile_section_1 .profile_camera {
    width: 24px;
    height: 24px;

    font-size: 14px;
  }

  .profile_section_3 .profile_input_div button,
  .profile_section_4 .profile_input_div button {
    flex-basis: 70px;
    font-size: 14px;
    height: 34px;
    background-color: #00c73c;
    font-weight: 500;
    color: #fff;
    padding: 0 10px;
    /* border-radius: 10 px; */
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-left: 4px;
  }

  .profile_section.profile_section_5 {
    width: 80%;
    position: relative;
    height: 80px;
    /* background-color: red; */
    padding-top: 20px;
    border-top: 1px solid #c3c3c3;
    display: flex;
  }
  .profile_section.profile_section_5 .profile_info_span {
    width: 140px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: -15px;
    right: 50%;
    margin-right: -70px;
    /* line-height: 15px; */
    line-height: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
  }

  .profile_section.profile_section_5 .profile_info_div {
    flex: 1;
  }
  .profile_info_div > div {
    /* padding-left: 5px; */
    text-align: center;
    font-size: 14px;
  }
  .profile_section.profile_section_5 .profile_info_div span {
    width: 100%;
    text-align: center;
    margin-bottom: 4px;
    font-weight: 500;
    /* text-indent: 2px; */
  }
  .profile_section.profile_section_5 label {
    /* background-color: red; */
    font-weight: 400;
    width: 50px;
    height: 26px;
    line-height: 26px;
    text-indent: 0px;
    /* background: red; */

    /* float: left; */
  }
  .profile_section.profile_section_5 select {
    width: 90%;
  }
  .mypage_profile_wrap .btn_box {
    width: 66%;
  }
  .mypage_profile_wrap .btn_box button {
    height: 34px;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: #00c73c;
    line-height: 34px;
    color: #fff;
    border-radius: 6px;
  }

  .profile_section.profile_section_6 {
    width: 80%;
    position: relative;
    height: auto;
    /* background-color: red; */
    padding-top: 20px;
    border-top: 1px solid #c3c3c3;
    display: flex;
    flex-direction: column;
  }
  .profile_section.profile_section_6 .profile_info_span {
    width: 160px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: -15px;
    right: 50%;
    margin-right: -80px;
    /* line-height: 15px; */
    line-height: 30px;
    text-align: center;
    font-weight: 500;
  }

  .profile_section_6 .profile_account_div.top_box {
    /* display: flex; */
    width: 100%;
  }
  .profile_section_6 .profile_account_div.top_box > div {
    flex: 1;
  }
  .profile_section_6 .profile_account_div.top_box > div:first-child {
    /* padding-right: 2px; */
  }
  .profile_section_6 .profile_account_div.top_box > div:last-child {
    /* padding-left: 2px; */
  }
  .profile_section_6 .profile_account_div.bottom_box > div {
    flex: 1;
  }
  .profile_section_6 .profile_account_div .profile_account_inp {
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    display: flex;
    width: 100%;
  }
  .profile_section_6 .profile_account_div .profile_account_inp input {
    flex: 1;
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    /* width: auto !important; */
  }
  .profile_section_6 .profile_account_div .profile_account_inp span {
    /* flex-basis: 40px; */
    /* display: block; */
    width: 30px;
    flex: none !important;
    text-align: center;
    font-size: 12px;
    /* padding-right: 5px; */
    height: 34px;
  }
  /* 비밀번호 변경 */
  .mypage_password_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 30px;
  }
  .mypage_password_wrap .mypage_password_inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 120px;
    /* width: 60%; */
  }
  .mypage_password_wrap .mypage_password_inner input {
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    width: 100%;
  }
  .mypage_password_confirm {
    height: 34px;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: #00c73c;
    line-height: 34px;
    color: #fff;
    border-radius: 6px;
  }

  .mypage_password_wrap .mypage_password_inner_1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 120px;
    width: 70%;
  }
  .mypage_password_wrap .mypage_password_inner_1 .mypage_password_section {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .mypage_password_wrap .mypage_password_inner_1 h4 {
    width: 100%;
    text-align: left;
    margin-bottom: 6px;
  }
  .mypage_password_wrap .mypage_password_inner_1 input {
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    width: 100%;
  }
  .mypage_interest_wrap .mypage_interest_box {
    /* width: 400px; */
    width: 100%;
    /* height: 350px; */
    position: relative;
  }

  .main_news_item {
    width: 100%;

    cursor: pointer;
  }

  .main_news_inner {
    /* border: 1px solid rgb(227, 227, 227); */
  }
  .main_news_inner .main_news_img {
  }

  .main_news_item .main_news_img img {
  }

  .main_news_item:hover .main_news_img img {
  }
  .main_news_item .main_news_content {
    width: 100%;
    height: auto;
    /* margin-top: 8px; */
  }
  .main_news_item .main_news_content .main_news_title {
    font-weight: 600;
    /* height: auto; */
    width: 100%;
    height: auto;
    line-height: 20px;
    /* background-color: red; */
    padding: 3px 0;
    font-size: 18px;
    /* max-width: 100%; */
    /* overflow: hidden; */
  }
  .main_news_content .main_news_title span {
    width: 100%;
    height: 100%;
  }

  .main_news_item .main_news_content .main_news_date {
    width: 100%;
    font-size: 12px;
    color: #848383;
    /* text-align: right; */
  }
  .main_news_item .main_news_content .main_news_date em {
    color: #00c73c;
    font-weight: bold;
    font-size: 14px;
    margin-right: 3px;
  }
  .main_news_item .main_news_content .main_news_cont {
    /* height: 50px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
    font-size: 13px;

    line-height: 18px;
    /* background-color: red; */
    /* white-space: pre; */
    /* height: 20px/; */
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul {
    margin-bottom: 6px;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul li {
    font-size: 14px;

    margin-right: 8px;
  }

  .mypage_invest_table_border {
    /* width: 1; */
    overflow: hidden;
    overflow-x: auto;
  }
  .mypage_invest_table table,
  .mypage_wait_table table {
    width: 140%;
    overflow-x: auto;
  }
  .mypage_content_inner.ivest_wrap {
    min-height: 520px;
    padding: 10px;
    /* justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%; */

    /* height: 260px; */
  }
}

@media all and (max-width: 550px) {
  .mypage_wrap {
    width: 100%;
    min-height: 500px;
    background-color: #ebeef3;
    padding-bottom: 40px;
  }
  .mypage_wrap .mypage_inner_wrap {
    max-width: 100%;

    padding: 20px 20px 20px;
  }
  .mypage_inner_wrap .mypage_content_wrap {
  }

  .mypage_content_wrap .mypage_content {
    min-height: 500px;

    display: flex;
    flex-direction: column;
  }
  .mypage_content_wrap .mypage_content_left {
    width: 100%;
    height: 140px;
    flex-direction: column;
    padding: 0;
  }

  .mypage_content_left .mypage_left_info {
    height: 100px !important;
    flex: auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #c1c1c1;
    padding-left: 24px;
  }

  .mypage_left_info .mypage_info_img {
    max-width: 60px;
    height: 60px;
  }

  .mypage_left_info_box {
    padding-left: 0;
    padding-right: 24px;
    /* text-align: right;
     */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .mypage_left_info .mypage_info_name {
    font-size: 14px;
  }
  .mypage_left_info .mypage_info_logout {
    width: 80px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #5c5c5c;
    margin: 0;
    line-height: 14px;
    margin-top: 0px;
  }
  .mypage_info_logout button {
    width: 100%;
    font-size: 11px;
    height: 100%;
  }
  .mypage_icon_type {
    width: 14px;
    height: 14px;
    margin-left: 4px;
  }
  .mypage_menu {
    flex: auto;
    height: 40px;
    height: auto;
  }
  .mypage_menu ul {
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
  .mypage_menu .mypage_menu_li {
    margin-bottom: 0;
    /* width: 100%; */
    padding: 0;
    border-bottom: 0;
    text-align: center;
    font-size: 15px;
    flex: 1;
  }
  .mypage_menu .mypage_menu_li a {
    font-weight: 500;
    width: 100%;
    text-align: center;
    border-bottom: 0;
    padding: 0;
    font-size: 12px;
    line-height: 17px;
    /* margin: 0 auto; */
  }
  .mypage_content_wrap .mypage_content_right {
    flex: 1;

    min-height: 280px;

    padding: 15px 20px;
    margin-top: 20px;
  }

  .mypage_content_right .mypage_content_title {
    font-size: 18px;
    font-weight: 600;
  }

  .mypage_content_inner {
    width: 100%;
    padding: 20px 10px;
    height: 260px;
    display: flex;
    /* background-color: red; */
    /* justify-content: center;
    align-items: center; */
  }
  .mypage_content_inner.profile_wrap {
    height: auto;
    height: 300px;
  }
  .mypage_activity {
    padding: 10px 20px;
    justify-content: center;
  }

  .mypage_activity ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 240px;
    /* background-color: red; */

    /* background-color: red; */
  }
  .mypage_activity li {
    width: 100%;
    height: auto;
    margin-right: 0px;
    /* border: 1px solid #77b69a; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #00c73c;
  }
  .mypage_activity .mypage_activity_icon {
    font-size: 50px;

    padding: 14px 0;
    border-bottom: 0;
    flex: 1;
  }

  .mypage_activity .mypage_activity_title {
    flex: 1;
    padding: 5px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 0;
  }
  .mypage_activity .mypage_activity_cnt {
    padding: 30px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 40px;
    flex: 1;
  }
  .mypage_content_inner.mypage_info_wrap {
    height: auto;
  }
  .mypage_info_step_1 {
    padding: 10px 30px;
    justify-content: center;
  }

  .mypage_info_step_1 ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 200px;
  }
  .mypage_info_step_1 li {
    width: 100%;
    height: auto;
    /* border: 1px solid #77b69a; */
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #00c73c;
    cursor: pointer;
    /* margin: 0; */
    /* background: red; */
  }
  .mypage_info_step_1 li:first-child::before {
    display: none;
  }
  .mypage_info_step_1 .mypage_info_icon {
    width: 100%;
    height: auto;
    /* font-size: 20px; */
    text-align: center;
    padding: 10px 0;
    color: #00c73c;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #77b69a; */
  }
  .mypage_info_step_1 .mypage_info_icon svg {
    width: 50px;
    height: 50px;
  }
  .mypage_info_step_1 .mypage_info_icon img {
    width: 50px;
    height: 50px;
  }

  .mypage_info_step_1 .mypage_info_title {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: #00c73c;
    font-weight: 700;
    font-size: 16px;

    /* border-bottom: 1px solid #77b69a; */
  }

  .mypage_content_inner.profile_wrap {
    height: auto;
    padding: 10px 20px;
  }
  .mypage_profile_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 0;
    /* background-color: red !important; */
  }
  .profile_section {
    display: flex;
    margin-bottom: 20px;
    /* background-color: red; */
    width: 100%;
    justify-content: space-between;
  }

  .profile_section label {
    /* background-color: red; */
    font-weight: 600;
    width: 90px;
    height: 34px;
    line-height: 30px;
    text-indent: 10px;
    font-size: 13px;
  }
  .profile_section .profile_section_right {
    width: 70%;
    height: auto;
    /* margin-left: 10px; */
  }

  .profile_section .profile_input_div {
    width: 100%;
  }
  .profile_section .profile_inp_box {
    /* border: 1px solid #5c5c5c; */
    width: 100%;
    height: 34px;
    display: flex;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
  }
  .profile_inp_box input {
    flex: 1;
    text-indent: 5px;
    font-size: 11px;
    background-color: transparent;
    height: 34px;

    /* background-color: rgb(233, 233, 233); */
  }
  .profile_inp_box span {
    flex-basis: 40px;
    text-align: right;
    font-size: 12px;
    padding-right: 5px;
    height: 34px;
    /* background-color: red; */
  }

  .profile_section_1 {
    width: 90px;
    height: 90px;
  }
  .profile_section_1 .profile_img_div {
    width: 90px;
    height: 90px;
  }

  .profile_section_1 .profile_camera {
    width: 24px;
    height: 24px;

    font-size: 14px;
  }

  .profile_section_3 .profile_input_div button,
  .profile_section_4 .profile_input_div button {
    flex-basis: 70px;
    font-size: 14px;
    height: 34px;
    background-color: #00c73c;
    font-weight: 500;
    color: #fff;
    padding: 0 10px;
    /* border-radius: 10 px; */
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-left: 4px;
  }

  .profile_section.profile_section_5 {
    width: 100%;
    position: relative;
    height: 74px;
    /* background-color: red; */
    padding-top: 10px;
    border-top: 1px solid #c3c3c3;
    display: flex;
  }
  .profile_section.profile_section_5 .profile_info_span {
    width: 140px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: -15px;
    right: 50%;
    margin-right: -70px;
    /* line-height: 15px; */
    line-height: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
  }

  .profile_section.profile_section_5 .profile_info_div {
    flex: 1;
  }
  .profile_info_div > div {
    /* padding-left: 5px; */
    text-align: center;
    font-size: 12px;
  }
  .profile_section.profile_section_5 .profile_info_div span {
    width: 100%;
    text-align: center;
    margin-bottom: 4px;
    font-weight: 500;
    /* text-indent: 2px; */
  }
  .profile_section.profile_section_5 label {
    /* background-color: red; */
    font-weight: 400;
    width: 30px;
    height: 26px;
    line-height: 26px;
    text-indent: 0px;
    /* background: red; */

    /* float: left; */
  }
  .profile_section.profile_section_5 select {
    width: 90%;
  }

  .profile_section.profile_section_6 {
    width: 100%;
    position: relative;
    height: auto;
    /* background-color: red; */
    padding-top: 20px;
    border-top: 1px solid #c3c3c3;
    display: flex;
    flex-direction: column;
  }
  .profile_section.profile_section_6 .profile_info_span {
    width: 160px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: -15px;
    right: 50%;
    margin-right: -80px;
    /* line-height: 15px; */
    line-height: 30px;
    text-align: center;
    font-weight: 500;
  }

  .profile_section_6 .profile_account_div.top_box {
    /* display: flex; */
    width: 100%;
  }
  .profile_section_6 .profile_account_div.top_box > div {
    flex: 1;
  }
  .profile_section_6 .profile_account_div.top_box > div:first-child {
    /* padding-right: 2px; */
  }
  .profile_section_6 .profile_account_div.top_box > div:last-child {
    /* padding-left: 2px; */
  }
  .profile_section_6 .profile_account_div.bottom_box > div {
    flex: 1;
  }
  .profile_section_6 .profile_account_div .profile_account_inp {
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    display: flex;
    width: 100%;
  }
  .profile_section_6 .profile_account_div .profile_account_inp input {
    flex: 1;
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    /* width: auto !important; */
  }
  .profile_section_6 .profile_account_div .profile_account_inp span {
    /* flex-basis: 40px; */
    /* display: block; */
    width: 30px;
    flex: none !important;
    text-align: center;
    font-size: 12px;
    /* padding-right: 5px; */
    height: 34px;
  }
  .mypage_profile_wrap .btn_box {
    width: 66%;
  }
  .mypage_profile_wrap .btn_box button {
    height: 34px;
    width: 100%;
    padding: 0 10px;
    font-size: 16px;
    background-color: #00c73c;
    line-height: 34px;
    color: #fff;
    border-radius: 6px;
  }

  /* 비밀번호 변경 */
  .mypage_password_wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 20px;
    font-size: 14px;
  }
  .mypage_password_wrap .mypage_password_inner {
    height: 100px;
    /* width: 60%; */
  }
  .mypage_password_wrap .mypage_password_inner input {
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 30px;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    width: 100%;
  }
  .mypage_password_confirm {
    height: 30px;
    width: 100%;
    padding: 0 10px;
    font-size: 14px;
    background-color: #00c73c;
    line-height: 34px;
    color: #fff;
    border-radius: 6px;
  }

  .mypage_password_wrap .mypage_password_inner_1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 120px;
    font-size: 14px;
    width: 80%;
  }
  .mypage_password_wrap .mypage_password_inner_1 .mypage_password_section {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .mypage_password_wrap .mypage_password_inner_1 h4 {
    width: 100%;
    text-align: left;
    margin-bottom: 6px;
  }
  .mypage_password_wrap .mypage_password_inner_1 input {
    text-indent: 5px;
    font-size: 13px;
    background-color: transparent;
    height: 34px;
    border: 2px solid #dfdfdf;
    background-color: rgb(233, 233, 233);
    border-radius: 6px;
    width: 100%;
  }
  .error_em {
    font-size: 10px;
    /* color: #00c73c; */
    /* height: 20px; */
    line-height: 14px;
    height: 20px;

    /* background-color: red; */
  }
  .mypage_content_inner.ivest_wrap {
    min-height: 520px;
    padding: 5px 10px;
    /* justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%; */

    /* height: 260px; */
  }

  .mypage_content_inner .mypage_content_invest,
  .mypage_content_inner .mypage_content_wait {
    width: 100%;
    height: 320px;
  }

  .mypage_content_invest_wrap {
    width: 100%;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul {
    display: flex;
    margin-bottom: 8px;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul li {
    font-size: 14px;

    margin-right: 10px;
  }
  .mypage_content_invest_wrap .mypage_content_invest_ul .invest_ul_li {
    position: relative;
    padding: 4px 0;
    cursor: pointer;
  }

  .mypage_content_invest_wrap
    .mypage_content_invest_ul
    .invest_ul_li.invest_ul_active::after {
  }

  .mypage_content_invest .mypage_invest_table,
  .mypage_content_wait .mypage_wait_table {
    /* border-bottom: 1px solid #5c5c5c; */

    width: 100%;
  }
  .mypage_invest_table table,
  .mypage_wait_table table {
    /* width: 100%; */
  }
  .mypage_invest_table .invest_table_head,
  .mypage_wait_table .invest_table_head {
    height: 34px;
    font-size: 14px;
  }
  .mypage_invest_table .invest_table_head th,
  .mypage_wait_table .invest_table_head th {
    font-size: 12px;
  }
  .mypage_invest_table .invest_table_tr,
  .mypage_wait_table .invest_table_tr {
    /* display: flex; */
    text-align: center;
  }
  .invest_table_tbody .invest_table_tr {
    border-bottom: 1px solid #c1c1c1;
    font-size: 12px;
    /* height: 20px; */
  }
  .ivest_wrap .table_pagination {
    text-align: center;
  }
  .ivest_wrap .table_not_data {
    width: 100%;
    text-align: center;
    padding: 6px 0;
  }
  .mypage_content_invest_box .mypage_invest_top_span {
    font-size: 12px;
  }
  .ivest_wrap .mypage_content_invest_box .mypage_invest_price_box {
    margin-left: 0;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_price_box
    .mypage_invest_datepicker {
    border: 1px solid #dadde6;
    margin: 0 10px;
  }
  .ivest_wrap .mypage_content_invest_box .mypage_invest_price_box input {
    /* text-align: right; */
    width: 70px;
    text-align: center;
    font-size: 12px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_price_box
    .mypage_invest_datepicker_ico {
    padding: 0 3px;
    font-size: 10px;
    margin: 0 5px;
    /* background-color: red; */
  }
  .ivest_wrap .mypage_content_invest_box .mypage_invest_filter_box {
    width: 100%;
    margin-top: 5px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_filter_box
    .filter_text {
    width: 50px;
    font-size: 10px;
  }
  .ivest_wrap
    .mypage_content_invest_box
    .mypage_invest_filter_box
    .filter_text.filter_active {
    background: #00c73c;
    color: #fffdff;
    font-weight: 600;
  }

  .ivest_wrap .mypage_content_invest_box .filter_text_active {
    background-color: #5e5e5e;
    color: #fff;
    font-weight: 700;
    border: 1px solid #e0e0e0;
    font-size: 12px;
    line-height: 2.4em;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
  }

  .ivest_wrap .mypage_content_invest_box .filter_text {
    border: 1px solid #e0e0e0;
    font-size: 12px;
    line-height: 2.4em;
    margin-bottom: 5px;
    text-align: center;
    width: 50px;
  }

  .mypage_interest_wrap .mySwiper {
    height: 100px;
    position: relative;
  }
  .mypage_interest_wrap .mySwiper.mySwiper_two {
    height: 200px;
  }
  .mypage_interest_wrap .mySwiper.mySwiper_three {
    height: 300px;
  }

  .mypage_interest_wrap .mypage_interest_box {
    /* width: 300px; */
    /* height: 250px; */
    position: relative;
  }
  .mypage_interest_wrap .slick_item_div {
    /* background-color: red; */
    width: 272px;
    height: 192px;
    /* margin-right: 20px; */
    /* padding: 0 10px; */
  }

  .main_news_item {
  }

  .main_news_inner {
    /* border: 1px solid rgb(227, 227, 227); */
  }
  .main_news_inner .main_news_img {
    width: 110px;
    height: 80px;
  }

  .main_news_item .main_news_img img {
  }

  .main_news_item:hover .main_news_img img {
  }
  .main_news_item .main_news_content {
    width: 100%;
    height: auto;
    padding: 6px 8px;
    /* margin-top: 8px; */
  }
  .main_news_item .main_news_content .main_news_title {
    font-weight: 600;
    /* height: auto; */
    width: 100%;
    height: auto;
    line-height: 20px;
    /* background-color: red; */
    padding: 3px 0;
    font-size: 18px;
    /* max-width: 100%; */
    /* overflow: hidden; */
  }
  .main_news_content .main_news_title span {
    width: 100%;
    height: 100%;
  }

  .main_news_item .main_news_content .main_news_date {
    width: 100%;
    font-size: 10px;
    color: #848383;
    /* text-align: right; */
  }
  .main_news_item .main_news_content .main_news_date em {
    color: #00c73c;
    font-weight: bold;
    font-size: 12px;
    margin-right: 3px;
  }
  .main_news_item .main_news_content .main_news_cont {
    /* height: 50px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
    font-size: 13px;
    width: 100%;
    height: auto;
    line-height: 14px;
    /* background-color: red; */
    /* white-space: pre; */
    /* height: 20px/; */
  }
}

/* 탈퇴 */

.my_delete_section_2 {
  margin-top: 20px;
  width: 100%;
}
.delete_section2_wrap {
  /* border-bottom: 1px solid rgb(237, 237, 237); */
  border-left: 0;
  border-right: 0;
}

.delete_section2_wrap > div {
  /* display: flex; */
  /* padding: 14px 5px; */
}

.delete_section2_wrap .mypage_delete_top {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  /* display: flex; */

  /* background-color: red; */
}
.delete_section2_wrap h5 {
  /* font-size: 14px; */
  font-weight: 500;
}
.delete_section2_wrap .mypage_delete_top .delete_top_box {
  padding-left: 10px;
  margin-top: 10px;
}
.delete_section2_wrap .mypage_delete_top p {
  font-size: 14px;
  height: auto;
  display: flex;
  align-items: center;
  /* background-color: red; */
}
.delete_section2_wrap .mypage_delete_top p svg {
  font-size: 20px;
}

.delete_section2_wrap .mypage_delete_bottom {
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  padding: 10px;
  width: 100%;
  margin: 30px 0;
  /* display: flex; */

  /* background-color: red; */
}
.delete_section2_wrap .mypage_delete_bottom .delete_bottom_box:last-child {
  margin-top: 30px;
}

.delete_section2_wrap .mypage_delete_bottom .delete_bottom_box ul {
  padding-left: 10px;
  margin: 4px 0;
  font-size: 14px;
}

.delete_section2_wrap
  .mypage_delete_bottom
  .delete_bottom_box
  ul
  li:last-child {
  /* display: flex;
  align-items: center; */
}
.delete_section2_wrap
  .mypage_delete_bottom
  .delete_bottom_box
  ul
  li:last-child
  .delete_reson_inp {
  padding-left: 20px;
}

.delete_section2_wrap
  .mypage_delete_bottom
  .delete_bottom_box
  ul
  li:last-child
  .delete_reson_inp
  input {
  border: 1px solid #cacaca;
  border-radius: 4px;
  width: 180px;
  /* margin-left: 8px;
  ; */
}

.delete_section2_wrap .mypage_delete_bottom .delete_bottom_box ul li > label {
  cursor: pointer;
}
.delete_section2_wrap
  .mypage_delete_bottom
  .delete_bottom_box
  ul
  li
  > label
  > input {
  margin-right: 5px;
}

.delete_section2_wrap .mypage_delete_bottom .delete_bottom_box .delete_confirm {
  width: 100%;
}
.delete_bottom_box .delete_confirm p {
  font-size: 14px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.delete_bottom_box .delete_confirm p:first-child span {
  font-weight: 500;
  width: 180px;
  /* margin: 0 4px; */
}
.delete_bottom_box .delete_confirm p:last-child {
  display: flex;
}
.delete_bottom_box .delete_confirm p:last-child input {
  border-radius: 4px;
  /* border: 1px solid #cacaca; */
  margin: 4px 2px;
  width: 180px;
  text-indent: 4px;
  font-size: 14px;
  background-color: #e6e6e6;
}
.delete_bottom_box .delete_confirm p:last-child > div {
  width: 180px;
  border-bottom: 1px solid #cacaca;
}

.delete_bottom_box .delete_confirm p button {
  background-color: #6e7881;
  border-radius: 2px;
  padding: 2px 5px;
  font-size: 12px;
  color: #fff;
  margin-left: 10px;
}

.delete_section2_wrap .mypage_delete_check label {
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  /* background-color: red; */
}
.delete_section2_wrap .mypage_delete_check label input {
  margin-right: 5px;
}
.delete_section2_wrap .mypage_delete_button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  /* background-color: red; */
}
.delete_section2_wrap .mypage_delete_button button {
  width: 120px;
  height: 34px;
  border-radius: 5px;
  /* border: 1px solid #848383; */
}
.delete_section2_wrap .mypage_delete_button button:first-child {
  font-weight: 500;
  background-color: #c7c7c7;
  margin-right: 5px;
}
.delete_section2_wrap .mypage_delete_button button:last-child {
  background-color: #00c73c;
  color: #fff;
  font-weight: 500;
}

/* 아래는 보류 */
.delete_section2_wrap .delete_user_title {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5em;
  color: rgb(64, 64, 64);
  width: 100px;
}
.delete_section2_wrap > .delete_user_3 > .delete_user_title {
  margin: auto 0;
}
.delete_section2_wrap .delete_user_title span {
  font-weight: normal;
  font-size: 10px;
  line-height: 1.5em;
  color: rgb(127, 127, 127);
}
.delete_section2_wrap .delete_user_content {
  width: 100%;
}
.delete_section2_wrap .delete_user_1 .delete_user_content ul {
  display: flex;
  flex-wrap: wrap;
}

.delete_section2_wrap .delete_user_1 .delete_user_content ul li {
  width: 170px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5em;
  color: rgb(127, 127, 127);
}

.delete_section2_wrap .delete_user_1 .delete_user_content ul li label {
  width: 100%;
  cursor: pointer;
}

.delete_section2_wrap .delete_user_1 .delete_user_content ul li label input {
  margin-right: 10px;
}
.delete_section2_wrap .delete_user_2 textarea {
  width: 100%;
  height: 140px;
  padding: 6px 12px;

  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-sizing: border-box;
  resize: none;
  font-size: 14px;
}
.delete_section2_wrap .delete_user_2 {
  border-top: 1px solid rgb(237, 237, 237);
}
.delete_section2_wrap .delete_user_3 {
  border-top: 1px solid rgb(237, 237, 237);
}
.delete_section2_wrap .delete_user_3 input {
  max-width: 300px;
  width: 100%;
  height: 40px;

  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-sizing: border-box;

  font-size: 14px;
  text-indent: 10px;
}
.delete_section2_wrap .delete_user_3 input::placeholder {
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5em;
  color: rgb(190, 190, 190);
}

.delete_btn_div {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete_btn_div button {
  font-size: 14px;

  text-align: center;
  line-height: 3.2em;
  border-radius: 4px;

  border-width: 1px;
  border-style: solid;
  border-color: rgb(224, 224, 224);
  font-weight: bold;
  width: 100px;
  height: 45px;

  margin: 0 10px;
}
.delete_btn_div .btn_move_re {
  color: rgb(64, 64, 64);
  background-color: rgb(255, 255, 255);
}
.delete_btn_div .btn_delete_bye {
  background-color: rgb(231, 76, 60);
  color: rgb(255, 255, 255);
}

/* 나의 문의내역 */
.mypage_content_tit_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mypage_content_tit_div > button {
  /* width: 94px; */
  height: 30px;
  font-size: 14px;
  /* background-color: #00c73c; */
  /* color: #fff; */
  font-weight: 500;
  /* color: #9a9a9a; */
  color: #00c73c;
  border-bottom: 1px solid #00c73c;
  /* border: 1px solid #9a9a9a; */
  padding: 0 4px;
  /* border-bottom: 1px solid #9a9a9a; */
  /* text-decoration: u; */
}
.mypage_content_inner.qna_wrap {
  /* justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%; */

  /* height: 260px; */
}
.mypage_content_inner.qna_wrap h4 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 5px;
  /* background-color: #77b69a; */
  background-color: #00c73c;
  padding: 5px 0;
  color: #fff;
  border-radius: 4px;
}

/* .mypage_content_inner .mypage_content_qna {
  width: 100%;
  height: 320px;
  background-color: red;
} */

.mypage_content_qna_wrap {
  width: 100%;
  margin-top: 20px;
}
.mypage_content_qna_wrap .mypage_content_qna_ul {
  display: flex;
  margin-bottom: 10px;
}
.mypage_content_qna_wrap .mypage_content_qna_ul li {
  font-size: 18px;
  font-weight: 500;
  margin-right: 12px;
}
.mypage_content_qna_wrap .mypage_content_qna_ul .qna_ul_li {
  position: relative;
  padding: 4px 0;
  cursor: pointer;
}
.mypage_content_qna_wrap
  .mypage_content_qna_ul
  .qna_ul_li.qna_ul_active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  background-color: #00c73c;
  height: 3px;
  border-radius: 2px;
  z-index: 1;
}
.mypage_content_qna_wrap .mypage_content_qna_top {
  display: flex;
  width: 100%;
}

@media (max-width: 1200px) {
  .mypage_content_qna_wrap .mypage_content_qna_top {
    flex-wrap: wrap;
    width: 90%;
  }
  .qna_wrap .mypage_content_qna_box .mypage_qna_filter_box {
    margin-top: 10px;
  }
}
.mypage_content_qna .mypage_qna_table,
.mypage_content_wait .mypage_wait_table {
  /* border-bottom: 1px solid #5c5c5c; */

  width: 100%;
}
.mypage_qna_table table,
.mypage_wait_table table {
  width: 96%;
}
.mypage_qna_table .qna_table_head,
.mypage_wait_table .qna_table_head {
  border-bottom: 2px solid #00c73c;
  height: 50px;
}
.mypage_qna_table .qna_table_head th,
.mypage_wait_table .qna_table_head th {
  font-weight: 500;
}
.mypage_qna_table .qna_table_tr,
.mypage_wait_table .qna_table_tr {
  /* display: flex; */
  text-align: center;
}
.qna_table_tbody .qna_table_tr {
  border-bottom: 1px solid #c1c1c1;
}
.qna_wrap .table_pagination {
  text-align: center;
  margin-top: 20px;
}
.qna_wrap .table_not_data_wrap {
  width: 100%;
  height: 410px;
  border-bottom: 2px solid #00c73c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.qna_wrap .table_not_data {
  width: 100%;
  text-align: center;
  padding: 6px 0;
}

.qna_wrap .mypage_content_qna_box .mypage_qna_price_box {
  margin-left: 5px;
}
.qna_wrap .mypage_content_qna_box .mypage_qna_price_box .mypage_qna_datepicker {
  border: 1px solid #dadde6;
  margin: 0 10px;
}
.qna_wrap .mypage_content_qna_box .mypage_qna_price_box input {
  /* text-align: right; */
  width: 90px;
  text-align: center;
  font-size: 14px;
}
.qna_wrap
  .mypage_content_qna_box
  .mypage_qna_price_box
  .mypage_qna_datepicker_ico {
  padding: 0 3px;
  font-size: 12px;
  margin: 0 5px;
  /* background-color: red; */
}
.qna_wrap .mypage_content_qna_box .mypage_qna_filter_box .filter_text {
  margin: 0 2px;
  font-size: 12px;
  width: 54px;
  border-radius: 4px;
}
.qna_wrap
  .mypage_content_qna_box
  .mypage_qna_filter_box
  .filter_text.filter_active {
  background: #00c73c;
  color: #fffdff;
  font-weight: 600;
}

.qna_wrap .mypage_content_qna_box .filter_text_active {
  background-color: #5e5e5e;
  color: #fff;
  font-weight: 700;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  line-height: 2.4em;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
}

.qna_wrap .mypage_content_qna_box .filter_text {
  border: 1px solid #e0e0e0;
  font-size: 12px;
  line-height: 2.4em;
  margin-bottom: 5px;
  text-align: center;
  width: 50px;
}
/* @media all and (max-width: 1200px) {
  .mypage_content_qna_wrap .mypage_content_qna_top {
    flex-wrap: wrap;
  }
  .mypage_qna_filter_box {
    margin-top: 10px;
  }
  .ivest_wrap
    .mypage_content_qna_box
    .mypage_invest_filter_box
    .filter_text {
    margin: 0 2px;
    font-size: 12px;
    width: 64px;
    border-radius: 4px;
  }
} */

.qna_content_wrap {
  width: 100%;
  /* min-height: 600px; */
  /* background-color: red; */
  margin: 0 auto;
  margin-top: 30px;
  /* border-bottom: 1px solid #00c73c; */
}

.qna_content {
  width: 100%;
  height: auto;
  border-top: 2px solid #00c73c;
  border-bottom: 2px solid #00c73c;
}

.qna_content_wrap .qna_content_list {
  height: auto;
  padding: 10px 20px;
  display: flex;

  /* border-bottom: 1px solid #00c73c; */
}
.qna_content_wrap .qna_content_list:first-child,
.qna_content_wrap .qna_content_list:nth-child(2) {
  align-items: center;
}

.qna_content_wrap .qna_content_list:first-child .qna_list_right {
  padding: 0 10px;
}
.qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv {
  width: 100%;
  height: 34px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.qna_content_wrap .qna_content_list:nth-child(2) .qna_right_inpdiv input {
  font-size: 14px;
  padding: 0 10px;
  width: 100%;
  height: 100%;
  background: transparent;
}

.qna_content_wrap .qna_content_list:nth-child(3) {
  align-items: flex-start;
}
.qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv {
  width: 100%;
  height: 160px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}
.qna_content_wrap .qna_content_list:nth-child(3) .qna_right_inpdiv textarea {
  resize: none;
  background: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 10px;
  font-size: 14px;
}

.qna_content_wrap .qna_content_list:last-child {
  border-bottom: 0;
}
.qna_content_wrap .qna_content_list .qna_list_left {
  /* font-size: 16px; */
  /* padding: 10px 0; */
  width: 100px;
  /* border-right: 1px solid #00c73c; */
}
.qna_content_wrap .qna_content_list .qna_list_right {
  /* background-color: red; */
  width: 100%;
}
.qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv {
  width: 100%;
}
.qna_content_wrap .qna_content_list .qna_list_right .qna_right_inpdiv input {
  width: 100%;
}
.qna_content_wrap .qna_content_list:last-child input {
  /* font-size: 12px; */

  height: 40px;
  /* border: 1px solid red; */
  /* line-height: 20px; */
  /* background-color: black; */
}
.qna_content_wrap .qna_content_list:last-child span {
  font-size: 12px;
  width: 100%;
  display: block;
  height: 20px;
  /* border: 1px solid red; */
  line-height: 20px;
  /* background-color: black; */
}

.qna_content_wrap .qna_button_div {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  /* padding: 0 10px; */
}
.qna_button_div .qna_button {
  width: 120px;
  height: 38px;
  font-weight: 600;
  background-color: #00c73c;
  color: #fff;
  border-radius: 5px;
}
/* 1:1 문의 내용 */

.qna_content_wrap_view {
  width: 100%;
  /* min-height: 600px; */
  /* background-color: red; */
  margin: 0 auto;
  margin-top: 30px;
  /* border-bottom: 1px solid #00c73c; */
}

.qna_content_wrap_view .qna_content_view:first-child {
  width: 100%;
  height: auto;
  border-top: 2px solid #00c73c;
  border-bottom: 2px solid #00c73c;
}
.qna_content_wrap_view .qna_content_view:last-child {
  width: 100%;
  height: auto;
  /* border-top: 2px solid #00c73c; */
  border-bottom: 2px solid #00c73c;
}

.qna_content_wrap_view .qna_content_list_view {
  height: auto;
  padding: 10px 20px;
  display: flex;
  margin: 0 10px;
  border-bottom: 1px solid #d1d1d1;
}
.qna_content_wrap_view .qna_content_list_view:first-child {
  align-items: center;
}

/* .qna_content_wrap_view .qna_content_list_view:first-child .qna_list_right_view {
  padding: 0 10px;
} */
.qna_content_wrap_view
  .qna_content_list_view:first-child
  .qna_right_inpdiv_view {
  width: 100%;
  height: auto;
  /* font-weight: 600; */
  /* background-color: red; */
  /* border: 1px solid #cacaca;
  border-radius: 5px; */
}

.qna_content_wrap_view .qna_content_list_view:nth-child(2) {
  align-items: flex-start;
}
.qna_content_wrap_view
  .qna_content_list_view:nth-child(2)
  .qna_right_inpdiv_view {
  width: 100%;
  height: 160px;
  /* border: 1px solid #cacaca; */
  border-radius: 5px;
  overflow: auto;
  padding: 0 4px;
}
.qna_content_wrap_view
  .qna_content_list_view:nth-child(2)
  .qna_right_inpdiv_view
  > div {
  height: 100%;
}
.qna_content_wrap_view
  .qna_content_list_view:nth-child(2)
  .qna_right_inpdiv_view
  textarea {
  resize: none;
  background: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 10px;
  font-size: 14px;
}

.qna_content_wrap_view .qna_content_list_view:last-child {
  border-bottom: 0;
}
.qna_content_wrap_view .qna_content_list_view .qna_list_left_view {
  /* font-size: 16px; */
  /* padding: 10px 0; */
  width: 100px;
  /* border-right: 1px solid #00c73c; */
}
.qna_content_wrap_view .qna_content_list_view .qna_list_right_view {
  /* background-color: red; */
  width: 100%;
}
.qna_content_wrap_view
  .qna_content_list_view
  .qna_list_right_view
  .qna_right_inpdiv_view {
  width: 100%;
}
.qna_content_wrap_view
  .qna_content_list_view
  .qna_list_right_view
  .qna_right_inpdiv_view
  input {
  width: 100%;
}
.qna_content_wrap_view .qna_content_list_view:last-child input {
  /* font-size: 12px; */

  height: 40px;
  /* border: 1px solid red; */
  /* line-height: 20px; */
  /* background-color: black; */
}
.qna_content_wrap_view .qna_content_list_view:last-child span {
  font-size: 12px;
  width: 100%;
  display: block;
  height: 20px;
  /* border: 1px solid red; */
  line-height: 20px;
  /* background-color: black; */
}

.qna_content_wrap_view .qna_button_div_view {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  /* padding: 0 10px; */
}
.qna_button_div_view .qna_button_view {
  width: 120px;
  height: 38px;
  font-weight: 600;
  background-color: #00c73c;
  color: #fff;
  border-radius: 5px;
}

.invest_my_data_box {
  /* width: 407px; */
  /* width: calc(33%-10px); */
  background: #00c73c;
  height: auto;
  border-radius: 4px;
  padding: 30px 20px;
  color: #fff;

  /* margin-top: 30px; */
}

.invest_my_data_box .invest_my_data_box_1 {
  margin-bottom: 40px;
  margin-top: 10px;
}
.invest_my_data_box_2 {
  margin-bottom: 30px;
  line-height: 30px;
}
.invest_my_data_box span {
  font-size: 15px;
}
.invest_my_data_box p {
  font-size: 21px;
}
.invest_my_data_box em {
  font-size: 31px;
  font-weight: 600;
}
.invest_my_data_box em span {
  font-size: 31px;
  font-weight: 600;
}
.invest_my_data_top {
  width: 100%;
  border-bottom: 2px solid #fff;
  padding-bottom: 9px;
}
.invest_my_data_top_div {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}
.invest_my_data_top_div > div {
  line-height: 9px;
  flex: 2;
}
.invest_my_data_top_div > div > span {
  font-size: 12px;
  line-height: 18px;
  display: block;
}
.invest_my_data_top_div > span {
  font-size: 12px;
}
.invest_my_data_box .invest_my_data_btn button {
  width: 100%;
  height: 40px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  color: #000;
  margin-bottom: 3px;
}

.invest_my_bottom {
  width: 100%;
  height: auto;
  margin-top: 30px;

  /* padding-top: 5px; */
}
.invest_my_bottom h4 {
  /* width: 407px; */
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
  /* text-align: center; */
}
.invest_my_bottom .invest_my_list_box {
  width: 100%;
  height: auto;

  position: relative;
}
.invest_myList_item {
  border: 2px solid #00c73c;
  border-radius: 5px;
  margin-right: 4px;
  padding: 40px 20px;
}
.invest_myList_item .invest_myList_title {
  font-size: 19px;
}

.invest_myList_item .invest_myList_join {
  font-size: 14px;
}
.invest_myList_item ul {
  margin-top: 25px;
  font-size: 15px;
}
.invest_myList_item ul li {
  display: flex;
  justify-content: space-between;
}
.invest_myList_item .myList_value {
  font-size: 18px;
  font-weight: 500;
}
.myList_account_box button {
  width: 100%;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00a370;
  margin-top: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #00a370;
}

.invest_my_bottom .invest_my_list_box {
  width: 100%;
  height: auto;

  position: relative;
}
.invest_myList_end_item {
  border: 2px solid #00c73c;
  border-radius: 5px;
  margin-right: 4px;
  padding: 40px 20px;
}
.invest_myList_end_item .invest_myList_end_title {
  font-size: 19px;
}

.invest_myList_end_item .invest_myList_end_join {
  font-size: 14px;
}
.invest_myList_end_item ul {
  margin-top: 25px;
  font-size: 15px;
}
.invest_myList_end_item ul li {
  display: flex;
  justify-content: space-between;
}
.invest_myList_end_item ._myList_end_value {
  font-size: 18px;
  font-weight: 500;
}
.invest_myList_end_item button {
  width: 100%;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00a370;
  margin-top: 10px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  color: #00a370;
}
.invest_my_data_end_not {
  width: 100%;
  aspect-ratio: 3/0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #898989;
  font-size: 15px;
}
.em_white {
  font-weight: 600;
  color: #fff;
}

@media all and (max-width: 880px) {
  /* 투자 상품 상세 880px */
  .mypage_content_inner.invest_detail_wrap {
    /* min-height: ; */
    height: auto;
  }
  .mypage_investdetail_wrap {
    width: 100%;
    height: 440px;
  }

  .invest_my_data_box {
    /* width: 407px; */
    /* width: calc(33%-10px); */
    background: #00c73c;
    height: auto;
    border-radius: 4px;
    padding: 26px 16px;
    color: #fff;

    /* margin-top: 30px; */
  }

  .invest_my_data_box .invest_my_data_box_1 {
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .invest_my_data_box_2 {
    margin-bottom: 20px;
    line-height: 30px;
  }
  .invest_my_data_box span {
    font-size: 14px;
  }
  .invest_my_data_box p {
    font-size: 18px;
  }
  .invest_my_data_box em {
    font-size: 28px;
  }
  .invest_my_data_box em span {
    font-size: 28px;
  }
  .invest_my_data_top {
    padding-bottom: 7px;
  }
  .invest_my_data_top_div {
    margin-top: 4px;
  }
  .invest_my_data_top_div > div {
    line-height: 9px;
    flex: 2;
  }
  .invest_my_data_top_div > div > span {
    font-size: 12px;
    line-height: 18px;
  }
  .invest_my_data_top_div > span {
    font-size: 12px;
  }
  .invest_my_data_box .invest_my_data_btn button {
    height: 36px;

    margin-bottom: 2px;
  }

  .invest_my_bottom {
    margin-top: 26px;

    /* padding-top: 5px; */
  }
  .invest_my_bottom h4 {
    margin-bottom: 13px;
  }
  .invest_my_bottom .invest_my_list_box {
  }
  .invest_myList_item {
    border: 2px solid #00c73c;
    border-radius: 5px;
    margin-right: 4px;
    padding: 40px 20px;
  }
  .invest_myList_item .invest_myList_title {
    font-size: 19px;
  }

  .invest_myList_item .invest_myList_join {
    font-size: 14px;
  }
  .invest_myList_item ul {
    margin-top: 25px;
    font-size: 15px;
  }
  .invest_myList_item ul li {
    display: flex;
    justify-content: space-between;
  }
  .invest_myList_item .myList_value {
    font-size: 18px;
    font-weight: 500;
  }
  .myList_account_box button {
    width: 100%;
    height: 34px;
    line-height: 34px;
    border: 1px solid #00a370;
    margin-top: 10px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    color: #00a370;
  }

  .invest_my_bottom .invest_my_list_box {
    width: 100%;
    height: auto;

    position: relative;
  }
  .invest_myList_end_item {
    border: 2px solid #00c73c;
    border-radius: 5px;
    margin-right: 4px;
    padding: 40px 20px;
  }
  .invest_myList_end_item .invest_myList_end_title {
    font-size: 19px;
  }

  .invest_myList_end_item .invest_myList_end_join {
    font-size: 14px;
  }
  .invest_myList_end_item ul {
    margin-top: 25px;
    font-size: 15px;
  }
  .invest_myList_end_item ul li {
    display: flex;
    justify-content: space-between;
  }
  .invest_myList_end_item ._myList_end_value {
    font-size: 18px;
    font-weight: 500;
  }
  .invest_myList_end_item button {
    width: 100%;
    height: 34px;
    line-height: 34px;
    border: 1px solid #00a370;
    margin-top: 10px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    color: #00a370;
  }
  .invest_my_data_end_not {
    width: 100%;
    aspect-ratio: 3/0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #898989;
    font-size: 15px;
  }
}
